/* eslint-disable */

import axios from 'axios';
import instance from './frontman';

export const getProgress = async (accessToken?: String) => {
    return await instance({
        url: "/progress",
        method: "get"
    })
};