/* eslint-disable */

import axios from 'axios';
import instance from './frontman';

export const getUser = async (accessToken?: String) => {
    return await instance({
        url: "/user",
        method: "get"
    })
};

export const postUser = async (body: object, accessToken?: String) => {
    return await instance({
        url: "/user",
        method: "post",
        data: body
    })
};