/**-------------------------------------------------------------
1.2.4.1 EV쿠폰 메인화면_3 - 쿠폰미등록
-------------------------------------------------------------
> http://basic.pointweb.kr/charge/public/html/public/coupon.html
-------------------------------------------------------------*/

/* eslint-disable */

// 기본
import React, { useState, useEffect, useCallback, ChangeEvent } from 'react';

import { RouteComponentProps, Link } from 'react-router-dom';

//헤더 
import { Header } from '../../components/Layout';
import { HandleBrowserBackButton } from '../../components/Layout'
import { globalVars } from '../../libs/global-vars';
import { tmapInterface } from '../../libs/tmap-interface';

//AxioS
import axios from 'axios'
import Const from '../../store/ev';
import { utils } from '../../libs/utils';
import { modal } from '../../components/GlobalModal';
import { appActions, store } from '../../store';

//Mixpanel
import { logbox, mixpanel } from '../../libs/logbox-mixpanel';

import btnAdd from '../../assets/images/coupon/btn_coupon_add.svg';
import { deleteSearch, getAcfSuggest, getSearch, patchSearch, postSearch } from '../../apis/search';

let scrollCache = 0;

let COUPON_USAGE = { '0': '완료', '1': '만료', '2': '유효' };

let cardInfo: any = {};	// 카드정보

let keyword: any = {};
let currentLon: any = {};
let currentLat: any = {};

function PoiSearch(props: RouteComponentProps<{ id: string }>) {

    const [inited, setInited] = useState(true)

    const [keywordList, setKeyworkList] = useState([{ keyword: "" }]);
    const [searchKeyword, setSearchKeyword] = useState(""); 
    const [searchResult, setSearchResult] = useState<any>([]);
    const [searchStatus, setSearchStatus] = useState(false);

    const [searchHistory, setSearchHistory] = useState<any>([]);
    const [historyStatus, setHistoryStatus] = useState(false);

    const [deletePop, setDeletePop] = useState(false);

    const [keyValue, setKeyValue] = useState("");
    const isPolestar = store.getState().app.isPolestar;


    useEffect(() => {
        tmapInterface.getCurrentPosition(currentPosition);
        // getLocation();
        retrieve();
        console.log(searchHistory);

        mixpanel("evcharge:/evcharge/stationsearch");
        logbox("/evcharge/stationsearch", "");
    }, [inited])

    // function getLocation(){ //현재위치 호출 및 position에 저장
    //     navigator.geolocation.getCurrentPosition(function(currentPosition) {

    //         currentLon = currentPosition.coords.longitude;
    //         currentLat = currentPosition.coords.latitude;

    //         //alert("LON - " + Math.floor(currentPositionLon));
    //         //alert("LAT - " + Math.floor(currentPositionLat));

    //     }, function(error) {
    //         console.error(error);
    //     }, {
    //         enableHighAccuracy: false,
    //         maximumAge: 0,
    //         timeout: Infinity
    //     });
    // }

    function currentPosition(latitude: string, longitude: string) {
        if (Const.DBG_OK) {
            alert("currentPosition" + JSON.stringify(latitude) + JSON.stringify(longitude));
        }
        //qr코드가 '' 빈값일때 qr인증실패로 이동

        currentLon = Number(longitude)
        currentLat = Number(latitude)

    }

    const goStart = () => {
        // 사용자 동의 Y로 변경
        props.history.replace('/main');
    }

    const goBack = () => {
        mixpanel("evcharge:/evcharge/stationsearch:tap.back");
        logbox("/evcharge/stationsearch", "tap.back");

        props.history.replace('/main');
    }

    const onChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
        const { name, value } = e.target;
        const list = [...keywordList];
        list[index]["keyword"] = value;
        if (value.length > 0) {
            setSearchKeyword(value);
        } else {

        }
        setKeyworkList(list);
        setKeyValue(e.target.value);
    };

    useEffect(() => {
        if (searchKeyword.length > 0) {
            const timeout = setTimeout(() => searchStation(searchKeyword), 50); //0.05s 자동완성
            return () => clearTimeout(timeout);
        }
    }, [searchKeyword])

    const searchStation = (keywordData) => {
        getAcfSuggest(currentLat, currentLon, keywordData)
            .then(function (response: any) {
                keyword = response;
                setSearchStatus(true);
                setSearchResult(keyword.response.suggestions)
            })
            .catch(function (error: any) {
                console.log(error);
            });

    };

    // const goSearchNearby = () => {
    //     tmapInterface.tSearchNearby();
    // }

    const retrieve = () => {
        getSearch()
            .then(function (response: any) {
                //alert(JSON.stringify(response.data))
                //console.log(response.data)

                if (response.result) {
                    setHistoryStatus(true);
                    setSearchHistory(response.searchHistory);
                }

            })
            .catch(function (error: any) {
                //alert(error);
                console.log(error);
            });
    };

    const regist = () => {

        if (searchKeyword !== '') {

            postSearch(searchKeyword)
                .then(function (response: any) {
                    searchStation(searchKeyword);
                })
                .catch(function (error: any) {
                    console.log(error);
                });

        }

    };

    const enterRegist = (e) => {

        if (e.key == 'Enter' && searchKeyword !== '') {

            e.target.blur();

            tmapInterface.tSearchNearby(searchResult[0].coordinates.lat, searchResult[0].coordinates.lon, Number(currentLat), Number(currentLon));
            setTimeout(() => {
                props.history.replace('/main');
            }, 500);

            mixpanel("evcharge:/evcharge/stationsearch:tap.search");
            logbox("/evcharge/stationsearch", "tap.search");

            postSearch(searchKeyword)
                .then(function (response: any) {
                    searchStation(searchKeyword);
                })
                .catch(function (error: any) {
                    console.log(error);
                });
        }

    };

    const singleDelete = (item) => {

        deleteSearch(item.searchHistoryIdx)
            .then(function (response: any) {
                if (response.result) {
                    retrieve();
                }
            })
            .catch(function (error: any) {
                console.log(error);
            });

    };

    const allDeletePop = () => {
        modal.alert(
            () => allDelete(), // 확인 버튼을 클릭할 때 실행될 콜백 함수
            '최근 기록을 전체<br/>삭제하시겠습니까?',   // 컨텐츠 제목 (선택 사항)
            '',
            true,
            () => allDeletePopCancel()
        );
    }

    const allDeletePopCancel = () => {
        setDeletePop(false);
    }

    const allDelete = () => {

        deleteSearch()
            .then(function (response: any) {
                if (response.result) {
                    setDeletePop(false);
                    retrieve();
                }
            })
            .catch(function (error: any) {
                console.log(error);
            });

    };

    const update = (item) => {

        patchSearch(item.searchHistoryIdx)
            .then(function (response: any) {
                if (response.result) {
                    searchStation(item.keyword);
                }
            })
            .catch(function (error: any) {
                console.log(error);
            });
    };

    const keywordSelect = (keyword: string) => {
        if (keyword.includes(searchKeyword)) {
            // let str = keyword.indexOf(keyValue);

            let word1 = keyword.substring(0, searchKeyword.length);
            let word2 = keyword.substring(searchKeyword.length);

            //키워드 위치 반환
            let keyIndex = keyword.indexOf(searchKeyword);
            let key1 = keyword.substring(0, keyIndex);
            let keywordInclude = keyword.substring(keyIndex, keyIndex + searchKeyword.length);
            let key2 = keyword.substring(keyIndex + searchKeyword.length);

            if (keyIndex === 0) {
                return <><div className="title" style={{ color: "#0064ff", display: "inline-block" }}>{word1}</div>{word2}</>;
            } else {
                return <>{key1}<div className="title" style={{ color: "#0064ff", display: "inline-block" }}>{keywordInclude}</div>{key2}</>;
            }
            // console.log(keyword.indexOf(searchKeyword));

        } else {
            return <div className="title">{keyword}</div>;
        }
    }

    return (
        <>
            <HandleBrowserBackButton />
            <div className="map-search">
                <div className="search">
                    {keywordList.map((x, i) => {
                        return (
                            <>
                                <input
                                    type="text"
                                    name="couponCode"
                                    placeholder="목적지를 입력해주세요."
                                    value={x.keyword}
                                    onChange={e => onChange(e, i)}
                                    onKeyPress={enterRegist}
                                />
                            </>
                        )
                    })}
                    <i onClick={goBack}></i>
                </div>
            </div>
            {isPolestar && (
              <div style={{ marginTop: "20px" }}>
                  <div
                    style={{
                        position: "relative",
                        display: "block",
                        width: "320px",
                        height: "64px",
                        margin: "0 auto",
                    }}
                  >
                      <img
                        src={require(`%/images/banner/img_search_banner_polestar.png`).default}
                        alt=""
                        id="banner"
                        style={{ width: "320px", height: "64px", objectFit: "cover" }}
                      />
                      <div
                        style={{
                            position: "absolute",
                            width: "201px",
                            height: "20px",
                            top: "13px",
                            left: "20px",
                            color: "white",
                            fontSize: "14px",
                            lineHeight: "20px",
                            fontFamily: "TMOBI500",
                            textAlign: "left",
                        }}
                      >
                          전기 퍼포먼스 SUV 쿠페
                      </div>
                      <div
                        style={{
                            position: "absolute",
                            width: "201px",
                            height: "16px",
                            top: "33px",
                            left: "20px",
                            color: "white",
                            fontSize: "11px",
                            lineHeight: "16px",
                            fontFamily: "TMOBI300",
                            textAlign: "left",
                        }}
                      >
                          Polestar 4
                      </div>
                  </div>
              </div>
            )}

          <div className="search-list">
                <div className="list-box">
                    {
                        searchStatus
                            ?
                            (
                                <div className="after-list">
                                    {
                                        (searchResult.length > 0)
                                            ?
                                            (
                                                searchResult.map((item, index) => {  // 시트안에 임시 충전소 데이터들 추가
                                                    return (
                                                        <>
                                                            <ul>
                                                                <li>
                                                                    <div onClick={() => {
                                                                        mixpanel("evcharge:/evcharge/stationsearch:tap.list");
                                                                        logbox("/evcharge/stationsearch", "tap.list");

                                                                        regist();
                                                                        tmapInterface.tSearchNearby(item.coordinates.lat, item.coordinates.lon, Number(currentLat), Number(currentLon));
                                                                        setTimeout(() => {
                                                                            props.history.replace('/main');
                                                                        }, 500);
                                                                    }}>
                                                                        <dd style={{ padding: '15px 0 15px 0', textAlign: 'left' }}>
                                                                            <div className="title">{keywordSelect(item.keyword)}</div>
                                                                            <div className="addr">{item.full_address}</div>
                                                                        </dd>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </>
                                                    )
                                                })
                                            )
                                            :
                                            <span>검색 결과가 없습니다.</span>
                                    }
                                </div>
                            )
                            :
                            (
                                (searchHistory.length > 0)
                                    ?
                                    (
                                        <div className="search-delete">
                                            <div className='recently'>
                                                <span className='title'>최근검색</span>
                                                <span className="status" onClick={allDeletePop}>전체삭제</span>
                                            </div>
                                            <div className="before-list">
                                                {
                                                    searchHistory.map((item, index) => {  // 시트안에 임시 충전소 데이터들 추가
                                                        return (
                                                            <>
                                                                <div className='delete-list'>
                                                                    <div onClick={() => update(item)} className="title">
                                                                        {utils.textLengthOverCut(item.keyword, 20, '...')}
                                                                    </div>
                                                                    <div>
                                                                        <img src={require('%/images/common/ico-exit.svg').default} alt="" onClick={() => singleDelete(item)} style={{ position: "relative", top: "3px" }} />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    )
                                    :
                                    <div className='no-search'>최근 검색한 기록이 없습니다.</div>
                            )
                    }
                </div>
            </div>
        </>
    );
}

export { PoiSearch }