/**-------------------------------------------------------------
1.2.3.1 충전 내역 메인화면_3
-------------------------------------------------------------
> http://basic.pointweb.kr/charge/public/html/public/charge_history.html
-------------------------------------------------------------*/

/* eslint-disable */

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';

//헤더 
import { Header } from '../../components/Layout';
import { HandleBrowserBackButton } from '../../components/Layout'

import { appManager } from '../../managers';
import { utils } from '../../libs/utils';

// DatePicker
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { ko } from 'date-fns/esm/locale'

import _ from 'lodash';
import moment from 'moment';

//Mixpanel
import { logbox, mixpanel } from '../../libs/logbox-mixpanel';
import { getHistory } from '../../apis/history';
import { getUser, postUser } from '../../apis/user';
import { globalVars } from '../../libs/global-vars';
import Const from '../../store/ev';

let scrollCache = 0;

function ChargeHistory(props: RouteComponentProps<{}>) {

  let temp = "";

  const [inited, setInited] = useState(false);

  const [pop, setPop] = useState(false);              //쿠폰 등록 팝업

  const [startDate, setStartDate] = useState(new Date());
  const [paramDate, setParmDate] = useState(moment(new Date()).format('YYYY-MM'));
  const [displayDate, setDisplayDate] = useState(false);

  const [first, setFirst] = useState(moment(new Date()).format('YYYYMM01'));
  const [last, setLast] = useState(moment(new Date()).endOf('month').format("YYYYMMDD"));
  const [data, setData] = useState(false);

  const [pressed, setPressed] = useState({
    chargeList: false,
    focusIdx: ""
  })

  const [historyList, setHistoryList] = useState({
    historyComponentInfo: [
      {
        guid: "",
        useIdx: "",
        chargeFinishDt: "",
        stationName: "",
        jibunAddress: "",
        roadAddress: "",
        chargeKwh: "",
        totalPrice: "",
        repaidYn: false
      }
    ],
    historyLength: 0,
    sumTotalPrice: "",
    sumChargeTime: "",
    sumUseKwh: "",
    result: "false"
  });

  const cacheScroll = useCallback(() => {
    // 간헐적으로 상세로 이동 후 호출되는 경우가 있어서 예외처리
    if (!/\/voice\/?$/.test(window.location.pathname)) return;
    scrollCache = window.scrollY;
  }, []);


  const dateFormat = (date: string, flag: any) => {
    let temp = date.split(" ");
    let formatDate = "";
    let yoil = utils.getInputDayLabel(date);

    if (flag == "D") {
      formatDate = (temp[0].substring(0, 10) + " " + "(" + yoil + ")").replaceAll("-", ".");
    } else {
      formatDate = temp[1].split(":")[0] + ":" + temp[1].split(":")[1];
    }
    return formatDate;
  };

  useEffect(() => {
    window.scrollTo(0, scrollCache);
  }, [inited]);

  useEffect(() => {
    window.addEventListener('scroll', cacheScroll);
    return () => window.removeEventListener('scroll', cacheScroll);
  }, [cacheScroll]);

  useEffect(() => {
    mixpanel("evcharge:/evcharge/chargehistory");
    logbox("/evcharge/chargehistory", "");
  }, [])

  function gotoView(guid: string, repaidYn: boolean) {
    mixpanel("evcharge:/evcharge/chargehistory:tap.detail");
    logbox("/evcharge/chargehistory", "tap.detail");

    props.history.push(`/chargeHistoryDetail/${guid}/${repaidYn}`);
  };

  useEffect(() => {
    // ACCESSKEY 가 있으면
    if (globalVars.accessKey) {
      let accessKey = globalVars.accessKey;

      if (Const.DBG_OK) {
        console.log("#2 AccessKey 검증 및 이상없으면 회원정보 리턴");
      }

      getUser()
        .then(function (response: any) {
          // 기존회원이므로 회원정보 받기
          if (response.result) {
            // userData = response;
            setData(true);
            if (Const.DBG_OK) {
              // alert("사용자정보 " + JSON.stringify(userData));
            }
          }
        })
        .catch(function (error: any) {
          if (error.response.status === 400 || error.response.data.result === false) {
            // 신규회원등록
            var data = {};

            postUser(data)
              .then(function (response: any) {
                if (Const.DBG_OK) {
                  // console.log('신규회원등록')
                  alert("신규회원등록 " + JSON.stringify(response.data));
                }

                getUser()
                  .then(function (response: any) {
                    // userData = response;
                    setData(true);
                    if (Const.DBG_OK) {
                      alert("신규회원 " + JSON.stringify(response.data));
                    }
                  })
                  .catch(function (error: any) {
                    //							alert('신규회원정보 조회실패')
                    console.log(error);
                  });
              })
              .catch(function (error: any) {
                console.log(error);
              });
          }
        });
    } else {
      if (Const.DBG_OK) {
        alert("ACCESS KEY 없는경우 - 접속 제한 ()");
        //props.history.replace("/notFound");
      }
      props.history.replace("/notFound");
    }
  }, [])

  useEffect(() => {

    appManager.globalLoadingInc();

    setPop(false);

    var axios = require('axios');

    let search_date = { paramDate }.paramDate;

    let first_date = { first }.first;
    let last_date = { last }.last;

    console.log(first_date);

    getHistory(first_date, last_date)
      .then(function (response: any) {
        appManager.globalLoadingDec();
        
        enableScroll();
        
        setHistoryList(response);
        
        setInited(true);
      })
      .catch(function (error: any) {
        appManager.globalLoadingDec();

        // props.history.push(`/main`); 
      });
  }, [startDate, data]);


  const listDate = (date: any) => historyList.historyComponentInfo.map((x, i) => {
    if (x.guid !== "") {

      if (date == dateFormat(x.chargeFinishDt, "D")) {
        return (
          <>
            <div className="charge-history-list" 
              onTouchStart={() => setPressed({ ...pressed, chargeList: true, focusIdx: x.useIdx })} 
              onTouchEnd={() => setPressed({ ...pressed, chargeList: false })} 
              style={pressed.chargeList && x.useIdx === pressed.focusIdx ? { background: "var(--gray-color-wb-transparent-300, rgba(0, 0, 0, 0.10))" } : {}}
              onClick={() => gotoView(x.guid, x.repaidYn)}
            >
              <div className='history-list-box'>
                <span className="time">{dateFormat(x.chargeFinishDt, "T")}</span>
                <div className='inner-text'>
                  <div className="station-txt">{x.stationName}</div>
                  <div className="addr-txt">{x.roadAddress}</div>

                </div>
              </div>
              <div className="history-kwh-txt">
                <span className='kwh-txt'>충전량  {Number(x.chargeKwh).toFixed(2)}kWh</span>

                <span className="price">
                  <span className='kwh-txt'>{x.repaidYn ? "재결제 " : ""}</span>
                  {utils.comma(x.totalPrice)}원
                </span>
              </div>
            </div>
          </>
        )
      }
    }
  });

  const enableScroll = () => {
    // popup 후 백그라운드 스크롤 금지 해제
    let ele = document.querySelector("body");
    if (ele?.classList[0] === 'scroll_y_none') {
      ele?.classList.replace('scroll_y_none', 'scroll_y_yes');
    }

    // let ele = document.querySelector("body");
    // enableBodyScroll(ele);
  }


  const preventScroll = () => {
    // 팝업 시 백그라운드 스크롤 막기
    let ele = document.querySelector("body");
    if (ele?.classList[0] === 'scroll_y_yes') {
      ele?.classList.remove('scroll_y_yes');
    }
    ele?.classList.add('scroll_y_none');

    // let ele = document.querySelector("body");
    // disableBodyScroll(ele);
  }


  const dateClickEv = () => {
    mixpanel("evcharge:/evcharge/chargehistory:tap.yearmonth");
    logbox("/evcharge/chargehistory", "tap.yearmonth");

    if (pop) {
      enableScroll();

      setPop(false);

      setDisplayDate(false);
    } else {
      preventScroll();

      setPop(true);

      setDisplayDate(true);
    }
  }

  const cancelClick = () => {
    enableScroll();

    setPop(false);

    setDisplayDate(displayDate ? false : true);
  }

  const dateChange = (date: Date) => {
    let tempDate = moment(date).format('YYYY-MM');

    let firstDate = moment(date).format('YYYYMM01');
    let lastDate = moment(date).endOf('month').format("YYYYMMDD");

    // if (date <= new Date('2021-10-01')) {
    //   setStartDate(new Date('2021-12-01'));
    // } else {
    //   setStartDate(date);
    // }

    setFirst(firstDate);
    setLast(lastDate);

    setStartDate(date);

    // alert( tempDate + " " +  startDate )

    setParmDate(tempDate);
    setDisplayDate(displayDate ? false : true);
  }

  const yearChange = (date: Date) => {
    // let tempDate = moment(date).format('YYYY-MM');
    // setStartDate(startDate);
    // alert(startDate);

    let ele = document.querySelector(".react-datepicker__monthPicker");

    for (var ith = 0; ith < 4; ++ith) {
      for (var jth = 0; jth < 3; ++jth) {
        ele?.children[ith].children[jth].classList.remove('react-datepicker__month-text--keyboard-selected');
      }
    }
  }
  if (!inited) {
    return (<></>);

  } else if ((historyList.historyLength < 1 && historyList.result === "true") || (historyList.result === "false")) {
    // } else if ( 1 ) {
    return (
      <>
        <Header title={'이용 내역'} isMenu={false} isHistoryMenu={true} />
        {/* <Header isMenu={ false } isHistoryMenu={ true }/> */}
        <HandleBrowserBackButton />

        <div className="charge-history">
          <div className="top">
            <div className="hgroup" onClick={dateClickEv}>
              <strong>{paramDate.split("-")[0]}년 {paramDate.split("-")[1]}월</strong>
              <button type="button" className="sort-button">정렬</button>
            </div>

            <div className="total-box">
              <div className='box-list'>
                <span><img src={require('%/images/common/charge-amount.svg').default} alt="" />누적 충전 금액</span>
                <span className="history-value">{utils.comma(historyList.sumTotalPrice)} 원</span>
              </div>
              <div className='box-list'>
                <span><img src={require('%/images/common/charge-time.svg').default} alt="" />충전시간</span>
                <span className="history-value" style={{ top: "1px" }}>{utils.comma(historyList.sumChargeTime)} 분</span>
              </div>
              <div className='box-list' style={{ borderBottom: "none" }}>
                <span><img src={require('%/images/common/charge-kwh.svg').default} alt="" /><span style={{ position: "relative", top: "-3px" }}>충전량</span></span>
                <span className="history-value">{Number(historyList.sumUseKwh).toFixed(2)} kWh</span>
              </div>
            </div>
            <div>
              <DatePicker
                open={pop}
                preSelection={new Date()}
                selected={startDate}
                locale={ko}
                minDate={new Date('2022-10-01')}
                maxDate={new Date()}
                showMonthYearPicker
                onYearChange={date => yearChange(date)}
                onChange={date => dateChange(date)}
              />
              <div className="t-popup-trans" style={{ visibility: pop ? 'visible' : 'hidden' }} onClick={cancelClick}></div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <Header title={'이용 내역'} isMenu={false} isHistoryMenu={true} />
        <HandleBrowserBackButton />

        <div className="charge-history">
          <div className="top">
            <div className="hgroup" onClick={dateClickEv}>
              <strong>{paramDate.split("-")[0]}년 {paramDate.split("-")[1]}월</strong>

              <button type="button" className="sort-button">정렬</button>
            </div>

            <div className="total-box">
              <div className='box-list'>
                <span><img src={require('%/images/common/charge-amount.svg').default} alt="" style={{ paddingRight: "8px" }} />누적 충전 금액</span>
                <span className="history-value">{utils.comma(historyList.sumTotalPrice)} 원</span>
              </div>
              <div className='box-list'>
                <span><img src={require('%/images/common/charge-time.svg').default} alt="" />충전시간</span>
                <span className="history-value" style={{ top: "1px" }}> {(Math.floor(Number(historyList.sumChargeTime) / 60) > 0) ? `${Math.floor(Number(historyList.sumChargeTime) / 60)}시간` : <></>} {Number(historyList.sumChargeTime) % 60}분</span>
              </div>
              <div className='box-list' style={{ borderBottom: "none" }}>
                <span><img src={require('%/images/common/charge-kwh.svg').default} alt="" style={{ paddingRight: "4px", right: "2px" }} /><span style={{ position: "relative", top: "-3px" }}>충전량</span></span>
                <span className="history-value">{Number(historyList.sumUseKwh).toFixed(2)} kWh</span>
              </div>
            </div>
          </div>

          <div style={{ height: "10px", backgroundColor: "#F8F9FA", width: "100%" }}></div>

          <div className="list-box">
            {
              historyList.historyComponentInfo.map((x, i) => {

                if (x.guid != "") {
                  if (dateFormat(temp, "D") != dateFormat(x.chargeFinishDt, "D")) {
                    temp = x.chargeFinishDt;
                    return (
                      <div className="list" key={i}>
                        <h4>{dateFormat(x.chargeFinishDt, "D")}</h4>
                        {listDate(dateFormat(x.chargeFinishDt, "D"))}
                      </div>
                    )
                  }
                }
              })
            }
          </div>

          <div>
            <DatePicker
              open={pop}
              preSelection={new Date()}
              selected={startDate}
              locale={ko}
              minDate={new Date('2022-10-01')}
              maxDate={new Date()}
              showMonthYearPicker
              onYearChange={date => yearChange(date)}
              onChange={date => dateChange(date)}
            />
            <div className="t-popup-trans" style={{ visibility: pop ? 'visible' : 'hidden' }} onClick={cancelClick}></div>
          </div>

        </div>
      </>
    );
  }
}


export { ChargeHistory };
