/* eslint-disable */

import instance from './frontman';

//검색내역 조회
export const getSearch = async (accessToken?: String) => {
    return await instance({
        url: "/search",
        method: "get"
    })
};

//검색내역 추가
export const postSearch = async (params?: string, accessToken?: String) => {
    return await instance({
        url: "/search",
        method: "post",
        params: {
            keyword: params
        }
    })
};

//검색내역 삭제
export const deleteSearch = async (params?: string, accessToken?: String) => {
    return await instance({
        url: "/search",
        method: "delete",
        params: {
            idx: params
        }
    })
};

//검색내역 갱신
export const patchSearch = async (params?: string, accessToken?: String) => {
    return await instance({
        url: "/search",
        method: "patch",
        params: {
            idx: params
        }
    })
};

//검색 자동 완성
export const getAcfSuggest = async (params1?: string, params2?: string, params3?: string, accessToken?: String) => {
    return await instance({
        url: "/acf/suggest",
        method: "get",
        params: {
            lat: params1,
            lon: params2,
            keyword: params3
        }
    })
};