/* eslint-disable */

import { appActions, store } from "../store";
import { globalVars } from "../libs/global-vars";
import axios from "axios";

//Logbox 테스트
import { LogBox as LogBoxPrd } from '../logboxLibs/logbox-prod-event-tmap-evcharge';
import { LogBox as LogBoxStg } from '../logboxLibs/logbox-stg-event-tmap-evcharge';
let logBox = process.env.REACT_APP_BUILD_ENV === 'PRD' ? LogBoxPrd() : LogBoxStg();
import moment from 'moment';
import { browserName, fullBrowserVersion, isIOS } from "react-device-detect";

//Mixpanel
import mixpanelBrowser from 'mixpanel-browser';

const logbox = (pageId, actionId) => {
  // 로그박스 날짜
  const timeformat = moment().format('YYYYMMDDHHmmssSSS');

  // ##### set common
  logBox.setIp("127.0.0.1")
    .setApp_version("1.0")
    .setSession_id(store.getState().app.sessionId)
    .setSub_session_id(store.getState().app.subSessionId)
    .setLocal_time(timeformat);

  // ##### set env
  logBox.setOs_name(globalVars.osName)
    .setOs_version(globalVars.osVersion)//--
    .setResolution(screen.width + "*" + screen.height)//window
    .setScreen_width(screen.width)//--
    .setScreen_height(screen.height)//--
    .setCarrier_name("")// 통신사
    .setNetwork_type(globalVars.connectionType)// android 정상 작동 ios 백화 현상
    .setManufacturer(globalVars.manufacturer)// 제조사
    .setDevice_id("")// X
    .setDevice_model(globalVars.isAndroid ? globalVars.splitDeviceModel ? globalVars.splitDeviceModel[2] : "Android" : globalVars.deviceModel) //--globalVars.deviceModel
    .setBrowser_name(browserName) //--
    .setBrowser_version(fullBrowserVersion)// --
    .setReferrer("")
    .setLanguage_code(globalVars.languageCode) //--
    .setUrl("")// X
    .setDocument_title("");// --

  // ##### set meta
  logBox.setPage_id(pageId)
    .setPage_type("WEB")
    .setSection_id("")
    .setAction_id(actionId);

  axios.get(logBox.getTlaURIEncode())
    .then(function (response: any) {
      const test = response.data
    });
}

const mixpanel = (actionId) => {
  // mixpanel
  mixpanelBrowser.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: true });
  mixpanelBrowser.identify(store.getState().app.euk);
  
  mixpanelBrowser.track(actionId);
}

export { logbox, mixpanel };
