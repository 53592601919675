/* eslint-disable */

import React, { useState, useEffect, useCallback } from 'react';
import { Header } from '../../components/Layout';
import Slider from "react-slick";
// import '~slick-carousel/slick/slick.css';
// import '~slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { RouteComponentProps, Link } from 'react-router-dom';
import firstImage from '../../assets/images/logo/img-touch-and-charge-01@3x.png'
import secondImage from '../../assets/images/logo/img-touch-and-charge-02.svg'
import thirdImage from '../../assets/images/logo/img-touch-and-charge-03.svg'
import fourthImage from '../../assets/images/logo/img-touch-and-charge-04.svg'
import fifthImage from '../../assets/images/logo/img-touch-and-charge-05.svg'

//Mixpanel
import { logbox, mixpanel } from '../../libs/logbox-mixpanel';

let scrollCache = 0;
let height = 0;
/**-------------------------------------------------------------
1.2.1.3 이용안내 화면_2
-------------------------------------------------------------
> http://basic.pointweb.kr/charge/public/html/public/pay.html
-------------------------------------------------------------*/
function TapChargeInfo(props: RouteComponentProps<{ poi: string, lon: string, lat: string }>) {
	const poi = props.match.params.poi;
	const lon = props.match.params.lon;
	const lat = props.match.params.lat;

	const [inited] = useState(false);
	const [guide, setGuide] = useState(1);
	const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

	// const [width, setWidth] = useState(0);
	height = window.innerHeight;

	const cacheScroll = useCallback(() => {
		// 간헐적으로 상세로 이동 후 호출되는 경우가 있어서 예외처리
		if (!/\/voice\/?$/.test(window.location.pathname)) return;
		scrollCache = window.scrollY;
	}, []);

	useEffect(() => {
		mixpanel("evcharge:/evcharge/easypayuseguide");
		logbox("/evcharge/easypayuseguide", "");
	}, [])

	useEffect(() => {
		window.scrollTo(0, scrollCache);
	}, [inited]);

	useEffect(() => {
		window.addEventListener('scroll', cacheScroll);
		return () => window.removeEventListener('scroll', cacheScroll);
	}, [cacheScroll]);

	var settings = {
		dots: true,
		infinite: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		// nextArrow: <SampleNextArrow />,
		// prevArrow: <SamplePrevArrow />,
		className: 'slider_custom',
		beforeChange: (beforeIndex, afterIndex) => {
			setCurrentSlideIndex(afterIndex);
		},
		appendDots: (dots: any) => (
			<div
				style={{
					width: '100%',
					position: 'fixed',
					bottom: '80px',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center'
				}}
			>
				<ul> {dots} </ul>
			</div>
		),
		dotsClass: 'dots_custom'
	}

	function SampleNextArrow(props) {
		const { className, style, onClick } = props;
		// console.log(currentSlideIndex)
		if (currentSlideIndex === 4) {
			console.log('disable', currentSlideIndex);
			return (
				<img src={require("%/images/common/ic-arrow-right-disabled.svg").default} alt="" className={className} onClick={onClick} style={{ position: "fixed", margin: "50px", width: "30px", height: "30px", zIndex: "999" }} />
			)
		} else {
			console.log('enable', currentSlideIndex);
			return (
				<img src={require("%/images/common/ic-arrow-right-enabled.svg").default} alt="" className={className} onClick={onClick} style={{ position: "fixed", margin: "50px", width: "30px", height: "30px", zIndex: "999" }} />
			)
		}
	}

	function SamplePrevArrow(props) {
		const { className, style, onClick } = props;
		if (currentSlideIndex === 0) {
			console.log('disable', currentSlideIndex);
			return (
				<img src={require("%/images/common/ic-arrow-left-disabled.svg").default} alt="" className={className} onClick={onClick} style={{ position: "fixed", margin: "50px", width: "30px", height: "30px", zIndex: "999" }} />
			)
		} else {
			console.log('enable', currentSlideIndex);
			return (
				<img src={require("%/images/common/ic-arrow-left-enabled.svg").default} alt="" className={className} onClick={onClick} style={{ position: "fixed", margin: "50px", width: "30px", height: "30px", zIndex: "999" }} />
			)
		}
	}

	const goBack = () => {
		mixpanel("evcharge:/evcharge/easypayuseguide:tap.back");
		logbox("/evcharge/easypayuseguide", "tap.back");
		props.history.goBack();

		// props.history.replace(`/tapCharge/${poi}/${lon}/${lat}`);
	}

	return (
		<>
			<body style={{overflow: "hidden", position:"fixed"}}>
				<div style={{ width: "100%", height: "56px", position: "relative", justifyContent: "center", textAlign: "center" }}>
					<img src={require('%/images/common/arrow-left.svg').default} alt="" style={{ width: "30px", position: "relative", left: "10px", float: "left", top: "13px" }} onClick={() => goBack()} />
					{/* <button type="button" onClick={() => { goBack() }} style={{ position: "absolute" }}>
						<img src={require('%/images/ic-top-arrow-back.svg').default} alt="" />
					</button> */}
					{/* <span  onClick={() => goInfo()}>이용안내</span> */}
					<span style={{ fontFamily: "TMOBI500", fontSize: "18px", color: "#111", letterSpacing: "normal", textAlign: "center", position: "relative", top: "15px", right: "20px" }}>{currentSlideIndex === 4 ? "주의사항" : "이용안내"}</span>
				</div>
				<div className="easypay-guide" style={{ height: height - 77 }}>
					<Slider {...settings}>
						<div className='charger-img'>
							<div className='content'>
								<img src={require('../../assets/images/logo/img-touch-and-charge-01.svg').default} alt="" style={Number(window.innerWidth) > 440 ? { paddingTop: "0px", width: "40%" } : { paddingTop: "0px", width: "70%" }} />
								<div className="big-content">사용할 충전기 선택</div>
								<div className="small-content">화면 상단 충전소 사진에 마킹된 번호나<br /> 충전기에 부착된 번호를 참고하셔서 충전기 리스트 중<br /> 사용하실 충전기를 선택해주세요.</div>
							</div>
						</div>

						<div className='charger-img'>
							<div className='content'>
								<img src={require('../../assets/images/logo/img-touch-and-charge-02.svg').default} alt="" style={Number(window.innerWidth) > 440 ? { width: "50%" } : { padding: "20px 0" }} />
								<div className="big-content" style={{ paddingTop: "10px" }}>커넥터 연결 대기상태로 변경</div>
								<div className="small-content">
									충전기를 선택하면 잠시 후 충전기와 TMAP이<br />
									커넥터 연결 대기상태로 변경됩니다.
								</div>
							</div>
						</div>

						<div className='charger-img'>
							<div className='content'>
								<img src={require('../../assets/images/logo/img-touch-and-charge-03.svg').default} alt="" style={Number(window.innerWidth) > 440 ? { width: "50%" } : {}} />
								<div className="big-content">충전기 별 다른 대기시간</div>
								<div className="small-content">
									커넥터 연결 대기시간은 충전기에 따라 다르며<br />
									최대 3분까지 걸릴 수 있습니다.
								</div>
							</div>
						</div>

						<div className='charger-img'>
							<div className='content'>
								<img src={require('../../assets/images/logo/img-touch-and-charge-04.svg').default} alt="" style={Number(window.innerWidth) > 440 ? { width: "50%" } : {}} />
								<div className="big-content">커넥터 연결 후 충전 시작</div>
								<div className="small-content">
									차량에 커넥터를 연결시키면 충전이 진행되고<br />
									커넥터 연결 대기 상태가 종료됩니다.
								</div>
							</div>
						</div>

						<div className='charger-img'>
							<div className='content' style={{ padding: "20px" }}>
								<div style={window.innerWidth >= 440 ? { padding: "0 35px" } : {}}>
									<div className="big-content" style={{ textAlign: "initial", paddingTop: "10px" }}>
										티맵결제 충전소<br />주의사항을 숙지해주세요.
									</div>
									<img src={require('../../assets/images/logo/img-touch-and-charge-05.svg').default} alt="" style={Number(window.innerWidth) > 440 ? { width: "60%" } : {}}/>
									<div className="small-content" style={{ textAlign: "inherit", fontSize: "13px" }}>
										<li><p>서비스는 꼭 충전소에 방문하셔서 사용해주세요.</p></li><br />
										<li><p>충전기 리스트에서 사용하실 충전기를 선택하시면 3분 동안 차량 커넥터 연결 대기 화면이 표시됩니다. 대기 시간 안에 차량과 커넥터를 연결해주세요.</p></li><br />
										<li><p>이용안내와 주의사항을 반드시 확인해 주세요. 잘못된 이용방법으로 발생한 문제는 해결해 드릴 수 없습니다.</p></li>
									</div>
								</div>
							</div>
						</div>
					</Slider>
				</div>
			</body >
		</>
	);
}
export { TapChargeInfo };
