/**-------------------------------------------------------------
1.2.2.3 충전 결제 완료 화면_3
-------------------------------------------------------------
> http://basic.pointweb.kr/charge/public/html/public/qr_charger_complete.html
-------------------------------------------------------------*/

/* eslint-disable */

//헤더 
import { Header } from '../../components/Layout';
import { HandleBrowserBackButton } from '../../components/Layout'
import { appManager } from '../../managers';

import { useHistory } from 'react-router-dom';

import React, { useState, useEffect, useCallback } from 'react';

import { appActions, store } from '../../store';
import axios from 'axios';
import Const from '../../store/ev';
import { modal } from '../../components/GlobalModal';

import { utils } from '../../libs/utils';
import { globalVars } from '../../libs/global-vars';

//Mixpanel
import { logbox, mixpanel } from '../../libs/logbox-mixpanel';
import { getPay } from '../../apis/pay';
import { postReview } from '../../apis/review';

// 리뷰 모달시트
import Sheet from "react-modal-sheet";

let scrollCache = 0;
let cardInfo: any = {};	// 카드정보

function PayComplete() {

  useEffect(() => {
    mixpanel("evcharge:/evcharge/paymentcomplete");
    logbox("/evcharge/paymentcomplete", "");
  }, [])

  const guid = store.getState().app.guid;

  const history = useHistory();

  const [inited, setInited] = useState(false);

  const [payType, setPayType] = useState('N');     // 결제수단 : TODO
  const [payTypeNm, setPayTypeNm] = useState('');     // 결제수단 명 : TODO
  const [payImageUrl, setPayImageUrl] = useState('');     // 결제수단 이미지 CI
  const [payKind, setPayKind] = useState(''); // 결제수단 종류
  const [payNumber, setPayNumber] = useState('');     // 결제카드 번호

  const [isCard, setIsCard] = useState('NoFocus');
  const [payImageBg, setPayImageBg] = useState('transparent');     // 결제수단 이미지 CI
  const [bselectCard, setBselectCard] = useState('');

  const [firstClick, setFirstClick] = useState(false);

  // press
  const [focusOkBtn, setFocusOkBtn] = useState(false); // 확인 press 버튼
  const [focusReviewBtn, setFocusReviewBtn] = useState(false); // 리뷰 press 버튼

  const [focusReviewCloseBtn, setFocusReviewCloseBtn] = useState(false); // 리뷰 닫기 press 버튼
  const [focusReviewSendBtn, setFocusReviewSendBtn] = useState(false); // 리뷰 보내기 press 버튼

  const [focusReviewOkBtn, setFocusReviewOkBtn] = useState(false); // 리뷰 확인 press 버튼

  var defaultData: any = {
    result: "",
    totalPrice: "",
    payPrice: "",
    chargeKwh: "",
    chargeStartDt: "",
    chargeFinishDt: "",
    chargeTime: "",
    stationName: "",
    couplerId: "",
    currentPrice: "",
    currentPointPrice: "",
    currentRatePrice: "",
    currentTmapPointPrice: ""
  }

  const [userData, setUserData] = useState(defaultData);

  const [review, setReview] = useState({
    worksWell: false,
    didNotWorkWell: false,
    easyToUse: false,
    freeParking: false,
    easyToFind: false,
    neat: false,
    shortWaiting: false,
    noneOfTheseApply: false
  });

  const [reviewSheet, setReviewSheet] = useState(false);
  const [reviewValue, setReviewValue] = useState(false);

  // const [newReview, setNewReview] = useState({
  //   good: 'N',
  //   unopened: 'N',
  //   connectorError: 'N',
  //   tooSlow: 'N',
  //   hardToFind: 'N'
  // });

  const onClickReview = (element) => {
    setReview(review => ({
      ...review,
      [element]: !review[element]
    }));
  }

  const updateChargerReview = ({ worksWell, didNotWorkWell, easyToUse, freeParking, easyToFind, neat, shortWaiting, noneOfTheseApply }) => {
    if (firstClick) return;
    if (worksWell) {
      mixpanel("evcharge:/evcharge/paymentcomplete:tap.review1");
      logbox("/evcharge/paymentcomplete", "tap.review1");
    }
    if (didNotWorkWell) {
      mixpanel("evcharge:/evcharge/paymentcomplete:tap.review2");
      logbox("/evcharge/paymentcomplete", "tap.review2");
    }
    if (easyToUse) {
      mixpanel("evcharge:/evcharge/paymentcomplete:tap.review3");
      logbox("/evcharge/paymentcomplete", "tap.review3");
    }
    if (freeParking) {
      mixpanel("evcharge:/evcharge/paymentcomplete:tap.review4");
      logbox("/evcharge/paymentcomplete", "tap.review4");
    }
    if (easyToFind) {
      mixpanel("evcharge:/evcharge/paymentcomplete:tap.review5");
      logbox("/evcharge/paymentcomplete", "tap.review5");
    }
    if (neat) {
      mixpanel("evcharge:/evcharge/paymentcomplete:tap.review6");
      logbox("/evcharge/paymentcomplete", "tap.review6");
    }
    if (shortWaiting) {
      mixpanel("evcharge:/evcharge/paymentcomplete:tap.review7");
      logbox("/evcharge/paymentcomplete", "tap.review7");
    }
    if (noneOfTheseApply) {
      mixpanel("evcharge:/evcharge/paymentcomplete:tap.review8");
      logbox("/evcharge/paymentcomplete", "tap.review8");
    }

    mixpanel("evcharge:/evcharge/paymentcomplete:tap.send");
    logbox("/evcharge/paymentcomplete", "tap.send");

    var data = {
      guid: guid,
      worksWell,
      didNotWorkWell,
      easyToUse,
      freeParking,
      easyToFind,
      neat,
      shortWaiting,
      noneOfTheseApply
    }

    if (!worksWell && !didNotWorkWell && !easyToUse && !freeParking && !easyToFind && !neat && !shortWaiting && !noneOfTheseApply) {
      setReviewValue(true);
    } else {
      postReview(data)
        .then(function (response: any) {
          setReviewValue(true);
        })
        .catch(function (error: any) {
          history.push("/main");
        });
    }
  }

  const reviewCancel = () => {
    setReviewSheet(false);
  }

  // useEffect(() => {
  //   const obj = Object.keys(review).reduce((accumulator, key) => {
  //     return { ...accumulator, [key]: review[key] === true ? "Y" : "N" };
  //   }, newReview);

  //   setNewReview(obj);
  // }, [review]);

  const getFinalEvStatus = () => {

    if (Const.DBG_OK) {
      alert('PayComplete - getFinalEvStatus \n' + guid)
    }

    getPay(guid)
      .then((response: any) => {

        setInited(true);

        if (Const.DBG_OK) {
          alert('PayComplete \n' + JSON.stringify(response.data))
        }

        setUserData(response);

        if (response.paymentType === 'CARD' || response.paymentType === 'SK_PAY' || response.paymentType === 'KB_PLCC' || response.paymentType === 'KB_PAY') {
          setIsCard('selected last');
          setPayImageUrl(response.ciImageUrl);
          setBselectCard('true');
          setPayTypeNm(response.payMeansDisplayName);
          setPayNumber(response.payMeansDisplayNumber);
          setPayImageBg(response.bgColor);
          if (response.paymentType === 'SK_PAY') {
            setPayKind('PAY');
          } else if (response.paymentType === 'KB_PAY') {
            setPayKind('KB_PAY');
          } else {
            setPayKind('CARD');
          }
        }

      }).catch((error: any) => {

        if (Const.DBG_OK) {
          alert('PayComplete 실패 - ' + error);
        }
      });
  };

  const cacheScroll = useCallback(() => {
    // 간헐적으로 상세로 이동 후 호출되는 경우가 있어서 예외처리
    if (!/\/voice\/?$/.test(window.location.pathname)) return;
    scrollCache = window.scrollY;
  }, []);

  useEffect(() => {
    appManager.globalLoadingReset();

    window.scrollTo(0, scrollCache);

    // retrieveCardInfo(globalVars.accessKey);

    getFinalEvStatus();

  }, []);


  useEffect(() => {
    window.addEventListener('scroll', cacheScroll);
    return () => window.removeEventListener('scroll', cacheScroll);
  }, [cacheScroll]);

  const borderBottomStyle = { width: "90%", margin: "0 auto", borderBottom: "1px solid #F0F0F0" };

  function ChargeInfo({ label, value }) {
    return (
      <div className="charge-info">
        <span className='head-value'>{label}</span>
        <span>{value}</span>
      </div>
    );
  }

  useEffect(() => {
    if (reviewSheet) {
      document.body.style.cssText = `
        overflow: hidden;`
        ;
      // return () => {
      //   document.body.style.cssText = '';
      // };
    } else {
      document.body.style.cssText = '';
    }
  })

  if (!inited) {
    return <></>
  } else {
    return (
      <>
        <HandleBrowserBackButton />
        <div className="finish-charge">

          <div className='top-section'>
            <img src={require('%/images/common/finish-sign-check.svg').default} alt="" />
            <div className='finish-title'>결제가 완료되었습니다.</div>
          </div>

          <div className="finish-payinfo">
            <div className="coupon-box">
              <div className='info-list'>
                <span>총 금액</span>
                <span className='amount'>{utils.comma(userData.totalPrice)}원</span>
              </div>
              {userData.currentRatePrice === 0 || !userData.currentRatePrice ? (
                <></>
              ) : (
                <>
                  {" "}
                  <div className='info-list'>
                    <span>할인 쿠폰 사용</span>
                    <span className='coupon-section'>- {utils.comma(userData.currentRatePrice)}원</span>
                  </div>
                </>
              )}
              {userData.currentPointPrice === 0 || !userData.currentPointPrice ? (
                <></>
              ) : (
                <>
                  <div className='info-list'>
                    <span>선불충전권 사용</span>
                    <span className='coupon-section'>- {utils.comma(userData.currentPointPrice)}원</span>
                  </div>
                </>
              )}
              {userData.currentTmapPointPrice === 0 || !userData.currentTmapPointPrice ? (
                <></>
              ) : (
                <>
                  <div className='info-list'>
                    <span>포인트 사용</span>
                    <span className='coupon-section'>- {utils.comma(userData.currentTmapPointPrice)}원</span>
                  </div>
                </>
              )}
            </div>

            <div className='credit-box'>
              <span className={isCard}>
                <span
                  className={payKind === 'PAY' ? "pay-logo-wrapper" : payKind === 'KB_PAY' ?"kb-pay-logo-wrapper":"card-logo-wrapper"}
                  style={payKind === 'KB_PAY' ?{}
                  :{
                    background: payImageBg !== "" ? payImageBg : "none",
                    padding: "1px",
                  }}
                >
                  {payTypeNm !== '' ? (
                    <img
                      src={payImageUrl}
                      alt=""
                      className={payKind === "PAY" ? "pay-logo" : payKind === "KB_PAY" ? "kb-pay-logo":"card-logo"}
                    />
                  ) : (
                    ""
                  )}
                </span>
                <span className='card' style={payKind === 'KB_PAY'?{position:"relative", top:"-7px"}:{}}>{userData.payPrice < 100 || !payTypeNm ? '' : `${payTypeNm}(${payNumber})`}</span>
              </span>
              <span className='pay-price'>{utils.comma(userData.payPrice)} 원</span>
            </div>
          </div>

          <div className='charge-info-box'>
            <ChargeInfo label="충전소" value={Number(window.innerWidth) <= 320
              ? utils.textLengthOverCut(userData.stationName, 6, "...") : utils.textLengthOverCut(userData.stationName, 12, "...")} />
          </div>
          <div className='charge-info-box'>
            <ChargeInfo label="시작일시" value={utils.convert12H(userData.chargeStartDt)} />
            <div style={borderBottomStyle} />
            <ChargeInfo label="종료일시" value={utils.convert12H(userData.chargeFinishDt)} />
            <div style={borderBottomStyle} />
            <ChargeInfo label="충전시간" value={(userData.chargeTime > 0) ? userData.chargeTime + " 분" : 0 + " 분"} />
          </div>
          <div className='charge-info-box'>
            <ChargeInfo label="충전량" value={Number(userData.chargeKwh).toFixed(2) + " kWh"} />
            <div style={borderBottomStyle} />
            <ChargeInfo label="충전기" value={userData.couplerId} />
          </div>

          {reviewValue ?
            <Sheet
              isOpen={reviewSheet} //바텀시트 열기
              onClose={() => setReviewSheet(false)}
              snapPoints={[0.2, 0]} // 높이 조절 기능 최대값 두개 설정해서 최상단 최하단 지정
              disableDrag={true}
              className="review"
            >
              <Sheet.Container
                style={{ bottom: "60px", borderRadius:"16px" }}
              >
                <Sheet.Content>
                  <div className="review-top">
                    <div className="review-title" style={{ top: "2vh" }}>
                      {"의견을 보내주셔서"}
                      <br />
                      {"감사합니다."}
                    </div>
                  </div>
                </Sheet.Content>
                <div className="review-button" style={{ backgroundColor: "#fff" }}>
                  <div className="yes-button-group">
                    <button
                      type="button"
                      onClick={() => {
                        history.push("/main");
                      }}
                      onTouchStart={() => {
                        setFocusReviewOkBtn(true);
                      }}
                      onTouchEnd={() => {
                        setFocusReviewOkBtn(false);
                      }}
                      style={focusReviewOkBtn ? { filter: "brightness(90%)", backgroundColor: "#0064ff", color: "#fff", fontFamily:"TMOBI300" } : { backgroundColor: "#0064ff", color: "#fff", fontFamily:"TMOBI300" }}
                    >
                      확인
                    </button>
                  </div>
                </div>
              </Sheet.Container>
              <Sheet.Backdrop
                style={{ opacity: "0.1", backgroundColor: "#00000099" }}
              />
            </Sheet>
            :
            <Sheet
              isOpen={reviewSheet} //바텀시트 열기
              onClose={() => setReviewSheet(false)}
              snapPoints={window.innerHeight > 750 ? [400, 0] : [400, 0]} // 높이 조절 기능 최대값 두개 설정해서 최상단 최하단 지정
              disableDrag={true}
              className="review"
            >
              <Sheet.Container style={{ borderRadius: "15px" }}>
                <div className="review-top">
                  <div className="review-title">
                    {Number(window.innerWidth) <= 320
                      ? utils.textLengthOverCut(userData.stationName, 10, "...")
                      : utils.textLengthOverCut(userData.stationName, 12, "...")}
                    <br />
                    {"어떠셨나요?"}
                  </div>
                </div>

                <Sheet.Content style={{ borderRadius: "15px" }}>
                  <div className="txt-wrap">
                    <div className="button-wrap">
                      <div className="type">
                        {
                          Object.entries(review).map(([key, value]) => {
                            return (
                              <>
                                <button
                                  type="button"
                                  className={value === true ? `type-${key} on` : `type-${key}`}
                                  onClick={() => {
                                    onClickReview(key);
                                  }}
                                >
                                </button>
                              </>
                            )
                          })
                        }
                      </div>
                    </div>
                  </div>
                </Sheet.Content>
                <div className="review-button">
                  <button
                    onClick={() => {
                      reviewCancel();
                    }}
                    onTouchStart={() => {
                      setFocusReviewCloseBtn(true);
                    }}
                    onTouchEnd={() => {
                      setFocusReviewCloseBtn(false);
                    }}
                    style={focusReviewCloseBtn ? { filter: "brightness(90%)", width: "30%" } : { width: "30%" }}
                  >
                    닫기
                  </button>
                  <button
                    onClick={() => {
                      updateChargerReview(review);
                      setFirstClick(true);
                    }}
                    onTouchStart={() => {
                      setFocusReviewSendBtn(true);
                    }}
                    onTouchEnd={() => {
                      setFocusReviewSendBtn(false);
                    }}
                    style={
                      Object.keys(review).find(value => review[value] === true) === undefined ?
                        { width: "68%", left: "2%", backgroundColor: "#F8F9FA", color: "#BEC5CC" } :
                        focusReviewSendBtn ?
                          { filter: "brightness(90%)", width: "68%", left: "2%", backgroundColor: "#0064ff", color: "#fff", fontFamily:"TMOBI500" } :
                          { width: "68%", left: "2%", backgroundColor: "#0064ff", color: "#fff", fontFamily:"TMOBI500" }
                    }
                  >
                    평가 보내기
                  </button>
                </div>
              </Sheet.Container>
              <Sheet.Backdrop
                style={{ opacity: "0.1", backgroundColor: "#00000099" }}
              // onTap={() => {
              //   setReviewSheet(false);
              // }}
              />
            </Sheet>
          }
          <div className="button-box">
            <button
              onClick={() => {
                history.push("/main");
              }}
              onTouchStart={() => {
                setFocusOkBtn(true);
              }}
              onTouchEnd={() => {
                setFocusOkBtn(false);
              }}
              style={focusOkBtn ? { filter: "brightness(90%)", width: "30%", fontFamily:"TMOBI300" } : { width: "30%", fontFamily:"TMOBI300" }}
            >
              확인
            </button>
            <button
              onClick={() => {
                setReviewSheet(true);
              }}
              onTouchStart={() => {
                setFocusReviewBtn(true);
              }}
              onTouchEnd={() => {
                setFocusReviewBtn(false);
              }}
              style={focusReviewBtn ? { filter: "brightness(90%)", width: "68%", left: "2%", backgroundColor: "#0064ff", color: "#fff" } : { width: "68%", left: "2%", backgroundColor: "#0064ff", color: "#fff" }}
            >
              평가하기
            </button>
          </div>
        </div>
      </>
    );
  }
}

export { PayComplete };
