/* eslint-disable */

import instance from './frontman';

export const getPopup = async (accessToken?: String) => {
    return await instance({
        url: "/popup",
        method: "get"
    })
};

export const patchPopup = async (body?: object, accessToken?: String) => {
    return await instance({
        url: "/popup",
        method: "patch",
        data: body
    })
};