import React, { useState, useEffect, useCallback } from 'react';
import { Header } from '../../components/Layout';

let scrollCache = 0;
/**-------------------------------------------------------------
1.2.1.3 이용안내 화면_2
-------------------------------------------------------------
> http://basic.pointweb.kr/charge/public/html/public/pay.html
-------------------------------------------------------------*/
function MainInfo() {
  const [inited] = useState(false);

  const cacheScroll = useCallback(() => {
    // 간헐적으로 상세로 이동 후 호출되는 경우가 있어서 예외처리
    if (!/\/voice\/?$/.test(window.location.pathname)) return;
    scrollCache = window.scrollY;
  }, []);
  useEffect(() => {
    window.scrollTo(0, scrollCache);
  }, [inited]);
  useEffect(() => {
    window.addEventListener('scroll', cacheScroll);
    return () => window.removeEventListener('scroll', cacheScroll);
  }, [cacheScroll]);

  // render
  // if (!inited) return null
  return (
    <>
      <Header title={'이용안내'} isInfoMenu={true} isActive={'1'} />

      <div id="page">
        <div className="charger-guide">
          <div className="version">Ver. 2.0.0</div>
          <span className="info-header">
            티맵결제 충전소<br />
            이렇게 이용해주세요.
          </span>

          <div className='info-body'>
            <div className='info-box'>
              <div className='title'>1.충전 시작하기</div>
              <span className='ment'>‘충전 시작하기' 버튼을 누르고 충전기에 부착되어 있거나 화면에 표시된 TMAP QR 코드를 스캔해 주세요.</span>
            </div>
            <div className='info-box'>
              <div className='title'>2.커넥터 연결</div>
              <span className='ment'>차량에 커넥터를 연결하면 충전이 시작됩니다.</span>
            </div>
            <div className='info-box'>
              <div className='title'>3.충전종료</div>
              <span className='ment'>충전이 종료되면, 충전량만큼 자동으로 결제됩니다.</span>
              <li>충전기의 종료 버튼을 눌러도 종료가 되지 않을 때, 차량에서 커넥터를 분리해주세요.</li>
              <li>일부 충전기는 시작 또는 종료 버튼을 눌러야 충전이 시작되거나 종료됩니다.</li>
            </div>
          </div>
        </div>
        <img src={require('../../assets/images/logo/img_info_evstastion.svg').default} className="ani" alt="" style={{width: "100%", position: "fixed", bottom: "0", backgroundColor: "#fff"}}/>
      </div>
    </>
  );
}
export { MainInfo };
