/* eslint-disable */

import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
// <!-- import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'; -->
import { useHistory } from "react-router";
import { HeaderBackButton } from './HeaderBackButton';
// import {HandleBrowserBackButton} from '../../components/Layout'
import { modal } from '../GlobalModal';
import { appActions, store } from "../../store";

import { globalVars } from '../../libs/global-vars';
//import axios from 'axios';

//Mixpanel
import { logbox, mixpanel } from '../../libs/logbox-mixpanel';

import Const from '../../store/ev';
import axios, { AxiosError, AxiosResponse } from "axios";
import { tmapInterface } from "../../libs/tmap-interface";
import { getDeliveryHistory } from '../../apis/delivery';


let isCouponPop = false;

function Header({
                  title,
                  isMenu,
                  isHistoryMenu,
                  isInfoMenu,
                  isActive,
                  tabLabels = { firstTab: "QR충전", secondTab: "오토차지" }, // 기본값 설정
                }: {
  title?: string;
  isMenu?: boolean;
  isHistoryMenu?: boolean;
  isInfoMenu?: boolean;
  isActive?: string;
  tabLabels?: { firstTab: string; secondTab: string }; // 탭 이름 설정용 props
}) {
  const history = useHistory();
  const [menu, setMenu] = useState(isMenu);
  const [active, setActive] = useState(isActive);
  const [historyMenu, setHistoryMenu] = useState(isHistoryMenu);
  const [infoMenu, setInfoMenu] = useState(isInfoMenu);

  const [inited, setInited] = useState(false);

  useEffect(() => {
    setInited(true);

    if (isActive === undefined) {
      setActive("1");
    }
  }, [isActive]);

  const onClickHeader = useCallback(
    (url: string) => {
      if (url === "/main") {
        mixpanel("evcharge:/evcharge/main:tap.main");
        logbox("/evcharge/main", "tap.main");
      } else if (url === "/chargeHistory") {
        mixpanel("evcharge:/evcharge/chargehistory:tap.chargehistory");
        logbox("/evcharge/chargehistory", "tap.chargehistory");
      } else if (url === "/pointCoupon") {
        mixpanel("evcharge:/evcharge/evcoupon:tap.evcoupon");
        logbox("/evcharge/evcoupon", "tap.evcoupon");
      }

      history.replace(`${url}`);
    },
    [history]
  );

  if (!inited) {
    return null;
  } else {
    return (
      <header>
        <div className="header-top">
          <HeaderBackButton />
          <h3 className="header-title">{title || "전기차충전"}</h3>
          {window.location.pathname === "/main" && (
            <Link
              to="/mainInfo"
              onClick={() => {
                mixpanel("evcharge:/evcharge/main:tap.useguide");
                logbox("/evcharge/main", "tap.useguide");
              }}
            >
              이용안내
            </Link>
          )}
        </div>

        {infoMenu && (
          <div className="tab-menu">
            {/* 첫 번째 탭 */}
            <button
              type="button"
              className={active === "1" ? "active" : ""}
              onClick={(e) => {
                e.stopPropagation();
                if (tabLabels.firstTab === "선불충전권 등록") {
                  onClickHeader("/polestarRegisterGuide");
                } else if (tabLabels.firstTab === "선불충전권 이용") {
                  onClickHeader("/polestarUseGuide");
                }
                else {
                  onClickHeader("/mainInfo");
                }
              }}
            >
              {tabLabels.firstTab}
            </button>
            {/* 두 번째 탭 */}
            <button
              type="button"
              className={active === "2" ? "active" : ""}
              onClick={(e) => {
                e.stopPropagation();

                if (tabLabels.secondTab === "선불충전권 등록") {
                  onClickHeader("/polestarRegisterGuide");
                } else if (tabLabels.secondTab === "선불충전권 이용") {
                  onClickHeader("/polestarUseGuide");
                }
                else {
                  onClickHeader("/pncInfo");
                }
              }}
            >
              {tabLabels.secondTab}
            </button>
          </div>
        )}
      </header>
    );
  }
}
export { Header };