/* eslint-disable */
import React, { useState, useEffect, useCallback, useRef } from "react";
import { RouteComponentProps } from 'react-router-dom';
import { tmapInterface } from "../../libs/tmap-interface";
import { globalVars } from "../../libs/global-vars";
import { appManager } from '../../managers';

// AxioS
import axios, { AxiosError, AxiosResponse } from "axios";
import Const from "../../store/ev";
import { getDeliveryWeb } from "../../apis/delivery";
import { getUser, postUser } from "../../apis/user";

let scrollCache = 0;
let currentPositionLon: any = {};
let currentPositionLat: any = {};
let userData: any = {};

function FreeDeliveryPromotion(props: RouteComponentProps) {
    const [inited, setInited] = useState(false);
    const [openInfo, setOpenInfo] = useState<boolean>(false);
    const [deliveryFirstClick, setDeliveryFirstClick] = useState(false);

    const cacheScroll = useCallback(() => {
        // 간헐적으로 상세로 이동 후 호출되는 경우가 있어서 예외처리
        if (!/\/voice\/?$/.test(window.location.pathname)) return;
        scrollCache = window.scrollY;
    }, []);

    let serviceType = (globalVars.queryData.extra?.serviceType)?.toLowerCase() || (globalVars.queryData.extra?.servicetype)?.toLowerCase();

    useEffect(() => {
        window.scrollTo(0, scrollCache);
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', cacheScroll);
        return () => window.removeEventListener('scroll', cacheScroll);
    }, [cacheScroll]);

    const goBack = () => {
        if (serviceType === 'evdeliveryfree') {
            serviceType = '';
            // props.history.replace('/mainRouter');
            tmapInterface.onBackKeyPressed();
        } else {
            props.history.goBack();
        }
    }

    useEffect(() => {
        appManager.globalLoadingInc();
        tmapInterface.getCurrentPosition(currentPosition);
    }, [])

    useEffect(() => {
        getUser()
            .then(function (response: any) {
                if (response.result) {
                    userData = response;
                }
            })
            .catch(function (error: any) {
                if (error.response.status === 400 || error.response.data.result === false) {
                    // 신규회원등록
                    var data = {};

                    postUser(data)
                        .then(function (response: any) {
                            if (Const.DBG_OK) {
                                // console.log('신규회원등록')
                                alert("신규회원등록 " + JSON.stringify(response.data));
                            }

                            getUser()
                                .then(function (response: any) {
                                    userData = response;

                                    if (Const.DBG_OK) {
                                        // console.log('신규회원정보 조회성공')
                                        alert("신규회원 " + JSON.stringify(response.data));
                                    }
                                })
                                .catch(function (error: any) {
                                    //							alert('신규회원정보 조회실패')
                                    console.log(error);
                                });
                        })
                        .catch(function (error: any) {
                            console.log(error);
                        });
                }
            })
    }, []);
    function currentPosition(latitude: string, longitude: string) {
        //qr코드가 '' 빈값일때 qr인증실패로 이동
        if (latitude) {
            currentPositionLat = Number(latitude);
            currentPositionLon = Number(longitude);

            appManager.globalLoadingReset();
            setInited(true);
        }
    }

    const goEvDelivery = () => {
        if (deliveryFirstClick) return;

        if (userData.evDeliveryYn) {
            getDeliveryWeb(currentPositionLat, currentPositionLon)
                .then((response: any) => {
                    const objectHtml = document.createElement('div');
                    objectHtml.innerHTML = response;
                    // console.log(objectHtml.querySelector('form')?.getAttribute('action'));

                    tmapInterface.tChargeDelivery(objectHtml.querySelector('form')?.getAttribute('action') as string);

                    setTimeout(() => {
                        setDeliveryFirstClick(false);
                    }, 500);
                })
                .catch((error: AxiosError) => {
                    console.log(error);
                });
        } else {
            props.history.replace("/evDelivery");
        }
    };

    if (!inited) {
        return <></>
    } else {
        return (
            <>
                <div className="free-ev-delivery">
                    <button type="button" className="back-btn" onClick={goBack} style={{ zIndex: "999" }}>
                        <img src={require('%/images/common/ic_gnb_back.svg').default} alt="" />
                    </button>
                    <div className="main-info">
                        <img src={require('%/images/promotion/img_charging_delivery_free_main.svg').default} alt="" style={{ position: "relative", display: "block", width: "100%", verticalAlign: "center" }} />
                        <div className="main-top" style={Number(window.innerWidth) > 440 ? { top: "15vh" } : {}}>
                            <img src={require('%/images/logo/img_logo_tmap_white.svg').default} alt="" style={{ width: "20vw", position: "relative", top: "4px", display: "inline", opacity: "0.6" }} />
                            &nbsp;&nbsp;<span className="title">충전배달 감사 이벤트</span>
                        </div>
                        <div className="main-title" style={Number(window.innerWidth) > 440 ? { top: "30vh" } : {}}>지금 바로 1회<br /> <span className="border-box">무료이용</span> 해보세요!</div>
                        <div className="main-subtitle">여러분의 성원에 힘입어<br /> <span className="bold-text">TMAP 충전배달 서비스 지역을 확대</span>합니다.</div>
                        <div className="first" onClick={() => {
                            setDeliveryFirstClick(true);
                            goEvDelivery()
                        }}>
                            <button type="button" className="go-info">
                                충전배달 무료로 이용하기
                            </button>
                        </div>
                    </div>
                    <div className="delivery">
                        <div className="head-title"><span className="bold">‘충전배달'</span>이란?</div>
                        <img src={require('%/images/promotion/img_charging_delivery_truck.svg').default} alt="" style={{ width: "40%", margin: "25px 0" }} />
                        <div className="inner-text">긴급한 충전이 필요할 때, <br />
                            충전 중 대기 시간이 아까울 때, <br />
                            충전이 너무 귀찮을 때,
                        </div>
                        <div className="inner-text2">
                            더이상 충전소 찾아다니지 마세요. <br />
                            충전기가 직접 찾아갑니다!
                        </div>
                    </div>

                    <div className="main-content">
                        <div className="head-title">충전배달 가능지역</div>
                        <div className="inner-text2">
                            서울, 성남시 분당구
                        </div>
                        <img src={require('%/images/promotion/img_charging_delivery_map_before.svg').default} alt="" style={{ width: "70%", margin: "25px 0 0 0" }} />
                    </div>
                    <div className="downarrow">
                        <img src={require('%/images/common/ic_downarrow.svg').default} alt="" style={{ width: "20px" }} />
                    </div>
                    <div className="main-content">
                        <div className="inner-text2" style={{ color: "#0064FF", borderBottom: "3px solid #0064ff", letterSpacing: "0.05em", fontSize: "22px" }}>
                            수도권 전지역으로 확대!
                        </div>
                        <div className="sub-noti">
                            서비스 확대 지역 및 일정은 변경될 수 있습니다.
                        </div>
                        <img src={require('%/images/promotion/img_charging_delivery_map_after.svg').default} alt="" style={{ width: "70%", margin: "25px 0 0 0" }} />
                    </div>

                    <div className="last">
                        <div className="date">8월까지 <span className="bold">50% 할인 가격</span>으로 이용 가능</div>
                        <div className="last-date">심지어 <span className="highlight">첫 1회는 무료!</span></div>
                        <img src={require('%/images/promotion/img_charging_delivery_coupon_truck.svg').default} alt="" style={{ position: "relative", display: "block", padding: "35px 20px" }} />
                        <div className="use">지금 바로 무료로 이용해 보세요.</div>
                        <button type="button" className="go-info" style={{ zIndex: 9999 }} onClick={() => {
                            setDeliveryFirstClick(true);
                            goEvDelivery()
                        }}>
                            충전배달 무료로 이용하기
                        </button>
                    </div>
                </div>
            </>
        )
    }
}

export { FreeDeliveryPromotion };