/* eslint-disable */

import instance from './frontman';

//QR 인증
export const postQrAuth = async (body: object, accessToken?: String) => {
    return await instance({
        url: "/auth",
        method: "post",
        data: body
    })
};
export const patchQrAuth = async (body: object, accessToken?: String) => {
    return await instance({
        url: "/auth",
        method: "patch",
        data: body
    })
};

//간편 인증
export const postTapCharge = async (body: object, accessToken?: String) => {
    return await instance({
        url: "/tap-tap",
        method: "post",
        data: body
    })
};

export const patchTapCharge = async (body: object, accessToken?: String) => {
    return await instance({
        url: "/tap-tap",
        method: "patch",
        data: body
    })
};