/* eslint-disable */

// 기본
import React, { useState, useEffect } from 'react';

import { RouteComponentProps, Link } from 'react-router-dom';

//헤더 
import { Header } from '../../components/Layout';
import { HandleBrowserBackButton } from '../../components/Layout'
import { globalVars } from '../../libs/global-vars';
import { tmapInterface } from '../../libs/tmap-interface';

import Const from '../../store/ev';
import { utils } from '../../libs/utils';
import { appActions, store } from '../../store';

//Mixpanel
import { logbox, mixpanel } from '../../libs/logbox-mixpanel';
import { PncButton } from './components/PncButton';
import Sheet from "react-modal-sheet";
import { modal } from '../../components/GlobalModal';
import { getCarInfo, postPncCoupler, postFindBrand, postFindModel, patchPncAuth, patchPncAgreement, postCarInfo, deleteMacRemove, patchCarInfo, getCarProfile } from '../../apis/pnc';
import { postMomenty } from '../../apis/momenty';
import { appManager } from '../../managers';
import { getAuthStatus } from '../../apis/status';
import { getUser } from '../../apis/user';
import { retrieveCardInfo } from '../../libs/commonFunction';
import { getCpos } from '../../apis/cpo';

let timer: Nullable<number> = null; // 타이머

function PncInfo(props: RouteComponentProps<{ id: string }>) {

    const [inited, setInited] = useState(false);

    const [userInfo, setUserInfo] = useState<any>({});
    const [pncBtnFocus, setPncBtnFocus] = useState(false);

    //결제수단
    const [payType, setPayType] = useState("N"); // 결제수단 : TODO
    const [payCount, setPayCount] = useState(0);

    const [carInfo, setCarInfo] = useState<any>({}); //유저 차량 정보
    const [carInfoStatus, setCarInfoStatus] = useState(0); //0: 차량 미등록 1: 등록 후 mac 없는 상태 2: 차량 정상 등록
    const [carInfoCpo, setCarInfoCpo] = useState<any>([]); //차량 정보 cpo 데이터
    // const [cpoList, setCpoList] = useState<any>([]);
    // const [cpoSelected, setCpoSelected] = useState("");
    // const [cpoStatus, setCpoStatus] = useState(false);

    const [brandList, setBrandList] = useState<any>([]); //브랜드 종류
    const [brandSelected, setBrandSelected] = useState(""); //선택된 브랜드
    const [brandStatus, setBrandStatus] = useState(false); //브랜드 선택한 상태 여부

    const [modelList, setModelList] = useState<any>([]); //모델 종류
    const [modelSelected, setModelSelected] = useState(""); //선택된 모델
    const [modelStatus, setModelStatus] = useState(false); //모델 선택한 상태 여부

    const [carCheck, setCarCheck] = useState(false); //pnc 약관 동의 여부
    const [carCheckItem, setCarCheckItem] = useState(false); //pnc 약관 동의 여부 항목

    const [registSheet, setRegistSheet] = useState(false); //등록 시트
    const [registDetailSheet, setRegistDetailSheet] = useState(false); //등록 상세 시트(모델, 브랜드 등)

    const [cpoSheet, setCpoSheet] = useState(false); //등록 삭제 cpo 시트
    const [cpoSheetType, setCpoSheetType] = useState("regist"); //cpo 시트 타입
    const [cpoAllCheck, setCpoAllCheck] = useState(false); //cpo 전체 선택
    const [cpoCheckStatus, setCpoCheckStatus] = useState(false); //cpo 선택한 상태 여부

    const [title, setTitle] = useState(""); //시트 타이틀

    const [lon, setLon] = useState(0);
    const [lat, setLat] = useState(0);
    const [pncStationList, setPncStationList] = useState<any>([]); //pnc 가능 충전소 리스트

    //포커스
    const [pncStationFocus, setPncStationFocus] = useState(false);
    const [pncStationPoiId, setPncStationPoiId] = useState("");

    const [profileInfo, setProfileInfo] = useState<any>([]);
    const [selectedCar, setSelectedCar] = useState({
        id: "",
        selectMakerName:"",
        selectCarName: "",
        selectImg:""
    });

    const getUserInfo = () => { //유저조회 - 약관 동의 여부 확인
        getUser()
            .then((response: any) => {
                setUserInfo(response);
                if (response.pncAgreementYn) {
                    setCarCheck(true);
                } else {
                    setCarCheck(false);
                }
            })
            .catch((error: any) => {
                console.log(error);
            })
    }

    const getCar = (type, bodyInfo?) => { //유저 차량 조회 type(플래그)별 동작 정의
        return getCarInfo()
            .then((response: any) => {
                if (type === "check") {
                    setCarInfo(response.carInfo);
                    getBrand();
                    if (response.result) {
                        setCarInfoStatus(2);
                        setBrandStatus(true);
                        setBrandSelected(response.carInfo.brandName);
                        setModelStatus(true);
                        setModelSelected(response.carInfo.modelName);
                    } else if (response.reason === "EV-USER-CARINFO-NOT-FOUND") {
                        setCarInfoStatus(0);
                        setBrandStatus(false);
                        setBrandSelected("");
                        setModelStatus(false);
                        setModelSelected("");
                    } else if (response.reason === "EV-USER-MACADDRESS-NOT-FOUND") {
                        setCarInfoStatus(1);
                        setBrandStatus(true);
                        setBrandSelected(response.carInfo.brandName);
                        setModelStatus(true);
                        setModelSelected(response.carInfo.modelName);
                    } else {
                        setCarInfoStatus(0);
                    }
                    // setCarInfoStatus(response.result);
                    // const transformedCpos = {
                    //     cpos: response.carInfo.cpos.map(name => ({ name }))
                    // };
                    const cposResponse = response.carInfo.cpos.map(item => ({
                        ...item, "status": "N"
                    }));
                    setCarInfoCpo(cposResponse);
                    // if (response.carInfo.cpos.length > 0 && response.result) {
                    //     setBrandStatus(true);
                    //     setBrandSelected(response.carInfo.brandName);
                    //     setModelStatus(true);
                    //     setModelSelected(response.carInfo.modelName);
                    // } else {
                    //     setBrandStatus(false);
                    //     setModelStatus(false);
                    // }
                    return true;
                } else if (type === "regist") {
                    if (response.reason === "EV-USER-CARINFO-NOT-FOUND") {
                        postCarInfo(bodyInfo? bodyInfo : carInfoData)
                            .then((response: any) => {
                                if (response.result) {
                                    tmapInterface.startQrCodeScanActivity(QrCodeScan);
                                    getCar("check");
                                } else {
                                    modal.alert(() => {
                                    }, "등록에 실패하였습니다.", "다시 시도해주세요.");
                                }
                            })
                            .catch((error: any) => {
                                console.log(error)
                            })
                    } else {
                        if (response.carInfo.brandName === selectedCar.selectMakerName && response.carInfo.modelName === selectedCar.selectCarName) {
                            tmapInterface.startQrCodeScanActivity(QrCodeScan);
                            getCar("check");
                        } else {
                            patchCarInfo(bodyInfo? bodyInfo : carInfoData)
                                .then((response: any) => {
                                    if (response.result) {
                                        tmapInterface.startQrCodeScanActivity(QrCodeScan);
                                        getCar("check");
                                    } else {
                                        modal.alert(() => {
                                        }, "등록에 실패하였습니다.", "다시 시도해주세요.");
                                    }
                                })
                                .catch((error: any) => {
                                    console.log(error)
                                })
                        }
                    }
                }
            })
            .catch((error: any) => {
                console.log(error);
            })
    }

    const handleCarCheck = () => { //약관 동의 업데이트 이벤트
        if (carCheck) {
            setCarCheck(false);
            const pncAgreementData = {
                pncAgreementYn: false
            }
            patchPncAgreement(pncAgreementData)
                .then((response: any) => {
                    console.log(response.result);
                })
                .catch((error: any) => {
                    console.log(error);
                })
        } else {
            setCarCheck(true);
            const pncAgreementData = {
                pncAgreementYn: true
            }
            patchPncAgreement(pncAgreementData)
                .then((response: any) => {
                    console.log(response.result);
                })
                .catch((error: any) => {
                    console.log(error);
                })
        }
    }

    useEffect(() => {
        if (payCount > 0) {
            setPayType(store.getState().app.payType);
        }
    }, [payCount]);

    const getPayMethod = () => {
        const promise = retrieveCardInfo();
        const getData = () => {
            promise.then((result) => {
                if (result) {
                    setPayCount(payCount + 1)
                }
            });
        };
        getData();
    }

    const onClickRegist = () => { //유저 차량 등록 요청
        if (payType == "N") {
            payMethodPop();
        } else {
            getCarProfile().then((res: any) => {
                const carProfileList = res.integrateCarInfos.filter(x => x.fuel === "FT_EV");
                if (carProfileList.length === 0 || carProfileList.length < 1) {
                    modal.alert(() => {
                        return tmapInterface.openBrowser(
                            "tmap://carprofile?pageid=register_car_profile"
                        );
                    }, "등록 가능한<br/> 전기차 유종 차량이 없습니다", '차량프로필에서 차량 등록 후 이용해주세요.', true, null, "닫기", "차량 등록");  
                } else {
                    setSelectedCar({...selectedCar,
                        id: carProfileList[0].carProfileId,
                        selectMakerName: carProfileList[0].makerName,
                        selectCarName: carProfileList[0].carName,
                        selectImg: carProfileList[0].extCarInfo?.imgUrl
                    })
                    const patchBody = {
                        "brandName": carProfileList[0].makerName,
                        "modelName": carProfileList[0].carName,
                        "imgUrl": carProfileList[0].extCarInfo?.imgUrl,
                        "cpo": "SE"
                    }
                    if (carProfileList.length > 1) {
                        setProfileInfo(carProfileList)
                        setRegistSheet(true);
                    } else {
                        getCar('regist',patchBody);
                    }
                }
            }).catch((err) => {
                modal.alert(() => {
            }, "등록 가능한<br/> 전기차 유종 차량이 없습니다", '차량프로필에서 차량 등록 후 이용해주세요.');  
            });
        }
    }

    const payMethodPop = () => {
        modal.alert(() => {
            popClosed();
        }, "결제 카드 등록 후</br>충전을 진행하실 수 있습니다.");
    };

    const popClosed = () => {
        if (timer) clearTimeout(timer);
        timer = window.setTimeout(() => goPayChoiceView(), 1);
    };

    const goPayChoiceView = () => {
        tmapInterface.startPaymentActivity(selectCard);
    };

    function selectCard(status: string) {
        if (Const.DBG_OK) {
            alert("selectCard \n " + "status: " + status);
        }

        if (globalVars.isIOS) {
            tmapInterface.getAccessKey(getAccessKey);
        } else {
            globalVars.accessKey = tmapInterface.getAccessKey();

            getPayMethod();

            props.history.replace("/main");
        }
    }

    function getAccessKey(value: string) {
        globalVars.accessKey = value

        getPayMethod();

        props.history.replace("/main");
    }

    const onClickDeleteCarInfo = (cpoCode: string) => { //삭제 버튼 이벤트
        modal.alert(
            () => {
                setCpoSheet(false);
                deleteMacRemove(cpoCode)
                    .then((response: any) => { //TODO 여러 cpo 중 하나 삭제되는건지 하나 cpo가 삭제 되는건지 케이스에 따라 화면 동작 분기 필요
                        tmapInterface.makeToast("등록이 취소되었습니다")
                        getCar("check");
                    })
                    .catch((error: any) => {
                        console.log(error); //TODO 케이스 별 에러 처리 필요
                    })
            },
            "삭제하시겠습니까?",
            "",
            true
        );
    }

    var carInfoData = { //TODO 현재는 cpo 값 se 고정으로 사용 추후에는 선택한 cpo 값으로 요청 필요
        "brandName": selectedCar.selectMakerName,
        "modelName": selectedCar.selectCarName,
        "imgUrl": selectedCar.selectImg,
        "cpo": "SE"
    }

    function QrCodeScan(status: string, qrcode: string) {
        setRegistSheet(false);
        if (status === "COMPLETE") {
            if (!qrcode) {
                modal.alert(() => {
                }, "등록에 실패하였습니다.", "다시 시도해주세요.");
            } else {
                qrcode = qrcode;
                pncCoupler(qrcode);
            }
        } else if (status === "CANCEL") {
            mixpanel("evcharge:/evcharge/qrscan:tap.back");
            logbox("/evcharge/qrscan", "tap.back");
        }
    }

    const pncCoupler = (qrcode: string) => {
        appManager.globalLoadingInc(); //로딩바
        postPncCoupler(pncCouplerData(qrcode))
            .then((response: any) => {
                if (response.pncYn) {
                    pncAuth(response.guid, qrcode);
                } else {
                    appManager.globalLoadingReset();
                    modal.alert(() => {
                    }, "등록에 실패하였습니다.", "다시 시도해주세요.");
                }
            })
            .catch((error: any) => {
                appManager.globalLoadingReset();
                modal.alert(() => {
                }, "등록에 실패하였습니다.", "다시 시도해주세요.");
            })
    }

    const pncCouplerData = (qrcode: string) => {
        return (
            {
                "qrCode": qrcode
            }
        )
    }

    const pncAuth = (guid: string, qrCode: string) => {
        patchPncAuth(pncAuthData(guid, qrCode))
            .then((response: any) => {
                globalVars.connCheckLoop = setInterval(() => {
                    authStatus(guid);
                }, 2000);
            })
            .catch((error: any) => {
                appManager.globalLoadingReset();
                modal.alert(() => {
                }, "등록에 실패하였습니다.", "다시 시도해주세요.");
            })
    }

    const authStatus = (guid: string) => {
        getAuthStatus(guid)
            .then((response: any) => {
                if (response.authStatus === 4 || response.authStatus === 6) { //성공
                    if (response.result) {
                        clearInterval(globalVars.connCheckLoop);
                        appManager.globalLoadingReset();
                        props.history.push(`/charging/${guid}`);
                    }
                } else if (response.authStatus === 3 || response.authStatus === 5 || response.authStatus === 7 || response.authStatus === 8) { //실패
                    clearInterval(globalVars.connCheckLoop);
                    appManager.globalLoadingReset();
                    modal.alert(() => {
                    }, "등록에 실패하였습니다.", "다시 시도해주세요.");
                }
            })
            .catch((error: any) => {
                clearInterval(globalVars.connCheckLoop);
                appManager.globalLoadingReset();
                modal.alert(() => {
                }, "등록에 실패하였습니다.", "다시 시도해주세요.");
            })
    }

    const pncAuthData = (guid: string, qrCode: string) => {
        return (
            {
                guid: guid,
                couplerType: "PNC",
                qrCode: qrCode
            }
        )
    }

    // const getCpoList = () => {
    //     getCpos()
    //         .then((response: any) => {
    //             const cpoCode = response.cpo.map((item) => item.cpoCode); //cpo 리스트 받아오기(cpoCode만)
    //             const transformedResponse = {
    //                 cpos: cpoCode.map(name => ({ name }))
    //             };
    //             const cpoResponse = transformedResponse.cpos.map(item => ({
    //                 ...item, "status": "N"
    //             }));
    //             setCpoList(cpoResponse);
    //         })
    //         .catch((error: any) => {
    //             console.log(error);
    //         })
    // }

    const getBrand = () => {
        postFindBrand()
            .then(function (response: any) {
                const transformedResponse = {
                    brands: response.brands.map(name => ({ name }))
                };
                const brandResponse = transformedResponse.brands.map(item => ({
                    ...item, "status": "N"
                }));
                setBrandList(brandResponse)
            })
            .catch(function (error: any) {
                console.log(error)
            })
    }

    const getModel = () => {
        postFindModel(modelData)
            .then(function (response: any) {
                const transformedResponse = {
                    models: response.models.map(name => ({ name }))
                };
                const modelResponse = transformedResponse.models.map(item => ({
                    ...item, "status": "N"
                }));
                setModelList(modelResponse)
            })
            .catch(function (error: any) {
                console.log(error)
            })
    }

    const modelData = {
        "brandName": brandSelected
    }

    const onClickBrand = (name: any) => { //브랜드 선택 status 값으로 선택여부 체크
        const brandY = brandList.map((item) => item.name === name ? { ...item, status: "Y" } : { ...item, status: "N" });
        const brandYItem = brandY.filter((item) => item.status == "Y");
        // const cpoN = cpoList.map(item => item ? { ...item, status: "N" } : item);

        if (brandSelected != name) {
            setBrandList(brandY);
            setBrandSelected(brandYItem[0].name);
            setModelSelected("");
            // setCpoSelected("");
            // setCpoList(cpoN);
        }

        setRegistDetailSheet(false);
    }

    const onClickModel = (name: any) => { //모델 선택 status 값으로 선택여부 체크
        const modelY = modelList.map((item) => item.name === name ? { ...item, status: "Y" } : { ...item, status: "N" });
        const modelYItem = modelY.filter((item) => item.status == "Y");
        // const cpoN = cpoList.map(item => item ? { ...item, status: "N" } : item);

        if (modelSelected != name) {
            setModelList(modelY);
            setModelSelected(modelYItem[0].name);
            // setCpoSelected("");
            // setCpoList(cpoN);
        }

        setRegistDetailSheet(false);
    }

    const onClickDeleteCpo = (cpoCode: any, status: any) => {
        const cpoY = carInfoCpo.map((item) => item.cpoCode === cpoCode ? { ...item, status: "Y" } : item);
        // const cpoYItem = cpoY.filter((item) => item.status == "Y");
        const cpoN = carInfoCpo.map((item) => item.cpoCode === cpoCode ? { ...item, status: "N" } : item);

        // if (cpoSelected != name) {
        //     setCarInfoCpo(cpoY);
        //     // setCpoSelected(cpoYItem[0].name);
        // } else {
        //     setCarInfoCpo(cpoN);
        // console.log(status)
        //

        if (status === "Y") {
            setCarInfoCpo(cpoN);
            if (cpoN.filter((item) => item.status === "Y").length !== cpoN.length) {
                setCpoAllCheck(false);
            } else {
                setCpoCheckStatus(false);
            }
        } else if (status === "N") {
            setCarInfoCpo(cpoY);
            if (cpoY.filter((item) => item.status === "Y").length === cpoY.length) {
                setCpoAllCheck(true);
            }
        }
        // console.log(cpoY.filter((item) => item.status === "Y").length)
        // console.log(cpoY.length)


        // if (cpoY.filter((item) => item.status === "Y").length === cpoY.length) {
        //     setCpoAllCheck(true);
        // } else {
        //     setCpoAllCheck(false);
        // }
    }

    // const onClickCpo = (name: any) => {
    //     const cpoY = cpoList.map((item) => item.name === name ? { ...item, status: "Y" } : { ...item, status: "N" });
    //     const cpoYItem = cpoY.filter((item) => item.status == "Y");

    //     if (cpoSelected != name) {
    //         setCpoList(cpoY);
    //         setCpoSelected(cpoYItem[0].name);
    //     }

    //     setRegistDetailSheet(false);
    // }

    const onClickDetail = (title: string) => {
        setTitle(title);
        setRegistDetailSheet(true);
    }

    const getCurrentPosition = () => {
        tmapInterface.getCurrentPosition(currentPosition);
    }

    function currentPosition(latitude: string, longitude: string) {
        setLon(Number(longitude));
        setLat(Number(latitude));
    }

    function getLocation() {
        //현재위치 호출 및 position에 저장
        navigator.geolocation.getCurrentPosition(
            function (currentPosition) {
                setLon(Number(currentPosition.coords.longitude));
                setLat(Number(currentPosition.coords.latitude));
            },
            function (error) {
                console.error(error);
            },
            {
                enableHighAccuracy: false,
                maximumAge: 0,
                timeout: Infinity,
            }
        );
    }

    const getPncStations = () => { //pnc 충전소 조회
        postMomenty(momentyData)
            .then((response: any) => {
                setPncStationList((response.body.docs).slice(0, 2));
                setInited(true);
            })
            .catch((error: any) => {
                setInited(true);
                console.log(error)
            })
    }

    const momentyData = { //범위 3km 제한, pnc 여부, 최대 갯수 2개
        lon: lon,
        lat: lat,
        radius: "3",
        pncIncludeYn: true,
        pageSize: 2
    }

    const goPoiDetail = () => {
        tmapInterface.startPoiDetailActivity();
    }

    const openCpoSheet = (type: string) => {
        setCpoSheetType(type);
        setCpoSheet(true);
    }

    const onClickCpoAll = () => {
        if (cpoAllCheck) {
            setCpoAllCheck(false);
            setCpoCheckStatus(false);
            const cpoAllN = carInfoCpo.map((item) => { return { ...item, status: "N" } });
            setCarInfoCpo(cpoAllN);
        } else {
            setCpoAllCheck(true);
            setCpoCheckStatus(true);
            const cpoAllY = carInfoCpo.map((item) => { return { ...item, status: "Y" } });
            setCarInfoCpo(cpoAllY);
        }
    }

    useEffect(() => {
        getCurrentPosition();
        // getLocation();
        getUserInfo();
        getCar("check");
        // getCpoList();
        getBrand();
        getPayMethod();
    }, [])

    useEffect(() => {
        if (lon !== 0 && lat !== 0) {
            getPncStations();
        }
    }, [lon, lat])

    useEffect(() => {
        if (brandSelected) {
            getModel();
            setBrandStatus(true);
        }
    }, [brandSelected])

    useEffect(() => {
        if (modelSelected) {
            setModelStatus(true);
        } else {
            setModelStatus(false);
        }
    }, [modelSelected])

    // useEffect(() => {
    //     if (cpoSelected) {
    //         setCpoStatus(true);
    //     } else {
    //         setCpoStatus(false);
    //     }
    // }, [cpoSelected])

    useEffect(() => {
        window.scrollTo(0, document.body.scrollHeight);
    }, [carCheckItem])

    useEffect(() => {
        if (registSheet){
            document.body.style.cssText = 'overflow: hidden;'
        } else {
            document.body.style.cssText = ''
        }
    }, [registSheet])
    
    const handleClick = (car) => {
        setSelectedCar({
            ...selectedCar,
            id: car.carProfileId,
            selectMakerName: car.makerName,
            selectCarName: car.carName,
            selectImg: car.extCarInfo?.imgUrl
        })
    };

    if (!inited) {
        return null;
    } else {
        return (
            <>
                <Header title={'이용안내'} isInfoMenu={true} isActive={'2'} />

                <div className='pnc'>
                    <div className='info'>
                        <div className='title'>
                            <p className='main-text'>충전소에 도착해서</p>
                            <p className='main-text'>커넥터만 연결하세요!</p>
                            <p className='sub-text'>인증없이 바로 충전하세요</p>
                        </div>
                        <div className='pnc-img'>
                            <img src={require('%/images/common/ic-pnc-info.png').default} alt="" />
                        </div>
                        <div className='station'>
                            <div className='notice'>
                                <img src={require('%/images/common/ic-info-blue.svg').default} alt="" />
                                <p>이렇게 사용하세요</p>
                            </div>
                            <div className='item'>
                                <p className='main-text'>&nbsp;1.&nbsp;&nbsp;오토차지 충전소 찾기</p>
                                <p className='sub-text'>전기차 충전 필터를 활용해 가까운 오토차지 충전소를 찾아주세요.</p>
                            </div>
                            <div className='list'>
                                {
                                    pncStationList.length == 0
                                        ?
                                        <></>
                                        :
                                        <p className='main-text'>가까운 오토차지 충전소</p>
                                }
                                {
                                    pncStationList.map((item) => {
                                        return (
                                            <div className='item' onClick={() => {
                                                store.dispatch(appActions.setPoiId(item.poi_id));
                                                store.dispatch(appActions.setPkey(item.pkey));
                                                store.dispatch(appActions.setNavX(item.center_x));
                                                store.dispatch(appActions.setNavY(item.center_y));
                                                goPoiDetail();
                                            }} style={item.poi_id === pncStationPoiId && pncStationFocus ? { filter: "brightness(90%)" } : {}}
                                                onTouchStart={() => {
                                                    setPncStationFocus(true);
                                                    setPncStationPoiId(item.poi_id);
                                                }}
                                                onTouchEnd={() => {
                                                    setPncStationFocus(false);
                                                    setPncStationPoiId("")
                                                }}>
                                                <p className='name'>{item.org_name}</p>
                                                <div className='available'>
                                                    {
                                                        item.ev_charger_count_info[2]?.available_count == 0
                                                            ?
                                                            <></>
                                                            :
                                                            <>
                                                                <p className='available-type'>초급속&nbsp;</p>
                                                                <p className='available-count'>{item.ev_charger_count_info[2]?.available_count ?? 0}&nbsp;대 가능</p>
                                                            </>
                                                    }
                                                    {
                                                        item.ev_charger_count_info[1]?.available_count == 0
                                                            ?
                                                            <></>
                                                            :
                                                            <>
                                                                <p className='available-type'>급속&nbsp;</p>
                                                                <p className='available-count'>{item.ev_charger_count_info[1]?.available_count ?? 0}&nbsp;대 가능</p>
                                                            </>
                                                    }
                                                    {
                                                        item.ev_charger_count_info[0]?.available_count == 0
                                                            ?
                                                            <></>
                                                            :
                                                            <>
                                                                <p className='available-type'>완속&nbsp;</p>
                                                                <p className='available-count'>{item.ev_charger_count_info[0]?.available_count ?? 0}&nbsp;대 가능</p>
                                                            </>
                                                    }
                                                </div>
                                                <p className='distance'>{Math.floor(item.distance)}m</p>
                                                <img src={require('%/images/common/ico_arrow_right.png').default} alt="" />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className='item'>
                                <p className='main-text'>&nbsp;2.&nbsp;&nbsp;커넥터 연결</p>
                                <p className='sub-text'>충전기 앞에 차를 주차한 후 바로 커넥터를 연결하여 충전하세요.</p>
                                <p className='second-sub-text'>이미 등록하셨다면 자동으로 충전이 진행됩니다.</p>
                            </div>
                        </div>
                        {
                            carInfoStatus === 2
                                ?
                                <div className='car-info'>
                                    <div className='car-title'>
                                        <p className='main-text'>등록차량</p>
                                        <p className='delete-btn' onClick={() => openCpoSheet("delete")}>등록취소</p>
                                    </div>
                                    {
                                        // carInfoCpo
                                        //     .map((item) => {
                                        //         return (
                                        <div className='info-box'>
                                            {
                                                carInfo?.imgUrl
                                                ?
                                                <img src={carInfo?.imgUrl} className='car-img' />
                                                :
                                                <img src={require('%/images/common/img_car_default.png').default} alt="" className='car-img' />
                                            }
                                            <div className='info-detail'>
                                                <p className='info-brand'>{carInfo?.brandName}</p>
                                                <p className='info-model'>{carInfo?.modelName}</p>
                                                <p className='info-mac'>{carInfo?.macAddress}</p>
                                                {/* <p className='info-mac'>{item}</p> */}
                                            </div>
                                            {/* <img src={require('%/images/common/ico_cancel_grey.svg').default} alt="" className='delete-img' onClick={() => onClickDeleteCarInfo("SE")} /> */}
                                        </div>
                                        //     )
                                        // })
                                    }
                                </div>
                                :
                                <div className='car-info'>
                                    <p className='main-text'>사용하기 위해 차량등록이 필요해요.</p>
                                    <p className='sub-text'>등록을 위해 오토차지 충전기 커넥터와 차량을 연결한 후 '오토차지 등록하기'를 눌러 진행해주세요.</p>
                                    <div className='check'>
                                        {
                                            carCheck
                                                ?
                                                <img src={require('%/images/common/ic-check-on.svg').default} alt="" onClick={handleCarCheck} />
                                                :
                                                <img src={require('%/images/common/ic-check-off.svg').default} alt="" onClick={handleCarCheck} />
                                        }
                                        <p className='check-text' onClick={handleCarCheck}>(필수) 차량 고유 번호 이용 동의</p>
                                        {
                                            carCheckItem
                                                ?
                                                <img className='check-img' src={require('%/images/common/ico_arrow_up_grey_mini.svg').default} alt="" style={{ marginLeft: "auto" }} onClick={() => setCarCheckItem(false)} />
                                                :
                                                <img className='check-img' src={require('%/images/common/ico_arrow_down_grey_mini.svg').default} alt="" style={{ marginLeft: "auto" }} onClick={() => setCarCheckItem(true)} />
                                        }
                                    </div>
                                    {
                                        carCheckItem
                                            ?
                                            <div className='agree-item'>
                                                <p className='first'>티맵모빌리티 주식회사는 오토차지 서비스 제공을 위해 아래와 같이 개인정보를 수집합니다.</p>
                                                <div className='first-item'>
                                                    <p className='item-title'>수집/이용목적</p>
                                                    <p className='item-content'>오토차지 서비스 제공</p>
                                                </div>
                                                <div className='first-item'>
                                                    <p className='item-title'>수집 항목</p>
                                                    <p className='item-content'>차량 Mac Address</p>
                                                </div>
                                                <div className='first-item'>
                                                    <p className='item-title'>보유/이용 기간</p>
                                                    <p className='item-content-bold'>오토차지 제공 기간 (단, 서비스 해지하는 경우 즉시 삭제)</p>
                                                </div>
                                                <p className='info-text'>개인정보 수집, 이용 등의 처리에 대해 거부할 권리가 있습니다. 거부 할 경우 오토차지 서비스 이용에 제한이 있습니다.</p>
                                            </div>

                                            :
                                            <></>
                                    }
                                </div>
                        }
                    </div>
                </div>
                {
                    carInfoStatus == 2
                        ?
                        <></>
                        :
                        <PncButton name={"regist"} carCheck={carCheck} onClickRegist={onClickRegist} />
                }
                <Sheet //등록 시트
                    isOpen={registSheet}
                    onClose={() => setRegistSheet(false)}
                    snapPoints={window.innerWidth > 322 ? [370, 0] : [410, 0]} // 높이 조절 기능 최대값 두개 설정해서 최상단 최하단 지정
                    disableDrag={true}
                >
                    <Sheet.Container style={{ borderTopRightRadius: "15px", borderTopLeftRadius: "15px", boxShadow: "0px -2px 4px 0px rgba(0, 0, 0, 0.20)" }}>
                        <Sheet.Content style={{ borderTopRightRadius: "15px", borderTopLeftRadius: "15px" }}>
                            <div className='regist-sheet'>
                                <div className='title'>
                                    <div className='main-text'>
                                        대표 차량 설정
                                    </div>
                                </div>
                                {
                                    profileInfo.map((x) => {
                                    return (
                                        <div className='select-box' key={x.carProfileId} onClick={() => handleClick(x)} style={ selectedCar.id === x.carProfileId ?{border: '1.5px solid #0064FF'}:{}}>
                                            <div className='info-detail'>
                                                <div className='info-brand' style={ selectedCar.id === x.carProfileId ?{color: '#0064FF'}:{}}>{x?.carNumber}</div>
                                                <div className='info-model' style={ selectedCar.id === x.carProfileId ?{color: '#0064FF'}:{}}>{x?.carName}</div>
                                            </div>
                                            <img src={x.extCarInfo ? x.extCarInfo?.imgUrl: require('%/images/common/img_car_default.png').default} alt="" className='car-img' />
                                        </div>
                                    )
                                    })
                                }
                                <div className='info-text'>
                                    <img src={require('%/images/common/ico_information.svg').default} alt=""  />
                                    <div style={{top:'3px', position:'relative'}}>오토차지는 1대의 차량만 등록하실 수 있습니다</div>
                                </div>
                                <div className={'footer-button'} onClick={()=>getCar('regist')}>
                                    <a style={pncBtnFocus ? { filter: "brightness(90%)" } : {}} onTouchStart={() => setPncBtnFocus(true)} onTouchEnd={() => setPncBtnFocus(false)}> 확인 </a>
                                </div>
                            </div>
                        </Sheet.Content>

                    </Sheet.Container>
                    <Sheet.Backdrop
                        style={{ opacity: "0.1" }}
                        onTap={() => {
                            setRegistSheet(false);
                        }}
                    />
                </Sheet>
                <Sheet //제조사, 차량모델 시트
                    isOpen={registDetailSheet}
                    onClose={() => setRegistDetailSheet(false)}
                    snapPoints={title == "제조사" ? [brandList.length > 5 ? 430 : 110 + 56 * brandList.length, 0] : title == "차량모델" ? [modelList.length > 5 ? 430 : 110 + 56 * modelList.length] : []} // 높이 조절 기능 최대값 두개 설정해서 최상단 최하단 지정
                    disableDrag={true}
                >
                    <Sheet.Container style={{ borderTopRightRadius: "15px", borderTopLeftRadius: "15px", boxShadow: "0px -2px 4px 0px rgba(0, 0, 0, 0.20)" }}>
                        <div className='detail'>
                            <p className='title'>{title}</p>
                        </div>
                        <Sheet.Content style={{ borderTopRightRadius: "15px", borderTopLeftRadius: "15px" }}>
                            <div className='detail-list'>
                                {
                                    title == "제조사"
                                        ?
                                        brandList
                                            .map((item) => {
                                                return (
                                                    item.status == "Y"
                                                        ?
                                                        <div className='item' onClick={() => onClickBrand(item.name)}>
                                                            <p className='name' style={{ color: "#0064FF" }}>{item.name}</p>
                                                            <img src={require('%/images/common/ico_check_on.svg').default} alt="" />
                                                        </div>
                                                        :
                                                        <div className='item' onClick={() => onClickBrand(item.name)}>
                                                            <p className='name'>{item.name}</p>
                                                        </div>
                                                )
                                            })
                                        :
                                        <></>
                                }
                                {
                                    title == "차량모델"
                                        ?
                                        modelList
                                            .map((item) => {
                                                return (
                                                    item.status == "Y"
                                                        ?
                                                        <div className='item' onClick={() => onClickModel(item.name)}>
                                                            <p className='name' style={{ color: "#0064FF" }}>{item.name}</p>
                                                            <img src={require('%/images/common/ico_check_on.svg').default} alt="" />
                                                        </div>
                                                        :
                                                        <div className='item' onClick={() => onClickModel(item.name)}>
                                                            <p className='name'>{item.name}</p>
                                                        </div>
                                                )
                                            })
                                        :
                                        <></>
                                }
                                {/* {
                                    title == "운영기관"
                                        ?
                                        cpoList
                                            .map((item) => {
                                                return (
                                                    item.status == "Y"
                                                        ?
                                                        <div className='item' onClick={() => onClickCpo(item.name)}>
                                                            <p className='name' style={{ color: "#0064FF" }}>{item.name}</p>
                                                            <img src={require('%/images/common/ico_check_on.svg').default} alt="" />
                                                        </div>
                                                        :
                                                        <div className='item' onClick={() => onClickCpo(item.name)}>
                                                            <p className='name'>{item.name}</p>
                                                        </div>
                                                )
                                            })
                                        :
                                        <></>
                                } */}
                            </div>
                        </Sheet.Content>
                    </Sheet.Container>
                    <Sheet.Backdrop
                        style={{ opacity: "0.1" }}
                        onTap={() => {
                            setRegistDetailSheet(false);
                        }}
                    />
                </Sheet>
                <Sheet //cpo 등록 취소 시트
                    isOpen={cpoSheet}
                    onClose={() => setCpoSheet(false)}
                    snapPoints={[carInfoCpo.length > 3 ? 450 : 230 + 54 * carInfoCpo.length, 0]} // 높이 조절 기능 최대값 두개 설정해서 최상단 최하단 지정
                    disableDrag={true}
                    style={{ backgroundColor: "black" }}
                >
                    <Sheet.Container style={{ borderTopRightRadius: "15px", borderTopLeftRadius: "15px", boxShadow: "0px -2px 4px 0px rgba(0, 0, 0, 0.20)" }}>
                        <div className='detail'>
                            <p className='title'>{"오토차지 등록 취소"}</p>
                        </div>
                        <Sheet.Content style={{ borderTopRightRadius: "15px", borderTopLeftRadius: "15px" }}>
                            <div className='detail-list'>
                                {
                                    cpoSheetType == "delete"
                                        ?
                                        <div>
                                            {
                                                cpoAllCheck
                                                    ?
                                                    <div className="all-check" onClick={onClickCpoAll}>
                                                        <img src={require('%/images/common/ico_check_on.svg').default} alt="" />
                                                        <p className="all-btn">전체선택</p>
                                                    </div>
                                                    :
                                                    <div className="all-check" onClick={onClickCpoAll}>
                                                        <img src={require('%/images/common/ico_check_off.svg').default} alt="" />
                                                        <p className="all-btn">전체선택</p>
                                                    </div>
                                            }
                                            <div className="cpo-list">
                                                <ul>
                                                    {carInfoCpo
                                                        .map((item, index) => {
                                                            return (
                                                                <li onClick={() => {
                                                                    onClickDeleteCpo(item.cpoCode, item.status);
                                                                }}>
                                                                    <img
                                                                        className="filter-img"
                                                                        src={
                                                                            require(`%/images/cpo/ic-list-evstation-${(item.cpoCode).toLowerCase()}@3x.png`)
                                                                                .default
                                                                        }
                                                                        alt=""
                                                                    />
                                                                    <div className="filter-checkbox">
                                                                        <input type="checkbox" id={item.couponIdx} checked={item.status === "Y" ? true : false} onChange={() => onClickDeleteCpo(item.cpoCode, item.status)} />
                                                                        <label htmlFor={item.couponIdx}>
                                                                        </label>
                                                                        <h3 className="list-title">
                                                                            {item.cpoName}
                                                                        </h3>
                                                                    </div>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                }
                            </div>
                            <PncButton name={"cpo"} cpoCheckStatus={cpoCheckStatus} carInfoCpo={carInfoCpo} onClickDeleteCarInfo={() => onClickDeleteCarInfo("SE")} />
                        </Sheet.Content>
                    </Sheet.Container>
                    <Sheet.Backdrop
                        style={{ opacity: "0.1" }}
                        onTap={() => {
                            setCpoSheet(false);
                        }}
                    />
                </Sheet>
            </>
        )
    }
}
export { PncInfo }