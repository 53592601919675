/* eslint-disable */
import { useState, useEffect, useCallback } from "react";
import { RouteComponentProps } from 'react-router-dom';
import { globalVars } from "../../libs/global-vars";
import { tmapInterface } from "../../libs/tmap-interface";

let scrollCache = 0;
let serviceType = (globalVars.queryData.extra?.serviceType)?.toLowerCase() || (globalVars.queryData.extra?.servicetype)?.toLowerCase();

function RepayInfo(props: RouteComponentProps) {
    const [inited, setInited] = useState(false);

    const cacheScroll = useCallback(() => {
        // 간헐적으로 상세로 이동 후 호출되는 경우가 있어서 예외처리
        if (!/\/voice\/?$/.test(window.location.pathname)) return;
        scrollCache = window.scrollY;
    }, []);

    useEffect(() => {
        window.scrollTo(0, scrollCache);
    }, [inited]);

    useEffect(() => {
        window.addEventListener('scroll', cacheScroll);
        return () => window.removeEventListener('scroll', cacheScroll);
    }, [cacheScroll]);

    const goBack = () => {
        if (serviceType === 'repayinfo') {
            serviceType = '';
            // props.history.replace('/mainRouter');
            tmapInterface.onBackKeyPressed();
        } else {
            props.history.goBack();
        }
    }

    return (
        <div className="ev-repay">
            <button type="button" className="back-btn" onClick={goBack} style={{ zIndex: "999" }}>
                <img src={require('%/images/common/ic_gnb_back.svg').default} alt="" />
            </button>
            <div className="main-info">
                <img src={require('%/images/promotion/img_repay_main_bg.svg').default} alt="" style={{ position: "relative", display: "block" }} />
                <div className="inline-text">
                    <span className="border-text">TMAP 전기차충전
                        <img src={require('%/images/promotion/ic_thunder.svg').default} alt="" className="icon" />
                    </span>
                    <div className="main-text">일부 충전요금<br />미(과)결제액 재결제 안내</div>
                    <p className="sub-text">
                        TMAP 전기차충전 서비스로 충전 진행 후 <br />
                        사용하신 전력 만큼의 충전요금이 결제 되지 않은<br />
                        <span className="highlight">비정상 충전요금</span> 에 대하여, 재결제를 진행한 뒤 이미<br />
                        결제하신 충전요금은 자동 취소됩니다.
                    </p>
                    <div className="info-msg">
                        <span className="info1">*23.09월분부터 확인된 비정상<br /> 충전요금에 대해 재결제가 진행됩니다.</span> <br />
                        <span className="info2">(사용하신 전력과 이에 해당되는 충전요금은<br /> 충전기와 충전기 운영 사업자의 충전내역을 따름)</span>
                    </div>
                </div>
            </div>

            <div className="repay-content">
                <div className="content-header">사용하신 전력에 해당되는 충전요금보다<br /> <span className="highlight">낮은 금액으로 결제</span>가 진행된 경우</div>
                <div className="title">1. 사용 전력에 맞는 충전요금으로 재결제 진행</div>
                <img src={require('%/images/promotion/low_amount_repay_push.svg').default} alt="" />
                <div className="title">2. 이미 진행된 결제건 취소</div>
                <img src={require('%/images/promotion/repay_cancel_push.svg').default} alt="" />
            </div>

            <div className="repay-content">
                <div className="content-header">사용하신 전력에 해당되는 충전요금보다<br /> <span className="highlight">높은 금액으로 결제</span>가 진행된 경우</div>
                <div className="title">1. 사용 전력에 맞는 충전요금으로 재결제 진행</div>
                <img src={require('%/images/promotion/high_amount_repay_push.svg').default} alt="" />
                <div className="title">2. 이미 진행된 결제건 취소</div>
                <img src={require('%/images/promotion/repay_cancel_push.svg').default} alt="" />
            </div>

            <div className="repay-content">
                <div className="content-header">사용하신 전력에 해당되는<br /> <span className="highlight">충전요금 결제가 진행되지 않은</span> 경우</div>
                <div className="title">사용 전력에 맞는 충전요금으로 결제 진행</div>
                <img src={require('%/images/promotion/no_pay_push.svg').default} alt="" />
            </div>

            <div className="repay-content">
                <div className="content-header">비정상 결제로 인한 취소 결제건은<br /> <span className="highlight" style={{ color: "#111" }}>재결제 내역에서 확인</span>하실 수 있습니다.</div>
                <div className="title" style={{ color: "#666", fontSize: "2.8vw", marginBottom: "10px" }}>{"TMAP 홈 > 마이 탭 > 이용내역 > 전기차충전"}</div>
                <img src={require('%/images/promotion/repay_history.svg').default} alt="" />
            </div>

        </div>
    )
}

export { RepayInfo };
