/* eslint-disable */
import React, { useState, useEffect, useCallback } from "react";
import { RouteComponentProps } from 'react-router-dom';
import { tmapInterface } from "../../libs/tmap-interface";

let scrollCache = 0;

function EvPromotion(props: RouteComponentProps) {
    const [inited] = useState(false);
  
    const cacheScroll = useCallback(() => {
        // 간헐적으로 상세로 이동 후 호출되는 경우가 있어서 예외처리
        if (!/\/voice\/?$/.test(window.location.pathname)) return;
        scrollCache = window.scrollY;
    }, []);

    useEffect(() => {
        window.scrollTo(0, scrollCache);
    }, [inited]);

    useEffect(() => {
        window.addEventListener('scroll', cacheScroll);
        return () => window.removeEventListener('scroll', cacheScroll);
    }, [cacheScroll]);
  
    const goBack = () => {
        props.history.replace("/main");
    }
    
    const goInfo = () => {
        props.history.replace("/tapChargePromotion");
    }

    const goMain = () => {
        props.history.replace("/main");
    }

    const goSubs = () => {
      tmapInterface.openBrowser(
        "https://m-sktuniverse.tworld.co.kr/product/view?prodId=NM00000197"
      );
    }
  
    // if (!inited) return null
    return (
        <>
          <div className="ev-push">
            <button type = "button" className = "back-btn" onClick={goBack} style={{zIndex:"999"}}>
                <img src={require('%/images/common/ic_gnb_back.svg').default} alt="" />
            </button>
            <div className="main-info">
              <img src={require('%/images/promotion/img_touchcharge_main_winter.svg').default} alt="" style={{position:"relative", display:"block"}}/>
              <div className="inline-text">
                <span className="border-text">전기차충전, 이제 TMAP에서!</span><br/><br/>
                <span className="main-text" style={{padding:"2.2px 0 2.2px 10px"}}>
                  한파에도 차 안에서&nbsp;<br/>
                </span>
                <span className="main-text" style={{padding:"2.2px 0 2.2px 10px"}}>
                  충전기 인증!
                <img src={require('%/images/common/ic_title_ev_mark.svg').default} alt="" style={{height:"38px", width:"38px", display:"inline", top:"8px", position:"relative"}}/>   
                </span>
              </div>
              <div className="first">
                <div className="first-comment">
                  충전기 터치 한 번으로 충전 준비 끝!<br/>
                  초간편 결제 서비스, 터치 앤 차지 출시<br/>
                  <div style={{fontFamily: 'TMOBI300', color:"#666", fontSize:"12.5px", padding:"10px 0"}}>※ 터치 앤 차지는 에버온, 환경부 충전기에서 사용 가능</div>
                </div>
                <button type="button" className="go-info" onClick={goInfo}>
                  이용방법 알아보기
                </button>
              </div>
            </div>
            <div className="second">
              충전소 검색부터 충전까지<br/>
              <img src={require('%/images/logo/img_logo_tmap_white.svg').default} alt="" style={{width:"55px", top:"2px", display:"inline", position:"relative"}}/>   
              &nbsp;에서 초간편 해결!
            </div>
            <div className="third">
              <img src={require('%/images/promotion/_img_touchcharge_tmappay.svg').default} alt="" style={{position:"relative", display:"block"}}/>   
              <span style={{color:"#0064ff", fontSize:"16px", fontFamily:"TMOBI500"}}>티맵결제 충전소, 얼마나 많은 거야?</span><br/><br/>
              전국 5만여 개 충전소에서 <br/>
              간편하게 티맵결제로 충전하세요!
            </div>
            <div className="third">
              <img src={require('%/images/promotion/_img_touchcharge_charging.svg').default} alt="" style={{position:"relative", display:"block"}}/>   
              <div style={{paddingBottom:"20px", fontFamily:"TMOBI300"}}>
                <span style={{color:"#0064ff", fontSize:"16px", fontFamily:"TMOBI500"}}>
                  터치 앤 차지로 초초초간편 충전!
                </span><br/><br/>
                터치 한 번으로 충전 시작!<br/>
                내 앞의 충전기를 선택하면 충전이 바로 시작돼요.
              </div>
              <button type="button" className="go-info" onClick={goInfo}>
                  이용방법 알아보기
              </button>
            </div>
            <div className="third">
              <img src={require('%/images/promotion/_img_touchcharge_qr_code.svg').default} alt="" style={{position:"relative", display:"block"}}/>   
              <span style={{color:"#0064ff", fontSize:"16px", fontFamily:"TMOBI500"}}>QR 충전</span><br/><br/>
                더 이상 회원카드 찾지 마세요!<br/>
                충전기의 QR코드를 스캔하면 충전이 바로 시작돼요.
            </div>
            <div className="third">
            <img src={require('%/images/promotion/_img_touchcharge_subscribe.svg').default} alt="" style={{position:"relative", display:"block"}}/>   
              <div style={{paddingBottom:"20px", fontFamily:"TMOBI300", lineHeight:"1.5"}}>
                <span style={{color:"#0064ff", fontSize:"16px", fontFamily:"TMOBI500"}}>
                  EV, 이제 구독하세요!
                </span><br/><br/>
                  월 16,000원에 EV 충전, 세차, 주차<br/>
                  모든 혜택을 제공합니다.<br/>
                  최대 40% 저렴하게 전기차를 관리하세요!
              </div>
              <button type="button" className="go-info" onClick={goSubs}>
                  전기차 구독상품 알아보기
              </button>
            </div>
            <div className="last">
              <span style={{position:"relative", top:"80px", fontFamily:"TMOBI300"}}>
                <span style={{fontSize:"33px", color:"#0064ff", fontFamily:"TMOBI500"}}>초간편 충전의 시작</span><br/>
                초간편 충전 서비스의 시작, <br/>
                <img src={require('%/images/logo/img_logo_tmap_black.svg').default} alt="" style={{width:"55px", top:"2px", display:"inline", position:"relative"}}/>   
                &nbsp;전기차충전과 함께 하세요!<br/>
              </span>
            <button type="button" className="go-info" onClick={goMain} style={{zIndex:9999}}>
              TMAP 전기차충전 사용하기
            </button>
            <img src={require('%/images/promotion/img_content_bg_wave.svg').default} alt="" style={{bottom:"-8px", position:"relative"}}/>   
            </div>
          </div>
        </>
    )
}

export { EvPromotion };
