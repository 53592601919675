function LogBox() {
    const token = "3AF19605D63E";
    const appId = "tmap";
    const serviceId = "evcharge";
    const logVersion = "1.0";
    const logboxVersion = "2.0.0";
    const tlaUrl = "https://tla-stg.tmap.co.kr:8988";
    const tlaPath = "/api/v1/log/event?data=";


    let fieldInfoMap = [	{section:"common", fieldName:"token", fieldType:"string", nullable:false},
	{section:"common", fieldName:"local_time", fieldType:"string", nullable:false},
	{section:"common", fieldName:"ip", fieldType:"string", nullable:false},
	{section:"common", fieldName:"app_version", fieldType:"string", nullable:false},
	{section:"common", fieldName:"log_version", fieldType:"string", nullable:false},
	{section:"common", fieldName:"logbox_version", fieldType:"string", nullable:false},
	{section:"common", fieldName:"session_id", fieldType:"string", nullable:false},
	{section:"common", fieldName:"sub_session_id", fieldType:"string", nullable:true},

	{section:"env", fieldName:"os_name", fieldType:"string", nullable:false},
	{section:"env", fieldName:"os_version", fieldType:"string", nullable:false},
	{section:"env", fieldName:"resolution", fieldType:"string", nullable:false},
	{section:"env", fieldName:"screen_width", fieldType:"integer", nullable:false},
	{section:"env", fieldName:"screen_height", fieldType:"integer", nullable:false},
	{section:"env", fieldName:"carrier_name", fieldType:"string", nullable:false},
	{section:"env", fieldName:"network_type", fieldType:"string", nullable:false},
	{section:"env", fieldName:"language_code", fieldType:"string", nullable:false},
	{section:"env", fieldName:"manufacturer", fieldType:"string", nullable:false},
	{section:"env", fieldName:"device_id", fieldType:"string", nullable:false},
	{section:"env", fieldName:"device_model", fieldType:"string", nullable:false},
	{section:"env", fieldName:"browser_name", fieldType:"string", nullable:false},
	{section:"env", fieldName:"browser_version", fieldType:"integer", nullable:false},
	{section:"env", fieldName:"url", fieldType:"string", nullable:false},
	{section:"env", fieldName:"referrer", fieldType:"string", nullable:false},
	{section:"env", fieldName:"document_title", fieldType:"string", nullable:false},

	{section:"meta", fieldName:"app_id", fieldType:"string", nullable:false},
	{section:"meta", fieldName:"service_id", fieldType:"string", nullable:false},
	{section:"meta", fieldName:"page_id", fieldType:"string", nullable:false},
	{section:"meta", fieldName:"page_type", fieldType:"string", nullable:false},
	{section:"meta", fieldName:"section_id", fieldType:"string", nullable:true},
	{section:"meta", fieldName:"action_id", fieldType:"string", nullable:false},

];


    function setBasicCommon(){
        commonLine = {};
        commonLine["token"] = token;
        commonLine["log_version"] = logVersion;
        commonLine["logbox_version"] = logboxVersion;
        line['common'] = commonLine;

        metaLine = {};
        metaLine["app_id"] = appId;
        metaLine["service_id"] = serviceId;
        line['meta'] = metaLine;

        envLine = {};
        line['env'] = envLine;

        customLine = {};
        line['custom'] = customLine;
    }

    function cleanLines(){
        line = {};
    }

    let logBox = {};
    let line = {};

    let commonLine = {};
    let envLine = {};
    let metaLine = {};
    let customLine = {};

    setBasicCommon();

    logBox.checkRequiredFields = function (isPrintSuccessMsg, isPrintFailMsg) {
        let result = true;
        fieldInfoMap.filter(x => !x["nullable"]).forEach(x => {
            if (line[x["section"]][x["fieldName"]] !== undefined) {
                if (isPrintSuccessMsg) {
                    console.log("This field dose exist.", x["section"] + "." + x["fieldName"]);
                }
            } else {
                result = false;
                if (isPrintFailMsg) {

                    console.log("This field dose not exist.", x["section"] + "." + x["fieldName"]);
                }
            }
        })
        return result;
     }

    // ##### common ##### 

    logBox.getToken = function () {
        return commonLine["token"];
    }


    logBox.setLocal_time = function (value) {
        commonLine["local_time"] = value;
        line["common"] = commonLine;
        return logBox;
    }

    logBox.getLocal_time = function () {
        return commonLine["local_time"];
    }


    logBox.setIp = function (value) {
        commonLine["ip"] = value;
        line["common"] = commonLine;
        return logBox;
    }

    logBox.getIp = function () {
        return commonLine["ip"];
    }


    logBox.setApp_version = function (value) {
        commonLine["app_version"] = value;
        line["common"] = commonLine;
        return logBox;
    }

    logBox.getApp_version = function () {
        return commonLine["app_version"];
    }



    logBox.getLog_version = function () {
        return commonLine["log_version"];
    }



    logBox.getLogbox_version = function () {
        return commonLine["logbox_version"];
    }


    logBox.setSession_id = function (value) {
        commonLine["session_id"] = value;
        line["common"] = commonLine;
        return logBox;
    }

    logBox.getSession_id = function () {
        return commonLine["session_id"];
    }


    logBox.setSub_session_id = function (value) {
        commonLine["sub_session_id"] = value;
        line["common"] = commonLine;
        return logBox;
    }

    logBox.getSub_session_id = function () {
        return commonLine["sub_session_id"];
    }



// ##### env ##### 
    logBox.setOs_name = function (value) {
        envLine["os_name"] = value;
        line["env"] = envLine;
        return logBox;
    }

    logBox.getOs_name = function () {
        return envLine["os_name"];
    }


    logBox.setOs_version = function (value) {
        envLine["os_version"] = value;
        line["env"] = envLine;
        return logBox;
    }

    logBox.getOs_version = function () {
        return envLine["os_version"];
    }


    logBox.setResolution = function (value) {
        envLine["resolution"] = value;
        line["env"] = envLine;
        return logBox;
    }

    logBox.getResolution = function () {
        return envLine["resolution"];
    }


    logBox.setScreen_width = function (value) {
        envLine["screen_width"] = value;
        line["env"] = envLine;
        return logBox;
    }

    logBox.getScreen_width = function () {
        return envLine["screen_width"];
    }


    logBox.setScreen_height = function (value) {
        envLine["screen_height"] = value;
        line["env"] = envLine;
        return logBox;
    }

    logBox.getScreen_height = function () {
        return envLine["screen_height"];
    }


    logBox.setCarrier_name = function (value) {
        envLine["carrier_name"] = value;
        line["env"] = envLine;
        return logBox;
    }

    logBox.getCarrier_name = function () {
        return envLine["carrier_name"];
    }


    logBox.setNetwork_type = function (value) {
        envLine["network_type"] = value;
        line["env"] = envLine;
        return logBox;
    }

    logBox.getNetwork_type = function () {
        return envLine["network_type"];
    }


    logBox.setLanguage_code = function (value) {
        envLine["language_code"] = value;
        line["env"] = envLine;
        return logBox;
    }

    logBox.getLanguage_code = function () {
        return envLine["language_code"];
    }


    logBox.setManufacturer = function (value) {
        envLine["manufacturer"] = value;
        line["env"] = envLine;
        return logBox;
    }

    logBox.getManufacturer = function () {
        return envLine["manufacturer"];
    }


    logBox.setDevice_id = function (value) {
        envLine["device_id"] = value;
        line["env"] = envLine;
        return logBox;
    }

    logBox.getDevice_id = function () {
        return envLine["device_id"];
    }


    logBox.setDevice_model = function (value) {
        envLine["device_model"] = value;
        line["env"] = envLine;
        return logBox;
    }

    logBox.getDevice_model = function () {
        return envLine["device_model"];
    }


    logBox.setBrowser_name = function (value) {
        envLine["browser_name"] = value;
        line["env"] = envLine;
        return logBox;
    }

    logBox.getBrowser_name = function () {
        return envLine["browser_name"];
    }


    logBox.setBrowser_version = function (value) {
        envLine["browser_version"] = value;
        line["env"] = envLine;
        return logBox;
    }

    logBox.getBrowser_version = function () {
        return envLine["browser_version"];
    }


    logBox.setUrl = function (value) {
        envLine["url"] = value;
        line["env"] = envLine;
        return logBox;
    }

    logBox.getUrl = function () {
        return envLine["url"];
    }


    logBox.setReferrer = function (value) {
        envLine["referrer"] = value;
        line["env"] = envLine;
        return logBox;
    }

    logBox.getReferrer = function () {
        return envLine["referrer"];
    }


    logBox.setDocument_title = function (value) {
        envLine["document_title"] = value;
        line["env"] = envLine;
        return logBox;
    }

    logBox.getDocument_title = function () {
        return envLine["document_title"];
    }



// ##### meta ##### 

    logBox.getApp_id = function () {
        return metaLine["app_id"];
    }



    logBox.getService_id = function () {
        return metaLine["service_id"];
    }


    logBox.setPage_id = function (value) {
        metaLine["page_id"] = value;
        line["meta"] = metaLine;
        return logBox;
    }

    logBox.getPage_id = function () {
        return metaLine["page_id"];
    }


    logBox.setPage_type = function (value) {
        metaLine["page_type"] = value;
        line["meta"] = metaLine;
        return logBox;
    }

    logBox.getPage_type = function () {
        return metaLine["page_type"];
    }


    logBox.setSection_id = function (value) {
        metaLine["section_id"] = value;
        line["meta"] = metaLine;
        return logBox;
    }

    logBox.getSection_id = function () {
        return metaLine["section_id"];
    }


    logBox.setAction_id = function (value) {
        metaLine["action_id"] = value;
        line["meta"] = metaLine;
        return logBox;
    }

    logBox.getAction_id = function () {
        return metaLine["action_id"];
    }





    logBox.addCustomField = function (key, value) {
        customLine[key] = value;
    }

    logBox.getCustomFieldAllJSONObject = function (){
        return customLine;
    }

    logBox.getCustomFieldAllJSONString = function (){
            return JSON.stringify(customLine);
    }

    logBox.getCustomFieldAll = function (){
            return customLine;
    }

    line['common'] = commonLine;
    line['env'] = envLine;
    line['meta'] = metaLine;
    line['custom'] = customLine;

    logBox.getJSONObject = function () {
        return JSON.parse(JSON.stringify(line));
    }

    logBox.getJSONString = function () {
        return JSON.stringify(line);
    }

    logBox.getJSONStringEncodeURI = function (){
        return encodeURIComponent(JSON.stringify(line));
    }

    logBox.getFieldInfoMap = function (){
        return fieldInfoMap.slice();
    }

    logBox.clear = function(){
        cleanLines();
        setBasicCommon();
    }

    logBox.getTlaURL = function(){
        return tlaUrl;
    }

    logBox.getTlaPath = function(){
        return tlaPath;
    }

    logBox.getTlaURI = function(){
        return tlaUrl+tlaPath+JSON.stringify(line);
    }
    logBox.getTlaURIEncode = function(){
        return tlaUrl+tlaPath+encodeURIComponent(JSON.stringify(line));
    }
    return logBox;
}

module.exports = {
    LogBox
};