// 전기차 충전 
const EV = {

  DBG_OK : false
	  	
  ,FE_API_DEV : "https://evpayment-dev.tmobiapi.com"
  ,FE_API_STG : "https://evpayment-stg.tmobiapi.com"
  ,FE_API_PRD : "https://evpayment.tmobiapi.com"
  
};

export default EV;
