/* eslint-disable */

import React, { useState, useEffect, useCallback } from 'react';
import { store } from '../../store';

const FooterButton = (props) => {
  const [pressed, setPressed] = useState({
    finishBtn: false
  })
  const isPolestar = store.getState().app.isPolestar;

  return (
    <>
      {props.remoteOffYN ?
        (
          // 버튼 dim처리 작업
          props.finishButtonStatus === 1
            ?
            <div className={'wait-button-box'}>
              <a onClick={
                props.onClickFinish
              }> 충전 종료하기 </a>
            </div>
            :
            props.finishButtonStatus === 2
              ?
              <div className={'button-box'}>
                <a onClick={
                  props.onClickFinish
                }
                  onTouchStart={() => setPressed({ ...pressed, finishBtn: true, })}
                  onTouchEnd={() => setPressed({ ...pressed, finishBtn: false })}
                  style={{ backgroundColor: isPolestar ? "#171819" : "#0064ff", filter: pressed.finishBtn ? "brightness(90%)" : "" }}
                > 충전 종료하기 </a>
              </div>
              :
              <div className={'dim-button-box'}>
                <a onClick={
                  props.onClickFinish
                }
                > 충전 종료하기 </a>
              </div>
        )
        :
        (
          <div className='dim-button-finish'>
            <button>충전 종료하기</button>
          </div>
          // <div className="button-box">
          //   <span className="low-charger-btn">
          //     충전 종료 기능이 제공되지 않는 충전기입니다.<br /><span className="warning">충전기에서 종료해 주세요.</span>
          //   </span>
          // </div>
        )}
    </>
  );
}
export { FooterButton };