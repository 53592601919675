/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router";
// import { RouteComponentProps } from 'react-router-dom';

import axios from 'axios';
import Const from '../../store/ev';

// 공통
import { Header } from '../../components/Layout';
import { HandleBrowserBackButton } from '../../components/Layout'
import { appActions, store } from '../../store';
import { appManager } from '../../managers';
import { globalVars } from "../../libs/global-vars";

//Mixpanel
import { logbox, mixpanel } from '../../libs/logbox-mixpanel';
import { patchQrAuth } from '../../apis/auth';
import { getAuthStatus } from '../../apis/status';

const dispatch = store.dispatch;

let scrollCache = 0;
let qrType: any = {};
// let timer: Nullable<number> = null;

const COUPLER_TYPES = ["AC 3상", "DC 콤보", "DC 차데모"];
// const CHARGEV_COUPLER_TYPES = ["ac3", "dc_combo", "dc_chademo"];
// const KP_COUPLER_TYPES = ["4", "5", "3"];
// const SE_COUPLER_TYPES = ["AC_THREE_PHASE", "DC_COMBO", "DC_CHADEMO"];
const SELECT_COUPLER_TYPES = ["AC_THREE_PHASE", "DC_COMBO", "DC_CHADEMO"];

const QrMultiCoupler = ({ location }) => {

    var defaultData: any = {
        chargerType: [],
        guid: "",
        accessKey: "",
        qrCode: "",
        cpoCode: "",
    }

    const [couplerData, setCouplerData] = useState(defaultData);

    qrType = store.getState().app.qrType;

    const history = useHistory();

    const [couplers, setCouplers] = useState({ types: COUPLER_TYPES, selectValue: "" });

    const current = decodeURI(window.location.href);
    const search = current.split("?")[1];
    const params = new URLSearchParams(search);
    const no_params = new URLSearchParams(current);

    /*한전*/
    const currentKP = decodeURI(window.location.href);
    const searchKP = current.split("=").at(-1); // 한전 belong
    // location.props.couplerData[0]
    const cpoCode = couplerData.cpoCode;
    const chargerType = couplerData.chargerType;
    const guid = couplerData.guid;
    const ak = couplerData.accessKey;
    const qrCode = couplerData.qrCode;

    /*환경부 */
    const current1 = decodeURI(window.location.href);
    const search1 = current1.split("?")[2];
    const params1 = new URLSearchParams(search1);
    const qr_url1 = params1.get('code');

    var coupler_type = '';

    const [charger, setCharger] = useState<any>([]);

    const chargerTypes = {
        DC_CHADEMO: "DC 차데모",
        AC_THREE_PHASE: "AC 3상",
        DC_COMBO: "DC 콤보"
    };

    const newChargerData = [...couplerData.chargerType];

    const localStorageItem = window.localStorage.getItem("multiCouplerData");
    const multiCouplerData = localStorageItem !== null ? JSON.parse(localStorageItem) : [];

    useEffect(() => {
        for (let i = 0; i < newChargerData.length; i++) {
            newChargerData[i] = chargerTypes[newChargerData[i]];
        }

        setCharger(newChargerData);

        if (globalVars.connCheckLoop != null) {
            clearInterval(globalVars.connCheckLoop);
        }

        if (couplerData.guid) {
            globalVars.connCheckLoop = setInterval(() => {

                getAuthStatus(guid)
                    .then((response: any) => {
                        if (response.authStatus === 5 || response.authStatus === 7 || response.authStatus === 8) {

                            clearInterval(globalVars.connCheckLoop);

							history.push(`/authFail/${response.cpoCode}`);
                        }
                    }).catch((response) => {
                        clearInterval(globalVars.connCheckLoop);

                        history.push('/authFail');

                        console.log('Error!', response)
                    });
            }, 2000); //2초에 한 번씩 조회
        }
    }, [couplerData])

    const handleChange = (value) => {
        const qrStatus = store.getState().app.qrStatus;
        
        const filterDeleteAuth = multiCouplerData.filter((item) => item.guid != guid);

        const carMakerName = store.getState().app.carMakerName
        const carName = store.getState().app.carName;

        const isPolestar = store.getState().app.isPolestar;

        window.localStorage.setItem('multiCouplerData', JSON.stringify(filterDeleteAuth));

        mixpanel("evcharge:/evcharge/selectconnector:tap.connector");
        logbox("/evcharge/selectconnector","tap.connector");

        appManager.globalLoadingInc();

        // setCouplers({types:COUPLER_TYPES, selectValue: e.target.value});

        // if(cpoCode === 'PI'){ //PI
        //     coupler_type = CHARGEV_COUPLER_TYPES[e.target.value];
        // } else if(cpoCode === 'KP'){ //KP
        //     coupler_type = KP_COUPLER_TYPES[e.target.value];
        // } else if(cpoCode === 'SE'){ //SE
        //     coupler_type = SE_COUPLER_TYPES[e.target.value];
        // } else {
        // coupler_type = e.target.value;
        // coupler_type = value;
        // }

        switch (value) {
            case "DC 콤보":
                coupler_type = "DC_COMBO";
                break;
            case "AC 3상":
                coupler_type = "AC_THREE_PHASE";
                break;
            case "DC 차데모":
                coupler_type = "DC_CHADEMO";
                break;
        }
        var patchQrAuthData = {};
        if(isPolestar)
        {
            patchQrAuthData = {
                guid: guid,
                couplerType: coupler_type,
                qrCode: multiCouplerData.filter((item) => item.guid == guid)[0]?.qrCode,
                carMakerName: carMakerName,
                carName: carName
            }
        }
        else
        {
            patchQrAuthData = {
                guid: guid,
                couplerType: coupler_type,
                qrCode: multiCouplerData.filter((item) => item.guid == guid)[0]?.qrCode
            }
        }

        getAuthStatus(guid)
            .then((response: any) => {
                if (response.authStatus === 5 || response.authStatus === 7 || response.authStatus === 8) {

                    clearInterval(globalVars.connCheckLoop);

                    history.push(`/authFail/${response.cpoCode}`);
                } else {
                    clearInterval(globalVars.connCheckLoop);

                    patchQrAuth(patchQrAuthData)
                        .then(function (response2: any) {
                            appManager.globalLoadingReset();

                            store.dispatch(appActions.setQrStatus("2"));
                            store.dispatch(appActions.setGuid(response2.guid));

                            history.replace("/qrAuth");
                        })
                        .catch(function (error2: any) {
                            appManager.globalLoadingReset();

                            history.push(`/authFail`);
                        });
                }
            }).catch((response) => {
                clearInterval(globalVars.connCheckLoop);

                history.push('/authFail');
            });
    };

    useEffect(() => {
        setCouplerData(location.props.couplerData[0]);
        // setCouplerData({
        //     "result": true,
        //     "guid": "23051000000104",
        //     "stationName": "용산역 아이파크몰",
        //     "cpoCode": "KP",
        //     "couplerId": "KEPE000721",
        //     "chargerType": [
        //         "DC_CHADEMO",
        //         "AC_THREE_PHASE"
        //     ]
        // });
        window.scrollTo(0, scrollCache);
    }, []);

    useEffect(() => {
        if (globalVars.connCheckLoop != null) {
            clearInterval(globalVars.connCheckLoop);
        }

        globalVars.connCheckLoop = setInterval(() => {

            getAuthStatus(guid)
                .then((response: any) => {
                    if (response.authStatus === 5 || response.authStatus === 7 || response.authStatus === 8) {

                        clearInterval(globalVars.connCheckLoop);

						history.push(`/authFail/${response.cpoCode}`);
                    }
                }).catch((response) => {
                    clearInterval(globalVars.connCheckLoop);

                    history.push('/authFail');

                    console.log('Error!', response)
                });
        }, 2000); //2초에 한 번씩 조회
    }, [couplerData])

    const couplerImg = (coupler) => {
        switch (coupler) {
            case "DC 콤보":
                return (
                    <>
                        <img src={require('%/images/coupler/ic-charging-type-1-disabled.svg').default} className="coupler_icon" alt="" />
                    </>
                )
            case "AC 3상":
                return (
                    <>
                        <img src={require('%/images/coupler/ic-charging-type-0-disabled.svg').default} className="coupler_icon" alt="" />
                    </>
                )
            case "DC 차데모":
                return (
                    <>
                        <img src={require('%/images/coupler/ic-charging-type-2-disabled.svg').default} className="coupler_icon" alt="" />
                    </>
                )
        }
    }

    return (
        <>
            <Header isMenu={false} />
            <HandleBrowserBackButton />

            <div className="charge-main">
                <div className="inner-box">
                    <div id="coupler-page-wrapper">
                        <div className="coupler-page">
                            <div className="txt-box">
                                <p className="txt2">
                                    <span>커넥터 타입을 선택해 주세요.</span>
                                </p>
                            </div>
                        </div>

                        <div className="coupler_types_selector_wrapper">
                            <ul className="coupler_types_selector">
                                {charger.map((value, i) => (
                                    <React.Fragment key={i}>
                                        {/* <li className={ ((chargerType === 'DC_CHADEMO_AND_DC_COMBO' && i === 0) || (chargerType === 'DC_CHADEMO_AND_AC_THREE_PHASE' && i === 1) || (chargerType === '6' && i === 2) || (chargerType === '7' && i === 0) || (chargerType === '8' && i === 1)) ? 'display_none': ''}> */}
                                        <li>
                                            <input
                                                id={i.toString()}
                                                value={i.toString()}
                                                name="coupler_type"
                                                type="radio"
                                                checked={couplers.selectValue === value}
                                                onChange={() => handleChange(value)}
                                            />
                                            <label htmlFor={i.toString()}>
                                                {couplerImg(value)}
                                                <span>{value}</span>
                                                {/* <img src={couplers.selectValue === i.toString()?require('%/images/ic-charging-type-' + i + '-enabled.svg').default : require('%/images/ic-charging-type-' + i + '-disabled.svg').default} className="coupler_icon" alt="" /> */}
                                            </label>
                                            {console.log(value)}
                                        </li>
                                    </React.Fragment>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export { QrMultiCoupler };
