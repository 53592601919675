/* eslint-disable */

import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { tmapInterface } from '../../libs/tmap-interface';
import { globalVars } from '../../libs/global-vars';
import { modal } from '../GlobalModal';

let isExit = false;
let timer: Nullable<number> = null;

const HandleBrowserBackButton = withRouter(function ({ match, history }) {

  useEffect(() => {
    
    const preventGoBack = () => {
      let path = window.location.href;
      
      window.history.pushState(null, '', path);
      
      // modal.alert(globalVars.isAndroid + ' / ' + path);

      if (globalVars.isAndroid) {
        // if ( !isExit ) {          
        //     tmapInterface.makeToast("'뒤로가기 버튼'을 한번 더 누르면 \n TMAP 메인 화면으로 돌아갑니다.");
        //     isExit = true;
        //     timer = window.setTimeout(() => { isExit = false; }, 2000);
        // } else {
        //   if(timer) {
        //     clearTimeout(timer);
        //     tmapInterface.onBackKeyPressed();
        //   }
        // } 
        tmapInterface.onBackKeyPressed();
      }
    };
    
    window.history.pushState(null, '', window.location.href);
    window.addEventListener('popstate', preventGoBack);
    
    return () => window.removeEventListener('popstate', preventGoBack);
  }, []); 

  

  return (
    <></>
  );
});

export { HandleBrowserBackButton };