/* eslint-disable */

import instance from './frontman';

export const getTopTmap = async (params1?: Number, params2?: Number, accessToken?: String) => {
    return await instance({
        url: "/top-tmap/reverse-geo-coding",
        method: "get",
        params: {
            lat: params1,
            lon: params2
        },
    })
};