/* eslint-disable */

import { tmapApi } from './libs/tmap-api';
import { globalVars } from './libs/global-vars';
import { appActions, store } from './store';
import { GuardFunction, Next } from 'react-router-guards/dist/types';
import { voiceManager } from './managers';
import { eventBus } from './libs/event-bus';
import { HistoryManagerEvent } from './components/HistoryManager';
import { modal } from './components/GlobalModal';

/*
주의 : redirect 실행하면 guard 실행 없이 바로 연결됨.
*/

function isSystemError() {
  return !!store.getState().app.systemError;
}

const reset: GuardFunction = (to, from, next) => {
  eventBus.emit(HistoryManagerEvent.CHANGE_START);
  next();
};

const requireUserId: GuardFunction = (to, from, next) => {
  if (isSystemError()) return;
  if (to.meta.auth && !globalVars.mdn) {
    tmapApi.getMdnByAccessKey().then(({ data }) => {
      globalVars.mdn = data.mdn;
      next();
    });
  } else next();
};

const voiceGuard: GuardFunction = (to, from, next) => {
  if (isSystemError()) return;


  const { pageid, productid, extra } = globalVars.queryData;

  // alert(pageid);

  if (!store.getState().app.routerInited && pageid === 'chargeHistory') redirect(next, `/chargeHistory`);
  // else if (!store.getState().app.routerInited && (pageid === 'couponRegister')) redirect(next, `/couponRegister/${0}`);
  else next();


  // 빨간앱일경우 안내페이지로 이동
  if (globalVars.isRedApp && to.location.pathname !== '/voice/info') {
    redirect(next, '/voice/info');
  } else if (to.meta.starVoice) {
    Promise.all([voiceManager.fetchPaymentAgree(), voiceManager.fetchPurchaseList()]).then(() => {
      /*
        다른화면에서 app scheme로 셀럽을 열었을때 상세페이지로 이동
      */
      const { pageid, productid } = globalVars.queryData;
      if (!store.getState().app.routerInited && pageid === 'celeb') redirect(next, `/voice/${productid}`);
      else next();
    });
  } else next();
};

const setInited: GuardFunction = (to, from, next) => {
  _setInited();
  next();
};

function _setInited() {
  store.dispatch(appActions.setRouterInited(true));
}

function redirect(next: Next, path: string) {
  next.redirect(path);
  _setInited();
}

export { reset, requireUserId, voiceGuard, setInited };
