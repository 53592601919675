/* eslint-disable */

import instance from './frontman';

//충전배달 가능여부
export const getDelivery = async (params: String, params2: String, accessToken?: String) => {
    return await instance({
        url: "/delivery",
        method: "get",
        params: {
            latitude: params,
            longitude: params2
        }
    })
};

//충전배달 이용약관 업데이트
export const patchDelivery = async (accessToken?: String) => {
    return await instance({
        url: "/delivery",
        method: "patch"
    })
};

//충전배달 이용내역 리다이렉트
export const getDeliveryHistory = async (accessToken?: String) => {
    return await instance({
        url: "/delivery/history",
        method: "get"
    })
};

//충전배달 메인 리다이렉트
export const getDeliveryWeb = async (params: String, params2: String, accessToken?: String) => {
    return await instance({
        url: "/delivery/web",
        method: "get",
        params: {
            latitude: params,
            longitude: params2
        }
    })
};