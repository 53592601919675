/* eslint-disable */

import instance from './frontman';

//tap-tap 사용가능 커플러 조회
export const getTapCharge = async (params: String, params2:String, params3:String, accessToken?: String) => {
    return await instance({
        url: "/tap-tap",
        method: "get",
        params: {
            poiId : params,
            lon : params2,
            lat : params3
        }
    })
};

//tap-tap 근처 충전소 찾기
export const getTapChargeRadius= async (params: String, params2:String, params3:String, params4:String, params5: String, accessToken?: String) => {
    return await instance({
        url: `/tap-tap/${params}`,
        method: "get",
        params: {
            lon : params2,
            lat : params3,
            cpoCode : params4,
            poiId : params5
        }
    })
};