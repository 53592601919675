import React, { useState, useEffect, useCallback } from 'react';
import { Header } from '../../components/Layout';

let scrollCache = 0;
/**-------------------------------------------------------------
1.2.1.3 이용안내 화면_2
-------------------------------------------------------------
> http://basic.pointweb.kr/charge/public/html/public/pay.html
-------------------------------------------------------------*/
function CouponInfo() {
  const [inited] = useState(false);
  
  const cacheScroll = useCallback(() => {
    // 간헐적으로 상세로 이동 후 호출되는 경우가 있어서 예외처리
    if (!/\/voice\/?$/.test(window.location.pathname)) return;
    scrollCache = window.scrollY;
  }, []);
  useEffect(() => {
    window.scrollTo(0, scrollCache);
  }, [inited]);
  useEffect(() => {
    window.addEventListener('scroll', cacheScroll);
    return () => window.removeEventListener('scroll', cacheScroll);
  }, [cacheScroll]);

  // render
  // if (!inited) return null
  return (
   <>
   <Header title={'유의사항'} isMenu={ false } />
   
    <div id="page">
        <div className="coupon-guide">
          <h3>
            <span className="underline">전기차충전 쿠폰</span>에 대해<br />
            알려드립니다.
          </h3>
          <div className="txt-box">
            <ol>
              <li>쿠폰은 아래의 유형을 지닌 상품권(Voucher)을 <br /> 뜻합니다.
                <ul>
                  <li> 회사에서 마케팅 및 프로모션 목적으로 지급되는 할인</li>
                  <li> 자동차 제조사 및 충전사업자 등 사업협력을 통해<br />마련된 충전 상품</li>
                </ul>
              </li>
              <li>쿠폰은 일정한 유효기간을 지니며, 해당 유효기간이 <br />경과되면 관련 쿠폰 사용이 불가합니다.</li>
              <li>쿠폰 금액은 유효기간과 상관없이 충전권 포인트 <br />또는 현금으로 환불되지 않습니다.</li>
              <li>통신사 또는 전화번호 변경 시, 등록된 쿠폰은 소멸됩니다. 쿠폰 등록 전 T 아이디로 전환해주세요.</li>
            </ol>
          </div>          
        </div>
    </div>
   </>
  );
}
export { CouponInfo };
