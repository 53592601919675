/* eslint-disable */

import React, { useState, useEffect, useCallback } from 'react';
import { Header } from '../../components/Layout';
import Slider from "react-slick";
// import '~slick-carousel/slick/slick.css';
// import '~slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { RouteComponentProps , Link } from 'react-router-dom';
// import zerothImage from '../../assets/images/img-info-touchcharge-00.svg'
import zerothImage from '../../assets/images/promotion/img-info-touchcharge-00@3x.png'

// import firstImage from '../../assets/images/img-info-touchcharge-01.svg'
import firstImage from '../../assets/images/promotion/img-info-touchcharge-01@3x.png'
import secondImage from '../../assets/images/promotion/img-info-touchcharge-02@3x.png'
import thirdImage from '../../assets/images/promotion/img-info-touchcharge-03.svg'
import fourthImage from '../../assets/images/promotion/img-info-touchcharge-04.svg'
import fifthImage from '../../assets/images/promotion/img-info-touchcharge-05.svg'



let scrollCache = 0;
let height = 0;
/**-------------------------------------------------------------
1.2.1.3 이용안내 화면_2
-------------------------------------------------------------
> http://basic.pointweb.kr/charge/public/html/public/pay.html
-------------------------------------------------------------*/
function TapChargePromotion(props: RouteComponentProps) {
  const [inited] = useState(false);
  const [guide, setGuide] = useState(1);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  // const [width, setWidth] = useState(0);
  height = window.innerHeight;

  const cacheScroll = useCallback(() => {
    // 간헐적으로 상세로 이동 후 호출되는 경우가 있어서 예외처리
    if (!/\/voice\/?$/.test(window.location.pathname)) return;
    scrollCache = window.scrollY;
  }, []);
  useEffect(() => {
    window.scrollTo(0, scrollCache);
  }, [inited]);
  useEffect(() => {
    window.addEventListener('scroll', cacheScroll);
    return () => window.removeEventListener('scroll', cacheScroll);
  }, [cacheScroll]);
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // nextArrow: <SampleNextArrow/>,
    // prevArrow: <SamplePrevArrow />,
    className: 'slider_custom',
    beforeChange: (beforeIndex, afterIndex) => {
      setCurrentSlideIndex(afterIndex);
    },
    appendDots: (dots: any) => (
      <div
        style={{
          width: '100%',
          position: 'fixed',
          bottom: '100px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <ul> {dots} </ul>
      </div>
    ),
    dotsClass: 'dots_custom'
  }
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    // console.log(currentSlideIndex)
    if(currentSlideIndex=== 4 )
    {
      console.log('disable',currentSlideIndex);
    return (
      <img src = {require("%/images/common/ic-arrow-right-disabled.svg").default} alt="" className={className} onClick={onClick} style={{position: "fixed", margin:"50px", width:"30px", height:"30px", zIndex:"999"}}/>
      )
    }else{
      console.log('enable',currentSlideIndex);
      return (
        <img src = {require("%/images/common/ic-arrow-right-enabled.svg").default} alt="" className={className} onClick={onClick} style={{position: "fixed", margin:"50px", width:"30px", height:"30px", zIndex:"999"}}/>  
        )
      }
  }
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    if(currentSlideIndex=== 0 )
    {
      console.log('disable',currentSlideIndex);
    return (
      <img src = {require("%/images/common/ic-arrow-left-disabled.svg").default} alt="" className={className} onClick={onClick} style={{position: "fixed", margin:"50px", width:"30px", height:"30px", zIndex:"999"}}/>
      )
    }else{
      console.log('enable',currentSlideIndex);
      return (
        <img src = {require("%/images/common/ic-arrow-left-enabled.svg").default} alt="" className={className} onClick={onClick} style={{position: "fixed", margin:"50px", width:"30px", height:"30px", zIndex:"999"}}/>  
        )
      }
  }

  const goBack = () => {
    props.history.replace("/evPromotion");
  }
  const goMain = () => {
    props.history.replace("/main");
  }
  // function SamplePrevArrow(props) {
  //   const { className, style, onClick } = props;
  //   return (
  //     <div
  //       className={className}
  //       style={{ ...style, display: "block", background: "green" }}
  //       onClick={onClick}
  //     />
  //   );
  // }
  // function SamplePrevArrow(props) {
  //   const { className, style, onClick } = props;
  //   return (
  //     <img src={require("%/images/coupon/btn-position.svg").default} alt="" onClick={onClick}/>
  //   );
  // }

  // render
  // if (!inited) return null
  return (
   <>
   <body style={{overflow:"hidden", position:"fixed"}}>
   {/* <Header title={'이용안내'} isMenu={ false } /> */}
   <div style={{padding:"17px 19px", position:"relative"}}>
      {/* <button type="button" onClick={goBack} style={{position:"absolute"}}>
        <img src={require('%/images/ic-top-arrow-back.svg').default} alt="" />
      </button> */}
       <div style={{fontFamily:"TMOBI500", fontSize:"18px", color:"#111", marginTop:"8px", letterSpacing:"normal", textAlign:"center"}}>{currentSlideIndex===4?"주의사항":"이용안내"}</div>
      {/* <button type = "button" onClick={goBack}>
          <img src={require('%/images/ic-top-close.svg').default} alt="" />
      </button> */}
    </div>
        <div className="easypay-guide" style={{height: height-207}}>
          <Slider {...settings}>

            <div className='charger-img'>
              <div style={window.innerWidth >= 440 ? {height: height-207, background: `url(${zerothImage}) center bottom/ 60% no-repeat`} : {height: height-207, background: `url(${zerothImage}) center bottom/ 100% no-repeat`}} className='content'>
                  <div style={window.innerWidth >= 440 ? {padding:"0 35px"} : {}}>
                    <div className="big-content" style={{textAlign:"initial", fontFamily:"TMOBI300"}}>
                      <span className='underline'>충전소 검색부터 사용까지</span><br/>TMAP에서 충전 준비 끝!
                    </div>
                  </div>
                  <div className="small-content" style={{fontSize:"13px", position:"fixed", bottom:"0", marginBottom:"270px", width:window.innerWidth-40}}>전기차충전 서비스에서 충전시작 버튼 선택</div>
                {/* <img src={require('../../assets/images/img-info-touchcharge-00.svg').default} alt="" style={{margin:"0", width:"inherit", bottom: "150px", position: "absolute"}}/> */}
              </div>
            </div>

            <div className='charger-img' style={{bottom:"0", position:"relative"}}>
              <div className='content' style={window.innerWidth >= 440 ? {height: height-207, background: `url(${firstImage}) center bottom/ 60% no-repeat`} : {height: height-207, background: `url(${firstImage}) center bottom/ 100% no-repeat`}}>
                <div className="big-content">사용할 충전기 선택</div>
                <div className="small-content">화면 상단 충전소 사진에 마킹된 번호나<br/> 충전기에 부착된 번호를 참고해,<br/> 리스트 중 <span className='color-blue'>사용할 충전기를 선택</span>해주세요.</div>
              </div>
              {/* <img src={require('../../assets/images/img-touch-and-charge-01.svg').default} alt="" /> */}
            </div>

            <div className='charger-img'>
              <div className='content' style={window.innerWidth >= 440 ? {height: height-207, background: `url(${secondImage}) center bottom/ 60% no-repeat`} : {height: height-207, background: `url(${secondImage}) center bottom/ 100% no-repeat`}}>
                <div className="big-content">커넥터 연결 대기상태</div>
                <div className="small-content">
                  충전기를 선택하면 충전기와 TMAP이<br/>
                  <span className='color-blue'>커넥터 연결 대기상태</span>로 변경됩니다.<br/>
                  <div style={{paddingTop:"10px", fontFamily:"TMOBI300"}}><span className='color-blue'>대기시간은 충전기에 따라 다르며</span><br/>
                  최대 3분까지 걸릴 수 있습니다.</div>
                </div>
              </div>
              {/* <img src={require('../../assets/images/img-touch-and-charge-02.svg').default} alt="" /> */}
            </div>
            
            <div className='charger-img'>
              <div className='content' style={window.innerWidth >= 440 ? {height: height-207, background: `url(${fourthImage}) center bottom/ 60% no-repeat`} : {height: height-207, background: `url(${fourthImage}) center bottom/ 100% no-repeat`}}>
                <div className="big-content">커넥터 연결 후 충전 시작</div>
                <div className="small-content">
                  차량에 <span className='color-blue'>커넥터를 연결시키면<br/> 충전이 진행</span>되고<br/>
                  연결 대기 상태가 종료됩니다.<br/>
                  &nbsp;
                </div>
              </div>
                {/* <img src={require('../../assets/images/img-touch-and-charge-04.svg').default} alt="" /> */}
            </div>
            
            <div className='charger-img'>
                <div style={window.innerWidth >= 440 ? {height: height-207, background: `url(${fifthImage}) center bottom/ 60% no-repeat`} : {height: height-207, background: `url(${fifthImage}) center bottom/ 100% no-repeat`}} className='content'>
                  <div style={window.innerWidth >= 440 ? {padding:"0 35px"} : {}}>
                    <div className="big-content" style={{textAlign:"initial", fontFamily:"TMOBI300"}}>
                      <span className='underline'>티맵결제 충전소</span><br/>주의사항을 숙지해주세요.
                    </div>
                    <div className="small-content" style={{textAlign:"inherit"}}>
                      <li><p>서비스는 꼭 충전소에 방문하셔서 사용해주세요.</p></li><br/>
                      <li><p>충전기 리스트에서 사용하실 충전기를 선택하시면 3분 동안 차량 커넥터 연결 대기 화면이 표시됩니다. 대기 시간 안에 차량과 커넥터를 연결해주세요.</p></li><br/>
                      <li><p>이용안내와 주의사항을 반드시 확인해 주세요. 잘못된 이용방법으로 발생한 문제는 해결해 드릴 수 없습니다.</p></li>
                    </div>
                  </div>
                {/* <img src={require('../../assets/images/img-touch-and-charge-05.svg').default} alt="" style={{margin:"0", width:"inherit"}}/> */}
              </div>
          </div>
          </Slider>
          <div className="info-popup-bottom">
            <button type="button" style={window.innerWidth < 340 ? {fontSize:"15px"} : {}} onClick={goBack}>돌아가기</button>
            <button type="button" style={window.innerWidth < 340 ? {fontSize:"15px"} : {}} onClick={goMain}>전기차충전 이용하기</button>
          </div>
        </div>
        </body>
   </>
  );
}

export { TapChargePromotion };
