/**-------------------------------------------------------------
1.2.3.2 충전 내역 상세_4
-------------------------------------------------------------
> http://basic.pointweb.kr/charge/public/html/public/charge_history_view.html
-------------------------------------------------------------*/

/* eslint-disable */

import React, { useState, useEffect, useCallback } from 'react';
// import {useLocation} from "react-router";
import { RouteComponentProps } from 'react-router-dom';

// import axios from 'axios';
import Const from '../../store/ev';
import { Header } from '../../components/Layout';

import { utils } from '../../libs/utils';

import { modal } from '../../components/GlobalModal';

//Mixpanel
import { logbox, mixpanel } from '../../libs/logbox-mixpanel';
import { getHistoryDetail } from '../../apis/history';

let scrollCache = 0;

function ChargeHistoryDetail(props: RouteComponentProps<{ guid: string, repaidYn: any }>) {
  const guid = props.match.params.guid;
  const repaidYn = props.match.params.repaidYn

  const [end_date_info, setEndDateInfo] = useState('');

  const [historyData, setHistoryData] = useState({
    result: false,
    guid: "",
    chargeStartDt: "",
    chargeFinishDt: "",
    chargeTime: 0,
    stationName: "",
    jibunAddress: "",
    chargeKwh: 0,
    totalPrice: "",
    couplerId: "",
    roadAddress: "",
    currentPrice: 0,
    currentPointPrice: 0,
    currentRatePrice: 0,
    currentTmapPointPrice: 0,
    payMeansDisplayName: "",
    payMeansDisplayNumber: "",
    ciImageUrl: "",
    bgColor: "",
    repaidYn: false,
    refundInfo: {
      refundDateTime: "0000-00-00 00:00:00",
      refundAmount: 0,
      overPaid: false
    }
});

  const [payTypeNm, setPayTypeNm] = useState('');     // 결제수단 명 : TODO
  const [payNumber, setPayNumber] = useState('');     // 결제카드 번호
  const [payImageUrl, setPayImageUrl] = useState('');     // 결제수단 이미지 CI
  const [payKind, setPayKind] = useState(''); // 결제수단 종류

  const [isCard, setIsCard] = useState('NoFocus');
  const [payImageBg, setPayImageBg] = useState('transparent');     // 결제수단 이미지 bg
  const [bselectCard, setBselectCard] = useState('');

  const [inited, setInited] = useState(false);

  const cacheScroll = useCallback(() => {
    // 간헐적으로 상세로 이동 후 호출되는 경우가 있어서 예외처리
    if (!/\/voice\/?$/.test(window.location.pathname)) return;
    scrollCache = window.scrollY;
  }, []);


  const dateFormat = (date: string, date2: string) => {
    // alert(date + " / " + date2);

    let temp = date.split(" ");
    let formatDate = "";
    let yoil = utils.getInputDayLabel(date);

    formatDate = date2.substring(0, 10) + " (" + yoil + ") ";

    return formatDate;
  };

  useEffect(() => {
    // alert(props.match.params.guid);
    mixpanel("evcharge:/evcharge/detailchargehistory");
    logbox("/evcharge/detailchargehistory", "");

    window.scrollTo(0, scrollCache);

  }, [inited]);

  useEffect(() => {
    if (Const.DBG_OK) {
      alert("ChargeHistoryDetail \n" + props.match.params.guid)
    }

    getHistoryDetail(guid, repaidYn)
      .then(function (response: any) {
        // console.log(JSON.stringify(response));
        if (Const.DBG_OK) {
          alert('ChargeHistoryDetail \n' + JSON.stringify(response));
        }
        setHistoryData(response);

        if (response.result) {
          if (response.paymentType === 'CARD' || response.paymentType === 'SK_PAY' || response.paymentType === 'KB_PLCC' || response.paymentType === 'KB_PAY') {
            setIsCard('selected last');
            setPayImageUrl(response.ciImageUrl);
            setBselectCard('true');
            setPayTypeNm(response.payMeansDisplayName);
            setPayNumber(response.payMeansDisplayNumber);
            setPayImageBg(response.bgColor);
            if (response.paymentType === 'SK_PAY') {
              setPayKind('PAY');
            } else if (response.paymentType === 'KB_PAY') {
              setPayKind('KB_PAY');
            }else {
              setPayKind('CARD');
            }
          }

          let end_date = utils.convert12H(response.chargeFinishDt);

          setEndDateInfo(dateFormat(response.chargeFinishDt, end_date));
        }

        setInited(true);

      })
      .catch(function (error: any) {
        console.log(error);
      });

  }, [props.match.params.guid]);

  useEffect(() => {
    window.addEventListener('scroll', cacheScroll);
    return () => window.removeEventListener('scroll', cacheScroll);
  }, [cacheScroll]);

  function ChargeInfo({ label, value }) {
    return (
      <div className="charge-info">
        <span className='head-value'>{label}</span>
        <span>{value}</span>
      </div>
    );
  }

  const borderBottomStyle = { width: "90%", margin: "0 auto", borderBottom: "1px solid #EEF0F3" };

  if (!inited) return <></>
  else {
    return (
      <>
        <Header title={historyData.repaidYn ? "재결제 내역 상세" : '충전 내역 상세'} isActive={'2'} isMenu={false} />
        <div className="finish-charge" style={{padding: "53px 0 100px"}}>
            {
              historyData.repaidYn && historyData.refundInfo
                ?
                <div className='cancel-header'>
                  <span>취소일시 : {utils.convert12H(historyData?.refundInfo?.refundDateTime)} </span> <br />
                  <span>취소금액 : {utils.comma(Number(historyData?.refundInfo?.refundAmount))}원</span>
                  <p className='cancel-content'>충전 요금이 사용하신 충전량보다 {historyData?.refundInfo?.overPaid ? "많이" : "적게"} 결제되어 재결제 되었습니다. 이전에 결제하신 금액은 6~10일 이내 카드사 환불 예정입니다.</p>
                </div>
                :
                <></>
            }
          <div className='top-section'>
            <div className='time-info'>{end_date_info}</div>
            <div className='addr-info'>
              {historyData.stationName}
              <span>
                {historyData.roadAddress}
              </span>
            </div>
          </div>
          <div className="finish-payinfo">
            <div className="coupon-box">
              <div className='info-list'>
                <span>총 금액</span>
                <span className='amount'>{utils.comma(historyData.totalPrice)}원</span>
              </div>
              {historyData.currentRatePrice === 0 || !historyData.currentRatePrice ? (
                <></>
              ) : (
                <>
                  {" "}
                  <div className='info-list'>
                    <span>할인 쿠폰 사용</span>
                    <span className='coupon-section'>- {utils.comma(historyData.currentRatePrice)}원</span>
                  </div>
                </>
              )}
              {historyData.currentPointPrice === 0 || !historyData.currentPointPrice ? (
                <></>
              ) : (
                <>
                  <div className='info-list'>
                    <span>선불충전권 사용</span>
                    <span className='coupon-section'>- {utils.comma(historyData.currentPointPrice)}원</span>
                  </div>
                </>
              )}
              {historyData.currentTmapPointPrice === 0 || !historyData.currentTmapPointPrice ? (
                <></>
              ) : (
                <>
                  <div className='info-list'>
                    <span>포인트 사용</span>
                    <span className='coupon-section'>- {utils.comma(historyData.currentTmapPointPrice)}원</span>
                  </div>
                </>
              )}
            </div>

            <div className='credit-box'>
              <span className={isCard}>
                <span
                  className={payKind === 'PAY' ? "pay-logo-wrapper" : payKind === 'KB_PAY' ? "kb-pay-logo-wrapper":"card-logo-wrapper"}
                  style={payKind === 'KB_PAY' ?{}:{
                    background: payImageBg !== "" ? payImageBg : "none",
                    padding: "1px",
                  }}
                >
                  {payTypeNm !== '' ? (
                    <img
                      src={payImageUrl}
                      alt=""
                      className={payKind === "PAY" ? "pay-logo" : payKind === "KB_PAY" ? "kb-pay-logo":"card-logo"}
                    />
                  ) : (
                    ""
                  )}
                </span>
                <span className='card' style={payKind === 'KB_PAY'?{position:"relative", top:"-7px"}:{}}>{historyData.currentPrice < 100 || !payTypeNm ? '' : `${payTypeNm}(${payNumber})`}</span>
              </span>
              <span className='pay-price'>{utils.comma(historyData.currentPrice)} 원</span>
            </div>
          </div>

          <div className='charge-info-box'>
            <ChargeInfo label="충전소" value={Number(window.innerWidth) <= 320
              ? utils.textLengthOverCut(historyData.stationName, 6, "...") : utils.textLengthOverCut(historyData.stationName, 12, "...")} />
          </div>
          <div className='charge-info-box'>
            <ChargeInfo label="시작일시" value={utils.convert12H(historyData.chargeStartDt)} />
            <div style={borderBottomStyle} />
            <ChargeInfo label="종료일시" value={utils.convert12H(historyData.chargeFinishDt)} />
            <div style={borderBottomStyle} />
            <ChargeInfo label="충전시간" value={(historyData.chargeTime > 0) ? historyData.chargeTime + " 분" : 0 + " 분"} />
          </div>
          <div className='charge-info-box'>
            <ChargeInfo label="충전량" value={Number(historyData.chargeKwh).toFixed(2) + " kWh"} />
            <div style={borderBottomStyle} />
            <ChargeInfo label="충전기" value={historyData.couplerId} />
          </div>
        </div>
      </>
    );
  }
}

export { ChargeHistoryDetail };
