/**-------------------------------------------------------------
1.2.4.1 EV쿠폰 메인화면
-------------------------------------------------------------
> http://basic.pointweb.kr/charge/public/html/public/coupon.html
-------------------------------------------------------------*/

/* eslint-disable */

// 기본
import React, { useState, useEffect, useCallback } from 'react';

import { RouteComponentProps, Link } from 'react-router-dom';
import { appActions, store } from '../../store';

//헤더 
import { HandleBrowserBackButton } from '../../components/Layout'

import Const from '../../store/ev';
import { utils } from '../../libs/utils';

//Mixpanel
import { logbox, mixpanel } from '../../libs/logbox-mixpanel';
import { getUserStatus } from '../../apis/status';
import { getCoupon, patchCoupon, patchUseCoupon } from '../../apis/coupon';
import { modal } from '../../components/GlobalModal';
import { tmapInterface } from '../../libs/tmap-interface';
import { globalVars } from '../../libs/global-vars';

let scrollCache = 0;
let userData: any = {}

function PointCoupon(props: RouteComponentProps<{ id: string }>) {

    const [inited, setInited] = useState(false);
    const [noValidCoupon, setNoValidCoupon] = useState(false);
    const [firstClick, setFirstClick] = useState(false);

    const [useCouponToggle, setUseCouponToggle] = useState(true); // 자동사용 토글 T/F

    const [couponList, setCouponList] = useState<any>([]); //쿠폰리스트(정률,포인트)
    const [couponChkIdx, setCouponChkIdx] = useState<number>(); //선택한 쿠폰
    const [chargingGuid, setChargingGuid] = useState(false);
    const [userStatusCheck, setUserStatusCheck] = useState(false);

    const [pressed, setPressed] = useState({
        foucusCoupon: false,
        couponIdx: "",
        couponAdd: false,
        activeBtn: false
    });

    useEffect(() => {
        mixpanel("evcharge:/evcharge/pointcoupon");
        logbox("/evcharge/pointcoupon", "");
    }, []);

    const cacheScroll = useCallback(() => {
        // 간헐적으로 상세로 이동 후 호출되는 경우가 있어서 예외처리
        if (!/\/voice\/?$/.test(window.location.pathname)) return;
        scrollCache = window.scrollY;
    }, []);

    useEffect(() => {
        window.scrollTo(0, scrollCache);
    }, [inited]);

    useEffect(() => {
        window.addEventListener('scroll', cacheScroll);
        return () => window.removeEventListener('scroll', cacheScroll);
    }, [cacheScroll]);

    // useEffect(() => {
    //     const validCoupon = couponList.filter(x => x.couponStatus === 1 || x.couponStatus === 2);

    //     if (validCoupon.length === 0) {
    //         setNoValidCoupon(true);
    //     }
    // }, [couponList]);

    useEffect(() => {
        if (userStatusCheck) {
            getCoupon()
                .then((response: any) => {
                    setInited(true);
                    const list = response.data;

                    const chkCoupon = list.filter(x => x.couponType === 1 && x.userCheckYn);
                    const pointList = list.filter(x => x.couponType === 1);

                    const pointToggleInfo = response.pointCouponToggleYn ? response.pointCouponToggleYn : false

                    setCouponChkIdx((chargingGuid || chkCoupon.length === 0) ? pointList[0].couponIdx : chkCoupon[0].couponIdx);  //체크된 쿠폰

                    if (pointToggleInfo) {
                        setUseCouponToggle(true);
                    } else {
                        setUseCouponToggle(false);
                    }

                    if (list !== null) {
                        // var pointList = list.filter(x => x.couponType === 1);
                        setCouponList(
                            (chargingGuid || chkCoupon.length === 0) ?
                                pointList.filter(x => x[0]).concat(pointList.filter(x => !x[0]))
                                :
                                pointList.filter(x => x.userCheckYn).concat(pointList.filter(x => !x.userCheckYn))
                        );
                    }

                }).catch((response) => {
                    console.log('Error!', response)
                });
        }
    }, [userStatusCheck]);

    const addEvCoupon = () => {
        mixpanel("evcharge:/evcharge/pointcoupon:tap.addcoupon");
        logbox("/evcharge/pointcoupon", "tap.addcoupon");
        props.history.push(`/couponRegister/${"1"}`);
    };

    function goHistory(idx: number) {
        // props.history.push("/couponHistory?couponIdx="+idx);
        props.history.push(`/couponHistory/${idx}`);
    };

    const goBack = () => {
        mixpanel("evcharge:/evcharge/pointcoupon:tap.back");
        logbox("/evcharge/pointcoupon", "tap.back");

        getUserStatus()
            .then(function (response: any) {
                if (response.result) {
                    userData = response;

                    if (userData.result) {
                        if (userData.unpaidGuid !== null) {
                            // TODO : 미납금있을경우
                            store.dispatch(appActions.setGuid(userData.unpaidGuid));
                            props.history.replace("/nonPay");    // 미납금 페이지 이동
                        } else {
                            props.history.replace("/payMethod");
                        }
                    }
                }
            })
            .catch(function (error: any) {
                if (Const.DBG_OK) {
                    alert('사용자조회실패')
                }
            });
    };

    useEffect(() => {
        chkUserStatus((res) => {
            if (res) {
                setChargingGuid(true);
            }
        })
    }, []);

    //충전건 있는지 확인하는 함수
    const chkUserStatus = (callback) => {
        getUserStatus()
            .then((response: any) => {
                if (response.result) {
                    if (response.chargeSessionGuid) {
                        setChargingGuid(true);
                        setUserStatusCheck(true);
                        callback(true);
                    } else {
                        setUserStatusCheck(true);
                        callback(false);
                    }
                }
            })
    }

    // 토글 업데이트
    function chgToggleCheck(event: any) {
        const toggleYn = event.target.checked;

        chkUserStatus((res) => {
            if (!res) {
                mixpanel("evcharge:/evcharge/pointcoupon:tap.autouse");
                logbox("/evcharge/pointcoupon", "tap.autouse");

                if (toggleYn) {
                    setUseCouponToggle(true);
                } else {
                    setUseCouponToggle(false);
                }

                var toggleBody = {
                    autoUseType: 1,
                    toggleYn: toggleYn
                }

                patchCoupon(toggleBody)
                    .then(function (response: any) {
                        if (Const.DBG_OK) {
                            alert("쿠폰 자동 사용 체크 - " + JSON.stringify(response.data))
                        }
                    })
                    .catch(function (error: any) {
                        console.log(error);
                    });
            } else {
                modal.alert(() => {
                }, "쿠폰 변경이 불가합니다.", "충전이 시작되면 <br/>쿠폰은 변경할 수 없습니다.<br/>충전이 종료되고 다시 시도해주세요.");
            }
        })
    }

    //체크박스 업데이트
    function chgCouponCheck(couponIdx, couponType) {
        if (!firstClick) {
            chkUserStatus((res) => {
                if (!res) {
                    var couponBody = {
                        checkYn: true
                    }

                    patchUseCoupon(couponIdx, couponBody)
                        .then(function (response: any) {
                            setCouponChkIdx(couponIdx);
                            setFirstClick(false);

                            if (Const.DBG_OK) {
                                alert("쿠폰 체크 - " + JSON.stringify(response))
                            }
                        })
                        .catch(function (error: any) {
                            setFirstClick(false);
                            console.log(error);
                        });

                } else {
                    modal.alert(() => {
                        setFirstClick(false);
                    }, "쿠폰 변경이 불가합니다.", "충전이 시작되면 <br/>쿠폰은 변경할 수 없습니다.<br/>충전이 종료되고 다시 시도해주세요.");
                }
            });
        } else {
            return;
        }
    }

    const openPCApp = () => {
        setPressed({ ...pressed, activeBtn: false });

        const fallbackTimeout = setTimeout(() => {
            tmapInterface.openBrowser(globalVars.isIOS? "https://apps.apple.com/kr/app/id978597106":"https://play.google.com/store/apps/details?id=kr.co.iparking.android")
        }, 1500); // 1.5초 내에 앱이 열리지 않으면 스토어로 이동
        
        tmapInterface.openBrowser("pacl://evcharge?callerScheme=tmap");
        
        window.addEventListener('pagehide', () => {
            clearTimeout(fallbackTimeout); // 앱이 정상적으로 열리면 타이머 취소
        });
    }

    if (!inited) {
        return <></>
    } else {
        return (
            <>
                <HandleBrowserBackButton />
                <div className="coupon-top">
                    <button type="button"
                        onClick={() => { goBack(); }}
                    />
                    <><span className="header-title">선불충전권</span></>
                </div>
                <div className="coupon-contents">
                    <div className="coupon-section">
                        <div className="coupon-auto">
                            <h3>쿠폰 자동 사용</h3>
                            <label className="toggle-control" htmlFor="toggle">
                                <input
                                    type="checkbox"
                                    className="checked"
                                    id="toggle"
                                    checked={useCouponToggle}
                                    onClick={chgToggleCheck}
                                    readOnly={true}
                                />
                                <span className="control"></span>
                            </label>
                        </div>
                        {
                            couponList.map((x) => {
                                return (
                                    // status: 유효,충전중에 물린건,만료 1,2,3
                                    ((x.couponStatus !== 3))
                                        ?
                                        <div className='coupon-list'
                                            key={x.couponIdx}
                                            style={(pressed.foucusCoupon && x.couponIdx === pressed.couponIdx ? { background: "var(--gray-color-wb-transparent-300, rgba(0, 0, 0, 0.10))", flexDirection:"column" } : { background: "#FFF", flexDirection:"column" })}
                                            onClick={() => {
                                                if (useCouponToggle && x.activeYn) {
                                                    setFirstClick(true);
                                                    chgCouponCheck(x.couponIdx, x.couponType);
                                                }
                                            }} //쿠폰 선택 (체크박스에서 옮김 2.0)
                                            onTouchStart={() => !useCouponToggle || !x.activeYn? "" : setPressed({ ...pressed, foucusCoupon: true, couponIdx: x.couponIdx })}
                                            onTouchEnd={() => setPressed({ ...pressed, foucusCoupon: false })}
                                        >
                                            {x.activeYn ?
                                                <div style={{display:"flex", justifyContent:"space-between"}}>
                                                <div className='seperate'>
                                                    <img src={require(`%/images/coupon/coupon-${x.couponImgNo === 2 ?'polestar':'tmap'}.svg`).default} alt="" />
                                                    <div className='coupon-info-section' style={x.activeYn? {}:{opacity:"0.4"}}>
                                                        <span>{x.couponHeadName} {x.couponName}</span><br />
                                                        <span className='coupon-point'>{utils.comma(x.pointAmount)}원</span><br />
                                                        <span className='coupon-history-section'>
                                                            {(x.couponAvailableEndDt).substring(0, 10).replaceAll('-', '.')} 까지 <br />
                                                            <span onClick={() => {
                                                                x.activeYn && goHistory(x.couponIdx)
                                                            }} style={{ zIndex: "9", position: "relative" }}>{"내역 보기 >"}</span>
                                                        </span>
                                                    </div>
                                                </div>
                                                {
                                                    useCouponToggle === false //toggle YN여부
                                                        ?
                                                        <>
                                                            <div className="coupon-checkbox-off">
                                                                <input type="checkbox" id={x.couponIdx} disabled={useCouponToggle === false} checked={false} readOnly={true} />
                                                                <label htmlFor={x.couponIdx}>
                                                                </label>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <div className="coupon-checkbox">
                                                                <input type="checkbox" id={x.couponIdx} checked={(couponChkIdx === x.couponIdx && x.activeYn) ? true : false} readOnly={true} />
                                                                <label htmlFor={x.couponIdx}>
                                                                </label>
                                                            </div>
                                                        </>
                                                }
                                                </div>
                                                :
                                                <>
                                                    <div className='pc-deactive'>
                                                        <img src={require(`%/images/coupon/coupon-${x.couponImgNo === 2 ?'polestar':'tmap'}.svg`).default} alt="" />
                                                        <div className='title'>
                                                            {x.couponHeadName} <br />{x.couponName}
                                                            <button className='active-btn' onClick={() => openPCApp()}
                                                                onTouchStart={() => setPressed({ ...pressed, activeBtn: true })}
                                                                onTouchEnd={() => setPressed({ ...pressed, activeBtn: false })}
                                                                style={pressed.activeBtn?{filter: "brightness(90%)"}:{}}
                                                            >활성화하기</button>
                                                        </div>
                                                    </div>
                                                    <div className='pc-info'>
                                                        <div style={{display:'flex'}}>
                                                            <img src={require('%/images/common/ic_info.svg').default} alt="" className='info-ico'/>
                                                            <span className='info'>쿠폰 사용을 위해 활성화가 필요합니다.</span>
                                                        </div>
                                                    </div>
                                                </>
                                        }
                                        </div>
                                        :
                                        <></>
                                )
                            })
                        }
                        <div className="coupon-add-btn">
                            <button type="button"
                                onClick={addEvCoupon}
                                onTouchStart={() => setPressed({ ...pressed, couponAdd: true })}
                                onTouchEnd={() => setPressed({ ...pressed, couponAdd: false })}
                                style={pressed.couponAdd ? { background: 'var(--gray-color-wb-transparent-300, rgba(0, 0, 0, 0.10))' } : {}}
                            > 쿠폰 등록하기</button>
                        </div>
                    </div>
                    <div className="coupon-guide-bottom">
                        <div className="guide-list">
                            <img src={require('%/images/common/ico_info_dot.svg').default} alt="" />
                            <p>쿠폰은 아래의 유형을 지닌 상품권(Voucher)을 뜻합니다.<br/>(회사에서 마케팅 및 프로모션 목적으로 지급되는 할인, 자동차 제조사 및 충전사업자 등 사업협력을 통해 마련된 충전 상품)</p>
                        </div>
                        <div className="guide-list">
                            <img src={require('%/images/common/ico_info_dot.svg').default} alt="" />
                            <p>쿠폰은 일정한 유효기간을 지니며, 해당 유효기간이 경과되면 관련 쿠폰 사용이 불가합니다.</p>
                        </div>
                        <div className="guide-list">
                            <img src={require('%/images/common/ico_info_dot.svg').default} alt="" />
                            <p>쿠폰 금액은 유효기간과 상관없이 충전권 포인트 또는 현금으로 환불되지 않습니다.</p>
                        </div>
                        <div className="guide-list">
                            <img src={require('%/images/common/ico_info_dot.svg').default} alt="" />
                            <p>통신사 또는 전화번호 변경 시, 등록된 쿠폰은 소멸됩니다.<br/>쿠폰 등록 전 T 아이디로 전환해주세요.</p>
                        </div>
                        <div className="guide-list">
                            <img src={require('%/images/common/ico_info_dot.svg').default} alt="" />
                            <p>쿠폰은 종류에 따라 충전 거래에 1개씩만 사용 가능합니다.<br />(충전이 시작되면 해당 쿠폰은 다음 충전에 사용이 불가합니다.)</p>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export { PointCoupon };
