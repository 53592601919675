/* eslint-disable */

import instance from './frontman';


// export const postCharging = async ( body: object, accessToken?: String) => {
//     return await instance({
//         url: "/charging",
//         method: "post",
//         data: body
//     })
// };

//인증 상태 조회
export const getAuthStatus = async (params: String, accessToken?: String) => {
    return await instance({
        url: "/auth/status",
        method: "get",
        params: {guid: params}
    })
};

//충전 상태 조회
export const getChargeStatus = async (params: String, accessToken?: String) => {
    return await instance({
        url: "/charge/status",
        method: "get",
        params: {guid: params}
    })
};

//결제 상태 조회
export const getPayStatus = async (params: String, accessToken?: String) => {
    return await instance({
        url: "/pay/status",
        method: "get",
        params: {guid: params}
    })
};

export const getUserStatus = async (accessToken?: String) => {
    return await instance({
        url: "/user/status",
        method: "get",
    })
};

//충전중 카드상태조회
export const getCardStatus = async (params: String, accessToken?: String) => {
    return await instance({
        url: "/card/status",
        method: "get",
        params: {guid: params}
    })
};
