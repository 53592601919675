import React, { useCallback, useState } from 'react';
import { eventBus } from '../../../libs/event-bus';
import { ModalEvent } from '../index';

interface Props {
  callback?: Nullable<(a: any) => any>;
  // title?: string;
  content_title?: any; //첫번째 줄
  // content_title2?: string; //두번째 줄
  // content_title3?: string; //세번째 줄
  content?: any;
  cancelYn?: boolean;
  cancelCallback?: Nullable<(b: any) => any>;
  cancelName?: string;
  confirmName?: string;
}

function Alert(props: Props) {
  const onClickButton = useCallback(() => {
    eventBus.emit(ModalEvent.CLOSE_MODAL, () => {
      if (props.callback) props.callback(true);
    });
  }, [props]);

  const onClickCancel = useCallback(() => {
    eventBus.emit(ModalEvent.CLOSE_MODAL, () => {
      if (props.cancelCallback) props.cancelCallback(true);
    });
  }, [props]);

  const [pressed, setPressed] = useState({
    cancelBtn: false,
    confirmBtn: false
  })

  return (
    <div className="modal_wrap" style={{zIndex: "99999999"}}>
      <div className="modal_container">
        {/* {props.title && (
          <header className="modal_header">
            <h1 className="title">{props.title}</h1>
          </header>
        )} */}
        <div className="modal_content">
          {/* 첫번째 줄, 두번째 줄을 나눠서 작성함 (html 코드를 적용시키는 방법 필요) */}
          {/* <p className="modal_msg_head">{props.content_title}</p> */}
          <p className="modal_msg_head" dangerouslySetInnerHTML={{ __html: props.content_title }} />
          {!props.content ?
            <></>
            :
            <p className="modal_msg" dangerouslySetInnerHTML={{ __html: props.content }} />
          }
        </div>
        <footer className="modal_footer">
          {props.cancelYn ?
            <button type="button" className="btn_cancel" onClick={onClickCancel}
              onTouchStart={() => setPressed({ ...pressed, cancelBtn: true })} onTouchEnd={() => setPressed({ ...pressed, cancelBtn: false })} style={pressed.cancelBtn ? { filter: "brightness(90%)" } : {}}
            >
              { props.cancelName ? props.cancelName :"취소"}
            </button>
            :
            <></>
          }
          <button type="button" className="btn_confirm" onClick={onClickButton}
            onTouchStart={() => setPressed({ ...pressed, confirmBtn: true })} onTouchEnd={() => setPressed({ ...pressed, confirmBtn: false })} style={pressed.confirmBtn ? { filter: "brightness(90%)" } : {}}
          >
            
            { props.confirmName ? props.confirmName :"확인"}
          </button>
        </footer>
      </div>
      <div className="modal_dimmed" />
    </div>
  );
}

export { Alert };
