/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router";
import Const from '../../store/ev';

// 공통
import { Header } from '../../components/Layout';
import { HandleBrowserBackButton } from '../../components/Layout'
import { appActions, store } from '../../store';
import { appManager } from '../../managers';

import { patchTapCharge } from '../../apis/auth'
import { getAuthStatus } from '../../apis/status';
import { globalVars } from '../../libs/global-vars';

//Mixpanel
import { logbox, mixpanel } from '../../libs/logbox-mixpanel';
import moment from 'moment';
import axios from 'axios';

let scrollCache = 0;
// let timer: Nullable<number> = null;

const COUPLER_TYPES = ["AC 3상", "DC 콤보", "DC 차데모"];
// const CHARGEV_COUPLER_TYPES = ["ac3", "dc_combo", "dc_chademo"];
// const KP_COUPLER_TYPES = ["4", "5", "3"];
// const SE_COUPLER_TYPES = ["AC_THREE_PHASE", "DC_COMBO", "DC_CHADEMO"];

const TapChargeMultiCoupler = ({ location }) => {

    var defaultData: any = {
        chargerType: [],
        guid: "",
        // accessKey: "",
        cpoCode: "",
        couplerId: "",
    }

    const [couplerData, setCouplerData] = useState(defaultData);

    const history = useHistory();

    const [couplers, setCouplers] = useState({ types: COUPLER_TYPES, selectValue: "-1" });

    // const current = decodeURI(window.location.href);
    // const search = current.split("?")[1];
    // const params = new URLSearchParams(search);

    // const chargerType = params.get('chargerType');
    // const cpoCode = params.get('cpoCode');
    // const guid = params.get('guid');
    // const ak = params.get('accessKey');
    // const qr_url = params.get('qr_code');
    // const couplerId = params.get('couplerId');
    // const poiId = params.get('poi_id');

    var coupler_type = '';

    const [charger, setCharger] = useState<any>([]);

    const chargerTypes = {
        DC_CHADEMO: "DC 차데모",
        AC_THREE_PHASE: "AC 3상",
        DC_COMBO: "DC 콤보"
    };

    const newChargerData = [...couplerData.chargerType];

    useEffect(() => {
        for (let i = 0; i < newChargerData.length; i++) {
            newChargerData[i] = chargerTypes[newChargerData[i]];
        }

        setCharger(newChargerData);

        if (globalVars.connCheckLoop != null) {
            clearInterval(globalVars.connCheckLoop);
        }

        if (couplerData.guid) {
            globalVars.connCheckLoop = setInterval(() => {

                getAuthStatus(couplerData.guid)
                    .then((response: any) => {
                        if (response.authStatus === 5 || response.authStatus === 7 || response.authStatus === 8) {

                            clearInterval(globalVars.connCheckLoop);

                            history.push('/authFail');
                        }
                    }).catch((response) => {
                        clearInterval(globalVars.connCheckLoop);

                        history.push('/authFail');

                        console.log('Error!', response)
                    });
            }, 2000); //2초에 한 번씩 조회
        }
    }, [couplerData])

    const handleChange = (value) => {
        mixpanel("evcharge:/evcharge/selectconnector:tap.connector");
        logbox("/evcharge/selectconnector","tap.connector");

        console.log('value', value);

        appManager.globalLoadingInc();

        switch (value) {
            case "DC 콤보":
                coupler_type = "DC_COMBO";
                break;
            case "AC 3상":
                coupler_type = "AC_THREE_PHASE";
                break;
            case "DC 차데모":
                coupler_type = "DC_CHADEMO";
                break;
        }

        var PatchTapAuthdata = {
            guid: couplerData.guid,
            couplerType: coupler_type
        }

        getAuthStatus(couplerData.guid)
            .then((response: any) => {
                if (response.authStatus === 5 || response.authStatus === 7 || response.authStatus === 8) {

                    clearInterval(globalVars.connCheckLoop);

                    history.push('/authFail');
                } else {
                    clearInterval(globalVars.connCheckLoop);

                    patchTapCharge(PatchTapAuthdata)
                        .then(function (response2: any) {
                            appManager.globalLoadingReset();

                            store.dispatch(appActions.setQrStatus("2"));
                            store.dispatch(appActions.setGuid(response2.guid));

                            history.replace("/qrAuth");
                        })
                        .catch(function (error2: any) {
                            appManager.globalLoadingReset();

                            history.push('/authFail');
                        });
                }
            }).catch((response) => {
                clearInterval(globalVars.connCheckLoop);

                history.push('/authFail');
            });
    };

    useEffect(() => {
        setCouplerData(location.props.couplerData[0]);
        window.scrollTo(0, scrollCache);
    }, []);

    useEffect(() => {
        if (globalVars.connCheckLoop != null) {
            clearInterval(globalVars.connCheckLoop);
        }

        globalVars.connCheckLoop = setInterval(() => {

            getAuthStatus(couplerData.guid)
                .then((response: any) => {
                    if (response.authStatus === 5 || response.authStatus === 7 || response.authStatus === 8) {

                        clearInterval(globalVars.connCheckLoop);

                        history.push('/authFail');
                    }
                }).catch((response) => {
                    clearInterval(globalVars.connCheckLoop);

                    history.push('/authFail');

                    console.log('Error!', response)
                });
        }, 2000); //2초에 한 번씩 조회
    }, [])

    const couplerImg = (coupler) => {
        switch (coupler) {
            case "DC 콤보":
                return (
                    <>
                        <img src={require('%/images/coupler/ic-charging-type-1-disabled.svg').default} className="coupler_icon" alt="" />
                    </>
                )
            case "AC 3상":
                return (
                    <>
                        <img src={require('%/images/coupler/ic-charging-type-0-disabled.svg').default} className="coupler_icon" alt="" />
                    </>
                )
            case "DC 차데모":
                return (
                    <>
                        <img src={require('%/images/coupler/ic-charging-type-2-disabled.svg').default} className="coupler_icon" alt="" />
                    </>
                )
        }
    }

    return (
        <>
            <Header isMenu={false} />
            <HandleBrowserBackButton />

            <div className="charge-main">
                <div className="inner-box">
                    <div id="coupler-page-wrapper">
                        <div className="coupler-page">
                            <div className="txt-box">
                                <p className="txt2">
                                    <span>커넥터 타입을 선택해 주세요.</span>
                                </p>
                            </div>
                        </div>

                        <div className="coupler_types_selector_wrapper">
                            <ul className="coupler_types_selector">
                                {charger.map((value, i) => (
                                    <React.Fragment key={i}>
                                        {/* <p>{((chargerType === '03' && i === 0) || (chargerType === '05' && i === 1))?'뭬야?':'이런!'}</p> */}
                                        <li>
                                            <input
                                                id={i.toString()}
                                                value={i.toString()}
                                                name="coupler_type"
                                                type="radio"
                                                checked={couplers.selectValue === value}
                                                onChange={() => handleChange(value)}
                                            />
                                            <label htmlFor={i.toString()}>
                                                {couplerImg(value)}
                                                <span>{value}</span>
                                                {/* <img src={couplers.selectValue === i.toString() ? require('%/images/ic-charging-type-' + i + '-enabled.svg').default : require('%/images/ic-charging-type-' + i + '-disabled.svg').default} className="coupler_icon" alt="" /> */}
                                            </label>
                                        </li>
                                    </React.Fragment>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export { TapChargeMultiCoupler };

