
/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { utils } from '../../../libs/utils';

//Mixpanel
import { logbox, mixpanel } from '../../../libs/logbox-mixpanel';

import { globalVars } from '../../../libs/global-vars';
import { appActions, store } from '../../../store';

import axios from 'axios';

/**-------------------------------------------------------------
1.2.2.1 충전 중 화면 - 충전 데이터
-------------------------------------------------------------*/
const ChargingData = (props) => {

	const [payType, setPayType] = useState('N');     // 결제수단 : TODO
	const [payTypeNm, setPayTypeNm] = useState('');     // 결제수단 명 : TODO
	const [payImageUrl, setPayImageUrl] = useState('');     // 결제수단 이미지 CI
	const [payKind, setPayKind] = useState(''); // 결제수단 종류
	const [payNumber, setPayNumber] = useState(''); // 결제카드 번호

	const [isCard, setIsCard] = useState('NoFocus');
	const [payImageBg, setPayImageBg] = useState('transparent');     // 결제수단 이미지 CI
	const [bselectCard, setBselectCard] = useState('');

	const borderBottomStyle = { width: "90%", margin: "0 auto", borderBottom: "1px solid #F0F0F0" };

	function ChargeInfo({ label, value }) {
		return (
			<div className="charge-info">
				<span className='head-value'>{label}</span>
				<span>{value}</span>
			</div>
		);
	}

	useEffect(() => {
		if (props?.payData?.cardInfo?.cardStatus === 'OK' && props?.payData?.payInfo?.pay === null) {
			setPayType('Y');
			setPayNumber(props?.payData?.cardInfo?.card?.cardNumberDisplay);
			setPayTypeNm(props?.payData?.cardInfo?.card.cardCompany?.companyName);
			setPayImageUrl(props?.payData?.cardInfo?.card?.cardCompany?.ciImageUrl);
			setPayImageBg(props?.payData?.cardInfo?.card?.cardCompany?.bgColor);
			setPayKind('CARD');
			setIsCard('selected');
			setBselectCard('true');
			store.dispatch(appActions.setCIUrl(props?.payData?.cardInfo?.card?.cardCompany?.ciImageUrl));
			store.dispatch(appActions.setCIbg(props?.payData?.cardInfo?.card?.cardCompany?.bgColor));
		} else if (props?.payData?.payInfo?.payStatus === 'OK' && props?.payData?.cardInfo?.card === null) {
			setPayType('Y');
			setPayNumber(props?.payData?.payInfo?.pay?.displayNumber)
			setPayTypeNm(props?.payData?.payInfo?.pay?.payCompany?.companyName);
			setPayImageUrl(props?.payData?.payInfo?.pay?.payCompany?.ciImageUrl);
			setPayImageBg(props?.payData?.payInfo?.pay?.payCompany?.bgColor);
			setIsCard('selected');
			setBselectCard('true');
			store.dispatch(appActions.setCIUrl(props?.payData?.payInfo?.pay?.payCompany?.ciImageUrl));
			store.dispatch(appActions.setCIbg(props?.payData?.payInfo?.pay?.payCompany?.bgColor));
			if (props?.payData?.payInfo?.pay?.paymentType === 'KB_PLCC') {
				setPayKind('CARD')
			} else if (props?.payData?.payInfo?.pay?.paymentType === 'KB_PAY') {
				setPayKind('KB_PAY')
			} else {
				setPayKind('PAY')
			}

		} else if (props?.payData?.cardInfo?.cardStatus === 'NO_CARD' || props?.payData?.cardInfo?.cardStatus === 'NO_PAY') {
			setPayType('N');
			setPayTypeNm('');
			setIsCard('NoFocus');
			setBselectCard('신용/체크카드 등록하기');
			setPayImageUrl('');
			setPayImageBg('');
			setPayKind('');
		} else if (props?.payData?.cardInfo?.cardStatus === 'NO_PAY_MEANS') {
			setPayType('N');
			setPayTypeNm('');
			setIsCard('NoFocus');
			setBselectCard('신용/체크카드 등록하기');
			setPayImageUrl('');
			setPayImageBg('');
			setPayKind('');
		} else if (props?.payData?.cardInfo?.cardStatus === 'NO_PIN' || props?.payData?.payInfo?.payStatus === 'NO_PIN') {
			setPayType('N');
			setPayTypeNm('');
			setIsCard('NoFocus');
			setBselectCard('신용/체크카드 등록하기');
			setPayImageUrl('');
			setPayImageBg('');
			setPayKind('');
		} else if (props?.payData?.cardInfo?.cardStatus === 'CARD_EXPIRED') {
			setPayType('N');
			setPayTypeNm('');
			setIsCard('NoFocus');
			setBselectCard('카드 유효기간 만료');
			setPayImageUrl('');
			setPayImageBg('');
			setPayKind('');
		} else if (props?.payData?.cardInfo?.cardStatus === 'CARD_MAINTENANCE' || props?.payData?.payInfo?.payStatus === 'PAY_MAINTENANCE') {
			setPayType('N');
			setPayTypeNm('');
			setIsCard('NoFocus');
			setBselectCard('카드 점검 중');
			setPayImageUrl('');
			setPayImageBg('');
			setPayKind('');
		} else if (props?.payData?.cardInfo?.cardStatus === 'CORPCARD_AUTH_EXPIRED') {
			setPayType('N');
			setPayTypeNm('');
			setIsCard('NoFocus');
			setBselectCard('법인공용카드 인증만료');
			setPayImageUrl('');
			setPayImageBg('');
			setPayKind('');
		} else if (props?.payData?.payInfo?.payStatus === 'NO_PAY') {
			setPayType('N');
			setPayTypeNm('');
			setIsCard('NoFocus');
			setBselectCard('신용/체크카드 등록하기');
			setPayImageUrl('');
			setPayImageBg('');
			setPayKind('');
		} else {
			setPayType('N');
			setPayTypeNm('');
			setIsCard('NoFocus');
			setBselectCard('신용/체크카드 등록하기');
			setPayImageUrl('');
			setPayImageBg('');
			setPayKind('');
		}
	}, [])

	return (
		<>
			{/* 할인쿠폰 -> 선불충전권 -> 차액에서 (전사)포인트 */}
			<div className="finish-charge" style={{ padding: "0", marginTop: "24px" }}>
				<div className="finish-payinfo">
					<div className="coupon-box">
						<div className='info-list'>
							<span>예상 충전금액</span>
							<span className='amount'>{utils.comma(Math.floor(Number(props?.chargingData?.totalPrice)))} 원</span>
						</div>
						{props?.rateCouponSupport
							?
							<div className='info-list'>
								<span>할인 쿠폰 사용</span>
								{
									props?.chargerStatus === "충전중" ?
										<>
											{props?.rateGuid ?
												<>
													{
														Math.floor(Number(props?.chargingData?.currentRatePrice)) === 0 || !props?.chargingData?.currentRatePrice
															?
															<span className='coupon-section'>0 원</span>
															:
															<span className='coupon-section'> - {utils.comma(Math.floor(Number(props.chargingData.currentRatePrice)))} 원</span>
													}
												</>
												:
												<>
													<span className='coupon-section' style={{ color: "#a1a3a6" }}>미사용</span>
												</>
											}
										</>
										:
										<>
											{(props?.rateToggleInfo === false || props?.couponCnt === 0 || props?.noRateCoupon === false)//toggle off거나 쿠폰리스트가 없거나 유효쿠폰 없거나
												?
												<>
													<span className='coupon-section' style={{ color: "#a1a3a6" }}>미사용</span>
												</>
												:
												<>
													<span className='coupon-section'>0 원</span>
												</>
											}
										</>
								}
							</div>
							:
							<></>
						}

						{
							<div className='info-list'>
								<span>선불충전권 사용</span>
								{
									props?.chargerStatus === "충전중" ?
										<>
											{props?.pointGuid ?
												<>
													{Math.floor(Number(props?.chargingData?.currentPointPrice)) === 0 || !props?.chargingData?.currentPointPrice
														?
														<span className='coupon-section'>0 원</span>
														:
														<span className='coupon-section'> - {utils.comma(Math.floor(Number(props?.chargingData?.currentPointPrice)))} 원</span>
													}
												</>
												:
												<>
													<span className='coupon-section' style={{ color: "#a1a3a6" }}>미사용</span>
												</>
											}
										</>
										:
										<>
											{(props?.pointToggleInfo === false || props?.couponCnt === 0 || props?.noValidCoupon === false)//toggle off거나 쿠폰리스트가 없거나 유효쿠폰 없거나
												?
												<>
													<span className='coupon-section' style={{ color: "#a1a3a6" }}>미사용</span>
												</>
												:
												<>
													<span className='coupon-section'>0 원</span>
												</>
											}
										</>
								}
							</div>
						}
						{
							Math.floor(Number(props?.tmapPointInfo?.availablePoint)) !== 0 //보유 포인트 0일 때 레이블 삭제
								?
								<>
									<div className='info-list'>
										<span>포인트 사용</span>
										{
											props?.chargerStatus === "충전중"
												?
												props?.chargingData?.useTmapPointYn
													?
													Math.floor(Number(props?.chargingData?.currentTmapPointPrice)) === 0 || !props?.chargingData?.currentTmapPointPrice
														?
														<span className='coupon-section'>0 원</span>
														:
														<span className='coupon-section'> - {utils.comma(Math.floor(Number(props?.chargingData?.currentTmapPointPrice)))} 원</span>
													:
													<span className='coupon-section' style={{ color: "#a1a3a6" }}>미사용</span>
												:
												props?.tmapPointInfo?.toggleYn //포인트 토글 off 일 때 미사용
													?
													<span className='coupon-section'>0 원</span>
													:
													<span className='coupon-section' style={{ color: "#a1a3a6" }}>미사용</span>
										}
									</div>
								</>
								:
								<></>
						}
					</div>
				</div>

				<div className='credit-box' style={{ marginTop: "10px" }}>
					<span className={isCard}>
						<span
							className={payKind === 'PAY' ? "pay-logo-wrapper" :payKind === 'KB_PAY' ? "kb-pay-logo-wrapper": "card-logo-wrapper"}
							style={payKind === 'KB_PAY' ?{}
							:{
								background: payImageBg !== "" ? payImageBg : "none",
								padding: "1px",
							}}
						>
							{payTypeNm !== '' ? (
								<img
									src={payImageUrl}
									alt=""
									className={payKind === "PAY" ? "pay-logo" : payKind === "KB_PAY" ? "kb-pay-logo":"card-logo"}
								/>
							) : (
								""
							)}
						</span>
						<span className='card' style={payKind === 'KB_PAY'?{position:"relative", top:"-7px"}:{}}>{bselectCard === 'true' ? `${payTypeNm}(${payNumber})` : bselectCard}</span>
					</span>
					<span className='pay-price'>{utils.comma(Math.floor(Number(props?.chargingData?.currentPrice)))} 원</span>
				</div>

				<div className='charge-info-box'>
					<ChargeInfo label="충전소" value={Number(window.innerWidth) <= 320 ? utils.textLengthOverCut(props?.authData?.stationName, 6, '...') : utils.textLengthOverCut(props?.authData?.stationName, 12, '...')} />
				</div>
				<div className='charge-info-box'>
					<ChargeInfo label={props?.chargerStatus === "충전중" ?"시작일시":"인증일시"} value={props?.chargerStatus === "충전중" ? props?.chargingData?.chargeStartDt : props?.authData?.authCreateDt} />
					<div style={borderBottomStyle} />
					<ChargeInfo label="충전시간" value={(props?.chargingData?.elapsedChargeTime != null && props?.chargingData?.elapsedChargeTime > 0) ? (props?.chargingData?.elapsedChargeTime) + " 분" : ("0") + " 분"} />
				</div>
				<div className='charge-info-box'>
					<ChargeInfo label="충전기" value={props?.authData?.couplerId} />
					<div className="info-list">
						<li>실제 결제 금액과 다를 수 있습니다.</li>
						<li>충전기에 표시된 금액을 확인해 주세요.</li>
						<li>일부 충전기는 금액이 표시되지 않습니다.</li>
					</div>
				</div>
				<div className='charge-info-box'>
				</div>
			</div>

			<div className="charging-progress" style={{ marginTop: "0px" }}>
				<span style={{ width: "auto", color: "#8C9299" }}>사용 중인 충전기</span>
				<span onClick={props?.openInfoClick} style={{ display: "flex", zIndex: "1" }}>
					<p style={{ right: "5px" }}>
						{props?.progressData?.length}개
					</p>
					{props?.openInfo ?
						<img src={require('%/images/common/ic_shape_down.svg').default} alt="" style={{ width: "15px", position: "relative", transform: "rotate(180deg)" }} />
						:
						<img src={require('%/images/common/ic_shape_down.svg').default} alt="" style={{ width: "15px", position: "relative" }} />
					}
				</span>
			</div>
		</>
	);
}
export { ChargingData };