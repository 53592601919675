/* eslint-disable */
import React, { useState, useEffect, useCallback, useRef } from "react";
import { RouteComponentProps } from 'react-router-dom';
import { tmapInterface } from "../../libs/tmap-interface";
import { globalVars } from "../../libs/global-vars";
import { appManager } from '../../managers';

// Axios
import axios, { AxiosError, AxiosResponse } from "axios";
import Const from "../../store/ev";
import { postCoupon } from "../../apis/coupon";
import { logbox, mixpanel } from "../../libs/logbox-mixpanel";
import { modal } from "../../components/GlobalModal";

let scrollCache = 0;

function SpharosPromotion(props: RouteComponentProps) {
    const cacheScroll = useCallback(() => {
        // 간헐적으로 상세로 이동 후 호출되는 경우가 있어서 예외처리
        if (!/\/voice\/?$/.test(window.location.pathname)) return;
        scrollCache = window.scrollY;
    }, []);

    let serviceType = (globalVars.queryData.extra?.serviceType)?.toLowerCase() || (globalVars.queryData.extra?.servicetype)?.toLowerCase();

    useEffect(() => {
        window.scrollTo(0, scrollCache);
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', cacheScroll);
        return () => window.removeEventListener('scroll', cacheScroll);
    }, [cacheScroll]);

    const goBack = () => {
        if (serviceType === 'spharos') {
            serviceType = '';
            tmapInterface.onBackKeyPressed();
        } else {
            props.history.goBack();
        }
    }

    const couponRegist = () => {

        var data = {
            productCode: "IN_PROMOTION",
        };

        postCoupon(data)
            .then(function (response: any) {
                if (response.result) { // 정상적으로 쿠폰 추가
                    mixpanel("evcharge:/evcharge/addcoupon:view.successcoupon_popup");
                    logbox("/evcharge/addcoupon", "tap.successcoupon_popup");

                    modal.alert(() => {
                        registPop("success");
                    }, "쿠폰이 발급 되었습니다.<br/>할인 쿠폰 리스트를<br/>확인해주세요.");
                } else {
                    mixpanel("evcharge:/evcharge/addcoupon:view.wrongcoupon_popup");
                    logbox("/evcharge/addcoupon", "view.wrongcoupon_popup");

                    modal.alert(() => {
                        registPop("wrong");
                    }, "준비한 쿠폰이<br/>모두 발급 되었습니다.");
                }
            })
            .catch(function (error: any) {
                switch (error.response.data.reason) {
                    case "EV-PRODUCT-REGISTER-FAIL-002":
                        mixpanel("evcharge:/evcharge/addcoupon:view.registcoupon_popup");
                        logbox("/evcharge/addcoupon", "view.registcoupon_popup");

                        modal.alert(() => {
                            registPop("regist");
                        }, "이미 쿠폰을 발급 받으셨습니다.<br/>할인 쿠폰 리스트를<br/>확인해주세요.");
                        break;
                    case "EV-PRODUCT-EXCEED-LIMIT-001":
                        mixpanel("evcharge:/evcharge/addcoupon:view.wrongcoupon_popup");
                        logbox("/evcharge/addcoupon", "view.wrongcoupon_popup");

                        modal.alert(() => {
                            registPop("wrong");
                        }, "준비한 쿠폰이<br/>모두 발급 되었습니다.");
                        break;
                    default:
                        mixpanel("evcharge:/evcharge/addcoupon:view.wrongcoupon_popup");
                        logbox("/evcharge/addcoupon", "view.wrongcoupon_popup");

                        modal.alert(() => {
                            registPop("wrong");
                        }, "준비한 쿠폰이<br/>모두 발급 되었습니다.");
                }
            })
    }

    const registPop = (status: any) => {
        switch (status) {
            case "success":
                mixpanel("evcharge:/evcharge/addcoupon:tap.successcoupon_ok");
                logbox("/evcharge/addcoupon", "tap.successcoupon_ok");
                break;
            case "regist":
                mixpanel("evcharge:/evcharge/addcoupon:tap.registcoupon_ok");
                logbox("/evcharge/addcoupon", "tap.registcoupon_ok");
                goRateCoupon();
                break;
            case "end":
                mixpanel("evcharge:/evcharge/addcoupon:tap.dateendcoupon_ok");
                logbox("/evcharge/addcoupon", "tap.dateendcoupon_ok");
                break;
            default:
                mixpanel("evcharge:/evcharge/addcoupon:tap.wrongcoupon_ok");
                logbox("/evcharge/addcoupon", "tap.wrongcoupon_ok");
        }

        registSuccess(status);
    };

    const registSuccess = (result: any) => {
        mixpanel("evcharge:/evcharge/addcoupon:tap.add");
        logbox("/evcharge/addcoupon", "tap.add");
        if (globalVars.queryData.extra?.servicetype === 'couponRegister' || globalVars.queryData.extra?.serviceType === 'couponRegister' || globalVars.queryData.extra?.servicetype === 'couponregister' || globalVars.queryData.extra?.serviceType === 'couponregister') {
            globalVars.queryData.extra.servicetype = '';
            globalVars.queryData.extra.serviceType = '';
            globalVars.queryData.extra.servicetype = '';
            globalVars.queryData.extra.serviceType = '';
        }
        if (result === "success") {
            mixpanel("evcharge:/evcharge/addcoupon:view.successcoupon_popup");
            logbox("/evcharge/addcoupon", "view.successcoupon_popup");

            goRateCoupon();
        }
    }

    const goRateCoupon = () => {
        props.history.replace('/rateCoupon');
    }

    return (
        <>
            <div className="spharos-promotion">
                <button type="button" className="back-btn" onClick={goBack} style={{ zIndex: "999" }}>
                    <img src={require('%/images/common/ic_gnb_back.svg').default} alt="" />
                </button>
                <img src={require('%/images/promotion/img_spharos_promotion1.png').default} alt="" style={{ position: "relative", display: "block", width: "100%", verticalAlign: "center" }} />
                <div className="coupon">
                    <button type="button" className="coupon-btn" onClick={couponRegist} style={{ zIndex: "999" }}>30% 쿠폰 받기</button>
                </div>
            </div>
        </>
    )
}

export { SpharosPromotion };