import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import { globalVars } from '../libs/global-vars';
import { utils } from '../libs/utils';
import { VoiceState } from '../types/voice';
import { concat, find } from 'lodash-es';

// todo : AND에서 getVoiceGuideType은 callback이 아닌 함수 자체제서 값을 리턴하고 있다.
// IOS는 scheme호출 방식이라 바로 리턴이 불가능할 것으로 예상되어 확인이 필요하다. 확인되기 전까지 queryData로 처리함.
const selectedGuideType = (utils.isFirstLanding() ? globalVars.queryData.guideType : localStorage.getItem('voiceGuideType')) || 'female';
localStorage.setItem('voiceGuideType', selectedGuideType);

const initialState: VoiceState = {
  selectedGuideType,
  playingGuideType: null,
  isPlaying: false,
  bannerList: null,
  saleProductList: null,
  expiredProductList: null,
  purchaseList: null,
  paymentAgreeChecked: false,
  defaultProductList: [
    {
      productId: 'female',
      name: '여자 성우(기본)',
    },
    {
      productId: 'male',
      name: '남자 성우(기본)',
    },
  ],
};

const voiceSlice = createSlice({
  name: 'voice',
  initialState,
  reducers: {
    setBannerList(state, action) {
      state.bannerList = action.payload;
    },
    setProductList(state, action) {
      state.saleProductList = action.payload;
    },
    setExpiredProductList(state, action) {
      state.expiredProductList = action.payload;
    },
    setPurchaseList(state, action) {
      state.purchaseList = action.payload;
    },
    setPaymentAgree(state, action) {
      state.paymentAgreeChecked = action.payload;
    },
    setSelectedGuideType(state, action) {
      state.selectedGuideType = action.payload;
    },
    setPlayingGuideType(state, action) {
      state.playingGuideType = action.payload;
    },
    setIsPlaying(state, action) {
      state.isPlaying = action.payload;
    },
    addPurchaseProductItem(state, action) {
      state.purchaseList?.push(action.payload);
    },
  },
});

// 구매완료한 productItem : 판매중+판매종료 리스트에서 구매상품 추출
export const selectPurchasedProductList = createSelector(
  (state: RootState) => state.voice.saleProductList,
  (state: RootState) => state.voice.expiredProductList,
  (state: RootState) => state.voice.purchaseList,
  (saleProductList, expiredProductList, purchaseList) => {
    if (!saleProductList || !expiredProductList) return null;
    const combinedList = concat(saleProductList, expiredProductList);
    return combinedList.filter(productItem => !!find(purchaseList, purchaseItem => purchaseItem.itemId === productItem?.productId));
  },
);

// 구매가능한 productItem : 판매중 리스트에서 구매상품 제거
export const selectActiveProductList = createSelector(
  (state: RootState) => state.voice.saleProductList,
  (state: RootState) => state.voice.purchaseList,
  (saleProductList, purchaseList) => {
    if (!saleProductList) return null;
    return saleProductList.filter(productItem => !find(purchaseList, purchaseItem => purchaseItem.itemId === productItem?.productId));
  },
);

// 구매불가한(판매종료) productItem : 판매종료 리스트에서 구매상품 제거
export const selectDisabledProductList = createSelector(
  (state: RootState) => state.voice.expiredProductList,
  (state: RootState) => state.voice.purchaseList,
  (expiredProductList, purchaseList) => {
    if (!expiredProductList) return null;
    return expiredProductList.filter(productItem => !find(purchaseList, purchaseItem => purchaseItem.itemId === productItem?.productId));
  },
);

export default voiceSlice;
