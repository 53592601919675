/* eslint-disable */

import instance from './frontman';

//약관동의 업데이트
export const patchAgree = async (body: object) => {
    return await instance({
        url: "/agreement",
        method: "patch",
        data: body
    })
};