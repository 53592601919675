/**-------------------------------------------------------------
1.2.1.5 충전소 인식 화면_3
-------------------------------------------------------------
> http://basic.pointweb.kr/charge/public/html/public/charger1.html
-------------------------------------------------------------*/

/* eslint-disable */

import React, { useState, useEffect, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {useHistory} from "react-router";

// 공통
import { Header } 		from '../../components/Layout';
// import {HandleBrowserBackButton} from '../../components/Layout'

import axios from 'axios';
import Const from '../../store/ev';
import { appActions, store } from '../../store';
import { modal } from '../../components/GlobalModal';
import { globalVars } from '../../libs/global-vars';

let scrollCache = 0;
let everon = true;

function CP(props: RouteComponentProps<{ idx: string }>) {

	const [orientation, setOrientation] = useState(window.orientation);
  	const [inited] = useState(false);
	
	let state = "";

	if (globalVars.isAndroid) {
		if (window.orientation == 0 || window.orientation == 180) { //Landscape Mode			
			state = "TMAP\n‘전기차충전’ 메뉴에서";
			
			let ele = document.querySelector(".box-margin-landscape");
			ele?.classList.replace('box-margin-landscape', 'box-margin');

			ele = document.querySelector(".button-box-landscape");
			ele?.classList.replace("button-box-landscape", "button-box");
		}
		else if (window.orientation == 90 || window.orientation == -90) { //Portrait Mode
			state = "TMAP ‘전기차충전’ 메뉴에서";

			let ele = document.querySelector(".box-margin");
			ele?.classList.replace('box-margin', 'box-margin-landscape');

			ele = document.querySelector(".button-box");
			ele?.classList.replace("button-box", "button-box-landscape");			
		}
	}
	else {
		if (window.orientation == 0 || window.orientation == 180) { //Portrait Mode
			state = "TMAP\n‘전기차충전’ 메뉴에서";
			
			let ele = document.querySelector(".box-margin-landscape");
			ele?.classList.replace('box-margin-landscape', 'box-margin');

			ele = document.querySelector(".button-box-landscape");
			ele?.classList.replace("button-box-landscape", "button-box");
		} else if (window.orientation == 90 || window.orientation == -90) { //Landscape Mode
			state = "TMAP ‘전기차충전’ 메뉴에서";

			let ele = document.querySelector(".box-margin");
			ele?.classList.replace('box-margin', 'box-margin-landscape');

			ele = document.querySelector(".button-box");
			ele?.classList.replace("button-box", "button-box-landscape");
		}
	}


	// QR충전시작하기 클릭시  
	const goStartTmap = () => {
		// console.log('click');
		window.location.replace('http://www.tmap.co.kr/tmap2/mobile/tmap.jsp?scheme=tmap&host=evcharge&pageid=0');
 	};

	const cacheScroll = useCallback(() => {
		// 간헐적으로 상세로 이동 후 호출되는 경우가 있어서 예외처리
		if (!/\/voice\/?$/.test(window.location.pathname)) return;
		scrollCache = window.scrollY;
	}, []);
  	 
	useEffect(() => {

		window.location.replace(`http://www.tmap.co.kr/tmap2/mobile/tmap.jsp?scheme=tmap&host=evcharge&extra=%7B%22serviceId%22:%22tmap%22,%22serviceType%22:%22chkQrCode%22,%22payLoad%22:%22${window.location.href}%22%7D`);

		const handleOrientationChange= () => setOrientation(window.orientation);
		 
		window.addEventListener('orientationchange', handleOrientationChange);
		 
		return () => window.removeEventListener('orientationchange', handleOrientationChange);
	}, []);

	useEffect(() => {
		window.scrollTo(0, scrollCache);
	}, [inited]);
		
	if(everon){
		return (
			<>
				<div id="cp-page-wrapper">
					<div className="cp-page-fix-qr">
						<div className="txt-box">
							<p className="txt2"> {state.split('\n').map( line => {return (<span>{line}<br/></span>)})} </p>
							<p className="txt2">
								<span className="underline">QR코드를 촬영</span>해 주세요.
							</p>
						</div>
					</div>
					
					<div className="button-box">
						<div className="box-margin"></div>
						<a onClick={goStartTmap} >TMAP으로 이동하기</a>
					</div>
				</div>

				
			</>
		);
	} else {
		return (
			<>
				<div id="cp-page-wrapper">
					<div className="cp-page">
						<div className="txt-box">
							<p className="txt2">촬영하신 충전기는</p>
							<p className="txt2">
								<span className="underline">서비스 준비 중</span>입니다.
							</p>
							<p className="txt3">서비스가 시작되면<br /> TMAP 공지사항으로 안내 드리겠습니다.</p>
						</div>
					</div>
				</div>
			</>
		)
	};
		
  // render
  // if (!inited) return null
  
}

export { CP };
