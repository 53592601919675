import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Circular from '../assets/images/common/circular.svg';
import { RootState } from '../store';

let timer: Nullable<number> = null;

function GlobalLoading() {
  const globalLoading = useSelector((state: RootState) => state.app.globalLoading);
  const [isView, setIsView] = useState(!!globalLoading);

  // 상태가 연속으로 변경되면 시작점부터 circle이 새로 돌기 때문에 delay 적용
  useEffect(() => {
    if (timer) clearTimeout(timer);
    if (globalLoading && !isView) setIsView(true);
    else if (!globalLoading && isView) timer = window.setTimeout(() => setIsView(false), 50);
  }, [globalLoading, isView]);

  if (!isView) return null;
  return (
    // t-popup-trans, 
    <div className="loading-indicator">
      <img src={Circular} alt="" />
    </div>
  );
}

export { GlobalLoading };
