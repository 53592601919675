/* eslint-disable */

import axios from 'axios';
import instance from './frontman';

export const postReview = async (body?: object, accessToken?: String) => {
    return await instance({
        url: "/review",
        method: "post",
        data: body
    })
};