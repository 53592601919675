import { createSlice } from '@reduxjs/toolkit';
import { globalVars } from '../libs/global-vars';
import { AppState } from '../types/app';

const initialState: AppState = {
  accessKey: globalVars.accessKey || '',
  fuelType: globalVars.fuelType || '',
  globalLoading: 0,
  globalCover: false,
  systemError: null,
  routerInited: false,
  userKey: '',
  qrCode: '',
  qrStatus: '',
  guid: '',
  stationName: '',
  evPopCloseYn: 'N',
  CIUrl: "",
  CIbg: "",
  stationInfo: "",
  poiId: "",
  pkey: "",
  navX: "",
  navY: "",
  couponTypeInfo: "",
  pointToggleYN: true,
  rateToggleYN: true,
  cpoCode: "",
  sessionId: "",
  subSessionId: "",
  tmapFilterValue: "Y",
  pncFilterValue: "",
  publicFilterValue: "",
  speedFilterValue: "",
  typeFilterValue: "",
  operatorFilterValue: "",
  availableFilterValue: "",
  speedFilterFirstValue: [{
    "key": "완속",
    "value": "SLOW",
    "status": "N",
    "extraStatus": "N"
  },
  {
    "key": "급속",
    "value": "FAST",
    "status": "N",
    "extraStatus": "N"
  },
  {
    "key": "초급속",
    "value": "SUPER_FAST",
    "status": "N",
    "extraStatus": "N"
  }],
  typeFilterFirstValue: [{
    "key": "DC 콤보",
    "value": "DC_COMBO",
    "status": "N",
    "extraStatus": "N"
  },
  {
    "key": "AC 완속",
    "value": "AC_SINGLE_PHASE",
    "status": "N",
    "extraStatus": "N"
  },
  {
    "key": "DC 차데모",
    "value": "DC_CHADEMO",
    "status": "N",
    "extraStatus": "N"
  },
  {
    "key": "AC 3상",
    "value": "AC_THREE_PHASE",
    "status": "N",
    "extraStatus": "N"
  },
  {
    "key": "Tesla 수퍼차저",
    "value": "TESLA_SUPER_CHARGER",
    "status": "N",
    "extraStatus": "N"
  }],
  operatorFilterFirstValue: "",
  poiDetailStatus: "N",
  qrPoiId: "",
  qrType: "2",
  easyPayData: "",
  resume: "N",
  resumeIdx: "0",
  euk: "",
  remoteOffYn: "",
  chargerSpeed: "",
  lon: "",
  lat: "",
  nearPoiId: "",
  payType: "N",
  payNumber: "",
  payTypeNm: "",
  payImgUrl:"",
  payImgBg:"",
  payKind:"",
  isCard:"",
  BselectCard:"",
  popoverOpen:true,
  finishGuidData: [],
  cvcAgree:false,
  isPolestar: false,
  ownerName : "",
  isWonbu: false,
  carMakerName: "",
  carName: ""
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setCIUrl(state, action) {
      state.CIUrl = action.payload;
    },
    setCIbg(state, action) {
      state.CIbg = action.payload;
    },
    setFuelType(state, action) {
      state.fuelType = action.payload;
    },
    setStationName(state, action) {
      state.stationName = action.payload;
    },
    setAccessKey(state, action) {
      state.accessKey = action.payload;
    },
    globalLoadingReset(state) {
      state.globalLoading = 0;
    },
    globalLoadingInc(state) {
      state.globalLoading++;
    },
    globalLoadingDec(state) {
      state.globalLoading--;
    },
    setGlobalCover(state, action) {
      state.globalCover = action.payload;
    },
    setSystemError(state, action) {
      if (!state.systemError) state.systemError = action.payload;
    },
    setRouterInited(state, action) {
      state.routerInited = action.payload;
    },
    setUserKey(state, action) {
      state.userKey = action.payload;
    },
    setQrCode(state, action) {
      state.qrCode = action.payload;
    },
    setQrStatus(state, action) {
      state.qrStatus = action.payload;
    },
    setGuid(state, action) {
      state.guid = action.payload;
    },
    setEvPopCloseYn(state, action) {
      state.evPopCloseYn = action.payload;
    },
    setStationInfo(state, action) {
      state.stationInfo = action.payload;
    },
    setPoiId(state, action) {
      state.poiId = action.payload;
    },
    setPkey(state, action) {
      state.pkey = action.payload;
    },
    setNavX(state, action) {
      state.navX = action.payload
    },
    setNavY(state, action) {
      state.navY = action.payload
    },
    setCouponTypeInfo(state, action) {
      state.couponTypeInfo = action.payload;
    },
    setPointToggleYN(state, action) {
      state.pointToggleYN = action.payload;
    },
    setRateToggleYN(state, action) {
      state.rateToggleYN = action.payload;
    },
    setCpoCode(state, action) {
      state.cpoCode = action.payload;
    },
    setSessionId(state, action) {
      state.sessionId = action.payload;
    },
    setSubSessionId(state, action) {
      state.subSessionId = action.payload;
    },
    setTmapFilterValue(state, action) {
      state.tmapFilterValue = action.payload;
    },
    setPncFilterValue(state, action) {
      state.pncFilterValue = action.payload;
    },
    setPublicFilterValue(state, action) {
      state.publicFilterValue = action.payload;
    },
    setSpeedFilterValue(state, action) {
      state.speedFilterValue = action.payload;
    },
    setTypeFilterValue(state, action) {
      state.typeFilterValue = action.payload;
    },
    setOperatorFilterValue(state, action) {
      state.operatorFilterValue = action.payload;
    },
    setAvailableFilterValue(state, action) {
      state.availableFilterValue = action.payload;
    },
    setSpeedFilterFirstValue(state, action) {
      state.speedFilterFirstValue = action.payload;
    },
    setTypeFilterFirstValue(state, action) {
      state.typeFilterFirstValue = action.payload;
    },
    setOperatorFilterFirstValue(state, action) {
      state.operatorFilterFirstValue = action.payload;
    },
    setPoiDetailStatus(state, action) {
      state.poiDetailStatus = action.payload;
    },
    setQrPoiId(state, action) {
      state.qrPoiId = action.payload;
    },
    setQrType(state, action) {
      state.qrType = action.payload;
    },
    setEasyPayData(state, action) {
      state.easyPayData = action.payload;
    },
    setResume(state, action) {
      state.resume = action.payload;
    },
    setResumeIdx(state, action) {
      state.resumeIdx = action.payload;
    },
    setEuk(state, action) {
      state.euk = action.payload;
    },
    setRemoteOffYn(state, action) {
      state.remoteOffYn = action.payload;
    },
    setChargerSpeed(state, action) {
      state.chargerSpeed = action.payload;
    },
    setLon(state, action) {
      state.lon = action.payload;
    },
    setLat(state, action) {
      state.lat = action.payload;
    },
    setNearPoiId(state, action) {
      state.nearPoiId = action.payload;
    },
    setPayType(state, action) {
      state.payType = action.payload;
    },
    setPayNumber(state, action) {
      state.payNumber = action.payload;
    },
    setPayTypeNm(state, action) {
      state.payTypeNm = action.payload;
    },
    setPayImgUrl(state, action) {
      state.payImgUrl = action.payload;
    },
    setPayImgBg(state, action) {
      state.payImgBg = action.payload;
    },
    setPayKind(state, action) {
      state.payKind = action.payload;
    },
    setIsCard(state, action) {
      state.isCard = action.payload;
    },
    setBselectedCard(state, action) {
      state.BselectCard = action.payload;
    },
    setPopoverOpen(state, action) {
      state.popoverOpen = action.payload;
    },
    setFinishGuidData(state, action) {
      state.finishGuidData = action.payload;
    },
    setCvcAgree(state, action) {
      state.cvcAgree = action.payload;
    },
    setIsPolestar(state, action) {
      state.isPolestar = action.payload;
    },
    setOwnerName(state, action) {
      state.ownerName = action.payload;
    },
    setIsWonbu(state, action) {
      state.isWonbu = action.payload;
    },
    setCarMakerName(state, action) {
      state.carMakerName = action.payload;
    },
    setCarName(state, action) {
      state.carName = action.payload;
    }
  }
});

export default appSlice;
