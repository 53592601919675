
/* eslint-disable */

import React, { useState, useEffect } from 'react';

/**-------------------------------------------------------------
1.2.2.1 충전 중 화면 - 충전 데이터
-------------------------------------------------------------*/
const Progress = (props) => {
	const [focusProgress, setFocusProgress] = useState(false);

	return (
		<>
			<div className='charging-box'
				onClick={props.progressClick}
				style={props.guid === props.progressCharges.guid ? { border: "1px solid #0064ff" } :
					focusProgress ? { background: 'var(--gray-color-wb-transparent-300, rgba(0, 0, 0, 0.10))' } : {}}
				onTouchStart={() => setFocusProgress(true)}
				onTouchEnd={() => setFocusProgress(false)}
			>
				<span style={{ color: "#222", fontSize: "14.5px" }}>{props.progressCharges.stationName}</span>
				<span className='auth-status'>
					{props.authStatus}
				</span><br />
				<span style={{ color: "#909090", fontSize: "14px", letterSpacing: "normal" }}>{props.progressCharges.couplerId}</span>
			</div>
		</>
	);
}
export { Progress };