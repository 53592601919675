/* eslint-disable */

const RemoteFinish = (props) => {

    return (
        <>
            {props.remoteOffYN ?
                (
                    <></>
                )
                :
                (
                    <div className="remote-finish">
                        <img src={require('%/images/common/ico_information_solid.svg').default} alt="" style={{ position: "relative", top: "-12px" }} />
                        <div className="remote-title">
                            <span>충전기에서 종료해 주세요.</span><br />
                            <span className="sub-title">충전 종료 기능이 제공되지 않는 충전기입니다.</span>
                        </div>
                    </div>
                )}
        </>
    );
}
export { RemoteFinish };