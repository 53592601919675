import React, { useCallback, useMemo, useState } from 'react';
import { eventBus } from '../../../libs/event-bus';
import { tmapInterface } from '../../../libs/tmap-interface';
import { ModalEvent } from '../index';

interface Props {
  value?: string;
  callback?: Nullable<(a: any) => any>;
}

function CouponRegister(props: Props) {
  const [couponNumber, setCouponNumber] = useState<string>('');

  const validate = useMemo(() => {
    if (couponNumber.length !== 12) return false;
    if (couponNumber !== couponNumber.replace(/[^A-Za-z0-9]/g, '')) return false;
    return true;
  }, [couponNumber]);

  const onClickCancel = useCallback(() => {
    eventBus.emit(ModalEvent.CLOSE_MODAL, () => {
      if (props.callback) props.callback(false);
    });
  }, [props]);

  const onSubmit = useCallback(
    e => {
      e.preventDefault();
      const value = couponNumber.trim();
      if (!value) {
        tmapInterface.makeToast('쿠폰번호를 입력해주세요.');
        return;
      }
      eventBus.emit(ModalEvent.CLOSE_MODAL, () => {
        if (props.callback) props.callback(value);
      });
    },
    [couponNumber, props],
  );

  return (
    <div className="modal_wrap">
      <div className="modal_container">
        <header className="modal_header">
          <h1 className="title">쿠폰 등록</h1>
        </header>
        <form onSubmit={onSubmit}>
          <div className="modal_content">
            <div className="modal_coupon_input">
              <input
                type="text"
                className="inp_text"
                placeholder="쿠폰번호를 입력해주세요"
                value={couponNumber}
                onChange={e => setCouponNumber(e.target.value.slice(0, 12))}
                maxLength={12}
                autoFocus
              />
            </div>
          </div>
          <footer className="modal_footer">
            <button type="button" className="btn_cancel" onClick={() => onClickCancel()}>
              취소
            </button>
            <button type="submit" className="btn_confirm" disabled={!validate}>
              등록
            </button>
          </footer>
        </form>
      </div>
      <div className="modal_dimmed" />
    </div>
  );
}

export { CouponRegister };
