/**-------------------------------------------------------------
1.2.3.1 쿠폰사용내역 화면
-------------------------------------------------------------
-------------------------------------------------------------*/

/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { appActions, store } from '../../store';

//헤더 
import { Header } from '../../components/Layout';
import { HandleBrowserBackButton } from '../../components/Layout'

import { appManager } from '../../managers';
import { utils } from '../../libs/utils';

import Const from '../../store/ev';
import { globalVars } from '../../libs/global-vars';
import { modal } from '../../components/GlobalModal';
import axios from 'axios'

//Mixpanel
import { logbox, mixpanel } from '../../libs/logbox-mixpanel';
import { getCouponHistory } from '../../apis/coupon';

function CouponHistory(props: RouteComponentProps<{ coupon_idx: string }>) {
  let prevDateTime = '';
  let currDateTime = '';

  const [inited, setInited] = useState(false);

  const [windowWidth, setWindowWidth] = useState(0);

  const [couponImage, setCouponImage] = useState({
    main: '',
    sub: ''
  });

  const [_couponHistory, setCouponHistory] = useState({
    data: [
      {
        // place: "",
        payIdx: 0,
        payType: 0,
        useIdx: 0,
        applyAmount: 0,
        payDateTime: "",
        stationName: ""
      }
    ],
    couponHeadName: "",
    couponName: "",
    result: false,
    couponIdx: 0,
    productIdx: 0,
    couponImgNo: 0,
    couponType: 0,
    couponIssuedDt: "",
    couponAvailableEndDt: "",
    pointAmount: 0,
    userCheckYn: false,
  });

  const dateFormat = (date: string, flag: any) => {
    let temp = date.split(" ");
    let formatDate = "";
    let check = "";
    let yoil = utils.getInputDayLabel(date);

    if (flag == "D") {

      formatDate = temp[0].substring(8, 10) + "일" + "(" + yoil + ")";

    } else if (flag == "C") {

      formatDate = temp[0].substring(0, 4) + "년 " + temp[0].substring(5, 7) + "월 " + temp[0].substring(8, 10) + "일" + "(" + yoil + ")";

    }
    else {
      formatDate = temp[1].split(":")[0] + ":" + temp[1].split(":")[1];
    }
    return formatDate;
  };

  useEffect(() => {
    mixpanel("evcharge:/evcharge/detailcoupon");
    logbox("/evcharge/detailcoupon", "");
  }, [])

  useEffect(() => {
    appManager.globalLoadingInc();

    const coupon_idx = props.match.params.coupon_idx;

    getCouponHistory(coupon_idx)
      .then(function (response: any) {
        appManager.globalLoadingDec();
        if (response.data.length === 0) {
          modal.alert(() => {
            props.history.push("/pointCoupon");
          }, '쿠폰 사용내역이 없습니다.');
        } else {
          setInited(true);
          setCouponHistory(response);
        }
      })
      .catch(function (error: any) {
        appManager.globalLoadingDec();

        props.history.push(`/main`);
      });
  }, [inited]);


  function addDateTime(x) {
    currDateTime = dateFormat(x.payDateTime, "C");

    if (prevDateTime !== currDateTime) {
      prevDateTime = currDateTime;

      return (
        <>
          <div className='coupon-use-date'> {currDateTime} </div>
        </>
      );
    }
  }

  function listDate(data: any) {
    return (
      <>
        <div className='coupon-history-list' style={_couponHistory.data.length > 1 ? { borderBottom: "1px solid #E0E2E8" } : {}}>
          <span className="time">{dateFormat(data.payDateTime, "T")}</span>
          <div className="addr-txt">
            {Number(window.innerWidth) <= 320 ? utils.textLengthOverCut(data.stationName, 12, '...') : data.stationName}
            <span className="price">
              -{utils.comma(Math.floor(Number(data.applyAmount)))}원
            </span>
          </div>
        </div>
      </>

    )
  }

  if (_couponHistory.result === false) {
    return (
      <>
        <Header title={'쿠폰 사용내역'} isMenu={false} isActive={'3'} />
      </>
    );
  } else {
    return (
      <>
        <Header title={'선불충전권 사용내역'} isMenu={false} isActive={'3'} />
        <div id="page">
          <div className="coupon-history">
            <div className="top">
              <img src={require('%/images/coupon/coupon-tmap.svg').default} alt="" style={{ paddingBottom: "15px" }} />
              <div className='coupon-info'>
                <span className='coupon-name'>{_couponHistory.couponHeadName} {_couponHistory.couponName}</span>
                <span className='coupon-point'>{utils.comma(_couponHistory.pointAmount)}원</span>
                <span className='coupon-expire'>{(_couponHistory.couponAvailableEndDt).substring(0, 10).replaceAll('-', '.')} 까지 </span>
              </div>
              {/* <ul>

                {
                  (_couponHistory.couponImgNo === 2)
                  ?
                  <li className="type1">
                    <figure>
                        <img src={require('%/images/coupon/img-coupon-polestar.svg').default} alt="" />
                    </figure>
                    <div className="black-text-box">
                        <p className='sub-title' style={{fontSize:"12px"}}> {_couponHistory.couponHeadName} </p>
                        <em className="underline main-title" style={{fontSize:"14px"}}>{_couponHistory.couponName}</em>
                        <p style={{fontSize:"18px", top:"-4px"}}>
                            <i><img src={require('%/images/coupon/ic_point_p_polestar.svg').default} alt="" style={{transform:"scale(0.7, 0.7)"}}/></i>{utils.comma(_couponHistory.pointAmount)}
                        </p>
                        <span className="exp-date" style={{fontSize:"10px", bottom:"10px"}}>{_couponHistory.couponAvailableEndDt.substring(0, 10).replaceAll('-','.')} 만료</span>
                        <span className="exp-date polestar-call" style={{color:'#a1a3a6', opacity:'1', fontSize:"10px", bottom:"10px"}}>고객센터 1661-7999</span>
                    </div>  
                  </li>
                  :
                  <></>
                }
                {
                  (_couponHistory.couponImgNo === 3)
                  ?
                  <li className="type5">
                    <figure>
                        <img src={require('%/images/coupon/img-coupon-tmap-blue.svg').default} alt="" />
                    </figure>
                    <div className="text-box">
                        <p className='sub-title' style={{fontSize:"12px"}}> {_couponHistory.couponHeadName} </p>
                        <em className="underline main-title" style={{fontSize:"14px"}}>{_couponHistory.couponName}</em>
                        <p style={{fontSize:"18px", top:"-4px"}}>
                            <i><img src={require('%/images/coupon/ic_point_p_winter_2021.svg').default} alt="" style={{transform:"scale(0.7, 0.7)"}}/></i>{utils.comma(_couponHistory.pointAmount)}
                        </p>
                        <span className="exp-date" style={{fontSize:"10px", bottom:"10px"}}>{_couponHistory.couponAvailableEndDt.substring(0, 10).replaceAll('-','.')} 만료</span>
                    </div>  
                  </li>
                  :
                  <></>
                }
                {
                  (_couponHistory.couponImgNo === 4)
                  ?
                  <li className="type4">
                    <figure>
                        <img src={require('%/images/coupon/img-coupon-tmap-green.svg').default} alt="" />
                    </figure>
                    <div className="text-box">
                        <p className='sub-title' style={{fontSize:"12px"}}> {_couponHistory.couponHeadName} </p>
                        <em className="underline main-title" style={{fontSize:"14px"}}>{_couponHistory.couponName}</em>
                        <p style={{fontSize:"18px", top:"-4px"}}>
                            <i><img src={require('%/images/coupon/ic_point_p_winter_2021.svg').default} alt="" style={{transform:"scale(0.7, 0.7)"}}/></i>{utils.comma(_couponHistory.pointAmount)}
                        </p>
                        <span className="exp-date" style={{fontSize:"10px", bottom:"10px"}}>{_couponHistory.couponAvailableEndDt.substring(0, 10).replaceAll('-','.')} 만료</span>
                    </div>  
                  </li>
                  :
                  <></>
                }
                </ul> */}
            </div>


            <div className="list-box">
              {
                _couponHistory.data.map((x, i) => {
                  return (
                    <div className="list" key={i}>
                      {addDateTime(x)}
                      {listDate(x)}
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </>
    )
  }
}

export { CouponHistory };
