/* eslint-disable */
import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import Const from "../../store/ev";
import { tmapInterface } from "../../libs/tmap-interface";
import { appManager } from '../../managers';

//Mixpanel
import { logbox, mixpanel } from '../../libs/logbox-mixpanel';
import { getDeliveryWeb, patchDelivery } from "../../apis/delivery";

let currentPositionLon;
let currentPositionLat;
let scrollCache = 0;

function EvDelivery(props: RouteComponentProps<{ id: string }>) {

  const [inited, setInited] = useState(false);

  const [allCheck, setAllCheck] = useState(false); //전체 동의 여부
  const [privacyCheck, setPrivacyCheck] = useState(false); // 개인정보 수집 동의
  const [offerCheck, setOfferCheck] = useState(false); // 3자 제공 동의

  const [deliveryFirstClick, setDeliveryFirstClick] = useState(false);

  const handleSelectAll = () => {
    mixpanel("evcharge:/evcharge/chargedeliveryagree:tap.agree_all");
    logbox("/evcharge/chargedeliveryagree", "tap.agree_all");

    if (allCheck === false) {
      setAllCheck(true);
      setPrivacyCheck(true);
      setOfferCheck(true);
    } else {
      setAllCheck(false);
      setPrivacyCheck(false);
      setOfferCheck(false);
    }
  };

  const handleSelectPrivacy = () => {
    mixpanel("evcharge:/evcharge/chargedeliveryagree:tap.agree_location");
    logbox("/evcharge/chargedeliveryagree", "tap.agree_location");

    if (privacyCheck === false) {
      setPrivacyCheck(true);
    } else {
      setPrivacyCheck(false);
    }
  };

  const handleSelectOffer = () => {
    mixpanel("evcharge:/evcharge/chargedeliveryagree:tap.agree_give");
    logbox("/evcharge/chargedeliveryagree", "tap.agree_give");

    if (offerCheck === false) {
      setOfferCheck(true);
    } else {
      setOfferCheck(false);
    }
  };

  useEffect(() => {
    if (privacyCheck === true && offerCheck === true) {
      setAllCheck(true);
    } else {
      setAllCheck(false);
    }
  }, [privacyCheck, offerCheck]);

  useEffect(() => {
    window.scrollTo(0, scrollCache);
  }, [inited]);

  const ButtonState = () => {
    return (
      <>
        {privacyCheck === true && offerCheck === true ? (
          <div className="button-box">
            <button type="button" onClick={() => {
              setDeliveryFirstClick(true);
              goStart();
            }}>
              동의하고 시작하기
            </button>
          </div>
        ) : (
          <div className="dim-button-box">
            <button>동의하고 시작하기</button>
          </div>
        )}
      </>
    );
  };

  // function getLocation() {
  //   navigator.geolocation.getCurrentPosition(
  //     function (currentPosition) {
  //       currentPositionLon = currentPosition.coords.longitude.toString();
  //       currentPositionLat = currentPosition.coords.latitude.toString();
  //     },
  //     function (error) {
  //       console.error(error);
  //     },
  //     {
  //       enableHighAccuracy: false,
  //       maximumAge: 0,
  //       timeout: Infinity,
  //     }
  //   );
  // }

  function currentPosition(latitude: string, longitude: string) {
    if (Const.DBG_OK) {
      alert("currentPosition" + JSON.stringify(latitude) + JSON.stringify(longitude));
    }

    if (latitude) {
      //qr코드가 '' 빈값일때 qr인증실패로 이동
      currentPositionLat = latitude;
      currentPositionLon = longitude;

      appManager.globalLoadingReset();
      setInited(true);
    }

  }

  const goStart = () => {
    if (deliveryFirstClick) return;

    mixpanel("evcharge:/evcharge/chargedeliveryagree:tap.start");
    logbox("/evcharge/chargedeliveryagree", "tap.start");

    patchDelivery()
      .then(function (response: any) {
        if (response.result) {
          getDeliveryWeb(currentPositionLat, currentPositionLon)
            .then((response: any) => {
              props.history.replace("/main");

              const objectHtml = document.createElement('div');
              objectHtml.innerHTML = response;
              console.log(objectHtml.querySelector('form')?.getAttribute('action'));

              tmapInterface.tChargeDelivery(objectHtml.querySelector('form')?.getAttribute('action') as string);

              setTimeout(() => {
                setDeliveryFirstClick(false);
              }, 500);
            })
            .catch((error: any) => {
              console.log(error);
            });
        }
      })
      .catch(function (error: any) {
        if (Const.DBG_OK) {
          alert("충전소 정보 조회실패");
        }
        //alert('error - ' + error);
      });
  };

  const goBack = () => {
    mixpanel("evcharge:/evcharge/chargedeliveryagree:tap.back");
    logbox("/evcharge/chargedeliveryagree", "tap.back");

    props.history.replace("/main");
  };

  useEffect(() => {
    //getLocation();
    appManager.globalLoadingInc();
    tmapInterface.getCurrentPosition(currentPosition);

  }, []);

  const html = `<html>
  <body onload="setTimeout(function() { document.elecvery.submit() }, 1)">
      <form name="elecvery" action="https://webapi-dev.elecvery.net/flutter?action=init&token=UqlQY%2BEQSVdqOZ0T7eNO%2Bg%3D%3D&latitude=37.497926&longitude=127.027617&productId=4&productName=20+kWh" method="get">
      <input style="display:none;" type="submit">
      </form>
  </body>
  </html>`;

  if (!inited) {
    return <></>
  } else {
    return (
      <>
        <div className="term-top">
          <button type="button" onClick={goBack}>
            닫기
          </button>
        </div>
        <div className="term-bottom" />
        <div className="terms-box">
          <img src={require('%/images/common/face_smile_1.svg').default} alt="" style={{ padding: "0 20px" }} />
          <div className="title-box">
            <h3>
              충전배달 이용을 위한
              <br />
              필수 약관에 동의해 주세요.
            </h3>
          </div>
          <div className="chk-wrap">
            <div className="allchk" style={allCheck ? { border: "1.5px solid #0064ff" } : {}}>
              <p className="checkbox-type1">
                <input
                  type="checkbox"
                  id="allchk"
                  checked={allCheck}
                  onChange={handleSelectAll}
                />
                <label htmlFor="allchk" style={allCheck ? { color: "#0064ff" } : {}}>모두 동의합니다</label>
              </p>
            </div>
            <ul>
              <li>
                <p className="check">
                  <input
                    type="checkbox"
                    id="chk1"
                    checked={privacyCheck}
                    onChange={handleSelectPrivacy}
                  />
                  <label htmlFor="chk1">(필수) 개인정보 수집, 이용 동의</label>
                </p>
                <div className="txt">
                  티맵모빌리티 주식회사는 충전배달 서비스 제공을 위해 아래와 같이
                  개인정보를 수집합니다.
                </div>
                <dl className="dl-style1">
                  <dt>수집/이용목적</dt>
                  <dd>충전배달 서비스 제공</dd>
                  <dt>수집 항목</dt>
                  <dd>서비스 요청 위치</dd>
                  <dt>보유/이용 기간</dt>
                  <dd style={{fontFamily: "TMOBI500", fontWeight: "500"}}>서비스 제공 기간 (단, 서비스 해지하는 경우 즉시 삭제)</dd>
                  <div className="txt" style={{fontSize: "11px"}}>
                  개인정보 수집, 이용 동의 처리에 대해 거부할 권리가 있습니다. 거부 할 경우 충전배달 서비스 이용에 제한이 있습니다.
                  </div>
                </dl>
              </li>
              <li>
                <p className="check">
                  <input
                    type="checkbox"
                    id="chk2"
                    checked={offerCheck}
                    onChange={handleSelectOffer}
                  />
                  <label htmlFor="chk2">(필수) 개인정보 제 3자 제공 동의</label>
                </p>
                <div className="txt">
                  티맵모빌리티 주식회사는 충전배달 서비스 제공을 위해 아래와 같이
                  개인정보를 제공합니다.
                </div>
                <dl className="dl-style1">
                  <dt>제공받는 회사</dt>
                  <dd style={{fontFamily: "TMOBI500", fontWeight: "500"}}>(주)티비유</dd>
                  <dt>제공 목적</dt>
                  <dd style={{fontFamily: "TMOBI500", fontWeight: "500"}}>충전배달 서비스 제공</dd>
                  <dt>제공 정보</dt>
                  <dd>유저 고유키, 서비스 요청 위치</dd>
                  <dt>보유/이용 기간</dt>
                  <dd style={{fontFamily: "TMOBI500", fontWeight: "500"}}>동의일로부터 목적 달성 시까지</dd>
                  <div className="txt" style={{fontSize: "11px"}}>
                  개인정보 제 3자 제공에 대해 거부할 권리가 있습니다. 거부 할 경우 충전배달 서비스 이용에 제한이 있습니다.
                  </div>
                </dl>
              </li>
            </ul>
          </div>
          <ButtonState />
        </div>
      </>
    );
  }
}

export { EvDelivery };
