/* eslint-disable */
import instance from './frontman';

export const getPay = async (params: String, accessToken?: String) => {
    return await instance({
        url: "/pay",
        method: "get",
        params: {guid: params}
    })
};

//결제 요청
export const patchPay = async (body: object, accessToken?: String) => {
    return await instance({
        url: `/pay`,
        method: "patch",
        data: body
    })
};