/* eslint-disable */
import React, { useState, useEffect, useCallback, useRef } from "react";
import { RouteComponentProps } from 'react-router-dom';
import { tmapInterface } from "../../libs/tmap-interface";
import { globalVars } from "../../libs/global-vars";

let scrollCache = 0;

// let serviceType : any = {};

// serviceType = (globalVars.queryData.extra?.serviceType)?.toLowerCase() || (globalVars.queryData.extra?.servicetype)?.toLowerCase();

function ShinhanPromotion(props: RouteComponentProps) {
    const [inited] = useState(false);
    const [openInfo, setOpenInfo] = useState<boolean>(false);
    // const listRef = useRef<HTMLDivElement>(null); 	  

    // function foldList() {
    //   if (!listRef || !listRef.current) {
    //     return;						     
    //   }
      
    //   const style = listRef.current.style;  
   
    //   if (openInfo) {			  
    //     style.maxHeight = '0'; 	  	  
    //   } else if (!openInfo) {	  	  
    //     style.maxHeight = 		
    //       `${listRef.current.scrollHeight}px`; 
    //   }
    //   setOpenInfo(!openInfo);		  
    // }

    const cacheScroll = useCallback(() => {
        // 간헐적으로 상세로 이동 후 호출되는 경우가 있어서 예외처리
        if (!/\/voice\/?$/.test(window.location.pathname)) return;
        scrollCache = window.scrollY;
    }, []);

    useEffect(() => {
        window.scrollTo(0, scrollCache);
    }, [inited]);

    useEffect(() => {
        window.addEventListener('scroll', cacheScroll);
        return () => window.removeEventListener('scroll', cacheScroll);
    }, [cacheScroll]);
  
    const goBack = () => {
      if(globalVars.queryData.extra?.servicetype === 'shinhanpromotion' || globalVars.queryData.extra?.serviceType === 'shinhanpromotion' || globalVars.queryData.extra?.servicetype === 'shinhanPromotion' || globalVars.queryData.extra?.serviceType === 'shinhanPromotion'){
        globalVars.queryData.extra.servicetype = '';
        globalVars.queryData.extra.serviceType = '';
        // props.history.replace('/mainRouter');
        tmapInterface.onBackKeyPressed();
      } else {
        props.history.goBack();
      }
    }

    const goSubs = () => {
      tmapInterface.openBrowser(
        "https://www.shinhancard.com/pconts/html/benefit/event/1221061_2239.html"
      );
    }

    // useEffect(()=>{
    //   if(globalVars.queryData.extra?.servicetype === 'shinhanpromotion' || globalVars.queryData.extra?.serviceType === 'shinhanpromotion' || globalVars.queryData.extra?.servicetype === 'shinhanPromotion' || globalVars.queryData.extra?.serviceType === 'shinhanPromotion'){
    //     globalVars.queryData.extra.servicetype = '';
    //     globalVars.queryData.extra.serviceType = '';
    //   }
    // },[])
  
    // if (!inited) return null
    return (
        <>
          <div className="promotion">
            <button type = "button" className = "back-btn" onClick={goBack} style={{position:"absolute"}}>
                <img src={require('%/images/common/ic_shape.svg').default} alt=""/>
            </button>
            <div className="main-info">
              <img src={require('%/images/promotion/img_shinhan_main.svg').default} alt="" style={{position:"relative", display:"block"}}/>
            </div>
            <div className="event-info">
              <div className="main-header">
                <span>이벤트 내용</span>
              </div>
              <div className="header-title">
                신한 EVerywhere 카드 고객은<br/>
                3개월 간 최대 10만원까지 무료 충전!
              </div>
              <div className="info">
                <div className="info-box">
                  <li><span className="list">이벤트 기간</span><br/><span className="li-content">2023. 04. 10 ~ 04. 30</span></li>
                  <li>
                    <span className="list">혜택 제공 시점</span><br/><span className="li-content" style={{lineHeight:"22px"}}>
                      <tr>
                        <td style={{fontFamily:"TMOBI500"}}>충전 기간</td>
                        <td style={{paddingLeft:"10px"}}>2023. 04. 10 ~ 06.30</td>
                      </tr>
                      <tr>
                        <td style={{fontFamily:"TMOBI500"}}>캐시백 지급</td>
                        <td style={{paddingLeft:"10px"}}>2023. 07월 말</td>
                      </tr>
                    </span>
                  </li>
                  <li><span className="list">혜택 제공 대상</span><br/>
                    <span className="li-content"style={{lineHeight:"10px"}}>
                      <span className="bold-content">6개월 내</span> 모든 신한카드 이용 기록이 없으신 고객 중<br/>
                      이벤트 기간 동안 <span className="bold-content">‘TMAP 전기차충전’</span>에서<br/>
                      <span className="bold-content">신한 EVerywhere 카드를 결제 수단으로 등록</span>하여<br/>
                      <span className="bold-content">전기차를 충전하신</span> 고객께 최대 10만원의 100% 캐시백 혜택을 드립니다.
                    </span>
                  </li>
                  <div className="info-sub">
                    <span>
                      *타 신한카드 이벤트 혜택과 중복 적용 불가<br/>
                      **세부조건은 아래 유의사항 참고
                    </span>
                  </div>
                </div>
              </div>
              <div className="first">
                <button type="button" className="go-info" onClick={goSubs}>
                  이벤트 자세히 보기
                </button>
              </div>
            </div>
            <div onClick={()=>setOpenInfo(!openInfo)} className={openInfo? "open info-bottom" : "close info-bottom"}>
              <span>유의사항</span>
              <div>
                <button>
                  {openInfo?
                  <>
                  <img src={require('%/images/common/ic_shape_down.svg').default} alt="" style={{width:"15px", height:"15", transform:"rotate(180deg)"}}/>
                  </>
                  :
                  <>
                  <img src={require('%/images/common/ic_shape_down.svg').default} alt="" style={{width:"15px", height:"15"}}/>
                  </>
                  }
                </button>
              </div>
            </div>
            {openInfo?
            <>
              <div className="info-content" >
                충전료는 ‘TMAP 결제‘ 충전단가 기준으로 적용됩니다.<br/>

                아래 조건에 모두 해당되는 고객에 한해 혜택이 제공됩니다.<br/>
                <div className="list">
                  <li>· 본 신한카드 EVerywhere 카드를 티맵결제수단 내 ‘티맵결제’로 등록한 뒤 ‘TMAP 전기차 충전’을 결제한 고객<br/></li>
                  <li>· 신한카드 온라인 채널(PC, 모바일)을 통해 대상카드를 발급받은 고객<br/></li>
                  <li>· 최근 6개월 이내(2022.10.01~2023.04.09) 모든 신한 신용카드 이용 이력이 없는 고객<br/></li>
                  <li>· 행사월 포함 직전 1년간(혜택 지급월 기준, 2022년 4월~2023년 4월)<br/> 본채널을 포함한 신한카드의 다른 이벤트 혜택을 받은 적이 없는 고객
                  <br/>※ 다른 이벤트 예: 미이용회원 대상 이용 이벤트, 신규 회원 발급 이벤트, 친구추천 이벤트 등
                  </li>
                  <li>· 행사월 포함 직전 3개월 내(2023.01.01~2023.04.30)에 타 채널에서 카드 발급받지 않은 고객
                  <br/>※ 타 채널 예: 카드모집인, 은행채널(은행직원 추천, 쏠(SOL)앱, 영업점), 전화신청 등</li>
                </div>

                <div className="text-line">대상카드의 Everywhere 카드는 국내전용의 경우 연회비 1만 9천원, 해외겸용의 경우 연회비 2만 2천원이 적용됩니다.<br/></div>

                <div className="text-line">신한카드의 다른 이벤트와 중복 적용 불가능합니다.<br/></div>

                <div className="text-line">카드 이용과 응모하기 간에 선후 순서는 상관없으며, 반드시 응모한 휴대폰번호와 신한카드에 등록된 휴대폰번호가 동일해야 합니다.<br/></div>

                <div className="text-line">이용금액은 쿠폰적용/포인트 사용 등의 혜택금액을 제외한 최종 카드결제 금액 기준이며 이용기간까지 승인된 금액에 한합니다.<br/></div>

                <div className="text-line">이용금액이 승인거절, 승인취소, 부분취소, 매입취소, 반품/환불 등의 사유로 정상처리 되지 않을 시 합산 이용금액에 포함되지 않습니다.<br/></div>

                <div className="text-line">캐시백 지급시점 이전에 행사 대상카드를 해지/다른 카드로 교체/이용정지/분실/연체/카드폐기·반송 등의 사유로 정상 보유하지 않은 고객은 캐시백이 지급되지 않습니다.<br/></div>

                <div className="text-line">신한카드의 내부사정에 따라 사전고지 없이 이벤트가 중단 및 변경될 수 있으며, 본인 1인 1회에 한하여 제공됩니다.<br/></div>

                <div className="text-line">신한카드의 발급, 심사 기준에 따라 발급이 거절될 수 있습니다.<br/></div>

                <div className="text-line">계약 체결 전, 카드 상품별 연회비, 이용조건 등 상세사항은 금융상품설명서와 약관을 확인하시기 바랍니다.<br/></div>

                <div className="text-line">금융소비자는 금융소비자보호법 제19조 제1항에 따라 해당 금융상품 또는 서비스에 대하여 설명 받을 권리가 있습니다.<br/></div>

                <span className="bold-content">
                  <span style={{display:"block", lineHeight:"18px"}}>연체이자율은 '회원별, 이용상품별 약정금리+최대 연 3%, 법정 최고금리(연 20%)이내'에서 적용됩니다.<br/></span>
               
                  단, 연체 발생 시점에 약정금리가 없는 경우 아래와 같이 적용함<br/>
                  <div className="list">
                    <li style={{color:"#666", fontFamily:"TMOBI500"}}>· 일시불 거래 연체 시: 거래 발생 시점의 최소기간(2개월) 유이자 할부 금리</li>
                    <li style={{color:"#666", fontFamily:"TMOBI500"}}>· 무이자 할부 거래 연체 시: 거래 발생 시점의 동일한 할부 계약기간의 유이자 할부 금리</li>
                  </div>
                </span>
    

                <div className="text-line">신용카드 발급이 부적정한 경우(개인신용평점 낮음, 연체(단기 포함) 사유 발생 등), 카드발급이 제한될 수 있습니다.<br/></div>
                <div className="text-line">카드 이용대금과 이에 수반되는 모든 수수료는 고객님께서 지정하신 결제일에 상환하여야 합니다.<br/></div>

                <span className="bold-content" style={{display:"block", lineHeight:"10px"}}>
                  <span style={{display:"block", lineHeight:"18px"}}>상환능력에 비해 신용카드 사용액이 과도할 경우, 귀하의 개인신용평점이 하락할 수 있습니다.</span><br/>
                  <span style={{display:"block", lineHeight:"18px"}}>개인신용평점 하락 시 금융거래와 관련된 불이익이 발생할 수 있습니다.</span><br/>
                  <span style={{display:"block", lineHeight:"18px"}}>일정기간 신용카드 이용대금을 연체할 경우, 결제일이 도래하지 않은 모든 신용카드 이용대금을 변제할 의무가 발생할 수 있습니다.</span><br/>

                  <span style={{display:"block", lineHeight:"18px"}}>여신금융협회 심의필 제2023-C1h-03305호<br/>(2023.04.04~2024.04.03)</span>
                </span>
              </div>
            </>
            :
            <>
            </>
            }
          </div>
        </>
    )
}

export { ShinhanPromotion };
