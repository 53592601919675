
/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

//Mixpanel
import { logbox, mixpanel } from '../../../libs/logbox-mixpanel';

import { globalVars } from '../../../libs/global-vars';
import { appActions, store } from '../../../store';

import axios from 'axios';

/**-------------------------------------------------------------
1.2.2.1 충전 종료 화면_2 - 충전종료 팝업
-------------------------------------------------------------
> http://basic.pointweb.kr/charge/public/html/public/qr_charger2_popup.html
-------------------------------------------------------------*/
function FinishChargingPopup({ info, callback }: { info?: string, callback?: any }) {

	// 버튼 포커스
	const [focusOkBtn, setFocusOkBtn] = useState(false);
	const [focusCancelBtn, setFocusCancelBtn] = useState(false);

	useEffect(() => {
		mixpanel("evcharge:/evcharge/charging:view.chargestop_popup");
		logbox("/evcharge/charging", "tap.chargestop_popup");
	}, [])

	const history = useHistory();
	const onCloseBtn = () => {
		mixpanel("evcharge:/evcharge/charging:tap.chargestop_quit");
		logbox("/evcharge/charging", "tap.chargestop_quit");

		callback(false);
	};
	const onConfirmBtn = () => {
		mixpanel("evcharge:/evcharge/charging:tap.chargestop_ok");
		logbox("/evcharge/charging", "tap.chargestop_ok");

		callback(true);

		// history.push('/finishCharge');
	};

	return (
		<div style={{ lineHeight: "1.4" }}>
			<div className="popup-contents">
				<p>충전을 종료하시겠습니까?</p>
				<div>충전 종료는 최대 90초까지 시간이<br /> 소요될 수 있습니다. 종료 시 자동으로<br /> 충전요금이 결제됩니다.</div>
			</div>
			<div className="popup-bottom">
				<button type="button" onClick={onCloseBtn} onTouchStart={() => { setFocusOkBtn(true) }} onTouchEnd={() => { setFocusOkBtn(false) }} style={focusOkBtn ? { filter: "brightness(90%)" } : {}}>취소</button>
				<button type="button" onClick={onConfirmBtn} onTouchStart={() => { setFocusCancelBtn(true) }} onTouchEnd={() => { setFocusCancelBtn(false) }} style={focusCancelBtn ? { filter: "brightness(90%)", fontFamily: "TMOBI500" } : { fontFamily: "TMOBI500" }}>확인</button>
			</div>
		</div>
	);
}
export { FinishChargingPopup };

/*  적용할것.
	$(document).ready(function() {
	$('.close-popbutton').click(function () {
		$('.charge-popup').fadeOut('fast');
	});
	});
*/