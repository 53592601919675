/* eslint-disable */

import { getPayMeans } from "../apis/paymeans";
import { getProgress } from "../apis/progress";
import { getAuthStatus, getPayStatus } from "../apis/status";
import { appActions, store } from "../store";
import { tmapInterface } from "./tmap-interface";

export const retrieveCardInfo = async () => {
    const result = await getPayMeans()
        .then((response) => {
            return response;
        })
        .catch((err) => {
            handleCardType('N', '', '', '결제수단 미선택');
            return err;
        });

    switch (result?.status) {
        case 'OK':
            if (result.paymentType === 'CARD') {
                handleCardType('CARD', result.card?.cardNumberDisplay, result.card?.cardCompany);
            } else if (result.paymentType === 'SK_PAY') {
                handleCardType('SK_PAY', result.pay?.displayNumber, result.pay?.payCompany);
            } else if (result.paymentType === 'KB_PLCC') {
                handleCardType('KB_PLCC', result.pay?.displayNumber, result.pay?.payCompany);
            } else if (result.paymentType === 'KB_PAY') {
                handleCardType('KB_PAY', result.pay?.displayNumber, result.pay?.payCompany);
            } else {
                handleCardType('N');
            }
            return true;
        case 'NO_CARD':
        case 'NO_PAY':
        case 'NO_PAY_MEANS':
        case 'NO_PIN':
            handleCardType('N');
            return true;
        case 'CARD_EXPIRED':
            handleCardType('N', '', '', '카드 유효기간 만료');
            return true;
        case 'CARD_MAINTENANCE':
        case 'PAY_MAINTENANCE':
            handleCardType('N', '', '', '카드 점검 중');
            return true;
        case 'CORPCARD_AUTH_EXPIRED':
            handleCardType('N', '', '', '법인공용카드 인증만료');
            return true;
        default:
            handleCardType('N', '', '', '신용/체크카드 등록하기');
            return true;
    }
}

function handleCardType(paymentType: string, cardNumberDisplay?: string, cardCompany?: any, selectedCard?: string) {
    if (paymentType === 'CARD' || paymentType === 'SK_PAY' || paymentType === 'KB_PLCC'|| paymentType === 'KB_PAY') {
        store.dispatch(appActions.setPayType('Y'));
        store.dispatch(appActions.setPayNumber(cardNumberDisplay)); // 카드번호 (**00)
        store.dispatch(appActions.setPayTypeNm(cardCompany?.companyName)); // 카드사명
        store.dispatch(appActions.setPayImgUrl(cardCompany?.ciImageUrl)); // 카드 이미지 
        store.dispatch(appActions.setPayImgBg(cardCompany?.bgColor)); //카드 배경색상
        if (paymentType === 'CARD' || paymentType === 'KB_PLCC') {
            store.dispatch(appActions.setPayKind('CARD')); //카드 종류
        } else if (paymentType === 'SK_PAY') {
            store.dispatch(appActions.setPayKind('PAY')); //카드 종류
        } else if (paymentType === 'KB_PAY') {
            store.dispatch(appActions.setPayKind('KB_PAY')); //카드 종류
        }
        store.dispatch(appActions.setIsCard('selected')); //카드 선택여부
        store.dispatch(appActions.setBselectedCard('true')); //카드 선택여부
    } else {
        store.dispatch(appActions.setPayType('N'));
        store.dispatch(appActions.setPayNumber(''));
        store.dispatch(appActions.setPayTypeNm(''));
        store.dispatch(appActions.setPayImgUrl(''));
        store.dispatch(appActions.setPayImgBg(''));
        store.dispatch(appActions.setPayKind(''));
        store.dispatch(appActions.setIsCard('NoFocus'));
        store.dispatch(appActions.setBselectedCard(selectedCard || '신용/체크카드 등록하기'));
    }
}

let previousProgressData: any = [];

export const progressFinishGuid = async (status: string) => {
    try {
        if (status === "start") {
            previousProgressData = [];
        }

        const response: any = await getProgress();
        if (!response.result) {
            return null;
        }

        const currentProgressData = response.inProgressCharges;
        const finishedGuids = findFinishedGuids(previousProgressData, currentProgressData);

        const authenticatedGuids = await authFinishGuid(finishedGuids);

        // 추가: 결제 상태를 확인하고 필터링
        const paymentCompletedGuids = await payFinishGuid(authenticatedGuids);

        previousProgressData = currentProgressData;

        return paymentCompletedGuids.filter((result) => result !== null);
    } catch (error) {
        throw error;
    }
}

const findFinishedGuids = (previousData, currentData) => {
    const previousGuids = previousData.map((charge) => charge.guid);
    const currentGuids = currentData.map((charge) => charge.guid);

    return previousGuids.filter((guid) => !currentGuids.includes(guid));
}

export const authFinishGuid = async (guids: any) => {
    try {
        const results = await Promise.all(
            guids.map(async (guid) => {
                try {
                    const result = await authGuid(guid);
                    return result;
                } catch (error) {
                    return null;
                }
            })
        );
        return results.filter((result) => result !== null);
    } catch (error) {
        throw error;
    }
}

export const authGuid = async (guid: any) => {
    try {
        const response: any = await getAuthStatus(guid);
        if (response.authStatus == 6) {
            return guid;
        }
        return null;
    } catch (error) {
        throw error;
    }
}

const payStatusDelay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export const payFinishGuid = async (guids: any) => {
    await payStatusDelay(2000);

    try {
        const results = await Promise.all(
            guids.map(async (guid) => {
                try {
                    const result: any = await getPayStatus(guid);
                    if (result.payStatus == 5) {
                        return guid;
                    }
                    return null;
                } catch (error) {
                    return null;
                }
            })
        );
        return results.filter((result) => result !== null);
    } catch (error) {
        throw error;
    }
};

export const handleCopyClipBoard = async (text: any) => {
    try {
        await navigator.clipboard.writeText(text);
        tmapInterface.makeToast('복사되었습니다.');
        // alert('클립보드에 복사되었습니다.'+ak);
    } catch (e) {
        // alert('복사에 실패하였습니다');
        tmapInterface.makeToast('복사에 실패하였습니다.');
    }
};