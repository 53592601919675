/* eslint-disable */


import qs from 'qs';
import { forEach, map } from 'lodash-es';
import { eventBus } from './event-bus';
// import { modal } from '../components/GlobalModal';
import { appActions, store } from '../store';

let uid = 0;
window.TmapApp = window.TmapApp || {};
window.TmapWebView = window.TmapWebView || {};
window.TmapWebView.nativeCallback = {};
// 디바이스. 서비스마다 callback 호출시 TmapWebView 붙임여부다 다름. 
// 자기참조 추가하여 호출 오류 방지
window.TmapWebView.TmapWebView = window.TmapWebView;

const userAgentLower = navigator.userAgent.toLowerCase();
const isIOS = userAgentLower.indexOf('ios') !== -1;
const iframeId = '__tmap_custom_url_call__';

const tmapInterface = {  

  nativeCall(method: string, params?: any) {
    if (isIOS) {
      let iframe: Nullable<HTMLIFrameElement> = document.querySelector(`#${iframeId}`);
      if (!iframe) {
        iframe = document.createElement('IFRAME') as HTMLIFrameElement;
        iframe.id = iframeId;
        iframe.setAttribute('frameborder', '0');
        iframe.setAttribute('width', '0');
        iframe.setAttribute('height', '0');
        iframe.style.display = 'none';
        document.body.appendChild(iframe);
      }
      const paramString = qs.stringify(params, { skipNulls: true });
      const customUrl = `tmapjs://${method}${paramString ? `?${paramString}` : ''}`;
      iframe.setAttribute('src', customUrl);
    } else {
      if (window.TmapApp[method]) window.TmapApp[method](...map(params, val => val));
      // else alert(`NotFound interface - ${method}`);
    }
  },

  nativeCall2(method: string, params?: any) {
    if (isIOS) {
      let iframe: Nullable<HTMLIFrameElement> = document.querySelector(`#${iframeId}`);
      if (!iframe) {
        iframe = document.createElement('IFRAME') as HTMLIFrameElement;
        iframe.id = iframeId;
        iframe.setAttribute('frameborder', '0');
        iframe.setAttribute('width', '0');
        iframe.setAttribute('height', '0');
        iframe.style.display = 'none';
        document.body.appendChild(iframe);
      }
      
      const paramString = qs.stringify(params, { skipNulls: true });
      const customUrl = `tmap://${method}${paramString ? `?${paramString}` : ''}`;
     // alert(customUrl);
      iframe.setAttribute('src', customUrl);
    } else {
      if (window.TmapApp[method]) {window.TmapApp[method](...map(params, val => val));
      //alert(window.TmapApp[method]);
      }
      else console.log(`NotFound interface - ${method}`);
    }
  },
  nativeCallWeb(method: string, params?: any) {
    if (isIOS) {
      let iframe: Nullable<HTMLIFrameElement> = document.querySelector(`#${iframeId}`);
      if (!iframe) {
        iframe = document.createElement('IFRAME') as HTMLIFrameElement;
        iframe.id = iframeId;
        iframe.setAttribute('frameborder', '0');
        iframe.setAttribute('width', '0');
        iframe.setAttribute('height', '0');
        iframe.style.display = 'none';
        document.body.appendChild(iframe);
      }
      
      const paramString = qs.stringify(params, { skipNulls: true });
      const customUrl = `tmapweb://${method}${paramString ? `?${paramString}` : ''}`;
      //alert(customUrl);
      iframe.setAttribute('src', customUrl);
    } else {
      if (window.TmapApp[method]) window.TmapApp[method](...map(params, val => val));
      
      else console.log(`NotFound interface - ${method}`);
    }
  },
  nativeCallback: window.TmapWebView.nativeCallback,
  generateEventName() {
    return `event_${uid++}`;
  },
  addNativeCallbackListener(callback: (...args: any[]) => void, uniqueKey: string = '') {
    if (uniqueKey) {
      forEach(this.nativeCallback, (callback, eName) => {
        if (eName.startsWith(uniqueKey)) delete this.nativeCallback[eName];
      });
    }
    const eventName = uniqueKey ? `${uniqueKey}_${this.generateEventName()}` : `${this.generateEventName()}`;
    this.nativeCallback[eventName] = (...result: any[]) => {
      callback.call(null, ...result);
      delete this.nativeCallback[eventName];
    };
    return `TmapWebView.nativeCallback.${eventName}`;
  },
  purchaseProduct(productId: string, price: number, cdn: string) {
    const params = { productId, price, cdn };
    console.log('web - purchaseProduct', JSON.stringify(params));
    this.nativeCall('purchaseProduct', params);
  },
  setVoiceGuideType(guideType: string, name: string, cdn: string, thumbnailUrl: string) {
    const params = { guideType, name, cdn, thumbnailUrl };
    console.log('web - setVoiceGuideType', JSON.stringify(params));
    this.nativeCall('setVoiceGuideType', params);
  },
  getVoiceGuideType(callback: (s: string) => void) {
    const callJS = this.addNativeCallbackListener(callback, 'guideType');
    const params = { callJS };
    this.nativeCall('getVoiceGuideType', params);
  },
  playTTS(guideType: string, cdn: string, ttsMessage: string, callback: Nullable<() => void>) {
    const callJS = typeof callback === 'function' ? this.addNativeCallbackListener(callback, 'playTTS') : 'TmapWebView.onStopTTS';
    const params = { guideType, cdn, ttsMessage, callJS };
    console.log('web - playTTS', JSON.stringify(params));
    this.nativeCall('playTTS', params);
  },
  stopTTS() {
    console.log('web - stopTTS');
    this.nativeCall('stopTTS');
  },
  onBackKeyPressed(params = { errorCode: '', errorMsg: '' }) {
    console.log('web - onBackKeyPressed');
    this.nativeCall('onBackKeyPressed', params);
  },
  openBrowser(url: string) {
    this.nativeCall('openBrowser', { url });
  },
  getDisplayInfo(callback?: () => any) {
    const callbackJS =
      typeof callback === 'function' ? this.addNativeCallbackListener(callback, 'displayInfo') : 'TmapWebView.onGetDisplayInfo';
    this.nativeCall('getDisplayInfo', { callbackJS });
  },
  
  makeToast(msg: string) {
    this.nativeCall('makeToast', { msg });
  },
  
  updateAccessKey(key: string) {
    this.nativeCall('updateAccessKey', { key });
  },

  getAccessKey(callback?: (s:string) => void) {
    if(isIOS) {
      const callbackJS = typeof callback === 'function' ? this.addNativeCallbackListener(callback, 'accessKey') : 'TmapWebView.onAccessKey';
    
      const params = { callbackJS};
      
      this.nativeCallWeb('getAccessKey', params);
    } else {
      const params = "";
      if(window.TmapApp['getAccessKey']) {
        return window.TmapApp['getAccessKey'](...map(params, val => val));
      }
    }    
  },

  getUserSetting(callback?: (s:string) => void) { 
    const key = 'local.server_type';
    
    if(isIOS) {
      const callbackJS = typeof callback === 'function' ? this.addNativeCallbackListener(callback, 'userSetting') : 'TmapWebView.onUserSetting';
      
      const params = { key, callbackJS};

      this.nativeCallWeb('getUserSetting', params);

    } else {
      const params = { key };

      if(window.TmapApp['getUserSetting']) {
        return window.TmapApp['getUserSetting'](...map(params, val => val));
      }
    }    
  },


  startPaymentActivity(callback: (s:string, t:string) => void) {
    
    const url = "/payment/card/update?productionid=EVCHARGING";
    
    // 안드로이드 : selectPin, IOS : onSelectPin
    const callbackJS = typeof callback === 'function' ? this.addNativeCallbackListener(callback, 'selectCard') : 'TmapWebView.onSelectCard';
    
    const params = { url, callbackJS };
    
    this.nativeCallWeb('startPaymentActivity', params);
  },

  
  startPaymentRetryActivity(orderId:string, callback: (s:string, t:string) => void) {
    
    const url = "/payment/card/update?orderid=" + orderId + "&ordertype=retry&productionid=EVCHARGING";
    
    const callbackJS = typeof callback === 'function' ? this.addNativeCallbackListener(callback, 'selectCard') : 'TmapWebView.onSelectCard';
    
    const params = { url, callbackJS };

    this.nativeCallWeb('startPaymentActivity', params);
  },


  startPinActivity(orderId:string, callback: (s:string, t:string) => void) {
    
    const url = `/payment/pin?productionid=EVCHARGING&orderid=${orderId}`;

    // 안드로이드 : selectPin, IOS : onSelectPin
    const callbackJS = typeof callback === 'function' ? this.addNativeCallbackListener(callback, 'selectPin') : 'TmapWebView.onSelectPin';

    const params = { url, callbackJS };

    this.nativeCallWeb('startPaymentActivity', params);
  },

  startQrCodeScanActivity(callback: (s:string, t:string) => void) {
    const title = '전기차충전';
    const showBottom = false;

    // 안드로이드 : selectPin, IOS : onSelectPin
    const callbackJS = typeof callback === 'function' ? this.addNativeCallbackListener(callback, 'QrCodeScan') : 'TmapWebView.onQrCodeScan';

    const params = { title, showBottom, callbackJS };

    this.nativeCallWeb('startQrCodeScanActivity', params);
  },

  startPoiDetailActivity(callback?: (s:string, t:string) => void) {
    
    const poiId = store.getState().app.poiId;
    const navX = store.getState().app.navX;
    const navY = store.getState().app.navY;
    const pkey = store.getState().app.pkey;
    // const poiId = '5457254';
    // const navX = '4571143';
    // const navY = '1351759';
    // const pkey = '545725401';
    //const showBottom = false;

    // 안드로이드 : selectPin, IOS : onSelectPin
    const callbackJS = typeof callback === 'function' ? this.addNativeCallbackListener(callback, 'poiDetail') : 'TmapWebView.onPoiDetail';

    const params = { poiId, navX, navY, pkey, callbackJS };

    this.nativeCallWeb('showPoiDetailInfo', params);
  },

  startTSearchActivity(callback: (s:string, t:string) => void) {
    const poiId = '5457254';
    const navX = '4571143';
    const navY = '1351759';
    const pkey = '545725401';
    const tailParam = '%7B%22log_expose_referrer%22%3A%22ev_panel%22%2C%22log_app_referrer%22%3A%22ev_panel%22%7D';
    //const showBottom = false;

    // 안드로이드 : selectPin, IOS : onSelectPin
    const callbackJS = typeof callback === 'function' ? this.addNativeCallbackListener(callback, 'poiDetail') : 'TmapWebView.onTSearch';

    const params = { poiId, navX, navY, pkey, tailParam, callbackJS };

    this.nativeCallWeb('openServiceByUrl', params);
  },

  getIosSessionId(callback: (s:string) => void) {
    const callbackJS = typeof callback === 'function' ? this.addNativeCallbackListener(callback, 'getSessionId') : 'TmapWebView.onSessionId';

    const params = { callbackJS };

    this.nativeCallWeb('getSessionId', params);
  },

  getAosSessionId() {
    
    const params = "";

    return window.TmapApp['getSessionId'](...map(params, val => val));

  },


  searchIOS() {
    // tmap://search?name=티맵플러그

    const name = '티맵플러그'
    const params = { name };
    this.nativeCall2('search', params);
  },

  searchStationsbyIOS() {
    // "tmap://ev-search?extra='"' {"tmap_pay":"Y"}'"'"
    const extra = '{"tmap_pay":"Y"}';
    const params = { extra };
    this.nativeCall2('ev-search', params);
  },


  searchAOS( callback: (s:string, t:string) => void ) {
    const keyword = '티맵플러그';
    const searchFrom = 'main';
    
    // const callbackJS = this.addNativeCallbackListener(callback, 'searchPOIs');

    const callbackJS = typeof callback === 'function' ? this.addNativeCallbackListener(callback, 'searchPOIs') : 'TmapWebView.onSearchPOIs';

    const params = { keyword, callbackJS, searchFrom };

    this.nativeCallWeb('search', params);
  },

  

  openFuelSetView() {
    const serviceName = 'carprofile'
    const jsonData = ''
    const params = { serviceName, jsonData }

    this.nativeCallWeb( 'openServiceByName', params );
  },

  searchStations() {
    const serviceName = 'ev-search';
    const jsonData = '{"fixed_portrait":"Y","extra":{"speed":"", "charging_type":"", "status":"", "tmap_pay":"Y"}}';
    const params = { serviceName, jsonData };
    this.nativeCallWeb( 'openServiceByName', params );
  },


  tSearchNearby(centerLat, centerLon, userLat, userLon) {
    const url = `https://search.tmap.co.kr/app/place/category?type=OILEV&tailParam=%7B%22log_all_referrer%22%3A%22ev_panel%22%7D&centerLat=${centerLat}&centerLon=${centerLon}&userLat=${userLat}&userLon=${userLon}&defaultZoom=13.478720466001478`
    //const url = 'https://search.tmap.co.kr/app/place/category?type=OILEV&tailParam=%7B%22log_expose_referrer%22%3A%22mainhome%22%2C%22log_app_referrer%22%3A%22mainhome%22%257&centerLat=37.299695603684015&centerLon=127.14620669230695&userLat=37.3018477&userLon=127.1504475&defaultZoom=13.478720466001478'
    const title = '';
    const callbackJS = '';
    const cacheControl = true
    const params = { url, title, callbackJS, cacheControl };
    this.nativeCallWeb('openServiceByUrl', params);
  },

  tChargeDelivery(chargeDeliveryUrl: string) {
    const url = chargeDeliveryUrl;

    const title = "";
    const callbackJS = "";
    const cacheControl = true;
    const params = { url, title, callbackJS, cacheControl };

    this.nativeCallWeb("openServiceByUrl", params);
  },

  getCurrentPosition( callback: (s:string, t:string) => void ) {
    
    // const callbackJS = this.addNativeCallbackListener(callback, 'searchPOIs');

    const callbackJS = typeof callback === 'function' ? this.addNativeCallbackListener(callback, 'getCurrentPosition') : 'TmapWebView.getCurrentPosition';

    const params = { callbackJS };

    this.nativeCallWeb('getCurrentPosition', params);
  },

  getLastPosition( callback: (s:string, t:string) => void ) {
    
    // const callbackJS = this.addNativeCallbackListener(callback, 'searchPOIs');

    const callbackJS = typeof callback === 'function' ? this.addNativeCallbackListener(callback, 'getLastPosition') : 'TmapWebView.getLastPosition';

    const params = { callbackJS };

    this.nativeCallWeb('getLastPosition', params);
  },

  getEUK(callback?: (s:string) => void) {

    const callbackJS = typeof callback === 'function' ? this.addNativeCallbackListener(callback, 'EUK') : 'TmapWebView.onEUK';

    const params = { callbackJS };

    this.nativeCallWeb('getEUK', params);

  },

  getAosEUK() {

    const params = "";

    return window.TmapApp['getEUK'](...map(params, val => val));

  },

  startPayPointActivity() {
    const serviceName = 'paypoint';
    const jsonData = '';
    const params = { serviceName, jsonData };
    this.nativeCallWeb( 'openServiceByName', params );
  },
    
  // searchStations() {
  //   const url = 'tmap://ev-search?fixed_portrait=Y&extra={"tmap_pay":"Y"}';
  //   const title = '';
  //   const callbackJS = '';
  //   const params = { url, title, callbackJS };
  //   this.nativeCallWeb( 'openServiceByUrl', params );
  // },
};

// app >> web 방향 callback 수동정의
Object.assign(window.TmapWebView, {
  // 결제완료시
  purchaseProductComplete(isOK: string, productId: string, productDataString: string) {
    eventBus.emit('nativeCallback.purchaseProductComplete', isOK === 'true', productId, productDataString);
  },
  onStopTTS() {
    eventBus.emit('nativeCallback.onStopTTS');
  },
  onGetDisplayInfo(dataStr: string) {
    eventBus.emit('nativeCallback.onGetDisplayInfo', JSON.parse(dataStr));
  },
  onSelectPin(s: string, t: string) {
    eventBus.emit('nativeCallback.selectPin', s, t);
  },
  onQrCodeScan(s: string, t: string) {
    eventBus.emit('nativeCallback.QrCodeScan', s, t);
  },
  onSelectCard(s: string, t: string) {
    eventBus.emit('nativeCallback.selectCard', s, t);
  },
  onSetFuelType(s: string, t: string) {
    eventBus.emit('nativeCallback.setFuelType', s, t);
  },
  onSearchPOIs(s: string, t: string) {
    eventBus.emit('nativeCallback.searchPOIs', s, t);
  },
  onUserSetting(s: string) {
    eventBus.emit('nativeCallback.userSetting', s);
  },
  onResume(s: string) {
    if(store.getState().app.resume as string === "Y"){
      store.dispatch(appActions.setResume("N"));
      if(store.getState().app.resumeIdx as string === "0"){
        tmapInterface.onBackKeyPressed();
      }
      eventBus.emit('nativeCallback.onResume', s);
    }
  },
  onAccessKey(s: string) {
    eventBus.emit('nativeCallback.onGetAccessKey', s);
  },
  onEUK(s: string) {
    eventBus.emit('nativeCallback.EUK', s)
  },
});

export { tmapInterface };
