/* eslint-disable */

import instance from './frontman';

//길안내 주행모드
export const getDriving = async (params: String, accessToken?: String) => {
    return await instance({
        url: "/driving/on-the-road",
        method: "get",
        params: {
            poiId : params
        }
    })
};