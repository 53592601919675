/* eslint-disable */

import React, { useState, useEffect } from "react";
import { Link, RouteComponentProps } from "react-router-dom";

import { tmapInterface } from "../../libs/tmap-interface";
import { globalVars } from "../../libs/global-vars";
import { utils } from "../../libs/utils";

// Mok APi
//import { tmapApi } from '../../libs/tmap-api';

// 헤더
import { Header } from "../../components/Layout";
import { HandleBrowserBackButton } from "../../components/Layout";

import { appManager } from "../../managers";

// Util
import { isEmpty, isBoolean } from "lodash-es";
import { get } from "lodash-es";

// AxioS
import axios from "axios";
import Const from "../../store/ev";
import { appActions, store } from "../../store";
import { AccessKey } from "../../components/DebugTools/Panel/Items";
import { modal } from "../../components/GlobalModal";

//Mixpanel
import { logbox, mixpanel } from '../../libs/logbox-mixpanel';

// 전역
let userData: any = {}; // 회원정보조회(단건)
let cardInfo : any = {};	// 카드정보
let timer: Nullable<number> = null; //타이머

//const [inited, setInited] = useState(true)

// 팝업
// const [evPopVisble    , setEvPopVisble]   = useState(false);   // 유종변경 선택 팝업 Popup (숨김)

// // 상태값

// const [remainPoint    , setRemainPoint]   = useState("");       // 쿠폰 잔액 :userData.remain_point
// const [couponName   , setCouponName]   = useState("");       // 쿠폰 잔액 :userData.coupon_name

// const [couponFirst    , setCouponFirst] 	= useState(false);   // 쿠폰 우선사용 여부 : userData.coupon_first_yn

// const [payType        , setPayType]       = useState('N');     // 결제수단 : TODO
// const [payTypeNm      , setPayTypeNm]     = useState('');     // 결제수단 명 : TODO
// const [payImageUrl 	, setPayImageUrl]   = useState('');     // 결제수단 이미지 CI

// const [isCard 	, setIsCard]   = useState('NoFocus');
// const [payImageBg 	, setPayImageBg]   = useState('transparent');     // 결제수단 이미지 CI
// const [bselectCard    , setBselectCard]   = useState('');
// const [Nonpay    , setNonpay]   = useState(false);
// const [NowCharging    , setNowCharging]   = useState(false);
// const [disabledYn, setDisabledYn] = useState(true); // disabled 여부

// const [startButton, setStartButton] = useState(false);

let scrollCache = 0;
/**-------------------------------------------------------------
1.2.1.3 메인 진입 전 화면
-------------------------------------------------------------**/

function PoiDetailRouter(props: RouteComponentProps<{ id: string }>) {

  useEffect(() => {
    appManager.globalLoadingInc();

    window.setTimeout(() => {
      tmapInterface.startQrCodeScanActivity(QrCodeScan);
    },500)
  },[])

  function QrCodeScan(status: string, qrcode: string) {
    // alert('QrCode - ' + status + '\n' + JSON.stringify(qrcode));
    appManager.globalLoadingReset();

    if (Const.DBG_OK) {
      alert("QrCode" + status + " " + JSON.stringify(qrcode));
    }
    //qr코드가 '' 빈값일때 qr인증실패로 이동
    if (status === "COMPLETE") {
      if (!qrcode) {
        props.history.replace("/authFail");
      } else {
        store.dispatch(appActions.setQrCode(qrcode));

        props.history.replace("/qrAuth");
      }
    } else {
      mixpanel("evcharge:/evcharge/qrscan:tap.back");
      logbox("/evcharge/qrscan","tap.back");
      
      tmapInterface.onBackKeyPressed();
    }
  }

  return (
    <>
    </>
  )
}
export { PoiDetailRouter };
