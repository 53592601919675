/* eslint-disable */
import instance from './frontman';

//사용이력 조회
export const getHistory = async (params: String, params2:String, accessToken?: String) => {
    return await instance({
        url: "/history",
        method: "get",
        params : {
            startDate : params,
            endDate : params2,
        }
    })
};

//사용이력 상세조회
export const getHistoryDetail = async (params: String, params2: Boolean, accessToken?: String) => {
    return await instance({
        url: `/history/${params}`,
        method: "get",
        params : {
            repaidYn : params2,
        }
    })
};