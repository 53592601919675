import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
    applicationId: 'eec00e59-a93e-4149-a4d1-bafd0761ef7d',
    clientToken: 'pub5ab6a0909fed9d979a6425817858e9a3',
    site: 'datadoghq.com',
    service: 'evcharge-web',
    env: process.env.REACT_APP_BUILD_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog 
    // version: '1.0.0',
    sessionSampleRate: 10,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input'
});

datadogRum.startSessionReplayRecording();

export { datadogRum };