/* eslint-disable */
import { useEffect, useCallback } from "react";
import { RouteComponentProps } from 'react-router-dom';
import { tmapInterface } from "../../libs/tmap-interface";
import { globalVars } from "../../libs/global-vars";
import { postNonCode } from "../../apis/coupon";
import { modal } from "../../components/GlobalModal";

let scrollCache = 0;
let serviceType = (globalVars.queryData.extra?.serviceType)?.toLowerCase() || (globalVars.queryData.extra?.servicetype)?.toLowerCase();


function PlugLinkPromotion(props: RouteComponentProps) {
  const cacheScroll = useCallback(() => {
    if (!/\/voice\/?$/.test(window.location.pathname)) return;
    scrollCache = window.scrollY;
  }, []);

  useEffect(() => {
		window.scrollTo(0, scrollCache);
  }, []);

  useEffect(() => {
		window.addEventListener('scroll', cacheScroll);
		return () => window.removeEventListener('scroll', cacheScroll);
  }, [cacheScroll]);

  const goBack = () => {
		if (serviceType === 'pluglink') {
			serviceType = '';
			tmapInterface.onBackKeyPressed();
		} else {
			props.history.goBack();
		}
  }

  //쿠폰 발급 버튼
  const plugLinkCouponRegist = async () => {
    await postNonCode({
      "productKey": "PL-RATE-001"
    }).then((res:any) => {
      if (res.result) {
        modal.alert(() => {
          goRateCoupon();
        }, "쿠폰이 발급 되었습니다.<br/>할인 쿠폰 리스트를<br/>확인해주세요.");
      } else {
        switch (res.reason.slice(-1)) {
          case '2':
            modal.alert(() => {
              goRateCoupon();
            }, "이미 쿠폰을 발급 받으셨습니다.<br/>할인 쿠폰 리스트를 확인해주세요.");
            break;
          case '4':
            modal.alert(() => {
            }, "준비한 쿠폰이 모두 발급 되었습니다.");
            break;
          default:
            modal.alert(() => {
            }, "쿠폰등록에 실패하였습니다. 다시 시도해 주세요");
            break;
        }
      }
    }).catch((err) => {
      console.log(err);
      modal.alert(() => {
      }, "쿠폰등록에 실패하였습니다. 다시 시도해 주세요");
    })
  }

  const goRateCoupon = () => {
    props.history.replace('/rateCoupon');
  }

  return (
    <>
      <div className="pluglink-promotion">
        <button type="button" className="back-btn" onClick={goBack}>
          <img src={require('%/images/common/ic_gnb_back.svg').default} alt="pluglink-promotion-main" />
        </button>
        <img src={require('%/images/promotion/img_pluglink_main.svg').default} alt="" style={{ position: "relative", display: "block", width: "100%", verticalAlign: "center" }} />
        <div className="coupon" style={window.innerWidth < 340 ? {bottom:"80px"}:{}}>
          <button type="button" className="coupon-btn" onClick={plugLinkCouponRegist}>쿠폰 다운로드</button>
        </div>

        <div className="description">
          <h1>사용방법</h1>
          <div className="list" style={window.innerWidth < 340 ? {padding:"5px 30px"}:{}}>
            <img src={require('%/images/promotion/icon_pluglink_coupon.svg').default} />
            <div className="li-detail">
              <h3>1.쿠폰발급</h3>
              <span>상단 다운로드 버튼을 눌러 쿠폰을 발급 받아주세요</span>
            </div>
          </div>
          <div className="list" style={window.innerWidth < 340 ? {padding:"5px 30px"}:{}}>
            <img src={require('%/images/promotion/icon_pluglink_search.svg').default} />
            <div className="li-detail">
              <h3>2.충전소 찾기</h3>
              <span>운영기관 필터를 눌러 플러그링크 로고를 선택해주세요</span>
            </div>
          </div>
          <div className="list" style={window.innerWidth < 340 ? {padding:"5px 30px"}:{}}>
            <img src={require('%/images/promotion/icon_pluglink_charge.svg').default} />
            <div className="li-detail">
              <h3>3.충전소 방문</h3>
              <span>충전 대기중인 플러그링크 충전소를 방문해주세요</span>
            </div>
          </div>
          <div className="list" style={window.innerWidth < 340 ? {padding:"5px 30px"}:{}}>
            <img src={require('%/images/promotion/icon_pluglink_card.svg').default} />
            <div className="li-detail">
              <h3>4.티맵으로 결제</h3>
              <span>발급 받은 쿠폰을 사용하여 충전을 진행해주세요</span>
            </div>
          </div>
        </div>

        <div className="info" style={{padding: window.innerWidth > 640 ? "40px":""}}>
          <div className="guide">
            <h3>참여 안내</h3>
            <div style={{textIndent: window.innerWidth > 640 ? "-34px":"-13px", listStylePosition:"inside"}}>
                <li>기간 : 2024.07.24(수) ~ 2024.08.31(토)</li>
                <li>대상 : 티맵 결제 수단을 등록한 전기차충전 서비스 사용자</li>
              </div>
          </div>
          <div className="etc">
            <h3>기타 유의사항</h3>
            <div style={{textIndent: window.innerWidth > 640 ? "-34px":"-13px", listStylePosition:"inside"}}>
              <li>발급 받은 쿠폰은 티맵과 결제 연동 된 플러그링크 충전기에서만 사용 가능해요.</li>
              <li>쿠폰은 한 사람당 한장만 발급 받으실 수 있어요.</li>
              <li>사용기간이 지난 쿠폰은 사라지며 별도의 환불 및 보상 지급을 하지 않아요.</li>
              <li>발급 받은 쿠폰은 전기차충전 서비스 내 결제수단 설정 화면에서 확인하실 수 있어요.</li>
              <li style={{marginLeft:"15px"}}>{"결제수단 설정 화면 > 할인 쿠폰"}</li>
              <li>본 이벤트로 받은 할인 쿠폰과 선불충전권은 동시에 사용 가능하며, 충전 요금에서 할인 쿠폰이 먼저 적용돼요.</li>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { PlugLinkPromotion };