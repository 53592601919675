/**-------------------------------------------------------------

-------------------------------------------------------------*/

/* eslint-disable */
import { useState, useEffect, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { appActions, store } from '../../store';
import axios from 'axios';
import Const from '../../store/ev';
import moment from 'moment';
import "moment/locale/ko";

import { globalVars } from '../../libs/global-vars';
import { tmapInterface } from "../../libs/tmap-interface";
import _ from 'lodash';

import { Map, MapPositionCompatible, CustomMarker } from "@vsm/react-vsm";

//Mixpanel
import { logbox, mixpanel } from '../../libs/logbox-mixpanel';
import { modal } from "../../components/GlobalModal";
import { getDriving } from '../../apis/poi';
import { getAuthStatus, getUserStatus } from '../../apis/status';

import { isBoolean } from "lodash-es";
import { getPayMeans } from '../../apis/paymeans';

import { BottomSheet, BottomSheetRef } from "react-spring-bottom-sheet";
import { getTapCharge, getTapChargeRadius } from '../../apis/taptap';
import { utils } from '../../libs/utils';
import { retrieveCardInfo } from '../../libs/commonFunction';

import Lottie from "lottie-react";
import QrAnimation from "./qr_icon.json";
import { getCpos } from '../../apis/cpo';

let timer: Nullable<number> = null; // 타이머

let momentyInfo: any = {}
let cardInfo: any = {}; // 카드정보

function DestinationCall(props: RouteComponentProps<{ x: string }>) {
    const isPolestar = store.getState().app.isPolestar;

    const [position, setPosition] = useState<MapPositionCompatible>({ //중심좌표
        center: [0.0, 0.0], //127.0, 37.5  126.9556914, 37.3834682
        zoom: 14,
        bearing: 0,
        pitch: 0,
    });

    const [stationData, setStationData] = useState({
        roadMap: "",
        fullJibunAddr: "",
        poiNameSvc: "",
        operatorId: "",
        wgs84CenterY: 0,
        wgs84CenterX: 0,
    });

    const [inited, setInited] = useState(false);
    const [payType, setPayType] = useState("N"); // 결제수단 : TODO

    const [seqList, setSeqList] = useState<any>([]);
    const [bPop, setBPop] = useState(false); //충전 시작 팝업
    const [chargeStart, setChargeStart] = useState([]) as any;
    const [firstClick, setFirstClick] = useState(false);
    const [focusCharger, setFocusCharger] = useState("");
    const [noCharge, setNoCharge] = useState(false);
    const [noOpen, setNoOpen] = useState(false);

    const [payCount, setPayCount] = useState(0); //결제수단 조회

    //pressed
    const [focusCurrntStatus, setFocusCurrentStatus] = useState(false);
    const [focusCharging, setFocusCharging] = useState(false);

    const [chargerList, setChargerList] = useState({
        roadMap: "",
        orgName: "",
        fullAddressJibun: "",
        infoList: [
            {
                operatorId: "",
                operatorName: "",
                stationId: "",
                chargerId: "",
                status: "",
                chargerSpeed: "",
                type: "",
                powerType: "",
                chargingDateTime: "",
                updateDateTime: "",
                couplerSeq: "",
                stationImagePath: "",
                available: true,
                fast: true
            }
        ]
    });

    const sheetRef = useRef<BottomSheetRef>(null);

    const [clickBottomSheetList, setClickBottomSheetList] = useState(false);

    const [noDriving, setNoDriving] = useState(false);
    const [cpoList, setCpoList] = useState([]) as any;

    useEffect(() => {
        if (payCount > 0) {
            setPayType(store.getState().app.payType);
        }
    }, [payCount]);

    const getPayMethod = () => {
        const promise = retrieveCardInfo();
        const getData = () => {
            promise.then((result) => {
                if (result) {
                    setPayCount(payCount + 1)
                }
            });
        };
        getData();
    }

    useEffect(() => {
        momentyInfo = store.getState().app.stationInfo;

        getPayMethod();
    }, []);

    useEffect(() => {
        if (!momentyInfo?.ev_chargers || !momentyInfo) {
            setNoOpen(true);
            roadMapInfo();

            // modal.alert(() => {
            //     props.history.replace("/main");
            // }, "", "해당 충전소는", "서비스 오픈 준비중입니다.");
        } else {
            if (momentyInfo?.ev_chargers[0]?.operator_id === "PC") {
                mixpanel("evcharge:/evcharge/easypaynavitype");
                logbox("/evcharge/easypaynavitype", "");

                getTapCharge(momentyInfo.poi_id, momentyInfo.center_wgs84_lon, momentyInfo.center_wgs84_lat)
                    .then(function (response: any) {

                        const validCharge = response?.infoList?.filter(x => x.status === "CHARGING_STANDBY" || "UNCONFIRMED");

                        if (validCharge.length === 0 || response.infoList.length === 0) {
                            setNoCharge(true);
                            roadMapInfo();

                            // modal.alert(() => {
                            //     props.history.replace("/main");
                            // }, "", "사용 가능한", "충전기가 없습니다.");
                        } else {
                            setChargerList(response);
                            setSeqList(response?.infoList);
                            roadMapInfo();
                        }
                    })
                    .catch(function (error: any) {
                        console.log(error);
                        setNoOpen(true);
                        roadMapInfo();

                        // modal.alert(() => {
                        //     props.history.replace("/main");
                        // }, "", "해당 충전소는", "서비스 오픈 준비중입니다.");
                    })
            } else {
                mixpanel("evcharge:/evcharge/qrscannavitype");
                logbox("/evcharge/qrscannavitype", "");

                roadMapInfo();
            }
        }
    }, [momentyInfo]);

    useEffect(() => {
        setPayType(store.getState().app.payType);
    });

    useEffect(() => {
        document.body.style.cssText = `
          overflow: hidden;`;
        return () => {
            document.body.style.cssText = '';
        }
    }, []);

    const goChargeCurrentStatus = () => {
        getUserStatus()
            .then(function (response: any) {
                if (response.result) {
                    if (response.activeSessionCount === 0) {
                        mixpanel("evcharge:/evcharge/main:view.nousecharge_popup");
                        logbox("/evcharge/qrscannavitype", "view.nousecharge_popup");

                        modal.alert(() => {
                            mixpanel("evcharge:/evcharge/main:tap.nousecharge_ok");
                            logbox("/evcharge/qrscannavitype", "tap.nousecharge_ok");
                        }, "사용 중인 충전기가 없습니다.");
                    } else if (response.chargeSessionGuid) {
                        store.dispatch(appActions.setGuid(response.chargeSessionGuid));

                        getAuthStatus(response.chargeSessionGuid)
                            .then(function (response2: any) {
                                store.dispatch(appActions.setRemoteOffYn(response2.remoteOffYn));
                                store.dispatch(appActions.setChargerSpeed(response2.chargerSpeed));

                                props.history.push(`/charging/${response.chargeSessionGuid}`);
                            })
                    } else if (response.unpaidGuid) {
                        store.dispatch(appActions.setGuid(response.chargeSessionGuid));

                        modal.alert(() => {
                            props.history.replace("/nonPay");
                        }, "미납된 충전 금액이 있습니다.", "충전 서비스 이용을 위해 <br/>결제를 진행해주세요.");
                    } else if (response.authSessionGuid) {
                        store.dispatch(appActions.setGuid(response.authSessionGuid));

                        getAuthStatus(response.authSessionGuid)
                            .then(function (response2: any) {
                                store.dispatch(appActions.setRemoteOffYn(response2.remoteOffYn));
                                store.dispatch(appActions.setChargerSpeed(response2.chargerSpeed));

                                if (response2.authStatus === 1 || response2.authStatus === 2) {
                                    store.dispatch(appActions.setQrStatus("1"));
                                    props.history.replace("/qrAuth");
                                } else if (response2.authStatus === 4 || response2.authStatus === 6) {
                                    props.history.push(`/charging/${response.authSessionGuid}`);
                                }
                            })
                    }
                }
            })
    }

    function QrCodeScan(status: string, qrcode: string) {
        if (Const.DBG_OK) {
            alert("QrCode" + status + " " + JSON.stringify(qrcode));
        }
        //qr코드가 '' 빈값일때 qr인증실패로 이동
        if (status === "COMPLETE") {
            if (!qrcode) {
                props.history.replace("/authFail");
            } else {
                store.dispatch(appActions.setQrCode(qrcode));

                props.history.replace("/qrAuth");
            }
        } else if (status === "CANCEL") {
            mixpanel("evcharge:/evcharge/qrscan:tap.back");
            logbox("/evcharge/qrscannavitype", "tap.back");
        }
    }

    const goBack = () => {
        if (stationData.operatorId === 'PC') {
            mixpanel("evcharge:/evcharge/easypaynavitype:tap.back");
            logbox("/evcharge/easypaynavitype", "tap.back");
        } else {
            mixpanel("evcharge:/evcharge/qrscannavitype:tap.back");
            logbox("/evcharge/qrscannavitype", "tap.back");
        }
        // props.history.replace('/main');
        tmapInterface.onBackKeyPressed();
    }

    const getCurrentPosition = () => {
        tmapInterface.getCurrentPosition(currentPosition);
    }

    function currentPosition(latitude: string, longitude: string) {
        setPosition({
            center: [
                Number(longitude),
                Number(latitude)
            ],
            zoom: 14.5,
            bearing: 0,
            pitch: 0,
        });
        setInited(true);
    }

    function getLocation() {
        //현재위치 호출 및 position에 저장
        navigator.geolocation.getCurrentPosition(
            function (currentPosition) {
                setPosition({
                    center: [
                        Number(currentPosition.coords.longitude),
                        Number(currentPosition.coords.latitude)
                    ],
                    zoom: 14.5,
                    bearing: 0,
                    pitch: 0,
                });
                setInited(true);
            },
            function (error) {
                setInited(true);
                console.error(error);
            },
            {
                enableHighAccuracy: false,
                maximumAge: 0,
                timeout: Infinity,
            }
        );
    }

    const roadMapInfo = () => {
        getDriving(momentyInfo.poi_id)
            .then((response: any) => {
                // alert(JSON.stringify(response.result));
                if (response.operatorId === 'PC') { //화면 표출 로그용
                    mixpanel("evcharge:/evcharge/easypaynavitype");
                    logbox("/evcharge/qrscannavitype", "");
                } else {
                    mixpanel("evcharge:/evcharge/qrscannavitype");
                    logbox("/evcharge/qrscannavitype", "");
                }

                if (response.result === true) {
                    setStationData(response);
                    setPosition({
                        center: [
                            response.wgs84CenterX,
                            response.wgs84CenterY
                        ],
                        zoom: 14.5,
                        bearing: 0,
                        pitch: 0,
                    });
                    setInited(true);
                }
            }).catch((response: any) => {
                setNoDriving(true);
                // getLocation();
                getCurrentPosition();
                console.log(response);
            })
    }

    const goPayChoiceView = () => {
        tmapInterface.startPaymentActivity(selectCard);
    };

    function selectCard(status: string) {
        if (Const.DBG_OK) {
            alert("selectCard \n " + "status: " + status);
        }

        if (globalVars.isIOS) {
            tmapInterface.getAccessKey(getAccessKey);
        } else {
            globalVars.accessKey = tmapInterface.getAccessKey();


            getPayMethod();

            props.history.replace("/destinationCall");
        }
    }

    function getAccessKey(value: string) {
        globalVars.accessKey = value


        getPayMethod();

        props.history.replace("/destinationCall");
    }

    const payMethodPop = () => {
        modal.alert(() => {
            popClosed();
        }, "결제 카드 등록 후</br>충전을 진행하실 수 있습니다.");
    };

    const popClosed = () => {
        if (timer) clearTimeout(timer);
        timer = window.setTimeout(() => goPayChoiceView(), 1);
    };

    const goTapcharge = () => {
        mixpanel("evcharge:/evcharge/easypaynavitype:tap.picture");
        logbox("/evcharge/easypaynavitype", "tap.picture");

        if (payType == "N") {
            // 결제수단이 없는 경우
            payMethodPop();
        } else {
            props.history.replace(`/tapCharge/${momentyInfo.poi_id}/${momentyInfo.center_wgs84_lon}/${momentyInfo.center_wgs84_lat}`);
        }
    }

    const goStartQrChagingView = () => {
        store.dispatch(appActions.setQrStatus(''));

        mixpanel("evcharge:/evcharge/qrscannavitype:tap.qrscan");
        logbox("/evcharge/qrscannavitype", "tap.qrscan");

        if (firstClick) return;
        getUserStatus()
            .then(function (response: any) {
                if (response.result) {
                    if (response.unpaidGuid) {
                        store.dispatch(appActions.setGuid(response.unpaidGuid));
                        modal.alert(() => {
                            props.history.replace("/nonPay");
                        }, "미납된 충전 금액이 있습니다.", "충전 서비스 이용을 위해 <br/>결제를 진행해주세요.");
                    } else if (response.activeSessionCount >= 3) {
                        mixpanel("evcharge:/evcharge/main:view.allmultiuse_popup");
                        logbox("/evcharge/qrscannavitype", "view.allmultiuse_popup");
                        modal.alert(() => {
                            mixpanel("evcharge:/evcharge/main:tap.allmultiuse_ok");
                            logbox("/evcharge/qrscannavitype", "tap.allmultiuse_ok");
                        }, "사용 가능한 3건의 충전을 모두 사용중에 있습니다. <br/>충전 종료 후 다시 이용해주세요.");
                    } else {
                        if (payType == "N") {
                            // 결제수단이 없는 경우
                            payMethodPop();
                        } else {
                            tmapInterface.startQrCodeScanActivity(QrCodeScan);
                        }
                    }
                }
            })
    };

    const clickEvent = (data) => {
        setFocusCharger(data.chargerId);
    }

    function chargerTypes(chargerdata) {
        switch (chargerdata) {
            case "AC_SINGLE_PHASE":
                return <><span className="type4">AC 완속</span></>
            case "DC_CHADEMO_AND_AC_THREE_PHASE_AND_DC_COMBO":
                return <><span className="type2">DC 차데모</span> <span className="type3">AC 3상</span> <span className="type1">DC 콤보</span></>
            case "DC_COMBO":
                return <><span className="type1">DC 콤보</span></>
            case "DC_CHADEMO_AND_DC_COMBO":
                return <><span className="type2">DC 차데모</span> <span className="type1">DC 콤보</span></>
            case "DC_CHADEMO_AND_AC_THREE_PHASE":
                return <><span className="type2">DC 차데모</span> <span className="type3">AC 3상</span></>
            case "DC_CHADEMO":
                return <><span className="type2">DC 차데모</span></>
            case "AC_THREE_PHASE":
                return <><span className="type3">AC 3상</span></>
        }
    }

    function Caltime(data) {
        const caltime: string = data;

        if (caltime.includes('일') || caltime.includes('하루')) {
            return (
                <span className="time color2">{caltime}</span>
            )
        } else if (caltime.includes('시간') || caltime.includes('분')) {
            return (
                <span className="time color1">{caltime}</span>
            )
        } else if (caltime.includes('달')) {
            return (
                <span className="time color3">{caltime}</span>
            )
        } else {
            return (
                <span className="time color2">사용 내역 없음</span>
            )
        }
    }

    const cancelCharge = () => {
        setFirstClick(false);

        mixpanel("evcharge:/evcharge/easypaybtype:tap.easypaybtypeselect_quit");
        logbox("/evcharge/qrscannavitype", "tap.easypaybtypeselect_quit");

        setFocusCharger("");
        setBPop(false);
    }

    const goCharge = (chargeData: any) => {
        store.dispatch(appActions.setCpoCode(chargerList.infoList[0].operatorId));

        mixpanel("evcharge:/evcharge/easypaybtype:tap.easypaybtypeselect_ok");
        logbox("/evcharge/qrscannavitype", "tap.easypaybtypeselect_ok");

        getUserStatus()
            .then(function (response: any) {
                if (response.result) {
                    if (response.unpaidGuid) {
                        store.dispatch(appActions.setGuid(response.unpaidGuid));
                        modal.alert(() => {
                            props.history.replace("/nonPay");
                        }, "미납된 충전 금액이 있습니다.", "충전 서비스 이용을 위해 <br/>결제를 진행해주세요.");
                    } else if (response.activeSessionCount >= 3) {
                        modal.alert(() => {
                        }, "사용 가능한 3건의 충전을 모두 사용중에 있습니다. <br/>충전 종료 후 다시 이용해주세요.");
                    } else {
                        goTapChargeAuth(globalVars.accessKey, chargeData);
                    }
                }
            })
    }

    const goInfo = () => {
        mixpanel("evcharge:/evcharge/easypaybtype:tap.easypayuseguide");
        logbox("/evcharge/easypaybtype", "tap.easypayuseguide");

        props.history.push(`/tapChargeInfo`);
    }

    const goTapChargeAuth = (accessKey: any, data) => {
        store.dispatch(appActions.setEasyPayData(data));
        // console.log('detaildata', data);

        props.history.replace("/tapChargeAuth");
    };

    const Qrstyle = {
        height: 24,
        width: 24,
        marginRight: 5,
        marginTop: 1
        // right:"3px",
        // position:"relative"
    };

    useEffect(() => {
        getCpos()
            .then((response: any) => {
                const cpoCode = response.cpo.map((item) => item.cpoCode)
                setCpoList(cpoCode);
            })
    }, [])

    if (!inited) {
        return null;
    } else {
        return (
            <>
                <div className="charging-station">
                    <button type="button" className="back-btn" onClick={goBack} style={{ zIndex: "999" }}>
                        <img src={require('%/images/common/btn-arrow-bg-black.svg').default} alt="" />
                    </button>
                    <Map
                        style={{
                            width: "100vw", //width
                            height: "100vh", //100%
                            position: "fixed",
                            zIndex: "1",
                            bottom: "100px",
                        }}
                        config="https://farm41.ids.onestore.co.kr/hub/vsm/config/application/S001/TMAP_JS.conf"
                        position={position}
                    >
                        {
                            !noDriving ? (
                                <CustomMarker lngLat={position.center} anchor="center">
                                    {
                                        !cpoList.includes(stationData.operatorId) ? (
                                            <div
                                                style={{ height: "fit-content" }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        position: "relative",
                                                        justifyContent: "center",
                                                        marginRight: "12px"

                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            marginRight: "-50px",
                                                            marginTop: "-6px",
                                                            marginBottom: "22px",
                                                        }}
                                                    >
                                                        <img
                                                            src={
                                                                require("%/images/cpo/ic-map-cpo-pin.svg")
                                                                    .default
                                                            }
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div style={{ marginLeft: "14px", marginTop: "8px", width: "18px", height: "18px" }}>
                                                        <img
                                                            src={
                                                                require("%/images/cpo/ic-poi-evstation.svg")
                                                                    .default
                                                            }
                                                            alt=""
                                                            style={{
                                                                width: "35px",
                                                                top: "5px",
                                                                left: "10px",
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                <div
                                                    style={{ height: "fit-content" }}
                                                >
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            position: "relative",
                                                            justifyContent: "center",
                                                            marginRight: "12px"
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                marginRight: "-50px",
                                                                marginTop: "-6px",
                                                                marginBottom: "22px",
                                                            }}
                                                        >
                                                            <img
                                                                src={
                                                                    require("%/images/cpo/ic-map-cpo-pin.svg")
                                                                        .default
                                                                }
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div style={{ marginLeft: "5px" }}>
                                                            <img
                                                                src={
                                                                    require(`%/images/cpo/ic-list-evstation-${stationData.operatorId.toLowerCase()}@3x.png`)
                                                                        .default
                                                                }
                                                                alt=""
                                                                style={{
                                                                    width: "25px",
                                                                    marginLeft: "5px",
                                                                    marginTop: "5px",
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                </CustomMarker>
                            ) : (
                                <></>
                            )
                        }
                    </Map>
                    {
                        stationData.operatorId === 'PC' ?
                            <>
                                {
                                    noCharge || noOpen ?
                                        (<BottomSheet
                                            open //바텀시트 열기
                                            scrollLocking={false}
                                            snapPoints={({ maxHeight }) =>
                                                window.innerHeight <= 700
                                                    ? [0.7 * maxHeight, 0.45 * maxHeight, 0.3 * maxHeight]
                                                    : window.innerHeight <= 850
                                                        ? [0.7 * maxHeight, 0.45 * maxHeight, 0.25 * maxHeight]
                                                        : [0.7 * maxHeight, 0.45 * maxHeight, 0.2 * maxHeight]
                                            } // 높이 조절 기능 최대값 두개 설정해서 최상단 최하단 지정
                                            defaultSnap={({ maxHeight }) => 0.45 * maxHeight}
                                            blocking={false} // 뒷 배경 화면 비활성화 기능
                                        >
                                            {noCharge ?
                                                <div className='destination-nocharge'>
                                                    사용 가능한 충전기가 없습니다.
                                                </div>
                                                :
                                                <div className='destination-nocharge'>
                                                    해당 충전소는 서비스 오픈 준비중입니다.<br />
                                                    다른 충전소를 이용해주세요
                                                </div>
                                            }
                                        </BottomSheet>)
                                        :
                                        (<BottomSheet
                                            open //바텀시트 열기
                                            scrollLocking={false}
                                            snapPoints={({ maxHeight }) => [0.9 * maxHeight, maxHeight * 0 + 360]} // 높이 조절 기능 최대값 두개 설정해서 최상단 최하단 지정
                                            // defaultSnap={({ maxHeight }) => 0.45 * maxHeight}
                                            blocking={false} // 뒷 배경 화면 비활성화 기능
                                            // onSpringStart={() => {
                                            //     const fromHeight = sheetRef.current?.height;
                                            //     requestAnimationFrame(() => {
                                            //       const toHeight = sheetRef.current?.height;
                                            //     //   if(bottomSheet){
                                            //     if (fromHeight !== toHeight && clickBottomSheetList) {
                                            //         setClickBottomSheetList(false);
                                            //     } else if (fromHeight !== toHeight && clickBottomSheetList === false) {
                                            //         setClickBottomSheetList(true);
                                            //     }
                                            //     //   }
                                            //     })
                                            //   }}
                                            header={
                                                <div className="bottomsheet-header">
                                                    <div className="header-bar" onClick={() => {
                                                        sheetRef.current?.snapTo(({ maxHeight }) => clickBottomSheetList ? maxHeight * 0 + 300 : 0.9 * maxHeight)
                                                    }}>
                                                    </div>
                                                </div>
                                            }
                                        >
                                            <div className="destination">
                                                {stationData.operatorId === 'PC' && isPolestar ? (
                                                    <div className="picture-min">
                                                        <img
                                                            src={require("%/images/logo/img_ev_pc_polestart_station.svg").default}
                                                            alt=""
                                                        />
                                                    </div>
                                                ) : (
                                                    <div className="picture-min">
                                                        <img src={require(`%/images/logo/img-ev-station-slow-sticker.svg`).default} alt="" onClick={goTapcharge} />
                                                    </div>
                                                )
                                                }
                                                <div className="qr-title-group">
                                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <img src={require(`%/images/cpo/ic-list-evstation-${(chargerList.infoList[0].operatorId).toLowerCase()}@3x.png`).default} alt="" style={{ width: "45px", height: "45px" }} />
                                                        <button type='button' className='go-info' onClick={() => goInfo()} > 이용안내</button>
                                                    </div>
                                                    <div className='station-name'>
                                                        {Number(window.innerWidth) <= 320
                                                            ? utils.textLengthOverCut(stationData.poiNameSvc, 15, "...")
                                                            : utils.textLengthOverCut(stationData.poiNameSvc, 19, "...")}
                                                    </div>
                                                    {!stationData.roadMap ?
                                                        <>
                                                            <span>{stationData.fullJibunAddr}</span>
                                                        </>
                                                        :
                                                        <>
                                                            <span>{stationData.roadMap}</span>
                                                        </>
                                                    }
                                                </div>
                                                {/* <div className="button-list-group-easy" style={{ borderBottom: "8px solid #f1f1f1" }}>
                                                <div className="qr-title-group" style={{ width: "100%", backgroundColor: "#fff", zIndex: "9999" }}>
                                                    <div className="inner-list-item" style={{ width: "30px" }}>
                                                        <img
                                                            src={
                                                                require(`%/images/ic-list-evstation-${stationData.operatorId.toLowerCase()}@3x.png`)
                                                                    .default
                                                            }
                                                            alt=""
                                                        />
                                                    </div>
                                                    <h3>
                                                        {Number(window.innerWidth) <= 320
                                                            ? utils.textLengthOverCut(stationData.poiNameSvc, 13, "...")
                                                            : utils.textLengthOverCut(stationData.poiNameSvc, 19, "...")}
                                                    </h3>
                                                    {stationData.roadMap === null || "" ?
                                                        <>
                                                            <p>{stationData.fullJibunAddr}</p>
                                                        </>
                                                        :
                                                        <>
                                                            <p>{stationData.roadMap}</p>
                                                        </>
                                                    }
                                                </div>
                                            </div> */}
                                                {
                                                    (
                                                        <>
                                                            <div>
                                                                <div className="destination-list">
                                                                    <div className="destination-ment">
                                                                        <img src={require('%/images/common/taptap-info.svg').default} alt="" style={{ position: "relative", top: "3px", paddingRight: "10px" }} />
                                                                        충전기를 선택하면 충전이 시작됩니다.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                                {chargerList.infoList
                                                    // .filter(data=> (data['station_id']+data['chargerId']).toString() == seqList.map(i=>i.chargerId))
                                                    // .filter(data=> data.available === true)
                                                    .map(data => {
                                                        return (
                                                            <div style={{ padding: '0 20px' }}>
                                                                <ul>
                                                                    <li onClick={() => {
                                                                        mixpanel("evcharge:/evcharge/easypaynavitype:tap.connector");
                                                                        logbox("/evcharge/easypaynavitype", "tap.connector");

                                                                        if (payType == "N") {
                                                                            // 결제수단이 없는 경우
                                                                            payMethodPop();
                                                                        } else {
                                                                            modal.alert(
                                                                                () => goCharge(data), // 확인 버튼을 클릭할 때 실행될 콜백 함수
                                                                                '충전을 시작하시겠습니까?',   // 컨텐츠 제목 (선택 사항)
                                                                                "",
                                                                                true,
                                                                                () => cancelCharge()
                                                                            );
                                                                            // setBPop(true);
                                                                            setChargeStart(data);
                                                                            setFirstClick(true);
                                                                        }
                                                                        // }
                                                                    }} onTouchStart={() => clickEvent(data)} onTouchEnd={() => setFocusCharger("")} className="finish"
                                                                        style={data.chargerId === focusCharger ? { backgroundColor: "var(--gray-color-wb-transparent-300, rgba(0, 0, 0, 0.10))" } : {}}>
                                                                        <em style={(data.chargerId === focusCharger) ? { backgroundColor: "#fff", color: "#222" } : {}}>{data.couplerSeq ? data.couplerSeq : data.chargerId}</em>
                                                                        <div className="txt">
                                                                            <div className="dc">
                                                                                <>{chargerTypes(data.type)}</>
                                                                            </div>
                                                                            <p className="info">
                                                                                <span style={{ color: "#909090" }}>{data.powerType}</span>
                                                                                <span style={{ color: "#909090" }}>{data.stationId + data.chargerId}</span>
                                                                            </p>
                                                                        </div>
                                                                        {
                                                                            data.available === true
                                                                                ?
                                                                                <>
                                                                                    {Caltime(moment(data.updateDateTime, 'YYYYMMDDHH').fromNow())}
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    {data.status === "CHARGING"
                                                                                        ?
                                                                                        <span className="time color1">사용중</span>
                                                                                        :
                                                                                        <span className="time color4">{data.status === "UNCONFIRMED" ? "알수없음" : "사용불가"}</span>
                                                                                    }
                                                                                </>
                                                                        }
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </BottomSheet>)
                                }
                                <div className="button-group">
                                    <button type="button" onClick={goChargeCurrentStatus} onTouchStart={() => setFocusCurrentStatus(true)} onTouchEnd={() => setFocusCurrentStatus(false)}
                                        style={focusCurrntStatus ? { filter: "brightness(90%)", width: "30%" } : { width: "30%" }}>
                                        {" "}
                                        충전현황
                                    </button>
                                    <button type="button" onClick={goStartQrChagingView} onTouchStart={() => setFocusCharging(true)} onTouchEnd={() => setFocusCharging(false)}
                                        style={{ filter: focusCharging ? "brightness(90%)" : "", width: "68%", left: "2%", backgroundColor: isPolestar ? "#171819" : "#0064ff", color: "#fff" }}>
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <Lottie animationData={QrAnimation} style={Qrstyle} />
                                            <span style={{ fontFamily: "TMOBI500" }}>충전 시작하기</span>
                                        </div>
                                    </button>
                                </div>

                            </>
                            :
                            <>
                                <div className="button-list-group">
                                    <div className="qr-title-group" style={{ width: "100%", backgroundColor: "#fff", zIndex: "9999", borderRadius: "10px" }}>

                                        <div className="inner-list-item" style={{ width: "30px" }}>
                                            {
                                                !cpoList.includes(stationData.operatorId) ? (
                                                    <img
                                                        style={{ width: "16px", height: "16px", marginLeft: "5px", marginTop: "5px" }}
                                                        src={
                                                            require("%/images/cpo/ic-poi-evstation.svg")
                                                                .default
                                                        }
                                                        alt=""
                                                    />
                                                ) : (
                                                    <>
                                                        <img
                                                            src={
                                                                require(`%/images/cpo/ic-list-evstation-${stationData.operatorId.toLowerCase()}@3x.png`)
                                                                    .default
                                                            }
                                                            alt=""
                                                        />
                                                    </>
                                                )}
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            <h3>
                                                {/* {stationData.poiNameSvc}     */}
                                                {Number(window.innerWidth) <= 320
                                                    ? utils.textLengthOverCut(stationData.poiNameSvc, 13, "...")
                                                    : utils.textLengthOverCut(stationData.poiNameSvc, 19, "...")
                                                }
                                            </h3>
                                            {stationData.roadMap === null || "" ?
                                                <>
                                                    <p>{stationData.fullJibunAddr}</p>
                                                </>
                                                :
                                                <>
                                                    <p>{stationData.roadMap}</p>
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <button type="button" onClick={goChargeCurrentStatus} onTouchStart={() => setFocusCurrentStatus(true)} onTouchEnd={() => setFocusCurrentStatus(false)}
                                            style={focusCurrntStatus ? { filter: "brightness(90%)", width: "30%" } : { width: "30%" }}>
                                            {" "}
                                            충전현황
                                        </button>
                                        <button type="button" onClick={goStartQrChagingView} onTouchStart={() => setFocusCharging(true)} onTouchEnd={() => setFocusCharging(false)}
                                            style={{ filter: focusCharging ? "brightness(90%)" : "", width: "68%", left: "2%", backgroundColor: isPolestar ? "#171819" : "#0064ff", color: "#fff" }}>
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                <Lottie animationData={QrAnimation} style={Qrstyle} />
                                                <span style={{ fontFamily: "TMOBI500" }}>충전 시작하기</span>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </>
                    }
                </div>
                {/* <div className="bottom-popup" style={{ visibility: bPop ? 'visible' : 'hidden' }}>
                    <div className="popup-wrap" style={{ left: "10%", bottom: "40%", width: "80%", borderRadius: "10px", boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.14)" }}>
                        <div className="popup-contents">
                            <p style={window.innerWidth < 340 ? { fontWeight: "normal", fontSize: "15px", padding: "20px 0" } : { fontWeight: "normal", padding: "20px 0" }}>충전을 시작하시겠습니까?</p>
                        </div>
                        <div className="popup-bottom">
                            <button type="button" style={window.innerWidth < 340 ? { fontSize: "15px", borderBottomLeftRadius: "10px" } : { borderBottomLeftRadius: "10px" }} onClick={cancelCharge}>취소</button>
                            <button type="button" style={window.innerWidth < 340 ? { fontSize: "15px", borderBottomRightRadius: "10px" } : { borderBottomRightRadius: "10px" }} onClick={goCharge}>확인</button>
                        </div>
                    </div>
                </div> */}
            </>
        )
    }
}

export { DestinationCall }