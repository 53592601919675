/* eslint-disable */

import React, { useState, useEffect, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { useHistory } from 'react-router-dom';

// 헤더 
import { Header } from '../../components/Layout';
import { appManager } from '../../managers';
import { HandleBrowserBackButton } from '../../components/Layout'
import { globalVars } from '../../libs/global-vars';

// 팝업
import { FinishChargingPopup } from './components/FinishChargingPopup';

import { FooterButton } from '../../components/Layout/FooterButton';
import { ChargingData, Indicator, Progress, RemoteFinish } from './components';

import { getAuthStatus, getCardStatus, getChargeStatus, getPayStatus } from '../../apis/status';

import axios from 'axios';
import Const from '../../store/ev';
import { appActions, store } from '../../store';
import { modal } from '../../components/GlobalModal';
import { utils } from '../../libs/utils';
import { tmapInterface } from '../../libs/tmap-interface';

//Mixpanel
import { logbox, mixpanel } from '../../libs/logbox-mixpanel';
import { postChargeStop } from '../../apis/charge';
import { getProgress } from '../../apis/progress';
import { getPayMeans } from '../../apis/paymeans';
import { patchPay } from '../../apis/pay';
import { getCoupon, getCouponGuid, getPoint } from '../../apis/coupon';
import { progressFinishGuid } from '../../libs/commonFunction';
import { patchPncPopupUpdate } from '../../apis/pnc';

let scrollCache = 0;
// var authStatusCheckLoop: any = null;
// var chargeStatusCheckLoop: any = null;
// var progressStatusCheckLoop: any = null;
// var chargeStopCheckLoop: any = null;
// var payStatusCheckLoop: any = null;
// var pinCheckLoop: any = null;
let chargerSpeed;
let remoteOffYN;

let unstopCnt = 0;
let payStopCnt = 0;
let pinStopCnt = 0;
let belongIdx: "";

let finishStatusCnt = 0;

let cardInfo: any = {};	// 카드정보
let couponType; // 포인트/정률 필터링

let finishInfo = false;

/**-------------------------------------------------------------
1.2.1.7 충전중 화면
-------------------------------------------------------------
> http://basic.pointweb.kr/charge/public/html/public/qr_charger.html
-------------------------------------------------------------*/

function Charging(props: RouteComponentProps<{ id: string, guid: string }>) {

    const guid = props.match.params.guid;

    const history = useHistory();

    const [inited, setInited] = useState(false);
    const [pointInit, setPointInit] = useState(false);
    const [couponInit, setCouponInit] = useState(false);
    const [paymeanInit, setPaymeanInit] = useState(false);
    const [belongIdxStatus, setBelongIdxStatus] = useState("");
    const [refreshBtn, setRefreshBtn] = useState(false);

    var defaultData: any = { //updateStatus
        guid: "",
        chargeStatusKwh: "0",
        ins_d1_dt: "",
        ins_e1_dt: "",
        charger_price: "",
        socPer: "0",
        status_idx: "",
        pay_status: "",
        orderId: "",
        chargerTime: "",
        chargerHour: "",
        elapsedChargeTime: "0",
        chargerMinute: "",
        charger_type: "",
        angle: "",
        charger_id: "",
        cal_point: 0,
        currCost: "",
        coupon_first_yn: "",
        currentPrice: "0", //예상 충전 금액
        currentPointPrice: "0",//포인트 쿠폰 금액
        currentRatePrice: "0", //정률 쿠폰 금액
        // use_point_coupon_yn : "",
        // use_rate_coupon_yn:"",
        stationName: "", //충전소 이름 값을 넣어준 defaultData값 추가
        predCost: "0",
        chargerSpeed: "",
        authStatus: "",
        remoteOffYn: "",
        payReqStatus: "",
        couplerId: "",
        usePrice: "",
        totalPrice: "",
        chargingStatus: "",
        real_use_time: "",
        chargeStartDt: "",
    };

    var authDefaultData: any = [
        {
            "stationName": "",
            "chargeStartDt": "",
            "couplerId": ""
        }
    ]

    var progressDefaultData: any = [
        {
            "stationName": "테스트 충전소 1번",
            "stationAddress": "테스트 충전소 주소 1번",
            "authStatus": 1,
            "guid": "",
            "chargingStatus": null,
            "chargeStatusKwh": null,
            "totalPrice": null,
            "payStatus": null,
            "couplerId": ""
        }
    ]

    const [couponList, setCouponList] = useState([ //getCouponList
        {
            productIdx: 0,
            couponIdx: 0,
            couponName: "",
            couponHeadName: "",
            pointAmount: 0,
            discountAmount: 0,
            userCheckYn: "",
            couponImgNo: 0,
            couponStatus: 0,
            couponType: 0,
            couponAvailableEndDt: "",
            couponAvailableYn: "",
        }
    ]);

    const [tmapPointInfo, setTmapPointInfo] = useState({
        toggleYn: true,
        availablePoint: 0
    })

    const [userData, setUserData] = useState(defaultData);
    const [progressData, setProgressData] = useState(progressDefaultData);
    const [authData, setAuthData] = useState(authDefaultData);
    const [isDisabled, setIsDisabled] = useState(false);

    //버튼 dim처리용
    const [buttonBox, setButtonBox] = useState(1);
    const [buttonClick, setButtonClick] = useState(false);
    const [statusChange, setStatusChange] = useState(false);

    const [isHours, setHours] = useState(0);
    const [isMinutes, setMinutes] = useState(0);

    const [payType, setPayType] = useState('N');     // 결제수단 : TODO
    const [payTypeNm, setPayTypeNm] = useState('');     // 결제수단 명 : TODO
    const [payImageUrl, setPayImageUrl] = useState('');     // 결제수단 이미지 CI
    const [payKind, setPayKind] = useState(''); // 결제수단 종류

    const [isCard, setIsCard] = useState('NoFocus');
    const [payImageBg, setPayImageBg] = useState('transparent');     // 결제수단 이미지 CI
    const [bselectCard, setBselectCard] = useState('');
    const [view, setView] = useState(false);

    // 충전 종료하기 팝업 Popup
    const [evPop, setEvPop] = useState(false);
    const [progressType, setProgressType] = useState('per');

    const [couponCnt, setCouponCnt] = useState(0);
    const [couponTest, setCouponTest] = useState(false);
    // const [toggleInfoList, setToggleInfoList] = useState();
    const [pointToggleInfo, setPointToggleInfo] = useState();
    const [rateToggleInfo, setRateToggleInfo] = useState();
    const [noRateCoupon, setNoRateCoupon] = useState(false); //정률 유효
    const [noValidCoupon, setNoValidCoupon] = useState(false); //포인트 유효
    const [availCpo, setAvailCpo] = useState(false);
    const [pointGuid, setPointGuid] = useState(false);
    const [rateGuid, setRateGuid] = useState(false);
    const [couponHide, setCouponHide] = useState(false);

    const [openInfo, setOpenInfo] = useState<boolean>(false);
    const [useCharge, setUseCharge] = useState<String>('1');

    const [headerTitle, setHeaderTitle] = useState("충전 대기 중");

    const [rateCouponSupport, setRateCouponSupport] = useState(false);

    let dummy = {
        "result": true,
        "inProgressCharges": [
            {
                "stationName": "에버온 테스트 충전소 1번",
                "stationAddress": "에버온 테스트 충전소 주소 1번",
                "authStatus": 1,
                "guid": "23032100000101",
                "chargingStatus": null,
                "chargeStatusKwh": null,
                "totalPrice": null,
                "payStatus": null,
                "couplerId": "ME17413801"
            },
            {
                "stationName": "에버온 테스트 충전소 2번",
                "stationAddress": "에버온 테스트 충전소 주소 2번",
                "authStatus": 2,
                "guid": "23032100000201",
                "chargingStatus": null,
                "chargeStatusKwh": null,
                "totalPrice": null,
                "payStatus": null,
                "couplerId": "ME17413901"
            },
            {
                "stationName": "에버온 테스트 충전소 5번 (미납 상태)",
                "stationAddress": "에버온 테스트 충전소 주소 5번",
                "authStatus": 4,
                "guid": "23032100000501",
                "chargingStatus": 3,
                "chargeStatusKwh": 0,
                "totalPrice": 0,
                "payStatus": 6,
                "couplerId": "ME17414201"
            }
        ]
    }

    function retrieveCardInfo(accessKey: any) {

        getCardStatus(guid)
            .then(function (response: any) {

                cardInfo = response;

                // if (response.cardInfo.cardStatus === 'OK') {
                //     setPayType('Y');
                //     // setPayTypeNm(cardInfo.card.alias);
                //     setPayTypeNm(response.cardInfo.card.cardCompany.companyName);
                //     setPayImageUrl(response.cardInfo.card.cardCompany.ciImageUrl);
                //     setPayImageBg(response.cardInfo.card.cardCompany.bgColor);
                //     setPayKind('CARD');
                //     setIsCard('selected');
                //     setBselectCard('true');
                //     store.dispatch(appActions.setCIUrl(response.cardInfo.card.cardCompany.ciImageUrl));
                //     store.dispatch(appActions.setCIbg(response.cardInfo.card.cardCompany.bgColor));
                // } else if (response.payInfo.payStatus === 'OK'){
                //     setPayType('Y');
                //     setPayTypeNm(cardInfo.pay.payCompany.companyName);
                //     setPayImageUrl(cardInfo.pay.payCompany.ciImageUrl);
                //     setPayImageBg(cardInfo.pay.payCompany.bgColor);
                //     setPayKind('PAY')
                //     setIsCard('selected');
                //     setBselectCard('true');
                //     store.dispatch(appActions.setCIUrl(cardInfo.pay.payCompany.ciImageUrl));
                //     store.dispatch(appActions.setCIbg(cardInfo.pay.payCompany.bgColor));
                // }

                setPaymeanInit(true);
            })
            .catch(function (error: any) {
                if (Const.DBG_OK) {
                    alert('카드정보 조회실패')
                }
                setPayType('N');
                setPayTypeNm('');
                setIsCard('NoFocus');
                setBselectCard('신용/체크카드 등록하기');
                setPayImageUrl('');
                setPayImageBg('');
                setPayKind('');

                setPaymeanInit(true);
            });
    }

    const cacheScroll = useCallback(() => {
        // 간헐적으로 상세로 이동 후 호출되는 경우가 있어서 예외처리
        if (!/\/voice\/?$/.test(window.location.pathname)) return;
        scrollCache = window.scrollY;
    }, []);

    // const guid = store.getState().app.guid;

    const authStatusRefresh = () => { // 커플러 연결 대기 체크
        if (globalVars.authStatusCheckLoop != null) {
            clearInterval(globalVars.authStatusCheckLoop);
        }
        globalVars.authStatusCheckLoop = setInterval(() => {
            getAuth();
        }, 2000); // 2초에 한 번씩 상태조회
    };

    const getAuth = () => {
        getAuthStatus(guid)
            .then(function (response: any) {
                remoteOffYN = response.remoteOffYn;
                chargerSpeed = response.couplerSpeed;

                setAuthData(response);

                setRateCouponSupport(response.isRateCouponSupported)

                let ele = document.querySelector("#refresh_btn");
                ele?.classList.remove('infinite-rotating-logo');

                // setUserData(response);
                if (response.authStatus === 6) {
                    // clearInterval(globalVars.authStatusCheckLoop);

                    // setHeaderTitle("충전중");
                    // setButtonBox(2);

                    // getEvStatus('common');
                    // chargeStatusRefresh();

                    getCouponGuid(guid) //couponguid
                        .then(function (response2: any) {
                            clearInterval(globalVars.authStatusCheckLoop);

                            getEvStatus('common');
                            chargeStatusRefresh();

                            setButtonBox(2);

                            let pointCoupon = response2.coupons.filter(x => x.couponType === 1).length //포인트
                            let rateCoupon = response2.coupons.filter(x => x.couponType === 2).length //정률

                            setPointGuid(pointCoupon !== 0 ? true : false);
                            setRateGuid(rateCoupon !== 0 ? true : false);

                            setInited(true);

                            // setCouponHide(true);
                        }).catch((error: any) => {
                            clearInterval(globalVars.authStatusCheckLoop);

                            getEvStatus('common');
                            chargeStatusRefresh();

                            setInited(true);
                        });
                } else if (response.authStatus === 3 || response.authStatus === 5 || response.authStatus === 7 || response.authStatus === 8) {
                    clearInterval(globalVars.authStatusCheckLoop);
                    clearInterval(globalVars.progressStatusCheckLoop);
                    clearInterval(globalVars.finishStatusCheckLoop);

                    props.history.replace("/authFail");
                } else {
                    setInited(true);
                }
            })
            .catch(function(error) {
                setInited(true);
            })
    }

    useEffect(() => {
        finishStatusCnt = 0;
        pinStopCnt = 0;
        finishInfo = false;
        progressStatus();
        progressStatusRefresh();
        finishStatus("start");
        finishStatusRefresh();
    }, [])

    useEffect(() => {
        if (couponInit) {
            getAuth();
            authStatusRefresh();
        }
    }, [couponInit])

    useEffect(() => {
        mixpanel("evcharge:/evcharge/charging");
        logbox("/evcharge/charging", "");

        // remoteOffYN = store.getState().app.remoteOffYn;
        // chargerSpeed = store.getState().app.chargerSpeed;

        window.scrollTo(0, scrollCache);

        retrieveCardInfo(globalVars.accessKey);

        // getEvStatus('common');

        // chargeStatusRefresh();

    }, []);

    useEffect(() => {
        window.addEventListener('scroll', cacheScroll);
        return () => window.removeEventListener('scroll', cacheScroll);
    }, [cacheScroll]);

    useEffect(() => {
        if (buttonClick) {
            stopEvCharger(true);
        }
    }, [buttonClick]);

    const qrCharget2PopCallback = (value: boolean) => {
        // setButtonBox(2);
        setEvPop(false);

        if (value === true) {
            setButtonClick(true);
            setButtonBox(3);
        }
    };

    const chargeStatusRefresh = () => {
        if (globalVars.chargeStatusCheckLoop != null) {
            clearInterval(globalVars.chargeStatusCheckLoop);
        }
        globalVars.chargeStatusCheckLoop = setInterval(() => {
            getEvStatus('common');
        }, 20000); // 20초에 한 번씩 상태조회
    };

    const progressStatusRefresh = () => {
        if (globalVars.progressStatusCheckLoop != null) {
            clearInterval(globalVars.progressStatusCheckLoop);
            clearInterval(globalVars.finishStatusCheckLoop);
        }
        globalVars.progressStatusCheckLoop = setInterval(() => {
            progressStatus();
        }, 20000)
    }

    const finishStatusRefresh = () => {
        if (globalVars.finishStatusCheckLoop != null) {
            clearInterval(globalVars.finishStatusCheckLoop);
        }
        globalVars.finishStatusCheckLoop = setInterval(() => {
            finishStatus("loop");
        }, 20000)
    }

    // 결제수단 미선택 클릭시
    function selectCard(code: string, status: string) {
        //if(code == "0") {
        clearInterval(globalVars.progressStatusCheckLoop);
        clearInterval(globalVars.finishStatusCheckLoop);
        props.history.replace('/nonPay');
        //}
    }

    const pncPopupUpdate = () =>{
        patchPncPopupUpdate({guid:guid})
        .then((response)=>{
            console.log(response);
        })
        .catch((error)=>{console.log(error)})
    }

    // type = 'common' : 일반 충전상태 시 체크
    // type = 'finish' : 충전종료 시 체크
    const getEvStatus = (type: string) => { //충전상태 업데이트

        // if (type === 'refresh') {
        //     let ele = document.querySelector("#refresh_btn");
        //     ele?.classList.add('infinite-rotating-logo');
        //     // if(ele?.classList[0] === 'scroll_y_none'){
        //     // 	ele?.classList.replace('scroll_y_none', 'scroll_y_yes');
        //     // }
        // }

        // if (typeof (guid) === 'undefined') {
        //     alert('getEvStatus - GUID - undefined')
        // }

        getChargeStatus(guid)
            .then((response: any) => {
                setRefreshBtn(false);

                setHeaderTitle("충전중");

                if (type === 'refresh') {
                    let ele = document.querySelector("#refresh_btn");
                    ele?.classList.remove('infinite-rotating-logo');
                }

                var chargeStatusData = response;

                if (Const.DBG_OK) {
                    alert('QrCharger1 - getEvStatus \n' + JSON.stringify(chargeStatusData))
                }

                if (chargeStatusData.result === true) {

                    const diffTime = new Date().getTime() - new Date(chargeStatusData?.reqMacRegisterDt).getTime();
                    const diffTimeSecond = Math.floor(diffTime / 1000);

                    if (chargeStatusData.reqMacRegisterDt && chargeStatusData.pncPopupCheckYn === false) {
                        //macRegisterStatus 2: 3분 timeout 에러, 3: 타 msp 에러, 4: 등록 실패, 5: 등록 성공
                        switch (chargeStatusData.macRegisterStatus) {
                            case 5:
                                pncPopupUpdate();
                                modal.alert(() => {}, "오토차지 등록이 완료되었습니다", "이제 오토차지 서비스를 이용하실 수 있습니다. 다음부터는 차량에 바로 커넥터만 연결하세요!");
                                break;
                            case 3:
                                pncPopupUpdate();
                                modal.alert(() => {}, "오토차지 등록이 실패하였습니다", "해당 차량은 다른 서비스에 이미 등록되어 있습니다. 삭제 후 티맵에서 다시 시도해주세요.");
                                break;
                            case 4:
                                pncPopupUpdate();
                                modal.alert(() => {}, "오토차지 등록이 실패하였습니다", "서비스를 지원하지 않는 충전기이거나 일시적인 통신 오류로 인하여 등록에 실패하였습니다.");
                                break;
                            case 2:
                                if (180 <= diffTimeSecond && diffTimeSecond < 200) {
                                    pncPopupUpdate();
                                    modal.alert(() => {}, "오토차지 등록이 실패하였습니다", "서비스를 지원하지 않는 충전기이거나 일시적인 통신 오류로 인하여 등록에 실패하였습니다.");
                                }
                                break;
                            default:
                                break;
                        }
                    }
                    // setView(true);
                    // setBelongIdxStatus(resData.belong_group_idx);
                    if (statusChange === false) {
                        if ((chargeStatusData?.chargingStatus == 1 || chargeStatusData?.chargingStatus == 2) && finishInfo) {
                            if (finishStatusCnt == 5) {
                                finishInfo = false;
                                stopEvChargerErrMsg(true);
                            }
                            finishStatusCnt++;
                        }

                        if (chargeStatusData.payStatus === 3) { //pinY
                            appManager.globalLoadingReset();

                            clearInterval(globalVars.chargeStatusCheckLoop);
                            clearInterval(globalVars.progressStatusCheckLoop);
                            clearInterval(globalVars.finishStatusCheckLoop);

                            tmapInterface.startPinActivity(chargeStatusData.orderId, selectPin);
                        }

                        if (chargeStatusData.payReqStatus === 2) {
                            setStatusChange(true);

                            clearInterval(globalVars.chargeStatusCheckLoop);
                            clearInterval(globalVars.chargeStopCheckLoop);

                            payStatusRefresh();
                        }


                        // if ((resData.status_idx === "5" || resData.status_idx === "6") && resData.pay_status === "SUCCESS") {
                        //     // if(type == 'finish') {
                        //     // 	appManager.globalLoadingReset();
                        //     // }
                        //     //appManager.globalLoadingDec();
                        //     appManager.globalLoadingReset();

                        //     setStatusChange(true);

                        //     clearInterval(globalVars.chargeStatusCheckLoop);
                        //     clearInterval(globalVars.chargeStopCheckLoop);

                        //     props.history.replace('/finishCharge');

                        //     // modal.alert("충전 및 결제가 완료되었습니다.", () => {

                        //     // 	  props.history.push('/finishCharge');
                        //     // });

                        // } else if ((resData.status_idx === "5" || resData.status_idx === "6") && resData.pay_status === "FAIL") {
                        //     // if(type == 'finish') {
                        //     // 	appManager.globalLoadingReset();
                        //     // }
                        //     appManager.globalLoadingReset();

                        //     setStatusChange(true);

                        //     clearInterval(globalVars.chargeStatusCheckLoop);
                        //     clearInterval(globalVars.chargeStopCheckLoop);

                        //     appManager.globalLoadingReset();

                        //     modal.alert("<br /> 다른 결제 수단을 선택해주세요.", () => {
                        //         tmapInterface.startPaymentRetryActivity(resData.orderId, selectCard);
                        //         // props.history.push('/finishCharge');
                        //     }, "", "결제 수단에 오류가", "발생하였습니다.");

                        // } else if ((resData.status_idx === "5" || resData.status_idx === "6") && resData.pay_status === "PINY") {
                        //     // const guid = store.getState().app.guid;
                        //     //alert("a")
                        //     // if(type == 'finish') {
                        //     // 	appManager.globalLoadingReset();
                        //     // }
                        //     appManager.globalLoadingReset();

                        //     setStatusChange(true);

                        //     clearInterval(globalVars.chargeStatusCheckLoop);
                        //     clearInterval(globalVars.chargeStopCheckLoop);

                        //     tmapInterface.startPinActivity(selectPin);
                        // }

                        // const rawPredCost = chargeStatusData.current_price - chargeStatusData.current_point_price - chargeStatusData.current_rate_price;
                        // chargeStatusData.predCost = rawPredCost > 0 ? rawPredCost : 0

                        setHours(Math.floor(Number(chargeStatusData.elapsedChargeTime) / 60));
                        setMinutes(Number(chargeStatusData.elapsedChargeTime) % 60);

                        chargeStatusData.angle = Math.floor(chargeStatusData.socPer * 6.29);
                    }

                }
                // else {
                //     if (chargeStatusData.payReqStatus === 1) {
                //         appManager.globalLoadingReset();

                //         // stopEvChargerErrMsg(true);
                //     }
                // }

                // if (Const.DBG_OK) {
                //     alert('getEvStatus \n' + JSON.stringify(resDchargeStatusDataata))
                // }

                // alert('getEvStatus \n' + JSON.stringify(resData));
                //resData.station_name ="충전소이름확인용값입니다최대한길게";
                setUserData(chargeStatusData);

                // alert(JSON.stringify(userData));
            });
    };

    const progressStatus = () => {
        getProgress()
            .then(function (response: any) {
                if (response.result) {
                    setProgressData(response.inProgressCharges);
                }
            })
    }

    const finishStatus = (status: string) => {
        progressFinishGuid(status)
            .then((results: any) => {
                if (results.length > 0) {
                    appManager.globalLoadingReset();

                    clearInterval(globalVars.pinCheckLoop);
                    clearInterval(globalVars.progressStatusCheckLoop);
                    clearInterval(globalVars.finishStatusCheckLoop);
                    clearInterval(globalVars.authStatusCheckLoop);
                    clearInterval(globalVars.chargeStatusCheckLoop);
                    clearInterval(globalVars.chargeStopCheckLoop);
                    clearInterval(globalVars.payStatusCheckLoop);
                    store.dispatch(appActions.setFinishGuidData(results));
                    props.history.replace(`/finishCharge/${results[0]}`)
                }
            })
            .catch((error) => {
                // appManager.globalLoadingReset();

                // stopEvChargerErrMsg(true);
                console.log(error);
            })
    }

    const payStatusRefresh = () => {
        if (globalVars.payStatusCheckLoop != null) {
            clearInterval(globalVars.payStatusCheckLoop);
        }
        globalVars.payStatusCheckLoop = setInterval(() => {
            getPay();
        }, 2000); // 20초에 한 번씩 상태조회
    };

    const getPay = () => {
        getPayStatus(guid)
            .then(function (response: any) {
                // payStatusCnt++;

                // if (payStatusCnt === 45) {
                //     appManager.globalLoadingReset();

                //     stopEvChargerErrMsg(true);
                // }

                if (response.result) {
                    // if (response.payStatus === 5) { // SUCCESS
                    //     appManager.globalLoadingReset();

                    //     clearInterval(globalVars.payStatusCheckLoop);
                    //     clearInterval(globalVars.progressStatusCheckLoop);
                    //     clearInterval(globalVars.finishStatusCheckLoop);

                    //     store.dispatch(appActions.setGuid(guid));
                    //     props.history.replace('/finishCharge'); //finishCharge 임시로 main 변경
                    // } else 
                    if (response.payStatus === 6) { // FAIL
                        appManager.globalLoadingReset();

                        clearInterval(globalVars.payStatusCheckLoop);
                        clearInterval(globalVars.progressStatusCheckLoop);
                        clearInterval(globalVars.finishStatusCheckLoop);

                        modal.alert(() => {
                            tmapInterface.startPaymentRetryActivity(response.orderId, selectCard);
                            // props.history.push('/finishCharge');
                        }, "결제 수단에<br/> 오류가 발생하였습니다.<br/> 다른 결제 수단을 선택해주세요.");
                    } else if (response.payStatus === 3) { // PINY
                        appManager.globalLoadingReset();

                        clearInterval(globalVars.payStatusCheckLoop);
                        clearInterval(globalVars.progressStatusCheckLoop);
                        clearInterval(globalVars.finishStatusCheckLoop);

                        tmapInterface.startPinActivity(userData.orderId, selectPin);
                    }
                }
            })
            .catch(function (error: any) {
                // appManager.globalLoadingReset();
                
                // stopEvChargerErrMsg(true);
            })
    }

    const stopEvCharger = (type: boolean) => { //충전 종료요청
        // const guid = store.getState().app.guid;

        if (Const.DBG_OK) {
            alert('stopEvCharger 호출')
        }

        appManager.globalLoadingInc();

        var postChargeStopData = {
            guid: guid
        }

        // axios(config)
        postChargeStop(postChargeStopData)
            .then(function (response: any) {
                // 충전 종료 중 팝업 보기
                // setEvFinPop(true);

                // const res_data = response.data;

                if (response?.result) {

                    if (Const.DBG_OK) {
                        alert('stopEvCharger \n' + JSON.stringify(response))
                    }

                    finishInfo = true;

                    // 상태 체크 루프 종료
                    //clearInterval(statusCheckLoop);

                    // 2초에 한번씩 stop 여부 체크
                    //evStopCheckRefresh();

                    // if(res_data?.pay === true){
                    // 	if(res_data?.pinRequired === true){
                    // 		tmapInterface.startPinActivity(selectPin);
                    // 	}
                    // }

                } else {
                    // 2초에 한번씩 stop 여부 체크
                    // evStopCheckRefresh();
                    //evStopCheckRefresh();
                    stopEvChargerErrMsg(type);
                }
            })
            .catch(function (error: any) {
                // 1분동안 응답이 없으면
                stopEvChargerErrMsg(type);
            });
    };


    // PIN 입력
    function selectPin(code: string, status: string) {

        // alert("slectPin - \n" + status);

        //let pinStopCnt = 0;

        appManager.globalLoadingInc();

        // globalVars.pinCheckLoop = setInterval(() => {

        //     if (pinStopCnt > 25) {

        //         clearInterval(globalVars.pinCheckLoop);

        //     } else {

        //         pinStopCnt = pinStopCnt + 1;

        //     }
        // }, 2000)

        if (code != "0") {
            // alert('selectPin 실패')
            // PIN 입력 안할 시 미납 화면으로 이동 ( < , X )
            // clearInterval(globalVars.pinCheckLoop);
            clearInterval(globalVars.progressStatusCheckLoop);
            clearInterval(globalVars.finishStatusCheckLoop);
            clearInterval(globalVars.authStatusCheckLoop);
            clearInterval(globalVars.chargeStatusCheckLoop);
            clearInterval(globalVars.chargeStopCheckLoop);
            clearInterval(globalVars.payStatusCheckLoop);
            store.dispatch(appActions.setGuid(guid));
            props.history.replace("/nonPay");
        } else {
            // 결제 요청
            // if (pinStopCnt > 25) {
            //     clearInterval(globalVars.pinCheckLoop);
            //     clearInterval(globalVars.progressStatusCheckLoop);
            //     clearInterval(globalVars.finishStatusCheckLoop);
            //     clearInterval(globalVars.authStatusCheckLoop);
            //     clearInterval(globalVars.chargeStatusCheckLoop);
            //     clearInterval(globalVars.chargeStopCheckLoop);
            //     clearInterval(globalVars.payStatusCheckLoop);
            //     store.dispatch(appActions.setGuid(guid));
            //     props.history.replace("/nonPay");
            // } else {
                // clearInterval(globalVars.pinCheckLoop);
                goPay()
            // }
        }
    }

    // 결제요청
    const goPay = () => {

        clearInterval(globalVars.chargeStatusCheckLoop);
        clearInterval(globalVars.chargeStopCheckLoop);

        // const guid = store.getState().app.guid;

        if (buttonClick === false) {
            appManager.globalLoadingInc();
        }

        var data = {
            guid: guid
        }

        patchPay(data)
            .then((response: any) => {

                // if(buttonClick === false){
                // 	appManager.globalLoadingDec();
                // }
                //appManager.globalLoadingDec();

                if (response.result) {
                    if (Const.DBG_OK) {
                        alert('결제요청' + JSON.stringify(response));
                    }

                    appManager.globalLoadingReset();
                    clearInterval(globalVars.progressStatusCheckLoop);
                    clearInterval(globalVars.finishStatusCheckLoop);

                    store.dispatch(appActions.setGuid(guid));
                    props.history.replace(`/finishCharge/${guid}`);

                } else {

                    appManager.globalLoadingReset();

                    modal.alert(() => {
                        store.dispatch(appActions.setGuid(guid));
                        clearInterval(globalVars.progressStatusCheckLoop);
                        clearInterval(globalVars.finishStatusCheckLoop);
                        history.replace("/nonPay");
                        // goPayChoiceView();
                    }, "결제 수단에<br/> 오류가 발생하였습니다.<br/> 다른 결제 수단을 선택해주세요.");
                }
            }).catch((error1: any) => {
                console.log(error1)
            });
    }

    useEffect(() => {
        // axios.get(process.env.REACT_APP_FE_API_URL + '/api/getCouponList?accessKey=' + globalVars.accessKey + "&appCode=" + "Mobile")
        if (paymeanInit) {
            getPoint()
                .then((response: any) => {
                    setTmapPointInfo(response);

                    setPointInit(true);
                })
                .catch((error: any) => {
                    setPointInit(true);
                })
        }
    }, [paymeanInit]);

    useEffect(() => {
        if (pointInit) {
            getCoupon()
            .then(function (response: any) {

                // if (headerTitle === "충전중") {
                //     setCouponHide(true);
                // }

                const list = response.data;

                    const pointToggleInfo = response.pointCouponToggleYn === true ? response.pointCouponToggleYn : false
                    const rateToggleInfo = response.rateCouponToggleYn === true ? response.rateCouponToggleYn : false


                // let pointCoupon = response.data.filter(x => x.couponType === 1) //포인트
                // let rateCoupon = response.data.filter(x => x.couponType === 2)

                // const couponCnt = response.data.data.length;
                setCouponCnt(response.data.length);

                if (list !== null) {
                    setCouponList(list);
                    //alert(JSON.stringify(list));
                    setCouponTest(true);
                    setPointToggleInfo(pointToggleInfo);
                    setRateToggleInfo(rateToggleInfo);

                    // setPointGuid(pointCoupon.some(x => x.matchedGuid === guid));
                    // setRateGuid(rateCoupon.some(x => x.matchedGuid === guid));
                }

                setCouponInit(true);

            }).catch((response) => {
                console.log('Error!', response)
                setCouponInit(true);
            });
        }
    },[pointInit])

    useEffect(() => {
        // 유효쿠폰 수 체크
        let pointCoupon = couponList.filter(x => x.couponType === 1 && x.couponStatus === 1) //포인트

		if (pointCoupon.length > 0) {
			setNoValidCoupon(true);
		}

        let rateCoupon = couponList.filter(x => x.couponType === 2 && x.couponStatus === 1) //정률

		if (rateCoupon.length > 0) {
			setNoRateCoupon(true);
		}
    }, [couponTest]);


    // const goRateCoupon = () => {
    //     mixpanel.track("evcharge:/evcharge/charging:tap.discount");

    //     logBox.setLocal_time(timeformat)
    //         .setAction_id("tap.discount")
    //         .setPage_id("/evcharge/charging");

    //     axios.get(logBox.getTlaURIEncode())
    //         .then(function (response: any) {
    //             const test = response.data
    //         })

    //     couponType = couponList.filter(x => x['product_type'] === "1")
    //     props.history.push(`/rateCoupon`);
    //     store.dispatch(appActions.setCouponTypeInfo(couponType));

    //     store.dispatch(appActions.setRateToggleYN(rateToggleInfo));
    // }

    const stopEvChargerErrMsg = (type: boolean) => {

        appManager.globalLoadingReset();

        modal.alert(() => {
            // setButtonBox(2);
        }, "충전 종료 요청에<br/>실패하였습니다.", "종료가 지연될 경우 충전기에서<br/> 충전을 종료해주세요.");
    };

    const onClickRefresh = () => {
        mixpanel("evcharge:/evcharge/charging:tap.refresh");
        logbox("/evcharge/charging", "tap.refresh");

        let ele = document.querySelector("#refresh_btn");
        ele?.classList.add('infinite-rotating-logo');

        if (headerTitle === "충전중") {
            getEvStatus('refresh');
        } else {
            getAuth();
        }

        // evStatusRefresh();
    };

    function status(authStatus?: any, chargingStatus?: any, payStatus?: any) {
        if (authStatus === 1 || authStatus === 2) {
            return <span style={{ color: "#51565C", backgroundColor: "#F8F9FA" }}>인증 중</span>;
        } else if (authStatus === 3 || authStatus === 4) {
            return <span style={{ color: "#51565C", backgroundColor: "#F8F9FA" }}>충전 대기 중</span>
        } else if (chargingStatus === 1 || chargingStatus === 2) {
            return <span style={{ color: "#038591", backgroundColor: "#DFF6F5" }}>충전 중</span>
        } else if (payStatus === 3 || payStatus === 6) {
            return <span style={{ color: "#e55545" }}>미납 중</span>;
        } else {
            return <span style={{ color: "#038591", backgroundColor: "#DFF6F5" }}>충전 중</span>
        }
        // switch (status) {
        //     case 1:
        //         return <span>인증 중</span>;
        //     case 2:
        //         return '충전 대기 중';
        //     case 3:
        //         return '충전 중';
        //     case 4:
        //         return <span style={{ color: "#e55545" }}>미납 중</span>;
        // }
    }

    const onClickFinish = () => {
        mixpanel("evcharge:/evcharge/charging:tap.stop");
        logbox("/evcharge/charging", "tap.stop");
        mixpanel("evcharge:/evcharge/charging:view.chargestop_popup");
        logbox("/evcharge/charging", "view.chargestop_popup");

        setEvPop(true);
    }

    const progressClick = (authStatus?: any, chargingStatus?: any, payStatus?: any, guid?: any) => {
        mixpanel("evcharge:/evcharge/charging:tap.multi");
        logbox("/evcharge/charging", "tap.multi");

        if (authStatus === 1 || authStatus === 2) {
            store.dispatch(appActions.setGuid(guid));
            store.dispatch(appActions.setQrStatus("1"));
            clearInterval(globalVars.progressStatusCheckLoop);
            clearInterval(globalVars.finishStatusCheckLoop);
            clearInterval(globalVars.authStatusCheckLoop);
            clearInterval(globalVars.chargeStatusCheckLoop);
            clearInterval(globalVars.chargeStopCheckLoop);
            clearInterval(globalVars.payStatusCheckLoop);
            clearInterval(globalVars.pinCheckLoop);

            props.history.replace("/qrAuth");
        } else if (authStatus === 4 || chargingStatus === 1 || chargingStatus === 2) {
            getAuthStatus(guid)
                .then(function (response: any) {
                    // store.dispatch(appActions.setRemoteOffYn(response.remoteOffYn));
                    // store.dispatch(appActions.setChargerSpeed(response.chargerSpeed));

                    clearInterval(globalVars.progressStatusCheckLoop);
                    clearInterval(globalVars.finishStatusCheckLoop);
                    clearInterval(globalVars.authStatusCheckLoop);
                    clearInterval(globalVars.chargeStatusCheckLoop);
                    clearInterval(globalVars.chargeStopCheckLoop);
                    clearInterval(globalVars.payStatusCheckLoop);
                    clearInterval(globalVars.pinCheckLoop);

                    props.history.push(`/charging/${guid}`)
                })
        } else if (payStatus === 3 || payStatus === 6) {
            store.dispatch(appActions.setGuid(guid));
            clearInterval(globalVars.progressStatusCheckLoop);
            clearInterval(globalVars.finishStatusCheckLoop);
            clearInterval(globalVars.authStatusCheckLoop);
            clearInterval(globalVars.chargeStatusCheckLoop);
            clearInterval(globalVars.chargeStopCheckLoop);
            clearInterval(globalVars.payStatusCheckLoop);
            clearInterval(globalVars.pinCheckLoop);

            props.history.replace("/nonPay")
        }
    }

    const goBack = () => {
        clearInterval(globalVars.finishStatusCheckLoop);
        clearInterval(globalVars.authStatusCheckLoop);
        clearInterval(globalVars.chargeStatusCheckLoop);
        clearInterval(globalVars.progressStatusCheckLoop);
        clearInterval(globalVars.chargeStopCheckLoop);
        clearInterval(globalVars.payStatusCheckLoop);
        clearInterval(globalVars.pinCheckLoop);

        history.replace("/main");
        // goMain("charge");
    }

    if (!inited) {
        return (<></>);
    } else {
        return (
            <>
                {/* <Header title={headerTitle} isMenu={false} isActive={'3'} /> */}
                <div className="charger-top-section">
                    <img src={require('%/images/common/ico_arrow_left.svg').default} alt="" onClick={goBack} />
                    <img src={require('%/images/common/ico_reload.svg').default} alt="" className="change-button" onClick={() => {
                        onClickRefresh();
                        setRefreshBtn(true);
                        // setTimeout(() => {
                        //     setRefreshBtn(false);
                        // }, 500);
                    }
                    }
                        // style={refreshBtn ? { transform: "rotate(360deg)", transition: "transform 1s" } : {}}
                    />
                </div>
                <HandleBrowserBackButton />
                <div className="qr-charger">
                    <Indicator chargingData={userData} chargerSpeed={chargerSpeed} chargerStatus={headerTitle} />
                    <RemoteFinish remoteOffYN={remoteOffYN} />
                    <ChargingData authData={authData} chargingData={userData} payData={cardInfo} rateCouponSupport={rateCouponSupport} pointToggleInfo={pointToggleInfo} rateToggleInfo={rateToggleInfo} couponCnt={couponCnt} noValidCoupon={noValidCoupon} noRateCoupon={noRateCoupon} openInfoClick={() => setOpenInfo(!openInfo)} openInfo={openInfo} progressData={progressData} chargerStatus={headerTitle} pointGuid={pointGuid} rateGuid={rateGuid} couponHide={couponHide} tmapPointInfo={tmapPointInfo}/>
                    {openInfo ?
                        <>
                            <div className='multisession'>
                                {progressData.map(x => {
                                    return (
                                        <Progress progressCharges={x} guid={guid} authStatus={status(x.authStatus, x.chargingStatus, x.payStatus)} progressClick={() => {
                                            if (guid !== x.guid) {
                                                progressClick(x.authStatus, x.chargingStatus, x.payStatus, x.guid)
                                            }
                                        }} />
                                    )
                                })
                                }
                            </div>
                        </>
                        :
                        <></>
                    }
                    <div className="info-list">
                        <li>통신 상태가 좋지 않을 경우 충전기 종료 이후 사용중으로 노출될 수 있으며 이 경우 24시간이 지나면 자동으로 종료가 됩니다.</li>
                        <li>사용을 시도하신 뒤 장소를 이동하시는 경우, 충전기가 사용 시도하시기 전과 같은 대기 상태로 돌아간 것을 확인 후 이동해주세요.</li>
                    </div>

                </div>
                <FooterButton onClickFinish={() => onClickFinish()} remoteOffYN={remoteOffYN} finishButtonStatus={buttonBox} />
                <div className="bottom-popup" style={{ visibility: evPop ? 'visible' : 'hidden' }}>
                    <div className="popup-wrap">
                        <FinishChargingPopup callback={qrCharget2PopCallback} />
                    </div>
                </div>
            </>
        );
    }
}

export { Charging };