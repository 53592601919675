/* eslint-disable */

import instance from './frontman';

export const postMomenty = async (body: object, accessToken?: String) => {
    return await instance({
        url: "/momenty",
        method: "post",
        data: body
    })
};

export const postPkeyMomenty = async (body: object, params: any, accessToken?: String) => {
    return await instance({
        url: `/momenty/${params}`,
        method: "post",
        data: body
    })
};