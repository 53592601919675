/* eslint-disable */

import React, { useState, useEffect, useCallback } from 'react';
import { get } from 'lodash-es';
import { eventBus } from '../../libs/event-bus';
import { Alert, VoiceServiceAgreement, CouponRegister } from './Items';
import { HistoryManagerEvent } from '../HistoryManager';
import { useListenEventBus, useListenWindowEvent } from '../../libs/custom-hooks';

export enum ModalEvent {
  OPEN_MODAL = 'OPEN_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL',
}

function GlobalModal() {
  const [currentComp, setCurrentComp] = useState(null);

  // methods
  const open = useCallback(
    targetComp => {
      if (currentComp) throw Error(`Modal 중복호출 : ${targetComp.toString()}`);
      const hasModalFlag = get(window, 'history.state.state.modal');
      if (!currentComp && !hasModalFlag) {
        // const { pathname, search } = window.location;
        // eventBus.emit(HistoryManagerEvent.PUSH_HISTORY, `${pathname}${search}`, { modal: true });
      }
      setCurrentComp(targetComp);
    },
    [currentComp],
  );

  const close = useCallback(
    callback => {
      const hasModalFlag = get(window, 'history.state.state.modal');
      // if (currentComp && hasModalFlag) eventBus.emit(HistoryManagerEvent.GO_BACK);
      // 다음 이벤트큐에서 history가 변경됨. callback에서 history변경시 오류 가능성 있음. 변경된 후 callback 호출.
      if (typeof callback === 'function') setTimeout(() => callback(), 0);
      setCurrentComp(null);
    },
    [currentComp],
  );

  useEffect(() => {
    document.body.style.overflow = currentComp ? 'hidden' : '';
  }, [currentComp]);

  // listen eventbus
  useListenEventBus(ModalEvent.OPEN_MODAL, open);
  useListenEventBus(ModalEvent.CLOSE_MODAL, close);
  useListenWindowEvent('popstate', close);

  return <>{currentComp}</>;
}
// 모달 알림창 타이틀 텍스트 추가 건
const modal = {
  alert: (callback?: Nullable<(a: any) => any>, content_title?: string, content?: string, cancelYn?: boolean, cancelCallback?: Nullable<(b: any) => any>, cancelName?, confirmName?) => {
    eventBus.emit(ModalEvent.OPEN_MODAL, <Alert {...{ callback, content_title, content, cancelYn, cancelCallback, cancelName, confirmName }} />);
  },
  voiceServiceAgreement: (callback?: Nullable<(a: any) => any>, value?: boolean) => {
    eventBus.emit(ModalEvent.OPEN_MODAL, <VoiceServiceAgreement {...{ callback, value }} />);
  },
  couponRegister: (callback?: Nullable<(a: any) => any>, value?: string) => {
    eventBus.emit(ModalEvent.OPEN_MODAL, <CouponRegister {...{ callback, value }} />);
  },

  // couponRegisterPopup: (callback?: Nullable<(a: any) => any>, value?: string) => {
  //   eventBus.emit(ModalEvent.OPEN_MODAL, <CouponRegisterPopup />);
  // },
};

export { GlobalModal, modal };
