import React, { useState, useEffect, useCallback } from 'react';
import { Header } from '../../components/Layout';


let scrollCache = 0;
/**-------------------------------------------------------------
 1.2.1.3 이용안내 화면_2
 -------------------------------------------------------------
 > http://basic.pointweb.kr/charge/public/html/public/pay.html
 -------------------------------------------------------------*/
function PolestarRegisterGuide() {
  const [inited] = useState(false);

  const cacheScroll = useCallback(() => {
    // 간헐적으로 상세로 이동 후 호출되는 경우가 있어서 예외처리
    if (!/\/voice\/?$/.test(window.location.pathname)) return;
    scrollCache = window.scrollY;
  }, []);
  useEffect(() => {
    window.scrollTo(0, scrollCache);
  }, [inited]);
  useEffect(() => {
    window.addEventListener('scroll', cacheScroll);
    return () => window.removeEventListener('scroll', cacheScroll);
  }, [cacheScroll]);

  // render
  // if (!inited) return null
  return (
    <>
      <Header
        title={'폴스타 선불충전권 이용안내'}
        isInfoMenu={true}
        isActive={'1'}
        tabLabels={{ firstTab: "선불충전권 등록", secondTab: "선불충전권 이용" }}
      />

      <div className="polestar-guide">
        <div className="guide-header">
          폴스타 선불충전권<br />
          이렇게 등록해주세요.
        </div>
        <div className="guide-body">
          {/* Step 1 */}
          <div className="guide-step">
            <div className="step-header" style={{ marginBottom: '20px' }}>
              <div className="step-number">Step 1</div>
              <div className="step-title">티맵에서 카드 번호 입력</div>
            </div>
            <div className="step-description">
              티맵 전기차충전 진입 후 결제수단 등록 메뉴에서 "선불충전권 카드 번호"를 등록해주세요.
            </div>
          </div>

          {/* Step 2 */}
          <div className="guide-step">
            <div className="step-header">
              <div className="step-number">Step 2</div>
              <div className="step-title">아이파킹에서 결제수단 등록</div>
            </div>
            <div className="step-description">
              아이파킹 앱을 설치하고, 회원가입 후 결제수단을 등록해주세요.
            </div>
          </div>

          {/* Step 3 */}
          <div className="guide-step">
            <div className="step-header">
              <div className="step-number">Step 3</div>
              <div className="step-title">아이파킹에서 카드 번호 등록</div>
            </div>
            <div className="step-description">
              아이파킹 앱에서 [내정보 &gt; 선불권 포인트 관리] 메뉴로 진입하여
              "선불충전권 카드 번호"를 등록해주세요.
            </div>
          </div>
          <div className='footer-image'>
            <img src={require('%/images/logo/icon_polestar_logo.png').default} alt="polestar-logo" className="footer-logo" />
          </div>
        </div>
      </div>
    </>
  );
}
export { PolestarRegisterGuide };
