/* eslint-disable */

import React, { useState, useEffect, useCallback } from 'react';

const PncButton = (props) => {
    const [pressed, setPressed] = useState({
        finishBtn: false
    })

    const [pncBtnFocus, setPncBtnFocus] = useState(false);

    return (
        <>
            {
                props.name == "regist"
                    ?
                    props.carCheck
                        ?
                        <div className={'footer-button'}>
                            <a onClick={props.onClickRegist} style={pncBtnFocus ? { filter: "brightness(90%)" } : {}} onTouchStart={() => setPncBtnFocus(true)} onTouchEnd={() => setPncBtnFocus(false)}> 오토차지 등록하기 </a>
                        </div>
                        :
                        <div className={'dim-footer-button'}>
                            <a> 오토차지 등록하기 </a>
                        </div>
                    :
                    <></>
            }
            {
                props.name == "qr"
                    ?
                    props.brandStatus && props.modelStatus
                        ?
                        <div className={'footer-button'}>
                            <a onClick={props.onClickQrScan} style={pncBtnFocus ? { filter: "brightness(90%)" } : {}} onTouchStart={() => setPncBtnFocus(true)} onTouchEnd={() => setPncBtnFocus(false)}> 서비스 등록 QR스캔 </a>
                        </div>
                        :
                        <div className={'dim-footer-button'}>
                            <a> 서비스 등록 QR스캔 </a>
                        </div>
                    :
                    <></>
            }
            {
                props.name == "cpo"
                    ?
                    props.cpoCheckStatus || props?.carInfoCpo?.filter((item) => item.status === "Y").length !== 0
                        ?
                        <div className={'footer-button'}>
                            <a onClick={props.onClickDeleteCarInfo} style={pncBtnFocus ? { filter: "brightness(90%)" } : {}} onTouchStart={() => setPncBtnFocus(true)} onTouchEnd={() => setPncBtnFocus(false)}> 등록 취소 </a>
                        </div>
                        :
                        <div className={'dim-footer-button'}>
                            <a> 등록 취소 </a>
                        </div>
                    :
                    <></>
            }
        </>
    );
}
export { PncButton };