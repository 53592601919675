/**-------------------------------------------------------------
1.2.1.5 충전소 인식 화면_3
-------------------------------------------------------------
> http://basic.pointweb.kr/charge/public/html/public/charger1.html
-------------------------------------------------------------*/

/* eslint-disable */

import { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import {HandleBrowserBackButton} from '../../components/Layout'

// 공통
import { Header } 		from '../../components/Layout';
let scrollCache = 0;

function AuthFail(props: RouteComponentProps<{ cpoCode:string }>) {
  const [inited] = useState(false);

  const cpoCode = props.match.params.cpoCode;
  
  const goMain = () => {
    props.history.push('/main');
  }

  useEffect(() => {
		window.scrollTo(0, scrollCache);
	}, [inited]);
	
  return (
    <>
      <Header isMenu={false} />
      <HandleBrowserBackButton />

      <div id="page">
        <div className="qr-chager">
          <div className="txt-box">			  
		      </div>
        </div>
      </div>

      <div className="t-popup">
        <div className="t-popupwrap">
          <div className="t-popupcon">
            <h3>
              충전기 인증에 실패하였습니다.<br />
              해당 충전기는 사용중, 또는 일시적인<br /> 통신 장애일 수 있습니다.
            </h3>
            { //신세계면 
              (cpoCode === "IN") && (<p>스파로스 충전기일 경우 커플러를 먼저 연결한 후 QR 촬영을 진행해주세요.</p>)}
          </div>
          <div className="t-popup-bottom">
            <button type="button" className=" close-popbutton" onClick={ goMain } >확인</button>
          </div>
        </div>
      </div>
      
    </>
  );
}

export { AuthFail };
