/* eslint-disable */

import React, { useState, useEffect, useCallback } from 'react';

import { useHistory } from 'react-router-dom';
import Vsm from "@vsm/vsm";
import { tmapInterface } from '../../libs/tmap-interface';
import { globalVars } from '../../libs/global-vars';
import { HandleBrowserBackButton } from '../../components/Layout'

// 헤더 
import { Header } from '../../components/Layout';
import { appManager } from '../../managers';

import axios from 'axios';
import Const from '../../store/ev';
import { appActions, store } from '../../store';
import { modal } from '../../components/GlobalModal';
import { utils } from '../../libs/utils';

import { Map, MapPositionCompatible, CustomMarker, getCurrentPosition, MapEventListener } from "@vsm/react-vsm";
import { getPay, patchPay } from '../../apis/pay';
import { getPayMeans } from '../../apis/paymeans';
import { getCardStatus, getPayStatus } from '../../apis/status';

let scrollCache = 0;
var apiLoop: any = null;
var statusCheckLoop: any = null;
var payStatusCheckLoop: any = null;

let statusCnt = 0;

let currentPositionLon: any = {};
let currentPositionLat: any = {};

/**-------------------------------------------------------------
1.2.1.7 충전중 화면_3
-------------------------------------------------------------
> http://basic.pointweb.kr/charge/public/html/public/qr_charger.html
-------------------------------------------------------------*/

let cardInfo: any = {};  // 카드정보

function NonPay() {

  const guid = store.getState().app.guid;

  const history = useHistory();

  const [inited, setInited] = useState(false);

  var defaultData: any = {
    totalPrice: 0,
    salePrice: 0,
    payPrice: 0,
    chargeStartDt: "",
    chargeFinishDt: "",
    chargeTime: 0,
    chargeKwh: 0,
    stationName: "",
    // jibunAddress: "",
    roadAddress: "",
    couplerId: "",
    currentPrice: 0, //예상 충전 금액
    currentPointPrice: 0,//포인트 쿠폰 금액
    currentRatePrice: 0, //정률 쿠폰 금액
    currentTmapPointPrice: 0, //티맵포인트 쿠폰 금액
    poiId: "",
    // orderId: 0
  };

  const [userData, setUserData] = useState(defaultData);


  const [isCard, setIsCard] = useState('NoFocus');
  const [payImageBg, setPayImageBg] = useState('transparent');
  const [payImageUrl, setPayImageUrl] = useState('');     // 결제수단 이미지 CI
  const [payKind, setPayKind] = useState(''); // 결제수단 종류
  const [payType, setPayType] = useState('N');     // 결제수단 : TODO
  const [payTypeNm, setPayTypeNm] = useState('');     // 결제수단 명 : TODO
  const [payNumber, setPayNumber] = useState(''); // 결제카드 번호
  const [bselectCard, setBselectCard] = useState('');
  const [orderid, setorderid] = useState('');
  const [remainPoint, setRemainPoint] = useState("");       // 쿠폰 잔액 :userData.remain_point   
  const [couponName, setCouponName] = useState("");       // 쿠폰 잔액 :userData.coupon_name   
  const [couponFirst, setCouponFirst] = useState(false);   // 쿠폰 우선사용 여부 : userData.coupon_first_yn      

  // const [toggleInfoList, setToggleInfoList] = useState();
  const [pointToggleInfo, setPointToggleInfo] = useState();
  const [rateToggleInfo, setRateToggleInfo] = useState();
  const [couponCnt, setCouponCnt] = useState(0);
  const [noRateCoupon, setNoRateCoupon] = useState(false); //정률 유효
  const [noValidCoupon, setNoValidCoupon] = useState(false); //포인트 유효
  const [couponTest, setCouponTest] = useState(false);

  const cacheScroll = useCallback(() => {
    // 간헐적으로 상세로 이동 후 호출되는 경우가 있어서 예외처리
    if (!/\/voice\/?$/.test(window.location.pathname)) return;
    scrollCache = window.scrollY;
  }, []);
  const [couponList, setCouponList] = useState([
    {
      coupon_idx: "",
      name: "",
      head_name: "",
      cal_point: "",
      create_dt: "",
      expire_point_date: "",
      img_no: '',
      status: '',
      payCoupon: '',
      use_coupon_check_yn: '',
      use_expire_dt_yn: '',
      expire_dt: '',
      product_type: '',
      use_coupon_toggle_yn: '',
    }
  ]);
  const [position, setPosition] = useState<MapPositionCompatible>({ //중심좌표
    center: [0.0, 0.0], //127.0, 37.5  126.9556914, 37.3834682
    zoom: 13,
    bearing: 0,
    pitch: 0,
  });

  const [status, setStatus] = useState("");
  const [stationInfo, setStationInfo] = useState<any>([]);

  //  function getLocation(){ //현재위치 호출 및 position에 저장
  //       navigator.geolocation.getCurrentPosition(function(currentPosition) {
  //       if(position.center[0] === currentPosition.coords.longitude && position.center[1] === currentPosition.coords.latitude){
  //       setPosition({
  //       center: [currentPosition.coords.longitude+0.00001, currentPosition.coords.latitude+0.00001], //127.0, 37.5 126.9556914, 37.3834682
  //       zoom: 13,
  //       bearing: 0,
  //       pitch: 0
  //       });
  //       } else {
  //       setPosition({
  //       center: [currentPosition.coords.longitude, currentPosition.coords.latitude], //127.0, 37.5 126.9556914, 37.3834682
  //       zoom: 13,
  //       bearing: 0,
  //       pitch: 0
  //       });
  //       }

  //       currentPositionLon = currentPosition.coords.longitude;
  //       currentPositionLat = currentPosition.coords.latitude;

  //       //console.log(position.center[0]);
  //       //console.log(position.center[1]);

  //       //console.log(currentPosition.coords.longitude+', '+currentPosition.coords.latitude)

  //       }, function(error) {
  //       console.error(error);
  //       }, {
  //       enableHighAccuracy: false,
  //       maximumAge: 0,
  //       timeout: Infinity
  //       });
  //     }

  function currentPosition(latitude: string, longitude: string) {
    if (Const.DBG_OK) {
      alert("QrCode" + status + " " + JSON.stringify(latitude) + JSON.stringify(longitude));
    }
    //qr코드가 '' 빈값일때 qr인증실패로 이동
    currentPositionLat = Number(latitude)
    currentPositionLon = Number(longitude)

    if (position.center[0] === Number(longitude) && position.center[1] === Number(latitude)) {
      setPosition({
        center: [Number(longitude) + 0.00001, Number(latitude) + 0.00001], //127.0, 37.5  126.9556914, 37.3834682
        zoom: 13,
        bearing: 0,
        pitch: 0
      });
    } else {
      setPosition({
        center: [Number(longitude), Number(latitude)], //127.0, 37.5  126.9556914, 37.3834682
        zoom: 13,
        bearing: 0,
        pitch: 0
      });
    }
  }
  const getFinalEvStatus = () => {

    getPay(guid)
      .then((response: any) => {

        if (Const.DBG_OK) {
          alert('Pay \n' + JSON.stringify(response));
        }

        var resData = response;

        setUserData(resData);

        setInited(true);

      }).catch((response: any) => {
        console.log('Error!', response)

        // alert('Pay Error\n');
      });
  };

  const paymentRetry = () => {
    getCardStatus(guid)
      .then((response: any) => {
        if (response?.cardInfo?.cardStatus === 'OK' && response?.payInfo?.pay === null) {
          setPayType('Y');
          setPayNumber(response?.cardInfo?.card?.cardNumberDisplay);
          setPayTypeNm(response?.cardInfo?.card.cardCompany?.companyName);
          setPayImageUrl(response?.cardInfo?.card?.cardCompany?.ciImageUrl);
          setPayImageBg(response?.cardInfo?.card?.cardCompany?.bgColor);
          setPayKind('CARD');
          setIsCard('selected pay-btn');
          setBselectCard('true');
        } else if (response?.payInfo?.payStatus === 'OK' && response?.cardInfo?.card === null) {
          setPayType('Y');
          setPayNumber(response?.payInfo?.pay?.displayNumber)
          setPayTypeNm(response?.payInfo?.pay?.payCompany?.companyName);
          setPayImageUrl(response?.payInfo?.pay?.payCompany?.ciImageUrl);
          setPayImageBg(response?.payInfo?.pay?.payCompany?.bgColor);
          if(response?.payInfo?.pay?.paymentType === 'KB_PAY'){
            setPayKind('KB_PAY')
          }else {
            setPayKind('PAY')
          }
          setIsCard('selected pay-btn');
          setBselectCard('true');
        } else if (response?.cardInfo?.cardStatus === 'NO_CARD' || response?.cardInfo?.cardStatus === 'NO_PAY') {
          setPayType('N');
          setPayTypeNm('');
          setIsCard('NoFocus pay-btn');
          setBselectCard('신용/체크카드 등록하기');
          setPayImageUrl('');
          setPayImageBg('');
          setPayKind('');
        } else if (response?.cardInfo?.cardStatus === 'NO_PAY_MEANS') {
          setPayType('N');
          setPayTypeNm('');
          setIsCard('NoFocus pay-btn');
          setBselectCard('신용/체크카드 등록하기');
          setPayImageUrl('');
          setPayImageBg('');
          setPayKind('');
        } else if (response?.cardInfo?.cardStatus === 'NO_PIN' || response?.payInfo?.payStatus === 'NO_PIN') {
          setPayType('N');
          setPayTypeNm('');
          setIsCard('NoFocus pay-btn');
          setBselectCard('신용/체크카드 등록하기');
          setPayImageUrl('');
          setPayImageBg('');
          setPayKind('');
        } else if (response?.cardInfo?.cardStatus === 'CARD_EXPIRED') {
          setPayType('N');
          setPayTypeNm('');
          setIsCard('NoFocus pay-btn');
          setBselectCard('카드 유효기간 만료');
          setPayImageUrl('');
          setPayImageBg('');
          setPayKind('');
        } else if (response?.cardInfo?.cardStatus === 'CARD_MAINTENANCE' || response?.payInfo?.payStatus === 'PAY_MAINTENANCE') {
          setPayType('N');
          setPayTypeNm('');
          setIsCard('NoFocus pay-btn');
          setBselectCard('카드 점검 중');
          setPayImageUrl('');
          setPayImageBg('');
          setPayKind('');
        } else if (response?.cardInfo?.cardStatus === 'CORPCARD_AUTH_EXPIRED') {
          setPayType('N');
          setPayTypeNm('');
          setIsCard('NoFocus pay-btn');
          setBselectCard('법인공용카드 인증만료');
          setPayImageUrl('');
          setPayImageBg('');
          setPayKind('');
        } else if (response?.payInfo?.payStatus === 'NO_PAY') {
          setPayType('N');
          setPayTypeNm('');
          setIsCard('NoFocus pay-btn');
          setBselectCard('신용/체크카드 등록하기');
          setPayImageUrl('');
          setPayImageBg('');
          setPayKind('');
        } else {
          setPayType('N');
          setPayTypeNm('');
          setIsCard('NoFocus pay-btn');
          setBselectCard('신용/체크카드 등록하기');
          setPayImageUrl('');
          setPayImageBg('');
          setPayKind('');
        }
      })
  }

  // 결제수단 미선택 클릭시  
  // function selectCard(code : string, status : string) {

  //     // 회원결제카드 조회
  //     retrieveCardInfo(globalVars.accessKey)

  //     history.replace("/nonPay");
  // } 

  // 결제수단 미선택 클릭시  
  function selectCard(code: string, status: string) {

    // 회원결제카드 조회
    paymentRetry();

    history.replace("/nonPay");
  }

  const goPayChoiceView = () => {
    if (Const.DBG_OK) {
      alert("Pay - goPayChoiceView \n" + JSON.stringify(userData));
    }

    // alert( "Pay - goPayChoiceView \n" + JSON.stringify(userData) );

    tmapInterface.startPaymentRetryActivity(userData.orderId, selectCard);
  };

  // PIN 입력
  function selectPin(code: string, status: string) {

    // alert("slectPin - \n" + status);

    if (code != "0") {
      // alert('selectPin 실패')
      // PIN 입력 안할 시 미납 화면으로 이동 ( < , X )
      appManager.globalLoadingReset();
      history.replace("/nonPay");
    } else {
      // 결제 요청
      // const guid = store.getState().app.guid;

      var data = {
        guid: guid
      }

      patchPay(data)
        .then((response: any) => {
          if (response.result === true) {
            payStatusRefresh();
          }
        }).catch((error1: any) => {
          console.log(error1)
        });
    }
  }

  const payStatusRefresh = () => {
    if (payStatusCheckLoop != null) {
      clearInterval(payStatusCheckLoop);
    }
    payStatusCheckLoop = setInterval(() => {
      getPayStatusCheck();
    }, 2000); // 20초에 한 번씩 상태조회
  };

  // 결제요청
  const goPay = () => {
    appManager.globalLoadingInc();

    // const guid = store.getState().app.guid;

    var data = {
      guid: guid
    }
    patchPay(data)
      .then((response: any) => {
        if (response.result) {
          if (response.pinRequired) {
            tmapInterface.startPinActivity(userData.orderId, selectPin);
          } else {
            payStatusRefresh();
          }
        }
      })
  }

  const getPayStatusCheck = () => {
    // const guid = store.getState().app.guid;

    getPayStatus(guid)
      .then(function (response: any) {
        if (response.result) {
          if (response.payStatus === 5) { // SUCCESS
            appManager.globalLoadingReset();

            clearInterval(payStatusCheckLoop);

            store.dispatch(appActions.setGuid(guid));
            history.replace('/payComplete');
          } else if (response.payStatus === 6) { // FAIL
            appManager.globalLoadingReset();

            clearInterval(payStatusCheckLoop);

            modal.alert(() => {
              goPayChoiceView();
              // response.history.push('/finishCharge');
            }, "결제 수단에<br/> 오류가 발생하였습니다.<br/> 다른 결제 수단을 선택해주세요.");
          }
        }
      })
  }

  useEffect(() => {
    appManager.globalLoadingReset();

    window.scrollTo(0, scrollCache);

    paymentRetry();

    getFinalEvStatus();

  }, []);

  useEffect(() => {
    window.addEventListener('scroll', cacheScroll);
    return () => window.removeEventListener('scroll', cacheScroll);
  }, [cacheScroll]);

  useEffect(() => {
    // getLocation(); //현재 위치값은 처음 한번만 호출
    tmapInterface.getCurrentPosition(currentPosition);
  }, []);

  const borderBottomStyle = { width: "90%", margin: "0 auto", borderBottom: "2px solid #F8F9FA" };

  function ChargeInfo({ label, value }) {
    return (
      <div className="charge-info">
        <span className='head-value'>{label}</span>
        <span>{value}</span>
      </div>
    );
  }

  // render
  if (!inited) {
    return null
  } else {
    return (
      <>
        <Header title={'ㅤ'} isActive={'2'} isMenu={false} />
        <HandleBrowserBackButton />

        <div className="finish-charge">

          <div className='top-section'>
            <img src={require('%/images/common/nonpay-sign-check.svg').default} alt="" />
            <div className='finish-title'>미납 금액이 있습니다.</div>
          </div>

          <div className="finish-payinfo">
            <div className="coupon-box">
              <div className='info-list'>
                <span>총 금액</span>
                <span className='amount'>{utils.comma(userData.totalPrice)}원</span>
              </div>
              {userData.currentRatePrice === 0 || !userData.currentRatePrice ? (
                <></>
              ) : (
                <>
                  {" "}
                  <div className='info-list'>
                    <span>할인 쿠폰 사용</span>
                    <span className='coupon-section'>{utils.comma(userData.currentRatePrice)}원</span>
                  </div>
                </>
              )}
              {userData.currentPointPrice === 0 || !userData.currentPointPrice ? (
                <></>
              ) : (
                <>
                  <div className='info-list'>
                    <span>선불충전권 사용</span>
                    <span className='coupon-section'>{utils.comma(userData.currentPointPrice)}원</span>
                  </div>
                </>
              )}
              {userData.currentTmapPointPrice === 0 || !userData.currentTmapPointPrice ? (
                <></>
              ) : (
                <>
                  <div className='info-list'>
                    <span>포인트 사용</span>
                    <span className='coupon-section'>{utils.comma(userData.currentTmapPointPrice)}원</span>
                  </div>
                </>
              )}
            </div>

            <div className='credit-box'>
              <span className={isCard}>
                <span
                  className={payKind === 'PAY' ? "pay-logo-wrapper" : payKind === 'KB_PAY' ? "kb-pay-logo-wrapper":"card-logo-wrapper"}
                  style={payKind === 'KB_PAY' ?{}
                  :{
                    background: payImageBg !== "" ? payImageBg : "none",
                    padding: "1px",
                  }}
                >
                  {payTypeNm !== '' ? (
                    <img
                      src={payImageUrl}
                      alt=""
                      className={payKind === "PAY" ? "pay-logo" : payKind === "KB_PAY" ?"kb-pay-logo":"card-logo"}
                    />
                  ) : (
                    ""
                  )}
                </span>
                <button type="button" onClick={goPayChoiceView} style={payKind === 'KB_PAY'?{position:"relative", top:"-7px"}:{}}>{bselectCard === 'true' ? `${payTypeNm}(${payNumber})` : bselectCard}</button>
                {/* <span className='card'>{(userData.payPrice < 100 || !payTypeNm) ? '' : `${payTypeNm}(${payNumber})`}</span> */}
              </span>
              <span className='pay-price' style={{ color: "var(--semantic-color-red-500, #F24724)" }}>{utils.comma(userData.payPrice)} 원</span>
            </div>
          </div>

          <div className='charge-info-box'>
            <ChargeInfo label="충전소" value={Number(window.innerWidth) <= 320
              ? utils.textLengthOverCut(userData.stationName, 6, "...") : utils.textLengthOverCut(userData.stationName, 12, "...")} />
          </div>
          <div className='charge-info-box'>
            <ChargeInfo label="시작일시" value={utils.convert12H(userData.chargeStartDt)} />
            <div style={borderBottomStyle} />
            <ChargeInfo label="충전시간" value={(userData.chargeTime > 0) ? userData.chargeTime + " 분" : 0 + " 분"} />
            <div style={borderBottomStyle} />
            <ChargeInfo label="충전량" value={Number(userData.chargeKwh).toFixed(2) + " kWh"} />
          </div>
          <div className="button-box">
            <button type="button" className="button" onClick={goPay} style={{ color: "#fff", backgroundColor: "#0064ff" }}>결제하기</button>
          </div>
        </div>
      </>
    );
  }
}

export { NonPay };


