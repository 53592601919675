/* eslint-disable */

import instance from './frontman';

//cpo 목록 조회
export const getCpos = async (accessToken?: String) => {
    return await instance({
        url: "/cpos",
        method: "get"
    })
};