import React, { useState, useEffect, useCallback } from 'react';
import { Header } from '../../components/Layout';


let scrollCache = 0;
/**-------------------------------------------------------------
 1.2.1.3 이용안내 화면_2
 -------------------------------------------------------------
 > http://basic.pointweb.kr/charge/public/html/public/pay.html
 -------------------------------------------------------------*/
function PolestarUseGuide() {
  const [inited] = useState(false);

  const cacheScroll = useCallback(() => {
    // 간헐적으로 상세로 이동 후 호출되는 경우가 있어서 예외처리
    if (!/\/voice\/?$/.test(window.location.pathname)) return;
    scrollCache = window.scrollY;
  }, []);
  useEffect(() => {
    window.scrollTo(0, scrollCache);
  }, [inited]);
  useEffect(() => {
    window.addEventListener('scroll', cacheScroll);
    return () => window.removeEventListener('scroll', cacheScroll);
  }, [cacheScroll]);

  // render
  // if (!inited) return null
  return (
    <>
      <Header
        title={'폴스타 선불충전권 이용안내'}
        isInfoMenu={true}
        isActive={'2'}
        tabLabels={{ firstTab: "선불충전권 등록", secondTab: "선불충전권 이용" }}
      />

      <div className="polestar-guide">
        <div className="guide-body" style={{ margin: "0px 0"}}>
          {/* Step 1 */}
          <div className="guide-step" style={{ margin: "0px 0"}}>
            <div className="step-header" style={{ marginBottom: '20px' }}>
              <div className="step-number">방법 1</div>
            </div>
            <div className="step-middle-description" style={{ marginBottom: '10px' }}>
              차량 인도 시 지급 된 "선불충전카드"는 아이파킹 충전기 혹은 아이파킹 제휴 충전기에서 사용 가능합니다.
            </div>
            <div>
              <img src={require('%/images/promotion/img_polestar_cpos.png').default} alt="polestar-guide-cpos"
                   style={Number(window.innerWidth) > 440 ? { width: '320px', height: '357px' } : {}}
              />
            </div>
          </div>

          {/* Step 2 */}
          <div className="guide-step">
            <div className="step-header" style={{ marginBottom: '20px' }}>
              <div className="step-number">방법 2</div>
            </div>
            <div className="step-middle-description"style={{ marginBottom: '5px' }}>
              폴스타 차량에 설치된 티맵오토에서
              [충전소 이용하기] 기능을 통해 전기차 충전
              결제에 사용 가능합니다.
            </div>
            <div className="step-comments"style={{ marginBottom: '15px' }}>
              ※티맵 결제수단에 "선불충전카드" 등록 필요
            </div>
            <div>
              <img src={require('%/images/promotion/img_polestar_guide_1.png').default} alt="polestar-guide-img1" className="guide-image"
                   style={Number(window.innerWidth) > 440 ? { width: '320px', height: '353px' } : {}}
              />
            </div>
          </div>

          {/* Step 3 */}
          <div className="guide-step" style={{padding : '10px 20px 48px'}}>
            <div className="step-header" style={{ marginBottom: '20px' }}>
              <div className="step-number">방법 3</div>
            </div>
            <div className="step-middle-description"style={{ marginBottom: '20px' }}>
              티맵의 [전기차충전] 서비스 혹은 충전기에서
              아이파킹 회원번호를 입력하여 사용 가능합니다.
            </div>
            <div>
              <img src={require('%/images/promotion/img_polestar_guide_3.png').default} alt="polestar-guide-img3"
                   style={Number(window.innerWidth) > 440 ? { width: '320px', height: '154px'} : {}}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export { PolestarUseGuide };
