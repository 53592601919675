import { tmapApi } from '../libs/tmap-api';
import { appActions, store, voiceActions } from '../store';
import { tmapInterface } from '../libs/tmap-interface';
import { DefaultVoiceItem, ProductItem } from '../types/voice';
import { find, get, concat } from 'lodash-es';
import { utils } from '../libs/utils';
import { eventBus } from '../libs/event-bus';
import { HistoryManagerEvent } from '../components/HistoryManager';
import { modal } from '../components/GlobalModal';
import { nanoid } from 'nanoid';
import { globalVars } from '../libs/global-vars';

interface ProductListParams {
  productType: string;
  onSale: boolean;
  includeTest?: boolean;
}

const dispatch = store.dispatch;

const evManager = {
  /*
    초기화
  */
  initialize() {
    eventBus.on('nativeCallback.purchaseProductComplete', evManager.onPurchaseProductComplete);
    eventBus.on('nativeCallback.onStopTTS', () => dispatch(voiceActions.setPlayingGuideType('')));
    eventBus.on(HistoryManagerEvent.CHANGE_START, evManager.stopTTS);
    window.addEventListener('beforeunload', evManager.stopTTS);
  },

  /*
    api - store
  */
  async fetchBannerList(config?: any) {
    const { data } = await tmapApi.getBannerList({ adType: 'IN71' }, config);
    dispatch(voiceActions.setBannerList(data.advtBandBannerDetails));
  },
  getProductListParams(onSale: boolean): ProductListParams {
    const params: ProductListParams = { productType: 'VOICE', onSale };
    if (globalVars.testProductEnabled) params.includeTest = true;
    return params;
  },
  async fetchProductList(config?: any) {
    const { data } = await tmapApi.getProductList(evManager.getProductListParams(true), config);
    dispatch(voiceActions.setProductList(data.products || []));
  },
  async fetchExpiredProductList(config?: any) {
    const { data } = await tmapApi.getProductList(evManager.getProductListParams(false), config);
    dispatch(voiceActions.setExpiredProductList(data.products || []));
  },
  async fetchPurchaseList(config?: any) {
    const { data } = await tmapApi.getPurchaseList(
      {
        productType: 'VOICE',
        availableDate: utils.dateToText(),
        purchaseState: 'NORMAL',
      },
      config,
    );
    dispatch(voiceActions.setPurchaseList(data.purchaseItems || []));
  },
  async fetchPaymentAgree(config?: any) {
    const { data } = await tmapApi.getPaymentServiceAgreementsChecked({ termsListType: 'PAID_SERVICE_01' }, config);
    dispatch(voiceActions.setPaymentAgree(data.allowYn === 'Y'));
  },
  async pushPaymentAgree(isAgree: boolean, config?: any) {
    await tmapApi.updatePaymentServiceAgreementsChecked({ termsListType: 'PAID_SERVICE_01', allowYn: isAgree ? 'Y' : 'N' }, config);
    dispatch(voiceActions.setPaymentAgree(isAgree));
  },
  async fetchSelectedGuideType() {
    // todo : getVoiceGuideType interface 동작 확인 필요
    // await new Promise((resolve, reject) => {
    //   tmapInterface.getVoiceGuideType(guideType => resolve(guideType));
    // });
  },

  /*
    functions
  */
  getProductData(productId: string): Nullable<ProductItem> {
    const voiceState = store.getState().voice;
    const fullList = concat(voiceState.saleProductList, voiceState.expiredProductList);
    return find(fullList, item => item?.productId === productId) || null;
  },
  setGuideType(productData: ProductItem | DefaultVoiceItem) {
    const { productId, name } = productData;
    const cdn = get(productData, 'feature.features.cdn', '') || "";
    const thumbnailUrl = get(productData, 'cover.thumbnailUrl', '') || "";
    dispatch(voiceActions.setSelectedGuideType(productId));
    localStorage.setItem('voiceGuideType', productId);
    tmapInterface.setVoiceGuideType(productId, name, cdn, thumbnailUrl);
  },
  playTTS(productData: ProductItem) {
    /*
      재생중 stopTTS 호출하면 네이티브에서 stop하는데 delay가 발생 됨. (callback방식으로 제어된다고 함.)
      stop완료 후 play 요청하기 위해 delay 적용
    */
    const voiceState = store.getState().voice;
    const { productId, feature } = productData;
    const isPlaying = !!voiceState.playingGuideType;
    const isTargetPlaying = productId === voiceState.playingGuideType;
    if (isPlaying) {
      evManager.stopTTS();
      if (!isTargetPlaying) setTimeout(_callTTS, 100);
    } else {
      _callTTS();
    }
    function _callTTS() {
      const cdn = get(feature, 'features.cdn', '');
      const preview = get(feature, 'features.preview', '');
      dispatch(voiceActions.setPlayingGuideType(productId));
      tmapInterface.playTTS(productId, cdn, preview, null);
    }
  },
  stopTTS() {
    const voiceState = store.getState().voice;
    const isPlaying = !!voiceState.playingGuideType;
    if (isPlaying) tmapInterface.stopTTS();
  },
  paymentAgreeCheck() {
    const voiceState = store.getState().voice;
    if (voiceState.paymentAgreeChecked) return Promise.resolve();
    return new Promise<void>((resolve, reject) => {
      modal.voiceServiceAgreement((result: boolean) => {
        if (result) evManager.pushPaymentAgree(true).then(() => resolve());
      });
    });
  },
  /*
    구매하기 버튼 클릭시 핸들러
    동의여부 체크, 구매한 아이템 갱신 후 유료/무료 구분 호출
  */
  async callPurchase(productData: ProductItem) {
    evManager.stopTTS();
    await Promise.all([evManager.paymentAgreeCheck(), evManager.fetchPurchaseList({ loading: false })]);
    const { productId } = productData;
    const isPaidItem = !!store.getState().voice.purchaseList?.find(item => item.itemId === productId);
    const price = get(productData, 'sale.price.sellingPrice');
    const cdn = get(productData, 'feature.features.cdn');
    if (!isPaidItem) {
      // 무료 인경우 직접 api 호출
      if (price === 0) this.purchaseFreeProductItem(productData);
      // 결제 인터페이스 호출
      else {
        dispatch(appActions.setGlobalCover(true));
        tmapInterface.purchaseProduct(productId, price, cdn);
      }
    }
  },
  /*
    무료상품 구매
  */
  async purchaseFreeProductItem(productData: ProductItem) {
    const purchaseItem = { itemId: productData.productId, purchaseToken: `FREE-${nanoid(8)}` };
    const res = await tmapApi.registerPayment({ purchaseItem });
    dispatch(voiceActions.addPurchaseProductItem(res.data.purchaseItem));
    evManager.setGuideType(productData);
    evManager.alertPurchaseComplete();
  },
  /*
    쿠폰으로 구매
  */
  async purchaseByCoupon(productData: ProductItem, couponNumber: string) {
    const purchaseItem = { itemId: productData.productId, purchaseToken: `COUPON-${nanoid(8)}` };
    const res = await tmapApi.registerPayment({ purchaseItem, couponNumber }, {skipErrorHandler: true});
    const { errorCode, errorDetailCode } = res.data.header
    if (errorCode === '000000') {
      modal.alert(() => {
        dispatch(voiceActions.addPurchaseProductItem(res.data.purchaseItem));
        evManager.setGuideType(productData);
      }, '쿠폰이 정상적으로 등록됐습니다.');
    } else {
      let message
      switch(errorDetailCode) {
        case 'COUPON_FORMAT_ERROR': //잘못된 쿠폰형식
        case 'COUPON_NOTISSUED_ERROR': //미발급 쿠폰
          message = '올바르지 않은 쿠폰입니다.'
          break
        case 'COUPON_ALREADYUSED_ERROR': //이미 사용한 쿠폰
          message = '이미 사용된 쿠폰입니다.'
          break
        case 'COUPON_PRODUCTMATCH_ERROR': //해당 상품에 사용불가한 쿠폰
          message = '이 상품에 사용할 수 없는 쿠폰입니다.' // 기획서에 없음
          break
        case 'COUPON_EXPIRED_ERROR': //쿠폰기간 만료
          message = '유효기간이 만료된 쿠폰입니다.' // 기획서에 없음
          break
        case 'COUPON_REGISTPROC_ERROR': //쿠폰 사용처리시 오류 발생
        default:
          message = '오류가 발생했습니다.<br/>고객센터에 문의해주세요.'
      }
      modal.alert(message)
    }
  },
  /*
    유료상품 구매 (네이티브) 후 callback
  */
  onPurchaseProductComplete(isOK: boolean, productId: string, purchaseDataString: string) {
    dispatch(appActions.setGlobalCover(false));
    if (!isOK) return;

    const productData = evManager.getProductData(productId);
    const hasNativeData = !!purchaseDataString;
    if (productData) evManager.setGuideType(productData);
    if (hasNativeData) {
      const purchaseData = JSON.parse(purchaseDataString);
      dispatch(voiceActions.addPurchaseProductItem(purchaseData));
      evManager.alertPurchaseComplete(() => {
        evManager.moveToDetailPage(productId);
      });
    } else {
      evManager.alertPurchaseComplete(() => {
        evManager.fetchPurchaseList().then(() => evManager.moveToDetailPage(productId));
      });
    }
  },
  alertPurchaseComplete(callback?: Nullable<(...args: any[]) => any>) {
    modal.alert(callback, '성공적으로 구입이 완료되었습니다.', '결제완료');
  },
  moveToDetailPage(productId: string) {
    const targetPath = `/voice/${productId}`;
    if (window.location.pathname !== targetPath) eventBus.emit(HistoryManagerEvent.PUSH_HISTORY, targetPath);
  },
};

export { evManager };
