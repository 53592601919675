
/* eslint-disable */

import React, { useEffect, useRef, useState } from "react";

import ProgressBar from "@ramonak/react-progress-bar";
import Lottie from "lottie-react";
import ProgressAnimation from "./progress.json";

/**-------------------------------------------------------------
1.2.2.1 충전 중 화면 - 인디케이터
-------------------------------------------------------------*/
const Indicator = (props) => {
	const lottieRef = useRef();

	return (
		<>
			<div className='top-section'>
				<img src={props.chargerStatus === "충전중" ? require('%/images/common/charging-sign.svg').default : require('%/images/common/charging-sign-off.svg').default} alt="" />
				<div className='finish-title'>
					{props.chargerStatus === "충전중" ?
						<>
							{(props.chargerSpeed === "SLOW") ?
								<>
									<span className="hour" style={{ display: Math.floor(Number(props.chargingData.elapsedChargeTime) / 60) > 0 ? 'initial' : 'none' }}>
										{Math.floor(Number(props.chargingData.elapsedChargeTime) / 60)}시간
									</span>
									<span className="minute">
										{" "}{Number(props.chargingData.elapsedChargeTime) % 60}분 충전 중
									</span>
								</>
								:
								<>{Math.floor(Number(props.chargingData.socPer))}% 충전 중</>
							}
						</>
						:
						<>충전 대기 중</>
					}
					<span className='kwh'>({Number(props.chargingData.chargeStatusKwh).toFixed(2)} kWh)</span>
				</div>
				{props.chargerStatus === "충전중" ?
					<span className="warring-txt">
						충전 중에는 커넥터를 강제로 분리하지 마세요.
					</span>
					:
					<span className="warring-txt">
						차량에 커넥터를 연결하면 충전이 시작됩니다.
					</span>
				}
			</div>
			<button type="button" id="refresh_btn" className="change-button" onClick={props.onClickRefresh}></button>
			<div className="progress-bar">
				{(props.chargerSpeed === "SLOW") ?
					(
						<>
							{
								props.chargerStatus === "충전중" ?
									<Lottie animationData={ProgressAnimation} />
									:
									<ProgressBar
										completed={props.chargerStatus === "충전중" ? 100 : 0}
										transitionTimingFunction="linear"
										animateOnRender={true}
										bgColor="linear-gradient(to right, #783CFF 20%, #0064FF)"
										isLabelVisible={false}
										height="18px"
										baseBgColor="#EEF0F3"
									/>
							}
						</>
					)
					:
					(
						<>
							<ProgressBar
								completed={props.chargerStatus === "충전중" ? (Math.floor(Number(props.chargingData.socPer)) > 0 ? Math.floor(Number(props.chargingData.socPer)) : 0) : 0}
								transitionTimingFunction="linear"
								animateOnRender={true}
								bgColor="linear-gradient(to right, #783CFF 20%, #0064FF)"
								isLabelVisible={false}
								height="18px"
								baseBgColor="#EEF0F3"
							/>
							{/* <Lottie animationData={ProgressAnimation} /> */}
						</>
					)
				}
			</div>
		</>
	);
}
export { Indicator };