/* eslint-disable */

import React, { useState, useEffect, useCallback } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useHistory } from "react-router";
import { tmapInterface } from '../../libs/tmap-interface';
import instance from '../../apis/frontman';
import { appManager } from '../../managers';
import { globalVars } from '../../libs/global-vars';

function Maintenance(props: RouteComponentProps<{ url: any, method: any, data: any }>) {
  const history = useHistory();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const url:any = urlParams.get('url');
  const method:any = urlParams.get('method');
  const data:any = urlParams.get('data');
  const dataParse = (data && data !== "undefined") ? JSON.parse(data) : data;

  // const url = props.match.params.url;
  // const method = props.match.params.method;
  // const data = (props.match.params.data && props.match.params.data !== "undefined") ? JSON.parse(props.match.params.data) : props.match.params.data;

  const [pressRetry, setPressRetry] = useState(false); // 재시도 프레스

  const goBack = () => {
    tmapInterface.onBackKeyPressed();
  }

  const axiosApi = async () => {
    return await instance({
      url: url,
      method: method,
      data: dataParse
    })
  }

  const retry = async () => {
    appManager.globalLoadingInc();
    axiosApi()
      .then((response: any) => {
        appManager.globalLoadingReset();
        history.goBack();
      })
      .catch((error: any) => {
        appManager.globalLoadingReset();
      })
  }

  return (
    <>
      <div className="maintenance">
        <div className="header">
          <img src={require('%/images/common/arrow-left.svg').default} alt="" onClick={() => goBack()} />
        </div>
        <div className="content">
          <img src={require('../../assets/images/common/icon-common-notice.png').default} alt="" />
          <div className="big-content">서비스 점검 중입니다.<br />잠시 후 다시 시도해주세요.</div>
          <div className="small-content">소요시간은 최대 1분으로<br />이 후 정상적인 서비스 이용이 가능합니다.</div>
          <button onClick={() => retry()} onTouchStart={() => setPressRetry(true)} onTouchEnd={() => setPressRetry(false)} style={pressRetry ? { filter: "brightness(90%)" } : {}}>재시도</button>
        </div>
      </div>
    </>
  );
}
export { Maintenance };