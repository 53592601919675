/* eslint-disable */
import instance from './frontman';

//쿠폰 자동사용
export const patchCoupon = async (body: object, accessToken?: String) => {
    return await instance({
        url: "/coupon",
        method: "patch",
        data: body
    })
};

//쿠폰 선택
export const patchUseCoupon = async (param: string, body: object, accessToken?: String) => {
    return await instance({
        url: `/coupon/${param}`,
        method: "patch",
        data: body
    })
};

//쿠폰 조회
export const getCoupon = async (accessToken?: String) => {
    return await instance({
        url: `/coupon`,
        method: "get"
    })
};

//쿠폰 상세 조회
export const getCouponHistory = async (param: string, accessToken?: String) => {
    return await instance({
        url: `/coupon/${param}`,
        method: "get"
    })
};

//쿠폰 등록
export const postCoupon = async (body: object, accessToken?: String) => {
    return await instance({
        url: `/coupon`,
        method: "post",
        data: body
    })
};

//쿠폰 단건 조회
export const getCouponGuid = async (param: string) => {
    return await instance({
        url: `/coupon/guid/${param}`,
        method: "get"
    })
}

//전사포인트 조회
export const getPoint = async (accessToken?: String) => {
    return await instance({
        url: `/point`,
        method: "get",
        params: {
            deviceType: 1
        }
    })
};

//전사포인트 토글
export const patchPointToggle = async (body: object) => {
    return await instance({
        url: `/point/auto-use`,
        method: "patch",
        data: body
    })
};

//플러그링크 선착순쿠폰 프로모션
export const postNonCode = async (body: object) => {
    return await instance({
        url: `/coupon/nonCode`,
        method: "post",
        data: body
    })
};

//쿠폰 키 조회
export const getCouponKey = async (param: string) => {
    return await instance({
        url: `/coupon/key?productCode=${param}`,
        method: "get"
    })
}

//파킹클라우드 업데이트(쿠폰)
export const patchCouponActive = async (body: object) => {
    return await instance({
        url: `/coupon/active`,
        method: "patch",
        data: body
    })
};