/* eslint-disable */

import instance from './frontman';

//pnc 동의 업데이트
export const patchPncAgreement = async (body: object) => {
    return await instance({
        url: "/pnc/agreement",
        method: "patch",
        data: body
    })
};

//유저 차량 정보 조회
export const getCarInfo = async () => {
    return await instance({
        url: "/pnc/car-info",
        method: "get"
    })
};

//브랜드 종류 조회
export const postFindBrand = async () => {
    return await instance({
        url: "/pnc/find-brand",
        method: "post"
    })
};

//브랜드별 모델 조회
export const postFindModel = async (body: object) => {
    return await instance({
        url: "/pnc/find-model",
        method: "post",
        data: body
    })
};

//유저 차량 정보 등록
export const postCarInfo = async (body: object) => {
    return await instance({
        url: "/pnc/car-info",
        method: "post",
        data: body
    })
};

//유저 차량 정보 수정
export const patchCarInfo = async (body: object) => {
    return await instance({
        url: "/pnc/car-info",
        method: "patch",
        data: body
    })
};

//유저 차량 정보 삭제
export const deleteMacRemove = async (param: string) => {
    return await instance({
        url: `/pnc/${param}/mac-remove`,
        method: "delete"
    })
};

//QR 인증(충전기 체크)
export const postPncCoupler = async (body: object) => {
    return await instance({
        url: "/pnc/coupler",
        method: "post",
        data: body
    })
};

//QR 인증(인증 요청)
export const patchPncAuth = async (body: object) => {
    return await instance({
        url: "/pnc/auth",
        method: "patch",
        data: body
    })
};

//pnc 팝업 업데이트
export const patchPncPopupUpdate = async (body: object) => {
    return await instance({
        url: "/pnc/mac-popup-update",
        method: "patch",
        data: body
    })
};

export const getCarProfile = async () => {
    return await instance({
        url: "/car-info/$userKey",
        method: "get",
        baseURL: `${process.env.REACT_APP_FRONTMAN_API_URL}/flerken/api/v3`
    })
};