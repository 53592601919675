/**-------------------------------------------------------------
1.2.1.5 충전소 인식 화면_3
-------------------------------------------------------------
> http://basic.pointweb.kr/charge/public/html/public/charger1.html
-------------------------------------------------------------*/

/* eslint-disable */

import React, { useState, useEffect, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useHistory } from "react-router";

import { globalVars } from '../../libs/global-vars';

// 공통
import { Header } from '../../components/Layout';
import { HandleBrowserBackButton } from '../../components/Layout'

import axios from 'axios';
import Const from '../../store/ev';
import { appActions, store } from '../../store';
import { utils } from '../../libs/utils';
import { patchTapCharge, postTapCharge } from '../../apis/auth';
import { getAuthStatus } from '../../apis/status';


let scrollCache = 0;
let StopCnt = 0;
let timer: Nullable<number> = null;
let qrTimer: Nullable<number> = null;
let qrStatus: any = {};
var qrConfirmCheckLoop: any = null;
let qrType: any = {};


let stationInfo: any = {}
let easyPayData: any = {}

const dispatch = store.dispatch;

// const ENV_COUPLER_TYPES = ["03", "05", "06"];
// const KE_COUPLER_TYPES = ["6", "7", "8", "9"];
// const SE_COUPLER_TYPES = ["DC_CHADEMO_AND_AC_THREE_PHASE", "DC_CHADEMO_AND_DC_COMBO", "DC_CHADEMO_AND_AC_THREE_PHASE_AND_DC_COMBO"];

function TapChargeAuth(props: RouteComponentProps<{ id: string }>) {
    const [inited, setInited] = useState(false);
    // const [stationName, setstationName] = useState('');
    // const [loading, setLoading] = useState(false);
    // const [qrWait, setQrWait] = useState(false);
    const [chkData, setChkData] = useState(false);

    const history = useHistory();

    const [belongIdx, setBelongIdx] = useState('');
    // const [poiId, setPoiId] = useState('');
    const [data, setData] = useState();
    const [cpoCode, setCpoCode] = useState('');

    qrType = store.getState().app.qrType;

    stationInfo = store.getState().app.stationInfo; 
    easyPayData = store.getState().app.easyPayData;

    const cacheScroll = useCallback(() => {
        // 간헐적으로 상세로 이동 후 호출되는 경우가 있어서 예외처리
        if (!/\/voice\/?$/.test(window.location.pathname)) return;
        scrollCache = window.scrollY;
    }, []);

    useEffect(() => {
        // switch (stationInfo.ev_chargers[0].operator_id) {
        //     case 'CV':
        //         setBelongIdx('0');
        //         break;
        //     case 'EV':
        //         setBelongIdx('1');
        //         break;
        //     case 'ME':
        //         setBelongIdx('2');
        //         break;
        //     case 'PI':
        //         setBelongIdx('3');
        //         break;
        //     case 'KP':
        //         setBelongIdx('4');
        //         break;
        //     case 'SF':
        //         setBelongIdx('5');
        //         break;
        // }

        setCpoCode(easyPayData.operatorId);
        // setPoiId(stationInfo.poiId);

        setChkData(true);

    }, []);

    useEffect(() => {
        window.scrollTo(0, scrollCache);
    }, [inited]);

    useEffect(() => {
        // setInited(true);

        // checkConnectStatus();
        if (chkData) {
            chkOperatorId();
        }
    }, [chkData]);

    const chkOperatorId = () => {

        var PostTapAuthdata = {
            // poiId: poiId,
            cpoCode: cpoCode,
            couplerIdMe: easyPayData.stationId + easyPayData.chargerId
        };

        postTapCharge(PostTapAuthdata)
            .then(function (response: any) {

                // alert(JSON.stringify(response));

                if (Const.DBG_OK) {
                    console.log('GUID 획득 성공')
                    alert('chkQrCode - DATA \n' + JSON.stringify(response))
                }

                // alert('chkQrCode - DATA \n'+ JSON.stringify(response));
                
                if (response.result) {

                    // if(typeof(response.guid) === "undefined") {
                    // 	// checkQrCodeMsg();
                    // 	props.history.push('/authFail')

                    // } else {
                    //setstationName(response.station_name);

                    if (Const.DBG_OK) {
                        console.log('GUID 획득 성공')
                        alert('chkQrCode - station_name')
                    }

                    /***************************** 환경부 또는 차지비 충전기이고, 다중 커넥터이면 분기 처리 *******************************/
                    if(response.chargerType.length > 1){
                    // if ((cpoCode === "ME" || cpoCode === "CV") && (ENV_COUPLER_TYPES.indexOf(response.couplerType) >= 0)) {
                        // 3초간 충전소 정보 보여준 후, 커플러 선택 페이지 이동
                        if (timer) clearTimeout(timer);
                        timer = window.setTimeout(
                            () => 
                            history.push({
                                pathname:`/tapChargeMultiCoupler`,
                                props:{
                                    couplerData : [{
                                        chargerType : response.chargerType,
                                        guid : response.guid,
                                        // accessKey : globalVars.accessKey,
                                        cpoCode : response.cpoCode,
                                        couplerId : response.couplerId
                                    }]
                                }
                            }),
                                // '/qrMultiCoupler?couplerType='+response.charger_type+'&guid='+response.guid+'&accessKey='+accessKey+'&qr_code='+qr_code+'&cpoCode='+response.belong_idx), 
                            3000);	

                        // props.history.push('/tapChargeMultiCoupler?chargerType=' + response.chargerType + '&guid=' + response.guid + '&accessKey=' + globalVars.accessKey + '&cpoCode=' + cpoCode + '&couplerId=' + response.couplerId + '&poi_id=' + poiId);
                    }
                    /***************************** 한국전력 충전기이고, 다중 커넥터이면 분기 처리 *******************************/
                    // else if( ((response.cpoCode === "KP" ) && (response.chargerType.length > 1)) ){
                    //  // 3초간 충전소 정보 보여준 후, 커플러 선택 페이지 이동
                    //         // if (timer) clearTimeout(timer);
                    //         // timer = window.setTimeout(
                    //         //     () => history.push({
                    //         //         pathname:`/couplerEasy`,
                    //         //         props:{
                    //         //             couplerData : [{
                    //         //                 chargerType : response.chargerType,
                    //         //                 guid : response.guid,
                    //         //                 accessKey : globalVars.accessKey,
                    //         //                 cpoCode : belongIdx,
                    //         //                 couplerId : response.couplerId,
                    //         //                 poi_id : poiId
                    //         //             }]
                    //         //         }
                    //         //     }),
                    //         //     // () => props.history.push('/coupler?chargerType='+response.charger_type+'&guid='+response.data.guid+'&accessKey='+accessKey+'&qr_code='+qr_code+'&cpoCode='+response.data.belong_idx), 
                    //         //     3000);
                            
                    //         props.history.push('/tapChargeMultiCoupler?chargerType='+response.chargerType+'&guid='+response.guid+'&accessKey='+globalVars.accessKey+'&cpoCode='+belongIdx+'&couplerId='+response.couplerId+'&poi_id='+poiId);
                    // }
                    else {
                        /***************************** 그렇지 않으면 ****************************/
                        var PatchTapAuthdata = {
                            guid: response.guid,
                            couplerType: response.chargerType[0]
                        }

                        patchTapCharge(PatchTapAuthdata)
                            .then(function (response2: any) {

                                if (qrTimer) clearTimeout(qrTimer);

                                if (Const.DBG_OK) {
                                    alert('chkQrCode - updateCERT \n' + JSON.stringify(response2));
                                    console.log(JSON.stringify(response2));
                                }

                                globalVars.connCheckLoop = setInterval(() => {

                                    getAuthStatus(response2.guid)
                                    .then((response3: any) => {
                                        if(response3.authStatus === 4 || response3.authStatus === 6 ) { //성공
                                            // StopCnt = 0;
                                            clearInterval(globalVars.connCheckLoop);
                
                                            dispatch(appActions.setGuid(response2.guid));
                                            props.history.push(`/charging/${response2.guid}`);
                
                                        } else if(response3.authStatus === 3 || response3.authStatus === 5 || response3.authStatus === 7 || response3.authStatus === 8) { //실패
                                            clearInterval(globalVars.connCheckLoop);
                                            
                                            props.history.push('/authFail');
                                            
                                        }
                                    }).catch((response3) => {
                                        console.log('Error!',response3)
                                    });
    
                                }, 2000); //2초에 한 번씩 조회

                            })
                            .catch(function (error2: any) {

                                if (qrTimer) clearTimeout(qrTimer);

                                if (Const.DBG_OK) {
                                    alert('chkQrCode - authFail \n' + error2);
                                }

                                props.history.push('/authFail');
                            });
                    }
                    // }
                } else {
                    props.history.push('/authFail');
                }

            })
            .catch(function (error: any) {
                props.history.push('/authFail');
            });
    }

    if (0) {
        return (
            <>
                {/* <div id="page">
					<div className="qr-chager">
						<div className="txt-box">
							<p className="txt1">ㄱㄴㄷㄹㅁㅂㅅㅇㅈㅊㅋㅌㅍㅎㅂㅇㅇㄴㅇㄷㅇㄴㅇㄷㅇㅈㄷㅇㅎㅇㄷㄴㅇㄷㅇㅊ</p>
							{ stationName!='' &&
								<p className="txt2">
									<span className="underline">충전기가 인식</span>되었습니다.
								</p>
							}
						</div>
					</div>
				</div> */}
            </>
        );
    } else {
        return (
            <>
                <Header isMenu={false} />
                <HandleBrowserBackButton />

                <div id="page">
                    <div className="qr-chager">
                        <div className="txt-box">
                            {/* <p className="txt1"> {Number(window.innerWidth) <= 320 ? utils.textLengthOverCut(stationName, 6, '...') : utils.textLengthOverCut(stationName, 14, '...')} </p> */}
                            <p className="txt2">
                                <span>TMAP PLUG 인증 중입니다.</span>
                            </p>
                            <p className="txt1">
                                <p><span>잠시만 기다려주세요.</span></p>
                            </p>
                            {/* { stationName!='' && qrWait == false &&
							<p className="txt2">
								<span className="underline">충전기가 인식</span>되었습니다.
							</p>
						} */}
                        </div>
                    </div>
                </div>
            </>
        )
    };

    // render
    // if (!inited) return null

}

export { TapChargeAuth };
