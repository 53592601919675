/* eslint-disable */

import qs from 'qs';
import commaNumber from 'comma-number';
import dateformat from 'date-format';
import { eventBus } from './event-bus';
import { tmapInterface } from './tmap-interface';
import { AppSchemeParams } from './global-vars';
import { HistoryManagerEvent } from '../components/HistoryManager';
import moment from 'moment';
import { globalVars } from "../libs/global-vars";
import { modal } from '../components/GlobalModal';

type DateTextType = 'date' | 'time' | 'full' | null;

const utils = {
  // 서버에서 주는 형식을 표현텍스트로 변환 20210614235959 => 2021.06.14 23:59:59
  dateText(value: string, type: DateTextType = 'date') {
    switch (type) {
      case 'full':
        return value.replace(/^(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1.$2.$3 $4:$5:$6');
      case 'time':
        return value.replace(/^(\d{4})(\d{2})(\d{2})(\d{2})(\d{2}).*/, '$1.$2.$3 $4:$5');
      case 'date':
      default:
        return value.replace(/^(\d{4})(\d{2})(\d{2}).*/, '$1.$2.$3');
    }
  },
  dateString(value: string, type: DateTextType) {
    return this.dateText(value, type).replace(/\./g, '/');
  },
  comma(value: string | number) {
    return commaNumber(value);
  },
  getOffsetTop(el: HTMLElement, result = 0): number {
    if (typeof el.offsetTop !== 'number') return result;
    result += el.offsetTop;
    return el.offsetParent ? this.getOffsetTop(el.offsetParent as HTMLElement, result) : result;
  },
  dateToText(date?: Nullable<Date>) {
    if (!date) date = new Date();
    return dateformat('yyyyMMddhhmmss', date);
  },
  /*
    앱에서 처음 셀럽을 열었는지 판단.
    querystring으로 데이터(guidetype, pageid, productid)를 전달받기 때문에 
    외부페이지 이동 후 history.back()시 이미 변경된 상태를 querystring내용으로 다시 덮을 수 있음.
    history.length가 1인 경우에만 querystring을 받고. 아니면 local 저장소를 사용하기 위함.
  */
  isFirstLanding() {
    return window.history.length === 1;
  },
  linkToCustomUrl(url: string) {
    if (/^[a-z]*:\/\//.test(url)) tmapInterface.openBrowser(url);
    // 프로토콜 포함된 주소면 외부연결
    else {
      const urlData: AppSchemeParams = qs.parse(url);
      if (urlData.pageid === 'celeb') eventBus.emit(HistoryManagerEvent.PUSH_HISTORY, `/voice/${urlData.productid}`);
    }
  },
  linkToAppDownload() {
    tmapInterface.openBrowser('https://play.google.com/store/apps/details?id=com.skt.tmap.ku&launch=true');
  },
  regExp_date(value: string) {
    var result: string = "";
  	var regExp = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi;
  	if(regExp.test(value)) {
  		result = value.replace(regExp, "");
  	} else {
  		result = value;
  	}
  	result = result.replace(/ /gi, "");
  	return result;
  },
  
  convertDateFormat(value: string, type: string) {
  	var result: string = "";
  	if(type === 'len') {
	    if(value.length === 8) {
	    	result = value.replace(/^(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
	    } else if(value.length === 12) {
	    	result = value.replace(/^(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3 $4:$5');
	    } else {
	    	result = value.replace(/^(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1-$2-$3 $4:$5:$6');
	    }
  	}
  	return result;
  },
  
  dateDiffMinute(startDate: string, endDate: string) {
    

    var sDate1 = this.convertDateFormat(this.regExp_date(startDate), 'len');
    var sDate2 = this.convertDateFormat(this.regExp_date(endDate), 'len');
   
    var date1 = new Date(sDate1);     // 현재 
    var date2 = new Date(sDate2);     // 파라미터
   
    var elapsedMSec = date2.getTime() - date1.getTime(); 
    
    var elapsedMin = elapsedMSec / 1000 / 60; 
    
    elapsedMin = Math.round(Number(elapsedMin));    // 정수 표기를 위해 parseInt
    
    return elapsedMin;

  },

  

  // 시간을 yyyy-mm-dd hh:mm:ss 형태로 부터 오전(오후)로 변경
  convert12H( inTime : string ) { 
    var date = moment(inTime).format('YYYY.MM.DD '); // 'yyyy.mm.dd' 형태로 값이 들어온다
    var time = inTime.slice(11, 16); // 'hh:mm' 형태로 값이 들어온다
    var getTime = time.substring(0, 2); // 시간(hh)부분만 저장
    var intTime = parseInt(getTime); // int형으로 변환
    if (intTime < 12 ) { 
      // intTime이 12보다 작으면
      var str = '오전 '; // '오전' 출력
    } else { 
      // 12보다 크면
      var str = '오후 '; 
      // '오후 출력'
    } // intTime이 12면 변환 후 그대로 12

    // 나머지경우엔 intTime을 12로 나눈 나머지값이 변환 후 시간이 된다
    if (intTime == 12) {
      var cvHour = intTime;
    } else {
      var cvHour = intTime%12;
    } 
    // 'hh:mm'형태로 만들기

    var res = str + ('0' + cvHour).slice(-2) + time.slice(-3);

    return date.slice(0,11) + res; 
  },

  polarToCartesian(centerX:any, centerY:any, radius:any, angleInDegrees:any) {
    var angleInRadians = (angleInDegrees-90) * Math.PI / 180.0;
  
    return {
      x: centerX + (radius * Math.cos(angleInRadians)),
      y: centerY + (radius * Math.sin(angleInRadians))
    };
  },
  
  describeArc(x:any, y:any, radius:any, startAngle:any, endAngle:any){
  
      var start = this.polarToCartesian(x, y, radius, endAngle);
      var end = this.polarToCartesian(x, y, radius, startAngle);
  
      var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
  
      var d = [
          "M", start.x, start.y, 
          "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
      ].join(" ");
  
      return d;       
  },
  
  getInputDayLabel(date:any) {
    
    var week = new Array('일', '월', '화', '수', '목', '금', '토');
    let yoil = moment(date).day()
    
    return week[yoil];
  },

  dateFormat(date:string, flag:any) {
    let temp = date.split(" ");
    let formatDate = "";
    let yoil = utils.getInputDayLabel(date);
    
    if(flag == "D"){

      formatDate = temp[0].substring(8,10)+"일"+"("+yoil+")";
      
    }else{
      formatDate = temp[1].split(":")[0] + ":" +temp[1].split(":")[1];
    }
    return formatDate;
  },

   /** @param txt<br/>
     *  @param len : 생략시 기본값 20 <br/>
     *  @param lastTxt : 생략시 기본값 "..."<br/>
     *  @returns 결과값
     * <br/>
     * <br/>
     * 특정 글자수가 넘어가면 넘어가는 글자는 자르고 마지막에 대체문자 처리<br/>
     *  ex) 가나다라마바사 -> textLengthOverCut('가나다라마바사', '5', '...') : 가나다라마...<br/>
     */
  textLengthOverCut(txt, len, lastTxt) {
    if (txt) {
      if (len == "" || len == null) { // 기본값
          len = 20;
      }
      if (lastTxt == "" || lastTxt == null) { // 기본값
          lastTxt = "...";
      }
      if (txt.length > len) {
          txt = txt.substr(0, len) + lastTxt;
      }
      return txt;
    } else {
      return txt;
    }
  }
};

export { utils };
