import { modal } from '../components/GlobalModal';
import { eventBus } from '../libs/event-bus';
import { globalVars } from '../libs/global-vars';
import { tmapInterface } from '../libs/tmap-interface';
import { appActions, store } from '../store';
import { DisplayInfo } from '../types/app';

import { voiceManager } from './voice';
import { evManager } from './ev';

const dispatch = store.dispatch;

const appManager = {
  initialize() {
    // resize
    eventBus.on('nativeCallback.onGetDisplayInfo', appManager.onGetDisplayInfo);
    window.addEventListener('resize', () => tmapInterface.getDisplayInfo());
    tmapInterface.getDisplayInfo();

    voiceManager.initialize();
  },
  setSystemError(systemError: any) {
    const prevErrorState = store.getState().app.systemError;
    if (!prevErrorState) {
      dispatch(appActions.setSystemError(systemError));
      modal.alert(() => appManager.closeWebview(), systemError.message);
    }
  },
  // AND 셀럽은 onBackKeyPressed 호출시 뒤로가기가 작동하기 때문에 웹뷰가 종료될떄까지 반복호출한다.
  closeWebview() {
    tmapInterface.onBackKeyPressed();
    if (globalVars.isAndroid) setTimeout(() => appManager.closeWebview(), 1);
  },
  setGlobalCover(bln: boolean) {
    dispatch(appActions.setGlobalCover(bln));
  },
  globalLoadingReset() {
    dispatch(appActions.globalLoadingReset());
  },
  globalLoadingInc() {
    dispatch(appActions.globalLoadingInc());
  },
  globalLoadingDec() {
    dispatch(appActions.globalLoadingDec());
  },
  onGetDisplayInfo(displayInfo: DisplayInfo) {
    const body = document.querySelector('body');
    if (displayInfo.orientation === 2) body?.classList.add('landscape');
    else body?.classList.remove('landscape');
  },
};

export { appManager, voiceManager, evManager };
