/* eslint-disable */

import axios from 'axios';
import moment from "moment";
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { globalVars } from "../libs/global-vars";
import { store } from '../store';

let instance = axios.create({
  baseURL: `${process.env.REACT_APP_FRONTMAN_API_URL}/evcharge/mobile/api/v2`,
  headers: {
    "AccessKey": globalVars.accessKey,
    "App-Code": "tmap",
    "Using": "MAIN",
    "UserKey": "$userKey",
    "Os-Type": globalVars.osType,
    "Os-Version": globalVars.osVersion,
    "Req-Time": moment().format('YYYYMMDDHHmmss'),
    "App-Version": globalVars.appVersion,
    "Model-No": globalVars.isAndroid ? globalVars.splitDeviceModel ? globalVars.splitDeviceModel[2] : "Android" : globalVars.deviceModel
  }
})

instance.interceptors.request.use(
  function (config) {

    config.headers.AccessKey = globalVars.accessKey;

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    if ((location.pathname).substring(0,12) != "/maintenance" && error.response.status === 500) {
      globalVars.history.replace(`/maintenance?url=${error.config.url}&method=${error.config.method}&data=${error.config.data}`)
    }
    return Promise.reject(error);
  }
)

export default instance;