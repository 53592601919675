/* eslint-disable */

import qs from 'qs';

export interface AppSchemeParams {
  pageid?: string;
  productid?: string;
  pageId?: string;
  productId?: string;
  extra?: ExtraParams;
}
export interface ExtraParams {
  serviceid?: string;
  servicetype?: string;
  payload?: payLoadParams;
  serviceId?: string;
  serviceType?: string;
  payLoad?: payLoadParams;
}

export interface payLoadParams {
  pkey?: string;
  pKey?: string;
  lat?: string;
  Lat?: string;
  lon?: string;
  Lon?: string;
  poiid?: string;
  poiId?: string;
  cardNumber?: string;
  activation?: string;
}
export interface QueryData extends AppSchemeParams {
  ak?: string;
  guideType?: string;
  [propsName: string]: any;
}

// IOS에서 pageid와 productI가 camelCase로 오는 경우가 있음
const queryData: QueryData = qs.parse(window.location.search.replace(/^\?/, '')) as QueryData;
queryData.pageid = queryData.pageid || queryData.pageId
queryData.productid = queryData.productid || queryData.productId
queryData.extra = queryData.extra ? JSON.parse(queryData.extra as string) : {}
//const payload = (queryData.extra?.payload || queryData.extra?.payLoad) ? JSON.parse((queryData.extra.payload || queryData.extra.payLoad) as string) : {}

const userAgent = navigator.userAgent;
const accessKey = queryData.ak || localStorage.getItem('ak');
// const accessKey = 'ABG0740_076C7A9C89AF69D9E063106A1BACED0AS';
const fuelType = '';
const isIOS = /ipad|iphone/i.test(userAgent);
const isAndroid = /android/i.test(userAgent);
const isInApp = /\/tmap\//i.test(userAgent);
const isChrome = /chrome/i.test(userAgent);
const isMozilla = /mozilla/i.test(userAgent);
const isIphone = /iphone/i.test(userAgent);
const isIpad = /ipad/i.test(userAgent);
const isSamsung = /sm-/i.test(userAgent);
const isLGE = /lm-|lg-|lgm-/i.test(userAgent);
const isLGLM = /lm-/i.test(userAgent);
const isLGLG = /lg-/i.test(userAgent);
const isLGLGM = /lgm-/i.test(userAgent);

const connCheckLoop:any = null;
const authStatusCheckLoop: any = null;
const chargeStatusCheckLoop: any = null;
const progressStatusCheckLoop: any = null;
const chargeStopCheckLoop: any = null;
const payStatusCheckLoop: any = null;
const pinCheckLoop: any = null;
const finishStatusCheckLoop: any = null;
const history: any = null;

const remoteYN:string = '';

const cardUrl:string = '';
const cardBg:string = '';

// app version 추출
let appVersion;

//pageCount 전역변수 추가
let pageCount = 0;

if (isInApp && isIOS) appVersion = userAgent.replace(/.*\/ios\/([\d.]*).*/i, '$1');
else if (isInApp && isAndroid) appVersion = userAgent.replace(/.*\/android\/([\d.]*).*/i, '$1');
else appVersion = process.env.REACT_APP_TMAP_APP_VERSION || '8.5.0';

let browserVersion;
if (isChrome && isAndroid) browserVersion = userAgent.replace(/.*chrome\/([\d.]*).*/i, '$1');
else if (isMozilla && isIOS) browserVersion = userAgent.replace(/.*mozilla\/([\d.]*).*/i, '$1');
else browserVersion = '';

let osVersion;
if (isInApp && isIOS) osVersion = userAgent.replace(/.*iphone os ([\d_]*).*/i, '$1');
else if (isInApp && isAndroid) osVersion = userAgent.replace(/.*android ([\d.]*).*/i, '$1');
else osVersion = '';

let osName;
if (isInApp && isIOS) osName = 'iOS'
else if (isInApp && isAndroid) osName = 'Android'
else osName = 'ETC';

let osType;
if (isInApp && isIOS) osType = 'IOS'
else if (isInApp && isAndroid) osType = 'AND'
else osType = "ETC";

let deviceModel;
let splitDeviceModel;
if (isInApp && isIphone) deviceModel = 'Iphone';
else if (isInApp && isIpad) deviceModel = 'Ipad';
else if (isInApp && isAndroid) {
  deviceModel = userAgent.replace(/([\w]*)build.*/i, '$1');
  splitDeviceModel = deviceModel.split(";");
  //deviceModel = deviceModel.split(";");
}
// else if (isInApp && isAndroid && isSamsung) deviceModel = 'SM-' + userAgent.replace(/.*sm-([\w]*).*/i, '$1');
// else if (isInApp && isAndroid && isLGLG) deviceModel = 'LG-' + userAgent.replace(/.*lg-([\w]*).*/i, '$1');
// else if (isInApp && isAndroid && isLGLGM) deviceModel = 'LGM-' + userAgent.replace(/.*lgm-([\w]*).*/i, '$1');
// else if (isInApp && isAndroid && isLGLM) deviceModel = 'LM-' + userAgent.replace(/.*lm-([\w]*).*/i, '$1');
else deviceModel = '';

let connectionType;
if (isInApp && isIOS) connectionType = '';
else if (isInApp && isAndroid) {
  // if (navigator.connection.type === 'wifi') connectionType = 'WIFI';
  // else 
  connectionType = 'NOT WIFI';
}
else connectionType = '';

let manufacturer;
if (isInApp && isIOS) manufacturer = 'Apple';
else if (isInApp && isAndroid && isSamsung) manufacturer = 'samsung';
else if (isInApp && isAndroid && isLGE) manufacturer = 'LGE';
else manufacturer = 'ETC';

let languageCode;
languageCode = navigator.language.replace(/.*-([\w]*).*/i, '$1');

if (queryData.ak) localStorage.setItem('ak', queryData.ak);

const userWebUrl = process.env.REACT_APP_UW_URL;
const globalVars = {
  cardUrl,
  cardBg,
  connCheckLoop,
  remoteYN,
  queryData,
  accessKey,
  fuelType,
  userAgent,
  mdn: '',
  isLocal: process.env.NODE_ENV === 'development',
  isDebug: process.env.REACT_APP_DEBUG_VIEW === 'Y',
  isInApp,
  isIOS,
  isAndroid,
  isGreenApp: /\/KU\//.test(userAgent),
  isRedApp: /\/SK\//.test(userAgent),
  appVersion,
  userWebUrl,
  userWebFaqUrl: `${userWebUrl}/m/cs/faq?ak=${accessKey}`,
  testProductEnabled: process.env.REACT_APP_DEBUG_VIEW === 'Y',
  webBuildNumber: `${process.env.REACT_APP_BUILD_ENV}_${process.env.REACT_APP_BUILD_NUMBER}`,
  browserVersion,
  osVersion,
  osName,
  osType,
  deviceModel,
  connectionType,
  manufacturer,
  languageCode,
  splitDeviceModel,
  pageCount,
  authStatusCheckLoop,
  chargeStatusCheckLoop,
  payStatusCheckLoop,
  chargeStopCheckLoop,
  progressStatusCheckLoop,
  finishStatusCheckLoop,
  pinCheckLoop,
  history
};

export { globalVars };
