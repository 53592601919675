// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { ReactElement, useEffect } from 'react';
// import { Route } from 'react-router-dom';
import { globalVars } from '../../libs/global-vars';
import { applySimulator } from './app-simulator';

const Panel = React.lazy(() => import('./Panel'));

if (globalVars.isLocal) applySimulator();

function DebugTools() {
  // render
  return <>{globalVars.isDebug && <Panel />}</>;
}

let debugRoutes: Nullable<ReactElement[]> = null;

if (globalVars.isDebug) {
  debugRoutes = [];
}

export { DebugTools, debugRoutes };
