/**-------------------------------------------------------------
1.2.4.1 EV쿠폰 메인화면_
-------------------------------------------------------------
> http://basic.pointweb.kr/charge/public/html/public/coupon.html
-------------------------------------------------------------*/

/* eslint-disable */

// 기본
import React, { useState, useEffect, useCallback, ChangeEvent } from 'react';

import { RouteComponentProps, Link } from 'react-router-dom';
import { appActions, store } from '../../store';

//헤더 
import { HandleBrowserBackButton } from '../../components/Layout'

import Const from '../../store/ev';
import { utils } from '../../libs/utils';

import btnAdd from '../../assets/images/coupon/btn_coupon_add.svg';

//Mixpanel
import { logbox, mixpanel } from '../../libs/logbox-mixpanel';
import { getUserStatus } from '../../apis/status';
import { getCoupon, patchCoupon, patchUseCoupon } from '../../apis/coupon';
import { modal } from '../../components/GlobalModal';

let scrollCache = 0;
let userData: any = {}

function RateCoupon(props: RouteComponentProps<{ id: string }>) {

    const [inited, setInited] = useState(false);

    const [noValidCoupon, setNoValidCoupon] = useState(false); //couponStatus
    const [useCouponToggle, setUseCouponToggle] = useState(true); // 자동사용 토글 T/F
    const [firstClick, setFirstClick] = useState(false);

    const [couponList, setCouponList] = useState<any>([]); //쿠폰리스트(정률,포인트)
    const [couponChkIdx, setCouponChkIdx] = useState<number>();

    const [pressed, setPressed] = useState({
        foucusCoupon: false,
        couponIdx: "",
        couponAdd: false
    });

    const [chargingGuid, setChargingGuid] = useState(false);
    const [userStatusCheck, setUserStatusCheck] = useState(false);

    const cacheScroll = useCallback(() => {
        // 간헐적으로 상세로 이동 후 호출되는 경우가 있어서 예외처리
        if (!/\/voice\/?$/.test(window.location.pathname)) return;
        scrollCache = window.scrollY;
    }, []);

    useEffect(() => {
        mixpanel("evcharge:/evcharge/discountcoupon");
        logbox("/evcharge/discountcoupon", "");
    }, []);

    useEffect(() => {
        window.scrollTo(0, scrollCache);
    }, [inited]);

    useEffect(() => {
        window.addEventListener('scroll', cacheScroll);
        return () => window.removeEventListener('scroll', cacheScroll);
    }, [cacheScroll]);

    useEffect(() => {
        const validCoupon = couponList.filter(x => x.couponStatus === 1 || x.couponStatus === 2);

        if (validCoupon.length === 0) {
            setNoValidCoupon(true);
        }
    }, [couponList]);

    useEffect(() => {
        if (userStatusCheck) {
            getCoupon()
                .then((response: any) => {
                    setInited(true);
                    const list = response.data;
    
                    const chkCoupon = list.filter(x => x.couponType === 2 && x.userCheckYn);
                    const rateList = list.filter(x => x.couponType === 2);
    
                    const rateToggleInfo = response.rateCouponToggleYn ? response.rateCouponToggleYn : false
    
                    setCouponChkIdx((chargingGuid || chkCoupon.length === 0) ? rateList[0].couponIdx : chkCoupon[0].couponIdx);
    
                    if (rateToggleInfo) {
                        setUseCouponToggle(true);
                    } else {
                        setUseCouponToggle(false);
                    }
    
                    if (list !== null) {
                        // var rateList = list.filter(x => x.couponType === 2);
                        setCouponList(
                            (chargingGuid || chkCoupon.length === 0) ?
                                rateList.filter(x => x[0]).concat(rateList.filter(x => !x[0]))
                                :
                                rateList.filter(x => x.userCheckYn).concat(rateList.filter(x => x.userCheckYn !== true))
                        );
                    }
    
    
                }).catch((response) => {
                    console.log('Error!', response)
                });
        }
    }, [userStatusCheck]);

    const addEvCoupon = () => {
        mixpanel("evcharge:/evcharge/discountcoupon:tap.addcoupon");
        logbox("/evcharge/discountcoupon", "tap.addcoupon");
        props.history.push(`/couponRegister/${"2"}`);
    };

    const goBack = () => {
        mixpanel("evcharge:/evcharge/discountcoupon:tap.back");
        logbox("/evcharge/discountcoupon", "tap.back");

        getUserStatus()
            .then(function (response: any) {
                if (response.result) {
                    userData = response;

                    if (Const.DBG_OK) {
                        // console.log('mainStart')
                        alert('mainStart - userData : ' + userData.result)
                    }

                    if (userData.result) {
                        if (userData.unpaidGuid !== null) {
                            // TODO : 미납금있을경우
                            store.dispatch(appActions.setGuid(userData.unpaidGuid));
                            props.history.replace("/nonPay");    // 미납금 페이지 이동
                        } else {
                            props.history.replace("/payMethod");
                        }
                    }
                }
            })
            .catch(function (error: any) {
                if (Const.DBG_OK) {
                    alert('사용자조회실패')
                }
            });
    }

    useEffect(() => {
        chkUserStatus((res) => {
            if (res) {
                setChargingGuid(true);
            }
        })
    }, []);

    //충전건 있는지 확인하는 함수
    const chkUserStatus = (callback) => {
        getUserStatus()
            .then((response: any) => {
                if (response.result) {
                    if (response.chargeSessionGuid) {
                        setChargingGuid(true);
                        setUserStatusCheck(true);
                        callback(true);
                    } else {
                        console.log(response);
                        setUserStatusCheck(true);
                        callback(false);
                    }
                }
            })
    }

    // 토글 업데이트
    function chgToggleCheck(event: any) {
        const toggleYn = event.target.checked;

        chkUserStatus((res) => {
            if (!res) {
                mixpanel("evcharge:/evcharge/discountcoupon:tap.autouse");
                logbox("/evcharge/discountcoupon", "tap.autouse");

                if (toggleYn) {
                    setUseCouponToggle(true);
                } else {
                    setUseCouponToggle(false);
                }

                var toggleBody = {
                    autoUseType: 2,
                    toggleYn: toggleYn
                }

                patchCoupon(toggleBody)
                    .then(function (response: any) {
                        if (Const.DBG_OK) {
                            alert("쿠폰 자동 사용 체크 - " + JSON.stringify(response.data))
                        }
                    })
                    .catch(function (error: any) {
                        console.log(error);
                    });
            } else {
                modal.alert(() => {
                }, "쿠폰 변경이 불가합니다.", "충전이 시작되면 <br/>쿠폰은 변경할 수 없습니다.<br/>충전이 종료되고 다시 시도해주세요.");
            }
        })
    }

    //체크박스 업데이트
    function chgCouponCheck(couponIdx, couponType) {
        if (!firstClick) {

            chkUserStatus((res) => {
                if (!res) {
                    var couponBody = {
                        checkYn: true
                    }

                    patchUseCoupon(couponIdx, couponBody)
                        .then(function (response: any) {
                            setFirstClick(false);
                            setCouponChkIdx(couponIdx);
                            if (Const.DBG_OK) {
                                alert("쿠폰 체크 - " + JSON.stringify(response));
                            }
                        })
                        .catch(function (error: any) {
                            setFirstClick(false);
                            console.log(error);
                        });
                } else {
                    modal.alert(() => {
                        setFirstClick(false);
                    }, "쿠폰 변경이 불가합니다.", "충전이 시작되면 <br/>쿠폰은 변경할 수 없습니다.<br/>충전이 종료되고 다시 시도해주세요.");
                }
            });
        } else {
            return;
        }
    }

    if (!inited) {
        return <></>
    } else {
        return (
            <>
                <HandleBrowserBackButton />
                <div className="coupon-top">
                    <button type="button" onClick={goBack} />
                    <span className="header-title">할인 쿠폰</span>
                </div>
                <div className="coupon-contents">
                    <div className="coupon-section">
                        <div className="coupon-auto">
                            <h3>쿠폰 자동 사용</h3>
                            <label className="toggle-control" htmlFor="toggle">
                                <input
                                    type="checkbox"
                                    className="checked"
                                    id="toggle"
                                    checked={useCouponToggle}
                                    onClick={chgToggleCheck}
                                />
                                <span className="control"></span>
                            </label>
                        </div>
                        {
                            couponList.map((x, index) => {
                                return (
                                    (x.couponStatus !== 3)
                                        ?
                                        <div className='coupon-list'
                                            style=
                                            {x.couponImgNo === 5 ?
                                                (pressed.foucusCoupon && x.couponIdx === pressed.couponIdx ? { background: "#F4FCFE", filter: "brightness(90%)" } : { background: "#F4FCFE" })
                                                :
                                                (pressed.foucusCoupon && x.couponIdx === pressed.couponIdx ? { background: "var(--gray-color-wb-transparent-300, rgba(0, 0, 0, 0.10))" } : { background: "#FFF" })
                                            }
                                            onClick={() => {
                                                if (useCouponToggle) {
                                                    setFirstClick(true);
                                                    chgCouponCheck(x.couponIdx, x.couponType);
                                                }
                                            }}
                                            onTouchStart={() => !useCouponToggle ? "" : setPressed({ ...pressed, foucusCoupon: true, couponIdx: x.couponIdx })}
                                            onTouchEnd={() => setPressed({ ...pressed, foucusCoupon: false })}
                                        >
                                            <div className='seperate'>
                                                {
                                                    x.couponImgNo === 5 ? //(정률 everon)
                                                        <img src={require('%/images/coupon/coupon-everon.svg').default} alt="" />
                                                        :
                                                        <img src={require('%/images/coupon/coupon-tmap.svg').default} alt="" />
                                                }
                                                <div className='coupon-info-section'>
                                                    <span>{x.couponHeadName} {x.couponName}</span><br />
                                                    <span className='coupon-point'>{utils.comma(x.discountAmount)}%</span><br />
                                                    <span className='coupon-history-section'>
                                                        {(x.couponAvailableEndDt).substring(0, 10).replaceAll('-', '.')} 까지 <br />
                                                        {/* <span>{"내역 보기 >"}</span> */}
                                                    </span>
                                                </div>
                                            </div>
                                            {
                                                useCouponToggle === false //toggle YN여부
                                                    ?
                                                    <>
                                                        <div className="coupon-checkbox-off" style={{ padding: "20px 30px" }}>
                                                            <input type="checkbox" id={x.couponIdx} disabled={useCouponToggle === false} checked={false} />
                                                            <label htmlFor={x.couponIdx}>
                                                            </label>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="coupon-checkbox" style={{ padding: "20px 30px" }}>
                                                            <input type="checkbox" id={x.couponIdx} checked={(couponChkIdx === x.couponIdx) ? true : false} />
                                                            <label htmlFor={x.couponIdx}>
                                                            </label>
                                                        </div>
                                                    </>
                                            }
                                        </div>
                                        :
                                        <></>
                                )
                            })
                        }
                        <div className="coupon-add-btn">
                            <button type="button"
                                onClick={addEvCoupon}
                                onTouchStart={() => setPressed({ ...pressed, couponAdd: true })}
                                onTouchEnd={() => setPressed({ ...pressed, couponAdd: false })}
                                style={pressed.couponAdd ? { background: 'var(--gray-color-wb-transparent-300, rgba(0, 0, 0, 0.10))' } : {}}
                            > 쿠폰 등록하기</button>
                        </div>
                    </div>
                    <div className="coupon-guide-bottom">
                        <div className="guide-list">
                            <img src={require('%/images/common/ico_info_dot.svg').default} alt="" />
                            <p>쿠폰은 아래의 유형을 지닌 상품권(Voucher)을 뜻합니다.<br/>(회사에서 마케팅 및 프로모션 목적으로 지급되는 할인, 자동차 제조사 및 충전사업자 등 사업협력을 통해 마련된 충전 상품)</p>
                        </div>
                        <div className="guide-list">
                            <img src={require('%/images/common/ico_info_dot.svg').default} alt="" />
                            <p>쿠폰은 일정한 유효기간을 지니며, 해당 유효기간이 경과되면 관련 쿠폰 사용이 불가합니다.</p>
                        </div>
                        <div className="guide-list">
                            <img src={require('%/images/common/ico_info_dot.svg').default} alt="" />
                            <p>쿠폰 금액은 유효기간과 상관없이 충전권 포인트 또는 현금으로 환불되지 않습니다.</p>
                        </div>
                        <div className="guide-list">
                            <img src={require('%/images/common/ico_info_dot.svg').default} alt="" />
                            <p>통신사 또는 전화번호 변경 시, 등록된 쿠폰은 소멸됩니다.<br/>쿠폰 등록 전 T 아이디로 전환해주세요.</p>
                        </div>
                        <div className="guide-list">
                            <img src={require('%/images/common/ico_info_dot.svg').default} alt="" />
                            <p>쿠폰은 종류에 따라 충전 거래에 1개씩만 사용 가능합니다.<br />(충전이 시작되면 해당 쿠폰은 다음 충전에 사용이 불가합니다.)</p>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export { RateCoupon };